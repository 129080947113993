import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import ConditionalRender from "@components/FormEntityQuestionnaire/ConditionalRender";
import { EMAIL_VALIDATION } from "@components/FormEntityQuestionnaire/constants/validations";
import { FIELDS_CONFIG } from "@components/FormEntityQuestionnaire/fields-config";
import { getDefaultValues } from "@components/FormEntityQuestionnaire/utils";
import { AppStateContext } from "@core/AppContextProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, SvgIcon, Typography, Link, Dialog, DialogTitle, IconButton, DialogContent } from "@material-ui/core";
import { whatsAppLogo, WrapperButton } from "@product-site-frontend/shared";
import { Link as GatsbyLink } from "gatsby";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import useFetch from "use-http";
import * as yup from "yup";

FormIP.propTypes = {
  activeLegalEntity: PropTypes.object,
  activeQuestionnaireId: PropTypes.number,
  data: PropTypes.object,
  nameCompany: PropTypes.string,
  setFieldsForm: PropTypes.func,
};

export default function FormIP({ activeLegalEntity, activeQuestionnaireId, data, nameCompany, setFieldsForm }) {
  const intl = useIntl();

  const { leasingManager } = useContext(AppStateContext);

  const styles = useMemo(() => ({
    wrapper: {
      background: '#FFFFFF',
      padding: {
        xs: '26px 16px 26px',
        lg: '56px 96px 72px',
      },
      marginBottom: '24px',
      boxShadow: '0px 2px 8px rgba(47, 45, 40, 0.1)',
      borderRadius: '16px',
    },
    wrapperTitle: {
      display: 'flex',
      justifyContent: "space-between",
      flexWrap: {xs: "wrap", lg: "nowrap" },
    },
    title: {
      fontSize: "30px !important",
      fontWeight: 900,
      color: '#0D1D32',
      marginBottom: "44px !important",
    },
    border: {
      padding: '24px',
      border: '1px solid #E7E8EA',
      borderRadius: '8px',
    },
    description: {
      color: '#6E7884',
      fontSize: '16px',
      fontWeight: 300,
      paddingBottom: '10px',
      paddingTop: '20px',
    },
    wrapperFioAndStatus: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: {
        xs: "wrap",
        lg: "nowrap"
      },
      minHeight: '48px',
    },
    descriptionMail: {
      display: {
        xs: "none",
        lg: 'block'
      },
      color: '#6E7884',
      fontSize: '16px',
      fontWeight: 300,
      paddingLeft: '20px',
      paddingTop: '32px'
    },
    wrapperFields: {
      '&.is_accept_privacy_policy': {
        '& .MuiFormHelperText-root': {
          position: 'absolute',
          width: '462px',
          top: '40px',
        },
      }
    },
    field: {
      '& .MuiFilledInput-root': {
        border: '1px solid #F3F4F5',
        background: '#F3F4F5',
        borderRadius: '8px',
        color: '#6E7884',
      },
      '& .MuiInputLabel-root': {
        color: '#6E7884',
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '1.7990em',
      },
      '&:hover .MuiFilledInput-root': {
        background: '#EEEFF1',
      },
      '&:hover .MuiInputLabel-root': {
        color: '#6E7884',
      },
      '& .MuiFilledInput-root.Mui-focused': {
        border: '1px solid #B6BBC2',
        background: '#ffffff',
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: '#6E7884'
      },
      '& .Mui-focused .MuiInputBase-input': {
        color: '#0D1D32',
      },
      '& .MuiInputBase-input::placeholder': {
        color: '#B6BBC2 !important',
      },
      '& .MuiFilledInput-root.Mui-error': {
        background: '#FFEEED',
      },
      '& .MuiInputLabel-root.Mui-error': {
        color: '#FF5550',
      },
      '&.valid .MuiFilledInput-root': {
        background: '#F0F9EA',
        color: '#0D1D32',
      },
      '& .MuiSelect-root': {
        paddingRight: 0
      },
      '&.trademark': {
        width: {
          xs: '100%',
          lg: 'calc(50% - 8px)',
        },
        marginRight: '16px',
      },
      '&.employees_count': {
        width: {
          xs: '100%',
          lg: 'calc(50% - 8px)',
        },
      },
      '& .Mui-disabled': {
        background: '#f5f6f7',
        color: '#b7bbc1',
        borderRadius: '8px',

        '& fieldset': {
          borderColor: '#f5f6f7 !important',
        },

        '& input': {
          paddingTop: '25px',
          paddingRight: 0,
          paddingBottom: '8px',
          paddingLeft: '12px',
        },

        '& svg': {
          display: 'none',
        },
      },
      '& .MuiInputLabel-root.Mui-disabled': {
        transform: 'translate(12px, 4px) scale(0.75)',
      },
    },
    finishWrapper: {
      display: 'flex',
      flexWrap: { xs: 'wrap', lg: 'nowrap' },
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    mark: {
      color: '#fff',
      height: '34px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '20px',
      paddingLeft: '5px',
      paddingRight: '12px',
      fontSize: '14px',
      fontWeight: 500,
      minWidth: '190px',
      marginTop: {
        xs: '10px',
        lg: 0
      },

      '&.verification_requested': {
        background: "#f2f9ed",
        color: '#67BF3D',
        paddingLeft: '12px',
        cursor: 'pointer',
      },
      '&.no_verification': {
        background: "#B6BBC2",
      }
    },
    modal: {
      '& .MuiDialog-paper': {
        width: '596px',
        boxShadow: '0px 20px 64px rgba(47, 45, 40, 0.12)',
        borderRadius: '16px',
      }
    },
  }), []);

  const FIELDS_GENERAL = [
    'taxation_type',
    'description',
    'trademark',
    'employees_count',
    'site',
  ];

  const FIELDS_MANAGEMENT = [
    'ceo_email',
  ];

  const FIELDS_PERSON_FOR_IP = [
    "ceo_marital_status",
    "is_business_partner_exists",
    "attorney_name",
    "beneficiary_name",
  ];

  const FIELDS_CONTACTS = [
    'legal_address',
    'is_same_actual_address',
    'actual_address',
    'is_other_business_points_exists',
  ];

  const FIELDS_AGREEMENT = [
    'entity_money_freeze',
    'money_freeze',
    'money_freeze_judge',
    'ipdl',
  ]

  const FIELDS_PRIVACY_POLICY = [
    'is_accept_privacy_policy',
  ]

  const FIELDS_GENERAL_ORDERS = FIELDS_GENERAL.map(name => FIELDS_CONFIG[name]);
  const FIELDS_MANAGEMENT_ORDERS = FIELDS_MANAGEMENT.map(name => FIELDS_CONFIG[name]);
  const FIELDS_PERSON_FOR_IP_ORDERS = FIELDS_PERSON_FOR_IP.map(name => FIELDS_CONFIG[name]);
  const FIELDS_CONTACTS_ORDERS = FIELDS_CONTACTS.map(name => FIELDS_CONFIG[name]);
  const FIELDS_AGREEMENT_ORDERS = FIELDS_AGREEMENT.map(name => FIELDS_CONFIG[name]);
  const FIELDS_PRIVACY_POLICY_ORDERS = FIELDS_PRIVACY_POLICY.map(name => FIELDS_CONFIG[name]);

  const FIELDS_TO_SCHEMA = {
    ...FIELDS_GENERAL_ORDERS.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.validation }), {}),
    ...FIELDS_MANAGEMENT_ORDERS.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.validation }), {}),
    ...FIELDS_PERSON_FOR_IP_ORDERS.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.validation }), {}),
    ...FIELDS_CONTACTS_ORDERS.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.validation }), {}),
    ...FIELDS_AGREEMENT_ORDERS.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.validation }), {}),
    ...FIELDS_PRIVACY_POLICY_ORDERS.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.validation }), {}),
  };

  const SCHEMA = yup.object().shape(FIELDS_TO_SCHEMA);

  const [ OWNER ] = useState(activeLegalEntity?.legalName || activeLegalEntity.dadata?.name?.full);

  const {
    clearErrors,
    control,
    formState: {
      errors,
    },
    handleSubmit,
    setError,
    setFocus,
    watch
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(SCHEMA),
    defaultValues: getDefaultValues({
      activeLegalEntity,
      defaultValues: data,
      stepFields: [
        ...FIELDS_GENERAL_ORDERS,
        ...FIELDS_MANAGEMENT_ORDERS,
        ...FIELDS_PERSON_FOR_IP_ORDERS,
        ...FIELDS_CONTACTS_ORDERS,
        ...FIELDS_AGREEMENT_ORDERS,
        ...FIELDS_PRIVACY_POLICY_ORDERS,
      ]
    }),
  });

  const { loading: loadingComplete, patch, post: postComplete, response: responseComplete } = useFetch(`/entity_questionnaires/${activeQuestionnaireId}`);

  async function saveFieldValue(variables) {
    await patch({ data: variables });
  }

  const onSubmit = async formData => {
    if (!verificationRequested) {
      setError(`ceo_email`, {
        type: "manual",
        message: "Отправьте верификацию"
      });

      setShowBlockVerification(true);

      setTimeout(() => {
        setFocus("ceo_email");
      }, 200);
    } else {
      if (!navigator.onLine) {
        setModalErrorComplitedQuestionnaire(true)
      } else {
        patch({
          data: { ...formData }
        });

        await postComplete('/complete');

        if (responseComplete.ok) {
          setModalComplitedQuestionnaire(true)
        } else {
          setModalErrorComplitedQuestionnaire(true)
        }
      }
    }
  }

  const [ ceo_email ] = watch(["ceo_email"]);

  const [ disableVerificationSending, setDisableVerificationSending ] = useState(true);

  const onToggleDisableVerificationSending = useCallback(() => {
    if (EMAIL_VALIDATION.isValidSync(ceo_email) === false) {
      setError(`ceo_email`, {
        type: "manual",
        message:  "Проверьте адрес электронной почты"
      });
    }

    let validEmail = EMAIL_VALIDATION.required().isValidSync(ceo_email);

    setDisableVerificationSending(validEmail === false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ceo_email]);

  useEffect(() => {
    onToggleDisableVerificationSending();

    let arr = [];
    activeLegalEntity?.dadata?.okveds.forEach(el => {
      if (el.main) {
        arr.push(el.name)
      }
    });

    saveFieldValue({
      "type_legal_status": 'ip',
      "ceo_name": OWNER || 'Не удалось определить руководителя',
      "okveds": arr?.join(', ') || '',
      "ceo_name_ip": activeLegalEntity.dadata?.name?.full || '',
    });

    let allFields = watch();
    setFieldsForm(allFields);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errors?.ceo_email) {
      setShowBlockVerification(true);

      setTimeout(() => {
        setFocus("ceo_email");
      }, 200);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    const subscription = watch((value) => setFieldsForm(value))
    return () => subscription.unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    onToggleDisableVerificationSending();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ceo_email]);

  const [showBlockVerification, setShowBlockVerification] = useState(false);

  const toggleShowBlock = () => {
    setShowBlockVerification(!showBlockVerification)
  };

  const onChangeField = () => {
    if (!navigator.onLine) {
      setModalErrorComplitedQuestionnaire(true)
    }
  }

  const { loading: loadingVerification, post: postVerification, response } = useFetch(`/contacts/start_verification`);

  const [ verificationRequested, setVerificationRequested ] = useState(data?.verification_status === 'VERIFICATION_REQUESTED');

  async function sendVerification() {
    if (!navigator.onLine) {
      setModalErrorComplitedQuestionnaire(true)
    } else {
      await postVerification({
        first_name: OWNER.split(' ')[1] || null,
        middle_name: OWNER.split(' ')[2] || null,
        last_name: OWNER.split(' ')[0] || null,
        email: ceo_email,
        position: "ceo",
        entity_id: activeLegalEntity.id,
      });

      if (response.ok) {
        setModalSendVerified(true);
        setVerificationRequested(true);

        clearErrors('ceo_email');

        // eslint-disable-next-line
        saveFieldValue({ ["verification_status"]: 'VERIFICATION_REQUESTED' });
      } else if (response.status === 422) {
        setModalErrorVerified(true)
      } else {
        setModalError(true)
      }
    }
  }

  const [modalErrorVerified, setModalErrorVerified] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalSendVerified, setModalSendVerified] = useState(false);
  const [modalComplitedQuestionnaire, setModalComplitedQuestionnaire] = useState(false);
  const [modalErrorComplitedQuestionnaire, setModalErrorComplitedQuestionnaire] = useState(false);

  const handleCloseDialog = (type) => {
    switch(type) {
      case 'exit_questionnaire':
        setModalComplitedQuestionnaire(false);
        window.location.replace("/");

        break;
      case 'complited_questionnaire':
        setModalComplitedQuestionnaire(!modalComplitedQuestionnaire);
        break;
      case 'error_complited_questionnaire':
        setModalErrorComplitedQuestionnaire(!modalErrorComplitedQuestionnaire);
        break;
      case 'error_verified':
        setModalErrorVerified(!modalErrorVerified);
        break;
      case 'send_verified':
        setModalSendVerified(!modalSendVerified);
        break;
      case 'error':
        setModalError(!modalError);
        break;
      default:
        setModalError(false);
        setModalErrorVerified(false);
        setModalSendVerified(false);
        setModalComplitedQuestionnaire(false);
        setModalErrorComplitedQuestionnaire(false);
    }
  };

  const showStatusVerifications = (tab, func) => {
    switch (tab) {
      case 5:
      case 'NO_VERIFICATION':
        return (
          <Box className={'no_verification'} sx={ styles.mark }>
            <SvgIcon sx={{ marginRight: "4px" }}>
              <path d="M13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8C12.5523 8 13 7.55228 13 7Z" fill="white"/>
              <path d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V11C11 10.4477 11.4477 10 12 10C12.5523 10 13 10.4477 13 11V17Z" fill="white"/>
              <path clipRule="evenodd" d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z" fill="white" fillRule="evenodd"/>
            </SvgIcon>
            Не требует подтверждения
          </Box>
        )
      case 6:
      case 'VERIFICATION_REQUESTED':
        return (
          <Box className={'verification_requested'} onClick={func} sx={ styles.mark }>
            Верификация запрошена
          </Box>
        )
      default:
        return (
          <WrapperButton colorScheme={'orangeStroke'} onClick={func} sx={styles.verifyIdentity}>
            Подтвердите личность

            <SvgIcon>
              <path d="M15.5858 11H6C5.44771 11 5 11.4477 5 12C5 12.5523 5.44771 13 6 13H15.5858L11.2929 17.2929C10.9024 17.6834 10.9024 18.3166 11.2929 18.7071C11.6834 19.0976 12.3166 19.0976 12.7071 18.7071L18.7071 12.7071C19.0976 12.3166 19.0976 11.6834 18.7071 11.2929L12.7071 5.29289C12.3166 4.90237 11.6834 4.90237 11.2929 5.29289C10.9024 5.68342 10.9024 6.31658 11.2929 6.70711L15.5858 11Z" fill="#FF780F"/>
            </SvgIcon>
          </WrapperButton>
        )
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={ styles.wrapper }>
          <Box sx={ styles.wrapperTitle }>
            <Typography sx={ styles.title }>Описание вашего бизнеса</Typography>
          </Box>

          {FIELDS_GENERAL_ORDERS.map(({ FieldComponent, componentProps, name }) => (
            <FieldComponent
              className={name}
              control={control}
              disabled={navigator?.onLine === false}
              key={name}
              name={name}
              onChange={onChangeField}
              {...componentProps}
              label={
                <FormattedMessage
                  defaultMessage={intl.formatMessage({ id: `entity.questionnaire.${name}` })}
                  id={`entity.questionnaire.ip.${name}`}
                />
              }
              sx={ styles.field }
            />
          ))}
        </Box>

        <Box sx={ styles.wrapper }>
          <Box sx={ styles.wrapperTitle }>
            <Typography sx={ styles.title }>Владелец ИП</Typography>
          </Box>


          {!OWNER ? (
            <Typography sx={{ fontSize: '22px', fontWeight: 700 }}>
              Не удалось определить владельца ИП. Для заполнения анкеты обратитесь к вашему персональному менеджеру.
            </Typography>
          ) : (
            <Box sx={styles.border}>
              <Box sx={ styles.wrapperFioAndStatus }>
                <Typography sx={{ fontSize: '22px', fontWeight: 700 }}>
                  {OWNER}
                </Typography>

                {showStatusVerifications(verificationRequested ? 'VERIFICATION_REQUESTED' : data.step, toggleShowBlock)}
              </Box>

              {showBlockVerification && (
                <Box>
                  {verificationRequested || data?.step === 6 ? (
                    <Box sx={{ background: '#f2f9ed', padding: '16px', color: '#67BF3D', fontSize: '16px', fontWeight: 300, marginBottom: '24px', borderRadius: '8px', marginTop: '8px', display: 'flex', alignItems: 'center' }}>
                      <SvgIcon sx={{ marginRight: "12px" }}>
                        <path clipRule="evenodd" d="M2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11ZM11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0ZM16.7071 9.70711C17.0976 9.31658 17.0976 8.68342 16.7071 8.29289C16.3166 7.90237 15.6834 7.90237 15.2929 8.29289L10 13.5858L6.70711 10.2929C6.31658 9.90237 5.68342 9.90237 5.29289 10.2929C4.90237 10.6834 4.90237 11.3166 5.29289 11.7071L9.29289 15.7071C9.68342 16.0976 10.3166 16.0976 10.7071 15.7071L16.7071 9.70711Z" fill="#7dbd51" fillRule="evenodd"/>
                      </SvgIcon>

                      <Box sx={{ wordBreak: 'break-all' }}>
                        На email <b>{ceo_email}</b> была оправлена ссылка с анкетой верификации, пожалуйста пройдите по ссылке и заполните анкету
                      </Box>
                    </Box>
                  ) : (
                    <Typography sx={ styles.description }>Пожалуйста, укажите почту и телефон владельца ИП. Ссылка на верификацию будет отправлена на почту.</Typography>
                  )}

                  {FIELDS_MANAGEMENT_ORDERS.map(({ FieldComponent, componentProps, name }) => {
                    if (name === 'ceo_email') {
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          <FieldComponent
                            className={name}
                            control={control}
                            key={name}
                            name={name}
                            onChange={onChangeField}
                            {...componentProps}
                            disabled={verificationRequested|| data?.step === 6 || navigator?.onLine === false}
                            label={
                              <FormattedMessage
                                defaultMessage={intl.formatMessage({ id: `entity.questionnaire.${name}` })}
                                id={`entity.questionnaire.ip.${name}`}
                              />
                            }
                            sx={{ width: {xs: "100%", lg: '50%' }, ...styles.field }}
                          />

                          <Typography sx={ styles.descriptionMail }>Сюда будет отправлена ссылка</Typography>
                        </Box>
                      )
                    } else {
                      return (
                        <FieldComponent
                          className={name}
                          control={control}
                          key={name}
                          name={name}
                          onChangeField={onChangeField}
                          {...componentProps}
                          disabled={verificationRequested|| data?.step === 6}
                          label={
                            <FormattedMessage
                              defaultMessage={intl.formatMessage({ id: `entity.questionnaire.${name}` })}
                              id={`entity.questionnaire.ip.${name}`}
                            />
                          }
                          sx={{ width: {xs: "100%", lg: '50%' }, ...styles.field }}
                        />
                      )
                    }
                  })}

                  <Box sx={{ marginTop: '39px' }}>
                    {verificationRequested || data?.step === 6 ? (
                      <>
                        <Typography sx={{color: '#6E7884', fontSize: '16px', fontWeight: '400', marginBottom: '10px' }}>В случае ошибки заполнения данных свяжитесь с нами:</Typography>

                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: {xs: 'center', lg: 'flex-start'}, gap: '12px', color: '#B6BBC2', fontSize: '20px', alignItems: 'center' }}>
                          <Link href="tel:+74951254344" rel="noreferrer" sx={{ color: '#0D1D32', fontSize: '18px', textDecoration: 'none' }} target="_blank">+7 495 125 4344</Link>
                          /
                          <Link href={`https://wa.me/+74951254344?text=Здравствуйте,%20${leasingManager?.fullName}!%20Произошла%20ошибка%20в%20данных%20при%20заполнении%20анкеты%20компании%20${nameCompany}.%20Неверно указали%20…`} rel="noreferrer" target="_blank">
                            <Box component={"img"} src={whatsAppLogo} />
                          </Link>
                          /
                          <Link href={`mailto:info@arenza.ru`} rel="noreferrer" sx={{ color: '#0D1D32', fontSize: '18px', textDecoration: 'none' }} target="_blank">info@arenza.ru</Link>
                        </Box>
                      </>
                    ) : (
                      <WrapperButton disabled={disableVerificationSending || loadingVerification || verificationRequested || navigator?.onLine === false} onClick={sendVerification}>
                        Отправить верификацию

                        <SvgIcon>
                          <path d="M15.5858 11H6C5.44771 11 5 11.4477 5 12C5 12.5523 5.44771 13 6 13H15.5858L11.2929 17.2929C10.9024 17.6834 10.9024 18.3166 11.2929 18.7071C11.6834 19.0976 12.3166 19.0976 12.7071 18.7071L18.7071 12.7071C19.0976 12.3166 19.0976 11.6834 18.7071 11.2929L12.7071 5.29289C12.3166 4.90237 11.6834 4.90237 11.2929 5.29289C10.9024 5.68342 10.9024 6.31658 11.2929 6.70711L15.5858 11Z" fill="white"/>
                        </SvgIcon>
                      </WrapperButton>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Box sx={ styles.wrapper }>
          <Box sx={ styles.wrapperTitle }>
            <Typography sx={ styles.title }>Лица, связанные с ИП</Typography>
          </Box>

          <Box sx={styles.border}>
            {FIELDS_PERSON_FOR_IP_ORDERS.map(({ FieldComponent, componentProps, name }) => (
              <FieldComponent
                className={name}
                control={control}
                disabled={navigator?.onLine === false}
                key={name}
                name={name}
                onChange={onChangeField}
                {...componentProps}
                label={
                  <FormattedMessage
                    defaultMessage={intl.formatMessage({ id: `entity.questionnaire.${name}` })}
                    id={`entity.questionnaire.ip.${name}`}
                  />
                }
                sx={ styles.field }
              />
            ))}
          </Box>
        </Box>

        <Box sx={ styles.wrapper }>
          <Box sx={ styles.wrapperTitle }>
            <Typography sx={ styles.title }>Контактная информация</Typography>
          </Box>

          {FIELDS_CONTACTS_ORDERS.map(({ FieldComponent, componentProps, name }) => {
            const Field = (
              <FieldComponent
                control={control}
                disabled={navigator?.onLine === false}
                key={name}
                name={name}
                onChange={onChangeField}
                {...componentProps}
                label={
                  <FormattedMessage
                    defaultMessage={intl.formatMessage({ id: `entity.questionnaire.${name}` })}
                    id={`entity.questionnaire.ip.${name}`}
                  />
                }
                sx={ styles.field }
              />
            );

            if (name === "actual_address") {
              return (
                <ConditionalRender
                  control={control}
                  dependsOn="is_same_actual_address"
                  key={name}
                  when={value => value === "false"}
                >
                  {Field}
                </ConditionalRender>
              );
            } else {
              return (
                <>
                  {name === "legal_address" && (
                    <Typography sx={{ fontWeight: "600", color: "#373737" }}>
                      <FormattedMessage
                        defaultMessage={intl.formatMessage({ id: `entity.questionnaire.${name}` })}
                        id={`entity.questionnaire.ip.${name}`}
                      />
                    </Typography>
                  )}

                  {Field}
                </>
              )
            }
          })}
        </Box>

        <Box sx={ styles.wrapper }>
          <Box sx={ styles.wrapperTitle }>
            <Typography sx={ styles.title }>Дополнительные сведения о юридическом лице</Typography>
          </Box>

          {FIELDS_AGREEMENT_ORDERS.map(({ FieldComponent, componentProps, name }) => (
            <>
              <Typography sx={{ fontWeight: '600', color: '#373737' }}>
                <FormattedMessage
                  defaultMessage={intl.formatMessage({ id: `entity.questionnaire.${name}` })}
                  id={`entity.questionnaire.ip.${name}`}
                />
              </Typography>

              <FieldComponent
                className={name}
                control={control}
                disabled={navigator?.onLine === false}
                key={name}
                name={name}
                onChange={onChangeField}
                {...componentProps}
                sx={ styles.field }
              />
            </>
          ))}
        </Box>

        <Box sx={ styles.finishWrapper }>
          {FIELDS_PRIVACY_POLICY_ORDERS.map(({ FieldComponent, componentProps, name }) => (
            <Box className={name} key={name} sx={{ ...styles.wrapperFields, display: 'flex', alignItems: 'center', position: 'relative', '& .MuiFormControl-root': { top: '6px' } }}>
              <FieldComponent
                className={name}
                control={control}
                disabled={navigator?.onLine === false}
                name={name}
                onChange={onChangeField}
                sx={ styles.field }
                {...componentProps}
                label={
                  <FormattedMessage
                    defaultMessage={intl.formatMessage({ id: `entity.questionnaire.${name}` })}
                    id={`entity.questionnaire.${name}`}
                  />
                }
              />

              {name === 'is_accept_privacy_policy' && (
                <Typography sx={{fontSize: '13px', fontWeight: 300, color: '#0D1D32', marginLeft: '-10px'}}>
                  Даю <GatsbyLink style={{ textDecoration: 'underline'}} to={'https://public-documents.arenza.ru/политика_обработки_персональных_данных.pdf'}>согласие на обработку персональных данных</GatsbyLink>
                </Typography>
              )}
            </Box>
          ))}

          <WrapperButton disabled={loadingComplete || !OWNER || navigator?.onLine === false} type="submit">
            Отправить

            <SvgIcon>
              <path d="M15.5858 11H6C5.44771 11 5 11.4477 5 12C5 12.5523 5.44771 13 6 13H15.5858L11.2929 17.2929C10.9024 17.6834 10.9024 18.3166 11.2929 18.7071C11.6834 19.0976 12.3166 19.0976 12.7071 18.7071L18.7071 12.7071C19.0976 12.3166 19.0976 11.6834 18.7071 11.2929L12.7071 5.29289C12.3166 4.90237 11.6834 4.90237 11.2929 5.29289C10.9024 5.68342 10.9024 6.31658 11.2929 6.70711L15.5858 11Z" fill="white"/>
            </SvgIcon>
          </WrapperButton>
        </Box>
      </form>

      {modalErrorVerified && (
        <Dialog onClose={handleCloseDialog} open sx={ styles.modal }>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
            <IconButton aria-label="close" onClick={handleCloseDialog}>
              <SvgIcon>
                <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd"/>
              </SvgIcon>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'nowrap', flexDirection: 'column', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '26px', fontWeight: 700, textAlign: 'center' }}>
                Данному пользователю уже была отправлена ссылка на верификацию.
              </Typography>

              <Typography onClick={handleCloseDialog} sx={{ fontSize: '14px', fontWeight: 500, color: '#FF780F', marginTop: '17px', marginBottom: '57px', cursor: 'pointer', '&:hover': { textDecoration: 'underline' }  }}>Вернуться к анкете</Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {modalError && (
        <Dialog onClose={handleCloseDialog} open sx={ styles.modal }>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
            <IconButton aria-label="close" onClick={handleCloseDialog}>
              <SvgIcon>
                <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd"/>
              </SvgIcon>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'nowrap', flexDirection: 'column', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '26px', fontWeight: 700, textAlign: 'center' }}>
                При подтверждении пользователя произошла ошибка
              </Typography>

              <Typography onClick={handleCloseDialog} sx={{ fontSize: '14px', fontWeight: 500, color: '#FF780F', marginTop: '17px', marginBottom: '57px', cursor: 'pointer', '&:hover': { textDecoration: 'underline' }  }}>Вернуться к анкете</Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {modalSendVerified && (
        <Dialog onClose={handleCloseDialog} open sx={ styles.modal }>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
            <IconButton aria-label="close" onClick={handleCloseDialog}>
              <SvgIcon>
                <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd"/>
              </SvgIcon>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'nowrap', flexDirection: 'column', alignItems: 'center' }}>
              <SvgIcon sx={{ height: '96px', width: '96px', marginBottom: '17px', marginTop: '4px' }} viewBox="0 0 96 96">
                <path d="M70.8284 42.8284C72.3905 41.2663 72.3905 38.7337 70.8284 37.1716C69.2663 35.6095 66.7337 35.6095 65.1716 37.1716L44 58.3431L30.8284 45.1716C29.2663 43.6095 26.7337 43.6095 25.1716 45.1716C23.6095 46.7337 23.6095 49.2663 25.1716 50.8284L41.1716 66.8284C42.7337 68.3905 45.2663 68.3905 46.8284 66.8284L70.8284 42.8284Z" fill="#67BF3D"/>
                <path clipRule="evenodd" d="M48 4C23.6995 4 4 23.6995 4 48C4 72.3005 23.6995 92 48 92C72.3005 92 92 72.3005 92 48C92 23.6995 72.3005 4 48 4ZM12 48C12 28.1177 28.1177 12 48 12C67.8823 12 84 28.1177 84 48C84 67.8823 67.8823 84 48 84C28.1177 84 12 67.8823 12 48Z" fill="#67BF3D" fillRule="evenodd"/>
              </SvgIcon>

              <Typography sx={{ fontSize: '26px', fontWeight: 700, textAlign: 'center' }}>
                На {ceo_email} отправлена ссылка на заполнение анкеты
              </Typography>

              <Typography onClick={handleCloseDialog} sx={{ fontSize: '14px', fontWeight: 500, color: '#FF780F', marginTop: '17px', marginBottom: '57px', cursor: 'pointer', '&:hover': { textDecoration: 'underline' }  }}>Вернуться к анкете</Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}


      {modalComplitedQuestionnaire && (
        <Dialog onClose={handleCloseDialog.bind(this, 'exit_questionnaire')} open sx={ styles.modal }>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
            <IconButton aria-label="close" onClick={handleCloseDialog.bind(this, 'exit_questionnaire')}>
              <SvgIcon>
                <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd"/>
              </SvgIcon>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'nowrap', flexDirection: 'column', alignItems: 'center' }}>
              <SvgIcon sx={{ height: '96px', width: '96px', marginBottom: '17px', marginTop: '4px' }} viewBox="0 0 96 96">
                <path d="M70.8284 42.8284C72.3905 41.2663 72.3905 38.7337 70.8284 37.1716C69.2663 35.6095 66.7337 35.6095 65.1716 37.1716L44 58.3431L30.8284 45.1716C29.2663 43.6095 26.7337 43.6095 25.1716 45.1716C23.6095 46.7337 23.6095 49.2663 25.1716 50.8284L41.1716 66.8284C42.7337 68.3905 45.2663 68.3905 46.8284 66.8284L70.8284 42.8284Z" fill="#67BF3D"/>
                <path clipRule="evenodd" d="M48 4C23.6995 4 4 23.6995 4 48C4 72.3005 23.6995 92 48 92C72.3005 92 92 72.3005 92 48C92 23.6995 72.3005 4 48 4ZM12 48C12 28.1177 28.1177 12 48 12C67.8823 12 84 28.1177 84 48C84 67.8823 67.8823 84 48 84C28.1177 84 12 67.8823 12 48Z" fill="#67BF3D" fillRule="evenodd"/>
              </SvgIcon>

              <Typography sx={{ fontSize: '26px', fontWeight: 700 }}>
                Анкета успешно отправлена!
              </Typography>

              <Typography onClick={handleCloseDialog.bind(this, 'exit_questionnaire')} sx={{ fontSize: '14px', fontWeight: 500, color: '#FF780F', marginTop: '17px', marginBottom: '57px', cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}>Вернуться к анкете</Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {modalErrorComplitedQuestionnaire && (
        <Dialog onClose={handleCloseDialog} open sx={{ ...styles.modal,  '& .MuiDialog-paper': { minHeight: '300px' } }}>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
            <IconButton aria-label="close" onClick={handleCloseDialog}>
              <SvgIcon>
                <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd"/>
              </SvgIcon>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'nowrap', flexDirection: 'column', alignItems: 'center' }}>
              <SvgIcon sx={{ height: '80px', width: '80px', marginBottom: '17px', marginTop: '4px' }} viewBox="0 0 24 24">
                <path clipRule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V7Z" fill="#FF5C5C" fillRule="evenodd"/>
              </SvgIcon>
              <Typography sx={{ fontSize: '26px', fontWeight: 700, textAlign: 'center' }}>
                Ошибка при завершении анкеты!
              </Typography>

              {!navigator.onLine && (
                <Typography sx={{ fontSize: '19px', fontWeight: 700, textAlign: 'center' }}>
                  Отсутствует подключение к интернету.
                </Typography>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
