import PropTypes from "prop-types";
import React from "react";

import { Button, Typography } from "@material-ui/core";
import { DialogApplicationCreate } from "@product-site-frontend/shared";

import FormApplicationRepeat from "../FormApplicationRepeat";

DialogBoxApplicationRepeat.propTypes = {
  button: PropTypes.node,
};
export default function DialogBoxApplicationRepeat({ button }) {
  const [applicationOpen, setApplicationOpen] = React.useState(false);

  function closeApplicationDialog() {
    setApplicationOpen(false);
  }

  function handleOpenButtonClick() {
    setApplicationOpen(true);
  }

  return (
    <>
      {button ? (
        React.cloneElement(button, { onClick: handleOpenButtonClick })
      ) : (
        <Button
          disableElevation
          onClick={handleOpenButtonClick}
          sx={{
            backgroundImage: "radial-gradient(circle at 44% 50%, #ff720a, #ffb63d 197%)",
            borderRadius: "24px",
            py: 1,
            textTransform: "none",
          }}
          variant="contained"
        >
          <Typography noWrap variant="inherit">
            + Создать новую заявку
          </Typography>
        </Button>
      )}
      <DialogApplicationCreate
        content={<FormApplicationRepeat />}
        keepMounted={false}
        maxWidth="sm"
        onClose={closeApplicationDialog}
        open={applicationOpen}
        title="Заявка на лизинг"
      />
    </>
  );
}
