import { useMemo } from 'react';

import { config } from '../namespaces/config';
import useActiveLegalEntity from './useActiveLegalEntity';

export const useNavTabs = (primary = 'leasing', secondary, tertiary) => {
  const {
    data: activeLegalEntityData,
    meta,
    permissions,
  } = useActiveLegalEntity();

  const list = useMemo(() => ({
    leasing: {
      '/': config.customer.nav['/'],
      applications: config.customer.nav.applications,
      ...(
        meta?.userConfirmed
          ? { deals: config.customer.nav.deals }
          : {}
      ),
      ...(
        permissions?.canViewQuestionnaire &&
          ['not_exists', 'draft']
            .includes(activeLegalEntityData?.actionStatuses?.questionnaire)
          ? { questionnaire: config.customer.nav.questionnaire }
          : {}
      ),
      ...(
        permissions?.canViewBankStatements
          ? { 'bank-statements': config.customer.nav['bank-statements'] }
          : {}
      ),
      ...(
        permissions?.canViewDocs
          ? { docs: config.customer.nav.docs }
          : {}
      ),
      ...(
        permissions?.canViewAccounting
          ? { accounting: config.customer.nav.accounting }
          : {}
      )
    },

    supplying: {
      ...(
        !activeLegalEntityData?.roles?.includes('partner')
          ? {
            application: config?.partner?.nav?.application,
            'application/questionnaire': config?.partner?.nav?.application_questionnaire,
            'application/docs': config?.partner?.nav?.application_docs,
          }
          : {}
      ),
    },

    partnership: {
      ...(
        activeLegalEntityData?.roles?.includes('partner')
          ? {
            'partnership': config?.partnership?.nav.index,
            'partnership/profile': config?.partnership?.nav.profile,
            'partnership/marketing': config?.partnership?.nav.marketing,
          }
          : {}
      ),
    },

    entity: {
      '/': {
        label: 'Основная информация',
        to: '/entity',
      },
      // verification: {
      //   label: 'Верификация',
      //   to: '/entity/verification',
      // },
    },
  }), [permissions, activeLegalEntityData, meta]);

  return useMemo(() => {
    const nav = list?.[primary];
    const result = { activeTab: 0, tabs: undefined };

    if (nav) {
      const activeTabIdx = Object.keys(nav)?.findIndex(
        (item) => {
          let path = secondary;
          if (tertiary && primary === 'supplying') {
            path += `/${tertiary}`;
          }

          if (primary === 'partnership') {
            path = `partnership/${path}`;
          }
          return item === path;
        },
      );
      if (activeTabIdx && activeTabIdx !== -1) {
        result.activeTab = activeTabIdx;
      }
      result.tabs = Object.values(nav);
    }

    return result;
  }, [primary, secondary, tertiary, list]);
};