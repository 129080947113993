import PropTypes from 'prop-types';
import React from 'react';

import { Alert, AlertTitle, Box, Typography } from '@material-ui/core';
import 'react-dropzone-uploader/dist/styles.css';

DropzoneBankStatements.propTypes = {
  getInputProps: PropTypes.func,
  getRootProps: PropTypes.func,
};
export default function DropzoneBankStatements({ getInputProps, getRootProps }) {
  return (
    <>
      <Box component="ul" sx={{ listStyle: "disc inside !important" }}>
        <Typography component="li">
          Загрузите банковские выписки <b>по основным счетам</b> вашей компании.
        </Typography>
        <Typography component="li">
          Рекомендуем вам сразу загрузить выписку <b>за 1 год от текущей даты</b>.
        </Typography>
        <Typography component="li">
          Выписка должна быть в формате <b>1С</b> (текстовый файл с расширением <b>.txt</b>).
        </Typography>
        <Alert severity="info" sx={{ my: 4 }}>
          <AlertTitle>Функция экcпорта для 1С есть в интернет-банке вашей компании</AlertTitle>
          <Typography
            color="#4a90e2"
            component="a"
            href="https://help.tinkoff.ru/turnover/get-new/txt-how/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <b>Смотреть инструкции для популярных банков</b>
          </Typography>
        </Alert>
      </Box>
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{
          px: 4,
          py: 4,
          borderWidth: 2,
          borderRadius: 2,
          borderColor: "#eeeeee",
          borderStyle: "dashed",
          backgroundColor: "white",
          cursor: "pointer",
          outline: "none",
          transition: "border .24s ease-in-out",
        }}
      >
        <input {...getInputProps()} />
        <Typography fontWeight={700}>
          + Перетащите сюда файл или{" "}
          <Typography color="#4a90e2" component="span">
            нажмите для выбора файла
          </Typography>
        </Typography>
      </Box>
      <Typography color="text.secondary" component="div" sx={{ mb: 5 }} variant="caption">
        Максимально допустимый размер файла - 50 Mb
      </Typography>
    </>
  );
}
