import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import FormattedCurrency from '@components/FormattedCurrency';
import { formatDate } from '@core/helpers';
import { Box, Typography } from '@material-ui/core';
import dayjs from "dayjs";
import { FormattedMessage } from 'react-intl';

import arrowIcon from "./img/arrow.svg"
import paidIcon from "./img/paid.svg"
import pastIcon from "./img/past.svg"
import pastBlueIcon from "./img/past_blue.svg"

function addNumbersForMonthlyPayments(records) {
  let monthlyPaymentNumber = 0;

  return records.map(record => {
    if (record.type === 'monthly' || record.type === 'client_payment') {
      monthlyPaymentNumber++;
      return { ...record, monthlyPaymentNumber };
    }
    return { ...record, monthlyPaymentNumber: null };
  });
}

TimelinePaymentSchedule.propTypes = {
  activationDate: PropTypes.string,
  data: PropTypes.array,
  nexDayPay: PropTypes.string,
};
export default function TimelinePaymentSchedule({ activationDate, data, nexDayPay }) {
  const styles = useMemo(() => ({
    container: {
      display: 'flex',
      justifyContent: "space-between",
      padding: "16px 24px",
      borderTop: "1px solid #E7E8EA",
    },
    titleTable: {
      color: "#0D1D32",
      fontSize: "14px",
      fontWeight: 700,
    },
    subTitleTable: {
      color: "#6E7884",
      fontSize: "12px",
      fontWeight: 700,
    }
  }), []);

  const items = useMemo(() => {
    if (data) {
      return addNumbersForMonthlyPayments(data);
    }
    return [];
  }, [data]);

  const timelineItems = useMemo(() => {
    return items.map((item, index) => ({
      paymentData: item,
      meta: {
        isLast: items?.length - 1 === index,
      },
    }));
  }, [items]);

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ ...styles.titleTable, width: "32px", marginRight: "16px", textAlign: "center" }}>
            №
          </Box>
          <Box>
            <Typography sx={styles.titleTable}>Дата</Typography>
            <Typography sx={styles.subTitleTable}>Тип платежа</Typography>
          </Box>
        </Box>

        <Box minWidth={120} textAlign={"right"}>
          <Typography sx={styles.titleTable}>Сумма (руб)</Typography>
          <Typography sx={styles.subTitleTable}>Оплачено / Остаток</Typography>
        </Box>
      </Box>

      {timelineItems.map(({ meta, paymentData }, index) => {
        return <TimelineItemContainer activationDate={activationDate} key={index} meta={meta} nexDayPay={nexDayPay} paymentData={paymentData} />;
      })}
    </>
  );
}

RenderPt.propTypes = {
  activationDate: PropTypes.string,
  nexDayPay: PropTypes.string,
  paying: PropTypes.object,
};
function RenderPt({ activation, nexDayPay, paying }) {
  const styles = useMemo(() => ({
    description: {
      color: '#6E7884',
      fontSize: '12px',
      fontWeight: 700,
    },
    statusPay: {
      // color: color,
      fontWeight: 700,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',

      '& img': {
        width: '12px',
      }
    }
    // eslint-disable-next-line no-use-before-define
  }), []);

  if (dayjs(activation).diff(dayjs(paying.date)) <= 0 && paying.status === null) {
    return (
      <Box sx={styles.statusPay}>
        <Box component={"img"} src={pastBlueIcon} sx={{ display: "inline", marginRight: '4px', }} />

        <Typography sx={{ ...styles.description, color: '#3D6CE7' }}>
          <FormattedMessage id={`schedule.paymentStatus.new`} />
        </Typography>
      </Box>
    )
  } else if (dayjs(paying.date).diff(dayjs(nexDayPay)) > 0) {
    return (
      <Box sx={styles.statusPay}>
        <FormattedCurrency hideCurrency sx={{ ...styles.description, color: '#B6BBC2' }} value={paying?.paid_amount} />
        <Typography sx={{ ...styles.description, paddingLeft: '4px', paddingRight: '4px', color: '#B6BBC2' }}>/</Typography>
        <FormattedCurrency hideCurrency sx={{ ...styles.description, color: '#B6BBC2' }} value={Math.abs(paying?.residual_amount)} />
      </Box>
    )
  } else if (paying?.paid_amount === 0) {
    return (
      <Box sx={styles.statusPay}>
        <Box component={"img"} src={pastIcon} sx={{ display: "inline", marginRight: '4px', }} />

        <Typography sx={{ ...styles.description, color: '#FF5550' }}>
          <FormattedMessage id={`schedule.paymentStatus.${paying.status}`} />
        </Typography>
      </Box>
    );
  } else if (paying?.residual_amount?.toFixed(2) >= 0) {
    return (
      <Box sx={styles.statusPay}>
        <Box component={"img"} src={paidIcon} sx={{ display: "inline", marginRight: '4px', }} />

        <Typography sx={{ ...styles.description, color: '#67BF3D' }}>
          <FormattedMessage id={`schedule.paymentStatus.${paying.status}`} />
        </Typography>
      </Box>
    )
  } else {
    if (!paying?.paid_amount && !paying?.residual_amount) return false;

    return (
      <Box sx={styles.statusPay}>
        <FormattedCurrency hideCurrency sx={{ ...styles.description, color: '#67BF3D' }} value={paying?.paid_amount} />
        <Typography sx={{ ...styles.description, paddingLeft: '4px', paddingRight: '4px', }}>/</Typography>
        <FormattedCurrency hideCurrency sx={{ ...styles.description, color: '#FF5550' }} value={Math.abs(paying?.residual_amount)} />
      </Box>
    )
  }
}

TimelineItemContainer.propTypes = {
  activationDate: PropTypes.string,
  meta: PropTypes.shape({
    isLast: PropTypes.any,
  }),
  nexDayPay: PropTypes.string,
  paymentData: PropTypes.object,
};
function TimelineItemContainer({ activationDate, meta, nexDayPay, paymentData }) {
  const styles = useMemo(() => ({
    arrowButton: {
      margin: 0,
      display: 'inline-block',
      transition: 'all 0.3s',
      transform: 'rotate(180deg)',
      cursor: 'pointer',
    },
    subBlock: {
      background: '#F5F6F7',
      borderRadius: '8px',
      padding: '0',
      marginTop: '16px',
      maxHeight: '9999px',
      marginRight: '40px !important',
      transition: 'max-height 0.25s ease, margin 1s ease, padding 1s ease',

      '&.hidden': {
        maxHeight: '0px',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
      },

      '& > div': {
        padding: '14px 16px',

        '&:not(:last-child)': {
          borderBottom: '1px dashed rgba(13, 29, 50, 0.1)',
        }
      },
    },
    titleBlock: {
      color: '#0D1D32',
      fontSize: '14px',
      fontWeight: 700,
    },
    wrapperDescription: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    description: {
      color: '#6E7884',
      fontSize: '12px',
      fontWeight: 700,
    }
  }), []);

  const { amount, date, forfeits, monthlyPaymentNumber, status, type } = paymentData;

  function getColor() {
    if (type === 'client_payment' && dayjs(date).diff(dayjs(nexDayPay)) > 0) return "#3D6CE723";
    if (dayjs(date).diff(dayjs(nexDayPay)) > 0) return "#bdbdbd";
    if (status === "paid") return "#67BF3D";
    if (status === "past") return "#FF5550";
    if (status === "not_paid") return "#FF5550";
    // if (diffNowDays < -1) return "red";
    // if (diffNowMonth < 1) return "#E87F40";
    return "#bdbdbd";
  }

  function getColorBX() {
    if (dayjs(date).diff(dayjs(nexDayPay)) > 0) return "#fff";
    if (status === "paid") return "rgba(103, 191, 61, 0.3)";
    if (status === "past") return "rgba(255, 85, 80, 0.3)";
    if (status === "not_paid") return "rgba(255, 85, 80, 0.3)";
    // if (diffNowDays < -1) return "red";
    // if (diffNowMonth < 1) return "#E87F40";
    return '#fff';
  }

  function getFontColor() {
    if (type === 'client_payment' && dayjs(date).diff(dayjs(nexDayPay)) > 0) return "#3D6CE7";
    return '#fff';
  }

  const color = getColor();
  const colorBX = getColorBX();
  const fontColor = getFontColor();

  const renderLabel = () => {
    switch (type) {
      case 'issuing_commission':
        return "К"
      case 'advance':
        return "А"
      case 'redemption':
        return "В"
      default:
        return monthlyPaymentNumber
    }
  }

  const onToggleBlock = event => {
    const domElem = event.target;

    if (domElem?.parentElement?.parentElement?.nextSibling) {
      if (domElem?.parentElement?.parentElement?.nextSibling?.classList.contains("hidden")) {
        domElem.style.transform = 'rotate(0deg)';
      } else {
        domElem.style.transform = 'rotate(180deg)';
      }

      domElem?.parentElement?.parentElement?.nextSibling?.classList.toggle('hidden');
    }
  }

  return (
    <Box sx={{ borderTop: "1px solid #E7E8EA", padding: "16px 24px", }}>
      <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
        <Box minWidth={'calc(100% - 120px - 40px)'} sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              bgcolor: color,
              boxShadow: `0px 6px 16px ${colorBX}`,
              borderRadius: "50%",
              color: fontColor,
              fontSize: 20,
              fontWeight: 700,
              minHeight: 32,
              lineHeight: "32px",
              textAlign: "center",
              minWidth: 32,
              marginRight: "16px",
            }}
          >
            {renderLabel()}
          </Box>

          <Box>
            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{formatDate(date)}</Typography>

            <Typography color="textSecondary" component="div" sx={{ fontWeight: 700, }} variant="caption">
              <FormattedMessage id={`schedule.paymentType.${type}`} />
            </Typography>
          </Box>
        </Box>

        <Box minWidth={120} textAlign={"right"}>
          <Typography noWrap style={{ fontWeight: 700, fontSize: "14px" }}>
            <FormattedCurrency hideCurrency value={amount} />
          </Typography>
          <RenderPt activation={activationDate} nexDayPay={nexDayPay} paying={paymentData} />
        </Box>

        <Box minWidth={40} textAlign={"right"}>
          {forfeits?.length > 0 && (
            <Box component={"img"} onClick={onToggleBlock.bind(this)} src={arrowIcon} sx={styles.arrowButton} />
          )}
        </Box>
      </Box>

      {forfeits?.length > 0 && (
        <Box className={'hidden'} sx={styles.subBlock}>
          {forfeits.map((feit, index) => (
            <Box key={index}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={styles.titleBlock}>{formatDate(feit.date)}</Typography>

                <FormattedCurrency hideCurrency sx={styles.titleBlock} value={feit?.amount} />
              </Box>

              <Box sx={styles.wrapperDescription}>
                <Typography sx={styles.description}>
                  {feit.type === 'forfeit' && (!feit.sanction || !feit?.sanction?.endDay) ? (
                    "Пени"
                  ) : (
                    feit?.sanction?.startDay > 7 ? (
                      "Срок внесения платежа продлен на 14 дней"
                    ) : (
                      "Срок внесения платежа продлен на 7 дней"
                    )
                  )}
                </Typography>

                <Box sx={{ display: 'flex' }}>
                  <RenderPt activation={activationDate} paying={feit} />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
