import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";

import {
  FormFieldCheckboxWrapper,
  FormFieldAutocompleteNameWrapper,
  FormFieldRadioWrapper,
  FormFieldTextWrapper,
  FormFieldCheckboxGroupWrapper,
} from "@product-site-frontend/shared/components/FormFieldWrappers";
import _noop from "lodash.noop";
import { useController } from "react-hook-form";
import useFetch from "use-http";

import { AppStateContext } from "../../core/AppContextProvider";

const FIELD_TYPES = {
  text: FormFieldTextWrapper,
  radio: FormFieldRadioWrapper,
  checkbox: FormFieldCheckboxWrapper,
  checkboxGroup: FormFieldCheckboxGroupWrapper,
  nameAutocomplete: FormFieldAutocompleteNameWrapper,
};

FieldContainer.propTypes = {
  control: PropTypes.object,
  fieldType: PropTypes.oneOf(["text", "radio", "checkboxGroup", "checkbox", "nameAutocomplete"]),
  name: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  saveEvent: PropTypes.oneOf(["blur", "change", "none"]).isRequired,
  shouldUnregister: PropTypes.bool,
};

export default function FieldContainer({
  control,
  name,
  shouldUnregister,
  saveEvent,
  onBlur = _noop,
  onChange = _noop,
  fieldType,
  ...props
}) {
  const { activeQuestionnaireId } = useContext(AppStateContext);

  const Controller = useController({ name, control, shouldUnregister });

  const {
    field: { onBlur: _onBlur, onChange: _onChange, value },
    fieldState: { isDirty, isTouched }, //invalid
  } = Controller;

  const { patch } = useFetch(`/entity_questionnaires/${activeQuestionnaireId}`);

  async function saveFieldValue(variables) {
    await patch({ data: variables });
  }

  useEffect(() => {
    onChange(Controller);
    // if (saveEvent === "change" && !invalid && isDirty) { - не работает при переключении с дефолтного значения и обратно
    if (saveEvent === "change" && (isTouched || isDirty)) {
      saveFieldValue({ [name]: value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isTouched]);

  function handleChange(event) {
    _onChange(event);
    onChange(Controller);
  }

  function handleBlur(event) {
    _onBlur(event);
    onBlur(Controller);
    if (saveEvent === "blur" && isDirty) {
      saveFieldValue({ [name]: value });
    }
  }

  const FormField = FIELD_TYPES[fieldType];

  return (
    <FormField
      {...Controller}
      {...props}
      control={control}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
}
