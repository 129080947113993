export const INDUSTRY_TYPES = [
  { value: "retail", label: "Розничная и оптовая торговля" },
  { value: "catering", label: "Производство продуктов общественного питания" },
  { value: "industrial", label: "Промышленное оборудование" },
  { value: "raw_materials", label: "Обработка сырья" },
  { value: "restaurants", label: "Деятельность ресторанов" },
  { value: "transport", label: "Деятельность транспорта и связи" },
  { value: "medicine", label: "Медицинские услуги" },
  { value: "beauty", label: "Услуги салонов красоты, фитнес-центров" },
  { value: "building", label: "Строительство" },
  { value: "other", label: "Другое" },
];

export const ACTUAL_BUSINESS_OWNERSHIP_VALUES = [
  { label: "Менее 1 года", value: "0-1" },
  { label: "1 - 3 года", value: "1-3" },
  { label: "Более 3х лет", value: "3+" },
];

export const CUSTOMER_TYPES_VALUES = [
  { label: "Физические лица", value: "individuals" },
  { label: "Юридические лица", value: "entities" },
  { label: "Государство и бюджетные организации", value: "government" },
];

export const NATIONALITY_TYPES = [
  { label: "СССР", value: "1" },
  { label: "Россия", value: "2" },
  { label: "Беларусь", value: "3" },
];

export const YES_NO_OPTIONS = [
  { label: "Да", value: "true" },
  { label: "Нет", value: "false" },
];

export const TAX_SYSTEMS = [
  { value: "6%", label: "УСН 6%" },
  { value: "15%", label: "УСН 15%" },
  { value: "general", label: "ОСНО" },
  { value: "envd", label: "ЕНВД" },
  { value: "eshn", label: "ЕСХН" },
  { value: "trade_fee", label: "Торговый сбор" },
  { value: "patent", label: "Патентная система" },
];

export const YES_NO_ADDITIONAL_INFORMATION = [
  { label: "Отправить ссылку на заполнение (на указанный номер телефона)", value: "true" },
  { label: "Заполнить паспортные данные (самостоятельно сейчас)", value: "false" },
];

export const ACCOUNTING_VALUES = [
  { label: "В штате", value: "staff" },
  { label: "Аутсорс", value: "outsourced" },
  { label: "Ген. Директор", value: "ceo" }, // TODO: для ИП?!
];

export const MARITAL_STATUS_VALUES = [
  { label: "Не замужем/не женат", value: "single" },
  { label: "Замужем/женат", value: "married" },
];
