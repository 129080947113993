import { gql } from '@apollo/client';

export const GET_INVESTMENTS = gql`
  query GetInvestments($entityId: Number!) {
    investments(entityId: $entityId) @rest(type: "Investments", path: "/entities/{args.entityId}/investments") {
      amount
      createdAt
      endDate
      id
      interestBalance
      interestRate
      startDate
      status
      term
    }
  }
`;