const InvestContainer = {
  display: 'flex',
  flexWrap: 'wrap',
  mt: 5,
}

const InvestBox = {
  maxWidth: { lg: 360 },
  ml: 0,
  mb: 5,
  width: '100%',
}

const InvestCard = {
  boxShadow: '0 1px 50px 1px rgba(208, 208, 208, 0.5)',
  px: 4,
  py: { xs: 2, lg: 4 },
  mr: { lg: 5 },
  minHeight: 280,
}

const InvestTable = {
  mt: 4,
}

const InvestTableRow = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  mb: 2,
}

const InvestTableKey = {
  color: '#91919c',
  fontWeight: 500,
  pr: 2,
}

const InvestTableValue = {
  textAlign: 'right',
}

const InvestTableAccentValue = {
  textAlign: 'right',
  color: '#00c227',
}

export default {
  InvestContainer,
  InvestBox,
  InvestCard,
  InvestTable,
  InvestTableRow,
  InvestTableKey,
  InvestTableValue,
  InvestTableAccentValue,
}