import PropTypes from "prop-types";
import React from "react";

import { Box, Typography } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { useDropzone } from "react-dropzone";

const UploadButton = React.forwardRef(({ label, onChange, paramsDropzone, showAttachedFiles }, ref) => {
  const { acceptedFiles, getInputProps } = useDropzone({
    ...paramsDropzone,
    onDropAccepted: files => {
      onChange(files);
    },
  });

  const files = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>);

  return (
    <div>
      <input
        {...getInputProps({
          id: "application-form-upload-button",
        })}
      />
      <label htmlFor="application-form-upload-button">
        <Box
          role="button"
          sx={{
            cursor: "pointer",
            userSelect: "none",
            display: "inline-flex",
            color: "secondary.main",
            alignItems: "center",
            borderRadius: "8px",
            border: "1px dashed #CFD2D6",
            width: "100%",
            padding: "16px",
          }}
        >
          <Box mr={2} sx={{ display: 'flex', '& svg': { fill: '#6E7884' } }}>
            <AttachFileIcon />
          </Box>
          <Typography component="span" sx={{ color: '#6E7884', fontSize: '16px' }} >{label}</Typography>
        </Box>
      </label>

      {showAttachedFiles && (
        <ul>{files}</ul>
      )}
    </div>
  );
});

UploadButton.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  paramsDropzone: PropTypes.object,
  showAttachedFiles: PropTypes.bool
};

UploadButton.defaultProps = {
  label: "Прикрепить файлы",
  paramsDropzone: {
    multiple: true,
  },
  showAttachedFiles: true,
}

export default UploadButton;
