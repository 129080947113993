import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import BlockDocTypeNumberDate from '@components/BlockDocTypeNumberDate';
import FormattedCurrency from '@components/FormattedCurrency';
import { AppStateContext } from '@core/AppContextProvider';
import { formatDate } from '@core/helpers';
import _upperFirst from 'lodash.upperfirst';
import { FormattedMessage } from 'react-intl';

import { CardLeasingApplication } from '.';

LeasingApplication.propTypes = {
  amount: PropTypes.any,
  createdAt: PropTypes.any,
  id: PropTypes.any,
  loading: PropTypes.bool,
  status: PropTypes.string,
  subject: PropTypes.any,
  subjectClassificatorId: PropTypes.any,
  term: PropTypes.any,
};
export default function LeasingApplication({
  amount,
  createdAt,
  id,
  status,
  subject,
  subjectClassificatorId,
  term,
}) {
  const {
    entities: { classificators },
  } = useContext(AppStateContext);

  const amountNode = amount ? (
    <>
      <FormattedCurrency hideCurrency truncate value={amount} />
      &nbsp;руб
    </>
  ) : null;

  const statusNode = <FormattedMessage id={`application.status.${status}`} />;

  const numberDateNode = (
    <BlockDocTypeNumberDate date={formatDate(createdAt, "full")} docType="Заявка" number={id} />
  );

  const subjectNode =
    subject === "Не указано" && subjectClassificatorId
      ? classificators.data[subjectClassificatorId]?.name
      : _upperFirst(subject);

  const termNode = term ? <>{term}&nbsp;мес</> : "---";

  return (
    <CardLeasingApplication
      {...{ amountNode, numberDateNode, statusNode, subjectNode, termNode }}
    />
  );
}
