import PropTypes from 'prop-types';
import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';
import { navigate } from 'gatsby';

RouteProtected.propTypes = {
  component: PropTypes.func,
  condition: PropTypes.bool,
};
export default function RouteProtected({
  component: Component,
  condition,
  ...rest
}) {
  if (condition === undefined) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: 320,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (condition === false) {
    navigate('/');
    return 'false';
  }

  return <Component {...rest} />;
}
