const Page = {
  display: 'flex',
  flexDirection: { xs: 'column', lg: 'row' },
}

const Sidebar = {
  flexShrink: 0,
  mr: { xs: 0, md: 6, lg: 8 },
  width: { xs: '100%', lg: 300 },
}

const Content = {
  flex: 1,
  maxWidth: '100%',
}

const FinancialCard = {
  boxShadow: '0 1px 50px 1px rgba(208, 208, 208, 0.5)',
  mb: 5,
  px: { xs: 4, lg: 5 },
  py: 4,
}

const FinancialCardData = {
  display: { xs: 'flex', lg: 'block' },
  alignItems: { xs: 'center' },
  justifyContent: { xs: 'space-between' },
  mb: { xs: 2, lg: 0 },
}

const FinancialCardDataKey = {
  fontSize: 14,
  fontWeight: 500,
  color: '#91919c',
  pr: 2,
  m: 0,
}

const FinancialCardDataValue = {
  fontSize: { xs: 14, lg: 16 },
  textAlign: { xs: 'right', lg: 'inherit' },
  m: { xs: 0, lg: '0 0 16px' },
}

const ManagerCard = {
  display: { xs: 'none', lg: 'block' },
  boxShadow: '0 1px 50px 1px rgba(208, 208, 208, 0.5)',
  px: 5,
  py: 6,
}

const ManagerContact = {
  fontSize: 13,
  color: '#7b7b7b',
  textDecoration: 'none',
  transition: '300ms',
  '&:hover': {
    color: '#ff780f',
  },
}

export default {
  Page,
  Sidebar,
  Content,
  FinancialCard,
  FinancialCardData,
  FinancialCardDataKey,
  FinancialCardDataValue,
  ManagerCard,
  ManagerContact,
}