import React from "react";

import { Typography } from "@material-ui/core";

export default function PageEntities() {
  return (
    <>
      <Typography variant="h1">Компании</Typography>
      <Typography sx={{ fontSize: 32, fontWeight: 300, mt: 8 }}>TBD</Typography>
    </>
  );
}
