import { gql, useQuery } from '@apollo/client';

import useActiveLegalEntity from './useActiveLegalEntity';

const FETCH_LEASING_DEALS = gql`
  query FetchLeasingDeals($entityId: Number!, $dealId: Number!) {
    deals(entityId: $entityId, dealId: $dealId)
      @rest(type: "Deal", path: "/entities/{args.entityId}/loans") {
      id
      subject
      requestedAmount
      term
      number
      date
    }
  }
`;

export default function useLeasingDealsFetch() {
  const { activeLegalEntityId } = useActiveLegalEntity();

  const { data, error, loading } = useQuery(FETCH_LEASING_DEALS, {
    variables: { entityId: activeLegalEntityId },
    fetchPolicy: "cache-and-network",
  });

  return { deals: data?.deals, loading, error };
}
