import PropTypes from "prop-types";
import React, { useMemo, useCallback, useState } from 'react';

import FormattedCurrency from '@components/FormattedCurrency';
import {
    Box,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    Skeleton,
    Typography,
    IconButton,
    SvgIcon
} from '@material-ui/core';
import { InfoOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, FileDownloadOutlined } from '@material-ui/icons';
import { WrapperButton } from "@product-site-frontend/shared";
import dayjs from 'dayjs';
import { Link as GatsbyLink } from "gatsby";
import useFetch from 'use-http';

import TimelinePaymentSchedule from '../../namespaces/customer/components/PageLeasingDeal/TimelinePaymentScheduleChanged';

export default function NoticeElement({ data, entityId, next_payment_date }) {
    const styles = useMemo(() => ({
        noticeHeader: {
            marginBottom: '32px'
        },
        noticeWrapper: {
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%',
            padding: '24px',
            gap: '24px',
            background: '#FFF',
            marginBottom: '50px',
            borderRadius: '16px',
            fontSize: '16px',
            boxShadow: '0 1px 50px 1px rgba(208, 208, 208, 0.5)',

            '& > svg': {
                minWidth: '48px',
                width: '48px',
                height: 'auto',
                marginTop: '47px',

                '& path': {
                    fill: '#21A2FF'
                }
            }
        },
        noticeText: {
            color: '#0D1D32',
            fontSize: '20px',
            lineHeight: '30px',
            fontWeight: 400,

            '& > p': {
                margin: '0 0 16px'
            },

            '& a': {
                display: 'inline-flex',
                alignItems: 'center',
                color: '#FF780F',
                textDecoration: 'underline',
                gap: '4px'
            },
            '& span': {
                display: 'inline-flex',
                alignItems: 'center',
                gap: '4px',
                color: '#FF780F',
                textDecoration: 'underline',
                cursor: 'pointer'
            }
        },
        dropdown: {
            color: '#6E7884',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '24px',
            cursor: 'pointer',
            display: 'flex',
            gap: '8px',
            alignItems: 'center'
        },
        sheduleContainer: {
            gridArea: "schedule",
            maxWidth: {
                xs: '100%',
                md: "312px"
            }
        },
        sheduleCard: {
            borderRadius: '10px',
            boxShadow: '0px 20px 64px 0px #0D1D321A'
        },
        sheduleCardContent: {
            display: 'grid',
            gridTemplateColumns: {
                xs: '1fr 1fr',
                md: '1fr'
            },
            gap: {
                xs: '8px',
                md: 0
            },
            padding: {
                xs: '16px',
                md: '24px'
            }
        },
        sheduleItem: {
            padding: {
                xs: '8px',
                md: '8px 0'
            },
            background: {
                xs: '#F5F6F7',
                md: 'transparent'
            },
            borderRadius: {
                xs: '8px',
                md: 0
            }
        },
        sheduleItemTitle: {
            color: '#6E7884',
            fontSize: {
                xs: '12px',
                md: '16px'
            },
            lineHeight: {
                xs: '16px',
                md: '24px'
            },
            fontWeight: '300'
        },
        sheduleItemContent: {
            color: '#0D1D32',
            fontSize: {
                xs: '16px',
                md: '20px'
            },
            lineHeight: {
                xs: '24px',
                md: '32px'
            },
            fontWeight: '600'
        },
        modal: {
            '& .MuiDialog-paper': {
                maxWidth: '700px',
                boxSizing: 'border-box',
                boxShadow: '0px 20px 64px rgba(47, 45, 40, 0.12)',
                borderRadius: '16px',
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const [isOpen, setIsOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loanData, setLoanData] = useState(null);
    const [sumSchedule, setSumSchedule] = useState(null);

    const { get: getDoc, loading: loadingDoc, response: responseDoc } = useFetch(
        `${process.env.GATSBY_FRONTOFFICE_API}/entities/${entityId}/contracts/${data.contract_id}/actual_attachment`,
        { cachePolicy: 'no-cache' });

    const getLoan = useFetch(`/entities/${entityId}/loans/${data.loan_id}`);

    async function getLoanData() {
        const loanDataset = await getLoan.get();

        if (getLoan.response.ok) {
            if (loanDataset) {
                setLoanData(loanDataset);

                const activation = dayjs(loanDataset.deal_changes[0].activation_date)

                const oldSchedule = [...loanDataset.schedule];
                const dealChangesSchedule = [...loanDataset.deal_changes[0].schedule];

                const summarySchedule = [];
                const redemptionElement = oldSchedule.find((element) => (element.type === 'redemption'));

                oldSchedule.forEach((element) => {
                    if (activation.diff(element.date) > 0) {
                        summarySchedule.push(element);
                    }
                });

                dealChangesSchedule.forEach((element) => {
                    if (activation.diff(element.date) <= 0 && element.type === 'client_payment') {
                        summarySchedule.push(element);
                    }
                });

                if (redemptionElement) {
                    summarySchedule.push(redemptionElement);
                }

                setSumSchedule(summarySchedule);
            }
        } else {
            console.error('Ошибка запроса loans/id');
        }
    };

    const toggleOpen = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const toggleModal = useCallback(() => {
        if(process.env.GATSBY_ACTIVE_ENV === 'prod') {
            if (typeof window.ym !== 'undefined') {
              window.ym(98862652, 'reachGoal', 'see_new_actual_attachment')
            }
        }
        setShowModal(!showModal);

        if (!loanData && !getLoan.loading) {
            getLoanData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal, getLoan.loading, loanData]);

    const createFile = async () => {
        if (!loadingDoc) {
            await getDoc();

            if (responseDoc.ok) {
                let base64URL = responseDoc.data.actual_attachment;

                const binary = atob(base64URL.replace(/\s/g, ''));
                const len = binary.length;
                const buffer = new ArrayBuffer(len);
                const view = new Uint8Array(buffer);

                for (let i = 0; i < len; i += 1) {
                    view[i] = binary.charCodeAt(i);
                }

                const blob = new Blob([view], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);

                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style = 'display: none';
                a.href = url;
                a.download = 'Уведомление клиента об изменении условий сделки.pdf';
                a.target = '_blank';
                a.click();
            }
        }
    };

    const getNewMonthlyPaymentAmount = (schedule, activation_date) => {
        const activation = dayjs(activation_date)

        const find = schedule.find((element) => activation.diff(element.date) <= 0);

        if (!!find) {
            return find.amount;
        }

        return null;
    };

    const elModal = () => {
        if (getLoan.loading || !loanData) {
            return (
                <Box
                    sx={{
                        display: "grid",
                        gridAutoFlow: 'row dense',
                        gridTemplateAreas: {
                            xs: `"schedule"
                        "content"`,
                            md: `"content schedule"`,
                        },
                        gridTemplateColumns: {
                            xs: '100% 100%',
                            md: "2fr 1fr",
                        },
                        gap: 4,
                    }}
                >
                    <Skeleton animation="wave" width={'160px'} />
                    <Typography sx={{ fontSize: '20px', marginTop: '30px', }}>Загрузка</Typography>
                </Box>
            );
        }

        return (
            <Box
                sx={{
                    display: "grid",
                    gridAutoFlow: 'row dense',
                    gridTemplateAreas: {
                        xs: `"schedule"
                    "content"`,
                        md: `"content schedule"`,
                    },
                    gridTemplateColumns: {
                        xs: '100% 100%',
                        md: "2fr 1fr",
                    },
                    gap: 4,
                }}
            >
                <Box sx={{
                    gridArea: "content",
                    border: '1px solid #E7E8EA',
                    borderRadius: '16px',

                    '& > div:nth-child(1) ': {
                        borderTop: 'none'
                    }
                }}>
                    <TimelinePaymentSchedule activationDate={data.activation_date} data={sumSchedule} nexDayPay={next_payment_date} />
                </Box>
                <Box sx={styles.sheduleContainer}>
                    <Card sx={styles.sheduleCard}>
                        <CardContent sx={styles.sheduleCardContent}>
                            <Box sx={styles.sheduleItem}>
                                <Typography color="text.secondary" sx={styles.sheduleItemTitle} variant="body2" >
                                    Стоимость предмета лизинга
                                </Typography>
                                <Typography sx={styles.sheduleItemContent}>
                                    <FormattedCurrency
                                        hideCurrency
                                        value={loanData.requested_amount}
                                    /> руб
                                </Typography>
                            </Box>
                            <Box sx={styles.sheduleItem}>
                                <Typography color="text.secondary" sx={styles.sheduleItemTitle} variant="body2">
                                    Срок лизинга
                                </Typography>
                                <Typography sx={styles.sheduleItemContent}>
                                    {loanData.term} мес
                                </Typography>
                            </Box>
                            <Box sx={styles.sheduleItem}>
                                <Typography color="text.secondary" sx={styles.sheduleItemTitle} variant="body2">
                                    Размер аванса
                                </Typography>
                                <Typography sx={styles.sheduleItemContent}>
                                    {loanData.advance_rate + "%"}
                                </Typography>
                            </Box>
                            <Box sx={styles.sheduleItem}>
                                <Typography color="text.secondary" sx={styles.sheduleItemTitle} variant="body2">
                                    Ежемесячный платеж
                                </Typography>
                                <Typography sx={{
                                    ...styles.sheduleItemContent,
                                    color: '#B6BBC2',
                                    textDecoration: 'line-through',

                                    '& div': {
                                        color: '#B6BBC2',
                                        textDecoration: 'line-through'
                                    }
                                }}>
                                    <FormattedCurrency
                                        hideCurrency
                                        value={loanData.monthly_payment_amount}
                                    /> руб
                                </Typography>
                                <Typography sx={styles.sheduleItemContent}>
                                    {!!getNewMonthlyPaymentAmount(data.schedule, data.activation_date) && (
                                        <>
                                            <FormattedCurrency
                                                hideCurrency
                                                value={getNewMonthlyPaymentAmount(data.schedule, data.activation_date)}
                                            /> руб
                                        </>
                                    )}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        );
    };

    const elDownloadDocIcon = () => {
        if (loadingDoc) {
            return <Skeleton animation="wave" color="#FF780F" width={'16px'} />;
        }

        return <FileDownloadOutlined />;
    };

    const renderLink = () => {
        let simpleLink = data?.applied_at &&
          dayjs().diff(data.applied_at, "day") >= 0 &&
          dayjs().diff(data.applied_at, "day") <= 14 &&
          data?.activation_date &&
          dayjs().diff(data.activation_date, "day") >= 0

        if (simpleLink) {
            return <Box component={GatsbyLink} height="inherit" to={`/leasing/deals/${data.loan_id}`}>здесь</Box>
        }

        return <span onClick={toggleModal}>здесь</span>
    }

    return (
        <Box sx={styles.noticeWrapper}>
            <InfoOutlined />
            <Box>
                <Box sx={styles.noticeText}>
                    <p>
                        <b>Внимание!</b> В связи с изменением ключевой ставки Банка России, в соответствии с пунктом <b>3.5 Договора лизинга</b>, уведомляем Вас об изменении условий денежных обязательств, содержащихся в графике лизинговых платежей. Новая редакция вступает в силу с момента получения настоящего уведомления. При этом размеры платежей меняются с {dayjs(data.activation_date).format('DD.MM.YYYY')}.
                    </p>
                </Box>
                {isOpen && (
                    <Box sx={styles.noticeText}>
                        <p>
                            Добрый день!
                        </p>
                        <p>
                            Компания Аренза сообщает об изменении условий денежных обязательств, содержащихся в графике лизинговых платежей в соответствии с пунктом 3.5 Договора лизинга. Новая редакция вступает в силу с момента получения настоящего уведомления, при этом размеры платежей меняются с  {dayjs(data.activation_date).format('DD.MM.YYYY')}.
                        </p>
                        <p>
                            Новый график платежей вы можете скачать по <span onClick={createFile}>ссылке {elDownloadDocIcon()}</span> или ознакомиться с ним {renderLink()}
                        </p>
                        <p>
                            Почему изменяются условия денежных обязательств для лизингополучателей Аренза? Хотим отметить, что несмотря на многократные повышения ключевой ставки, Аренза, до этого момента, сохраняла условия для клиентов, в отличии от других лизинговых компаний, и брала на себя все связанные с этим финансовые сложности. Например, кредитующие нашу компанию банки давно увеличили ставки по ранее предоставленным кредитам, привлеченным в ходе финансирования приобретения лизингового имущества. Мы долго надеялись на снижение ключевой ставки, но, к сожалению, этого не произошло. В связи с этим Аренза вынуждена воспользоваться правом на пересмотр условий денежных обязательств по договорам лизинга.
                        </p>
                        <p>
                            На протяжении 2024 года мы наблюдаем, что большинство клиентов и партнеров Аренза, в свою очередь, также повысили цены на продукцию и услуги. Надеемся, что это нивелирует изменение условий по лизингу и поможет бизнесу продолжить развитие. В надежде на дальнейшее сотрудничество и понимание сложившихся обстоятельств, просим принять изменения размера обязательств по договору лизинга. Напоминаем, что вы всегда можете обратиться к своему менеджеру за расчетом досрочного выкупа или продления срока договора для уменьшения ежемесячных платежей.
                        </p>
                        <p>
                            *Ключевая ставка Центрального банка — это процент, под который ЦБ предоставляет кредиты коммерческим банкам и принимает у них депозиты на краткосрочной основе
                        </p>
                    </Box>
                )}
                <Box onClick={toggleOpen} sx={styles.dropdown}>
                    {isOpen ? (<>Свернуть <KeyboardArrowUpOutlined /></>) : (<>Развернуть <KeyboardArrowDownOutlined /></>)}
                </Box>
            </Box>
            {showModal && (
                <Dialog onClose={toggleModal} open={true} sx={styles.modal}>
                    <DialogTitle sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                        <Typography sx={{
                            fontWeight: 600,
                            fontSize: '26px',
                            lineHeight: '31px'
                        }}
                        >
                            Новый график платежей с {dayjs(data.activation_date).format('DD.MM.YYYY')}
                        </Typography>
                        <IconButton aria-label="close" onClick={toggleModal}>
                            <SvgIcon>
                                <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd" />
                            </SvgIcon>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {elModal()}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <WrapperButton colorScheme={'grey'} onClick={toggleModal} sx={{ margin: '24px 0 0' }}>
                                Закрыть
                            </WrapperButton>
                        </Box>
                    </DialogContent>
                </Dialog>
            )}
        </Box>
    );
};

NoticeElement.propTypes = {
    data: PropTypes.object,
    entityId: PropTypes.number,
    next_payment_date: PropTypes.string,
};