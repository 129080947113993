import React from 'react';

import useApplicationsFetch from '@hooks/useApplicationsFetch';
import { Alert, AlertTitle, Box } from '@material-ui/core';

import CardLeasingApplication from './CardLeasingApplication';
import LeasingApplication from './LeasingApplication';

export default function ListLeasingApplications() {
  const { applications, error, loading } = useApplicationsFetch();

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Ошибка</AlertTitle>
        {error.message} — <strong>{error.name}</strong>
      </Alert>
    );
  }

  if (loading) {
    return [...Array(3)].map((application, index) => (
      <Box key={index} sx={{ my: 4 }}>
        <CardLeasingApplication />
      </Box>
    ));
  }

  return applications.map(application => (
    <Box key={application.id} sx={{ my: 4 }}>
      <LeasingApplication {...application} />
    </Box>
  ));
}
