import PropTypes from "prop-types";
import React from "react";

import { AzErrorWindow } from "@Development/ui-kit";
import { Box, Link } from "@material-ui/core";
import { whatsAppLogo } from "@product-site-frontend/shared";
import { Link as RouterLink } from "gatsby";

AlertAppError.propTypes = {
  resetErrorBoundary: PropTypes.func,
};

export default function AlertAppError({ resetErrorBoundary }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "90vh" }}>
      <AzErrorWindow
        subtitle={
          <Box role="alert" sx={{ fontSize: "16px", fontWeight: 500 }}>
            <Box sx={{ mb: 5, display: "flex", alignItems: "center" }}>
              Свяжитесь с нами в

              <Link href="https://wa.me/+74951254344" sx={{ height: "33px !important", display: "inline-block", marginLeft: "4px" }}>
                <Box component={"img"} src={whatsAppLogo} sx={{ display: "inline-block" }} />
              </Link>
            </Box>

            {/* <pre>{error.message}</pre> */}


            <Link
              component={RouterLink}
              onClick={() => {
                resetErrorBoundary();
              }}
              to="/"
              underline="hover"
            >
              Вернуться на главную страницу
            </Link>
          </Box>
        }
        type={"error"}
      />
    </Box>
  );
}
