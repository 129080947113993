import React, { useContext } from "react";

import { Card, Link, Typography } from "@material-ui/core";
import { maskedPhone } from "@product-site-frontend/shared/components/PhoneMaskInput";

import { AppStateContext } from "../core/AppContextProvider";

export default function WidgetClientManager() {
  const { leasingManager } = useContext(AppStateContext);

  if (!leasingManager?.phone && !leasingManager?.email) {
    return null;
  }
  const { email, fullName, phone } = leasingManager;

  return (
    <Card
      sx={{
        boxShadow: '0 1px 50px 1px rgba(208, 208, 208, 0.5)',
        p: 4,
        mb: 4,
      }}
    >
      <Typography color="text.secondary" gutterBottom variant="caption">
        Ваш персональный менеджер
      </Typography>
      <Typography gutterBottom variant="h4">
        {fullName}
      </Typography>
      {phone && (
        <Link href={`tel:${phone}`} underline="none" variant="body2">
          {maskedPhone.resolve(phone)}
        </Link>
      )}
      <br />
      {email && (
        <Link href={`mailto:${email}`} variant="body2">
          {email}
        </Link>
      )}
    </Card>
  );
}
