import React from 'react';

import { Router } from '@reach/router';

import PageApplications from './components/PageApplications';
import PageMarketing from './components/PageMarketing';
import PageProfileCompany from './components/PageProfileCompany';
import PageWidgetButton from "./components/PageWidgetButton";
import PageWidgetButtonBanner from './components/PageWidgetButtonBanner';
import PageWidgetButtonCloud from './components/PageWidgetButtonCloud';
import PageWidgetOnThePage from "./components/PageWidgetOnThePage";

export default function Routes() {
  return (
    <Router>
      <PageApplications path="/" />
      <PageProfileCompany path="/profile" />
      <PageMarketing path="/marketing" />
      <PageWidgetButton path="/marketing/widget-button" />
      <PageWidgetButtonCloud path="/marketing/widget-button-cloud" />
      <PageWidgetButtonBanner path="/marketing/widget-button-banner" />
      <PageWidgetOnThePage path="/marketing/widget-on-the-page" />
    </Router>
  );
}