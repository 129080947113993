import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import {
  EMAIL_VALIDATION,
  FIO_VALIDATION,
  PHONE_VALIDATION,
} from "@components/FormEntityQuestionnaire/constants/validations";
import { AppStateContext } from "@core/AppContextProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableBody,
  Link,
  Dialog,
  DialogTitle,
  SvgIcon,
  DialogContent,
  CircularProgress,
  Tooltip,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from "@material-ui/core";
import {
  AmountMaskInput,
  FormFieldContainer,
  PhoneMaskInput,
  WrapperButton,
  DateRageMaskInput,
} from "@product-site-frontend/shared";
import UploadButton from "@product-site-frontend/shared/components/UploadButton";
import dayjs from "dayjs";
import { useIntl } from "gatsby-plugin-react-intl"
import { useForm } from "react-hook-form";
import { FormattedMessage } from 'react-intl';
import useFetch from "use-http";
import * as yup from "yup";
import 'react-day-picker/dist/style.css';

import iconArrowFilter from '../../img/arrowFilter.svg';
import iconBanner from '../../img/banner.svg';
import iconCompleteTransferClient from "../../img/completeTransferClient.svg";
import iconPerson from '../../img/person.svg';
import iconRemoveFilter from '../../img/removeFilter.svg';
import iconSearch from '../../img/search.svg';
import FooterInf from "../FooterInf";

export default function PageApplications() {
  const [showFilter, setShowFilter] = useState(true);

  const intl = useIntl();

  const styles = useMemo(() => ({
    bannerContainer: {
      background: 'linear-gradient(68.26deg, rgba(255, 164, 92, 0.08) -0.53%, rgba(0, 178, 255, 0.08) 100%)',
      boxSizing: 'border-box',
      padding: {
        sm: '16px',
        md: '48px 60px'
      },
      marginBottom: '48px',
      marginTop: '-32px'
    },
    banner: {
      background: 'linear-gradient(273.59deg, #FF910F 0%, #FF780F 94.1%)',
      borderRadius: '24px',
      boxShadow: '0px 10px 24px 0px #FF780F4D',
      padding: {
        xs: '16px',
        md: '16px 72px 0 0'
      },
      color: '#FFF',
      display: 'flex',
      alignItems: 'center',
      flexWrap: {
        xs: 'wrap',
        md: 'nowrap'
      },
      marginTop: {
        xs: "16px",
        md: 0,
      },

      '& img': {
        display: {
          xs: 'none',
          md: 'inline-block'
        }
      },

      '& a': {
        color: '#FFF',
        textDecoration: 'underline'
      },

      '& span': {
        position: 'relative',
        top: {
          xs: '0',
          md: '-10px'
        },
        fontSize: {
          xs: "14px",
          lg: "18px",
        },
        lineHeight: '30px',
        width: {
          xs: '100%'
        }
      }
    },
    iconBanner: {
      width: '138px',
      minWidth: '138px'
    },
    field: {
      '& .MuiFilledInput-root': {
        border: '1px solid #F3F4F5',
        background: '#F3F4F5',
        borderRadius: '8px',
        color: '#6E7884',
      },
      '& .MuiInputLabel-root': {
        color: '#6E7884',
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '1.7990em',
      },
      '&:hover .MuiFilledInput-root': {
        background: '#EEEFF1',
      },
      '&:hover .MuiInputLabel-root': {
        color: '#6E7884',
      },
      '& .MuiFilledInput-root.Mui-focused': {
        border: '1px solid #B6BBC2',
        background: '#ffffff',
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: '#6E7884'
      },
      '& .Mui-focused .MuiInputBase-input': {
        color: '#0D1D32',
      },
      '& .MuiInputBase-input::placeholder': {
        color: '#B6BBC2 !important',
      },
      '& .MuiFilledInput-root.Mui-error': {
        background: '#FFEEED',
      },
      '& .MuiInputLabel-root.Mui-error': {
        color: '#FF5550',
      },
      '&.valid .MuiFilledInput-root': {
        background: '#F0F9EA',
        color: '#0D1D32',
      },
    },
    topWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: {
        xs: 0,
        sm: '32px',
        md: '32px',
        lg: '32px',
      },
    },
    titlePage: {
      fontWeight: '900',
      fontSize: {
        xs: '24px',
        sm: '32px',
        md: '32px',
        lg: '36px',
      }
    },
    buttonOrange: {
      border: '1px solid rgba(255, 120, 15, 0.5)',
      color: '#FF780F',
      fontSize: '16px',
      fontWeight: 500,
      borderRadius: '8px',
      textTransform: 'initial',
      width: {
        xs: '100%',
        sm: '100%',
        md: '210px',
        lg: '210px',
      },
      height: '56px',

      '& .MuiTouchRipple-root': {
        display: 'none'
      },
      '& img': {
        marginRight: '12px'
      },
      '&:hover': {
        background: 'rgba(255, 120, 15, 0.08)',
        border: '1px solid; border-image-source: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), linear-gradient(0deg, #FF780F, #FF780F)',
      },
      '&:active': {
        color: '#D9660D',
        background: 'rgba(255, 120, 15, 0.12)',
        border: '1px solid; border-image-source: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), linear-gradient(0deg, #FF780F, #FF780F)',
      },
      '&:disbled': {
        color: '#CFD2D6',
        background: '#ffffff',
        border: '#CFD2D6',
      },
    },
    buttonIconFilter: {
      width: '48px',
      height: '48px',
      background: '#F5F6F7',
      borderRadius: '30px',
      display: {
        xs: 'none',
        sm: 'none',
        md: 'flex',
        lg: 'flex',
      },

      '& img': {
        transition: 'all 0.3s',
        transform: showFilter ? 'rotate(0)' : 'rotate(180deg)',
        cursor: 'pointer',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
    middleWrapperMob: {
      display: {
        xs: "flex",
        sm: "flex",
        md: "none",
        lg: "none",
      },
      gap: '24px',
      borderBottom: '1px solid #E7E8EA',
      marginTop: {
        xs: '16px',
        sm: '24px',
      },
      overflowX: 'auto',
      overflowY: 'hidden',

      '& > div': {
        color: '#6E7884',
        fontSize: {
          xs: '14px',
          sm: '16px',
        },
        fontWeight: 500,
        marginBottom: '-1px',
        padding: '10px 0',
        whiteSpace: 'nowrap',

        '& span': {
          marginLeft: '6px',
        },

        '&.active': {
          borderBottom: '4px solid #FF780F',
          color: '#FF780F',

          '& span': {
            color: '#B6BBC2'
          }
        }
      }
    },
    middleWrapper: {
      display: {
        xs: 'none',
        sm: 'none',
        md: 'flex',
        lg: 'flex',
      },
      maxHeight: showFilter ? '800px !important' : '0 !important',
      overflow: showFilter ? 'visible !important' : 'hidden !important',
      transition: 'all 0.3s',
      justifyContent: 'space-between',
      flexWrap: {
        md: 'wrap',
        lg: 'nowrap',
      },
      gap: '24px',

      '& > div': {
        overflow: 'hidden',
        width: {
          md: '100%',
          lg: '33%'
        },
        borderRadius: '16px',
        padding: '20px 16px',

        '&:nth-child(1)': {
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #FF780F',

          '& .active': {
            color: '#FF780F',
            background: 'rgba(255, 120, 15, 0.1)',
          }
        },
        '&:nth-child(2)': {
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #21A2FF',

          '& .active': {
            color: '#21A2FF',
            background: 'rgba(33, 162, 255, 0.1)',
          }
        },
        '&:nth-child(3)': {
          background: 'rgba(103, 191, 61, 0.1)',

          '& .active': {
            color: '#67BF3D',
            background: 'rgba(103, 191, 61, 0.1)',
          }
        },
      }
    },
    topWrapperTitle: {
      color: '#0D1D32',
      fontSize: '18px',
      fontWeight: '700',
      marginBottom: '24px',
      padding: '0 6px',

      '& span': {
        borderRadius: '20px',
        padding: '2px 8px',
        color: '#ffffff',
        fontSize: '18px',
        fontWeight: 500,
        marginLeft: '8px',
      }
    },
    topWrapperBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#6E7884',
      fontSize: '16px',
      fontWeight: 500,
      cursor: 'pointer',
      padding: '0px 6px',
      margin: '4px 0',
      borderRadius: '6px',
      height: '34px',
      userSelect: 'none',

      '& div:nth-child(2)': {
        fontWeight: 700,
      },

      '&:hover': {
        color: '#0D1D32',
      }
    },
    searchBox: {
      display: "flex",
      justifyContent: "space-between",
      gap: '20px',
      flexWrap: {
        xs: 'wrap',
        sm: 'nowrap',
        md: 'nowrap',
        lg: 'nowrap',
      },
      alignItems: 'center',
      marginTop: {
        xs: '16px',
        sm: '25px',
        md: '20px',
        lg: '20px',
      },
    },
    searchBoxTitle: {
      fontWeight: 500,
      fontSize: '18px',
      color: '#6E7884',
    },
    searchBoxTitleCount: {
      fontWeight: 500,
      fontSize: '18px',
      color: '#0D1D32',
      marginLeft: '6px',
    },
    inputSearch: {
      background: '#F5F6F7',
      borderRadius: '8px',
      padding: '12px 18px',
      margin: 0,
      fontSize: '16px',
      fontWeight: '500',
      color: '#6E7884',
      width: '100%',

      '& > div:before': {
        display: 'none',
      },
      '& > div:after': {
        display: 'none',
      },
    },
    select: {
      background: '#f5f6f7',
      border: '1px solid #f5f6f7',
      borderRadius: '8px',

      '&.Mui-focused': {
        border: '1px solid #ee8035',
      },
      '&:hover': {
        background: '#ecedef',
        border: '1px solid #ecedef',
      }
    },
    activeFilterBox: {
      minHeight: '33px',
      display: {
        xs: 'none',
        sm: 'none',
        md: 'flex',
        lg: 'flex',
      },
      flexWrap: 'wrap',
      gap: '8px',
      marginTop: {
        xs: '0px',
        sm: '8px',
        md: '20px',
        lg: '20px',
      },
    },
    activeFilterBoxMob: {
      display: {
        xs: 'flex',
        sm: 'flex',
        md: 'none',
        lg: 'none',
      },
      marginTop: '24px',
      marginBottom: '16px',
    },
    activeFilterChipBox: {
      background: 'rgba(13, 29, 50, 0.1)',
      borderRadius: '20px',
      padding: '6px 12px',
      fontSize: '14px',
      fontWeight: '500',
      color: '#0D1D32',
      display: 'flex',
      alignItems: 'center',
    },
    activeFilterRemoveButtonBox: {
      background: 'rgba(13, 29, 50, 0.15)',
      borderRadius: '20px',
      width: '16px',
      height: '16px',
      padding: 0,
      marginLeft: '4px',

      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '& img': {
        width: '8px',
        height: '8px',
        margin: 0,
        padding: 0,
      }
    },
    wrapperTable: {
      boxShadow: '0px 1px 4px 0px rgba(13, 29, 50, 0.10)',
      marginTop: '24px',
      color: '#0D1D32',
      borderRadius: '24px',

      '& div': {
        fontSize: '14px',
        fontWeight: 300,
        whiteSpace: 'nowrap',
      },

      '& .number': {
        width: 'auto',
        fontWeight: 500,
        whiteSpace: 'nowrap',
      },
      '& .number-data': {
        minWidth: '130px',
      },
      '& .status': {
        width: '190px',
        fontWeight: '500 !important',

        '& .type': {
          '&:before': {
            content: '""',
            width: '6px',
            height: '6px',
            display: 'inline-block',
            marginRight: '6px',
            borderRadius: '20px',
            position: 'relative',
            top: '-2px',
          },

          '&.lead, &.primary_consultation, &.proposal_sent, &.proposal_familiarized': {
            color: '#FF780F',

            '&:before': {
              background: 'rgba(255, 136, 25, 0.12)',
            }
          },
          '&.preparing_documents, &.leasing_sale, &.verification, &.additional_documents_request, &.underwriting, &.governanceing, &.preapproved, &.approved, &.preparing_deal, &.deal, &.partial_payment, &.advance_payment_received': {
            color: '#21A2FF',

            '&:before': {
              background: 'rgba(50, 157, 255, 0.12)',
            }
          },
          '&.issued, &.extinguished, &.not_realized': {
            color: '#67BF3D',

            '&:before': {
              background: 'rgba(132, 198, 26, 0.12)',
            }
          },
          '&.not_realized': {
            color: '#9EA5AD',

            '&:before': {
              background: 'rgba(13, 29, 50, 0.05)',
            }
          },
        }
      },
      '& .contact': {
        width: '260px'
      },
      '& .company': {
        maxWidth: '407px',
        overflow: 'hidden',

        '& .company_name': {
          wordWrap: 'break-word',
          whiteSpace: 'normal',
          marginBottom: '4px',
        }
      },
      '& .manager_arenza': {
        width: '240px'
      },
      '& .comment': {
        textAlign: 'right',

        '& img': {
          display: 'inline-block',
        }
      },
    },
    modal: {
      '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: '682px',
        margin: {
          xs: '0',
          sm: '32px',
        }
      },
      '& .MuiDialogContent-root': {
        paddingLeft: {
          xs: '24px',
          sm: '24px',
          md: '64px',
          lg: '64px',
        },
        paddingRight: {
          xs: '24px',
          sm: '24px',
          md: '64px',
          lg: '64px',
        },
      }
    },
    modalTitle: {
      fontSize: '26px',
      fontWeight: 700,
      marginBottom: '19px',
      display: {
        xs: 'none',
        sm: 'block',
        md: 'block',
        lg: 'block',
      },
    },
    modalTitleMob: {
      fontSize: '18px',
      fontWeight: 700,
      display: {
        xs: 'block',
        sm: 'none',
      },
    },
    modalInput: {
      borderRadius: '8px',
      overflow: 'hidden',
      border: 'none',

      '& fieldset': {
        border: 'none',
      },

      '& input': {
        width: '100%',
      },

      '& .MuiInputAdornment-root': {
        display: 'none',
      },

      '& .MuiFilledInput-root': {
        border: '1px solid #F3F4F5',
        background: '#F3F4F5',
        borderRadius: '8px',
        color: '#6E7884',
      },
      '& .MuiInputLabel-root': {
        color: '#6E7884',
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '1.7990em',
      },
      '&:hover .MuiFilledInput-root': {
        background: '#EEEFF1',
      },
      '&:hover .MuiInputLabel-root': {
        color: '#6E7884',
      },
      '& .MuiFilledInput-root.Mui-focused': {
        border: '1px solid #B6BBC2',
        background: '#ffffff',
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: '#6E7884'
      },
      '& .Mui-focused .MuiInputBase-input': {
        color: '#0D1D32',
      },
      '& .MuiInputBase-input::placeholder': {
        color: '#B6BBC2 !important',
      },
      '& .MuiFilledInput-root.Mui-error': {
        background: '#FFEEED',
      },
      '& .MuiInputLabel-root.Mui-error': {
        color: '#FF5550',
      },
      '&.valid .MuiFilledInput-root': {
        background: '#F0F9EA',
        color: '#0D1D32',
      },
    },
    modalTextArea: {
      borderRadius: '8px',
      overflow: 'hidden',

      '& fieldset': {
        border: 'none',
      },
      '& .MuiInputBase-multiline': {
        background: '#F5F6F7',
      }
    },
    modalButton: {
      background: '#FF780F',
      boxShadow: '0px 10px 24px rgba(255, 120, 15, 0.3)',
      borderRadius: '8px',
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: '500',
      textTransform: 'initial',
      padding: '14px 24px',
      width: {
        xs: '100%',
        sm: 'auto',
        md: 'auto',
        lg: 'auto',
      },

      '&:hover': {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #FF780F',
        boxShadow: 'none'
      },
      '&:active': {
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #FF780F',
        boxShadow: 'none'
      },
      '&:disabled': {
        background: '#E7E8EA',
        boxShadow: 'none',
        color: '#B6BBC2',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    modalComplete: {
      '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: '456px',
      },
    },
    modalCompleteImg: {
      margin: '0 auto',
      paddingTop: '43px',
      paddingBottom: '14px',
    },
    modalCompleteTitle: {
      fontSize: '22px',
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: '90px',
    },
  }), [showFilter]);

  const [files, setFiles] = useState([]);
  const onAttachFile = arr => {
    setFiles(arr)
  };

  const FIELDS_CONFIG = {
    full_name: {
      name: 'full_name',
      validation: FIO_VALIDATION.required('Укажите фамилию, имя и отчество через пробел'),
      defaultValue: '',
      FieldComponent: FormFieldContainer,
      componentProps: {
        fieldType: 'nameAutocomplete',
        placeholder: 'Фёдоров Михаил Сергеевич',
        label: 'ФИО клиента *',
      },
    },
    inn: {
      name: 'inn',
      validation: yup
        .string()
        .nullable()
        .required('Укажите организацию или ИП'),
      FieldComponent: FormFieldContainer,
      componentProps: {
        label: 'ИНН или наименование компании *',
        fieldType: 'legalEntityAutocomplete',
      },
    },
    phone: {
      name: 'phone',
      validation: PHONE_VALIDATION.required('Укажите номер телефона'),
      defaultValue: '',
      FieldComponent: FormFieldContainer,
      componentProps: {
        label: 'Номер телефона *',
        InputProps: {
          inputComponent: PhoneMaskInput,
        },
        fieldType: 'text',
        placeholder: '+7 (***) ***-**-**',
        type: 'tel',
      },
    },
    email: {
      name: 'email',
      validation: EMAIL_VALIDATION,
      defaultValue: '',
      FieldComponent: FormFieldContainer,
      componentProps: {
        fieldType: 'text',
        name: 'email',
        placeholder: 'fedorov@example.ru',
        label: 'Электронная почта',
      },
    },
    amount: {
      name: 'amount',
      validation: yup.string().required('Укажите стоимость оборудования'),
      defaultValue: '',
      FieldComponent: FormFieldContainer,
      componentProps: {
        InputProps: {
          inputComponent: AmountMaskInput,
        },
        label: 'Стоимость оборудования *',
        fieldType: 'text',
        placeholder: '500 000 руб.',
        inputProps: {
          inputMode: 'numeric',
          pattern: '[0-9]*',
        },
      },
    },
    comment: {
      name: 'comment',
      validation: yup.string(),
      defaultValue: '',
      FieldComponent: FormFieldContainer,
      componentProps: {
        label: 'Комментарий',
        fieldType: 'text',
        placeholder: 'Комментарий',
      },
    },
  };

  const FIELDS_ORDER = [
    'full_name',
    'inn',
    'phone',
    'email',
    'amount',
    'comment',
  ];

  function buildStepFields() {
    return FIELDS_ORDER.map((name) => {

      return FIELDS_CONFIG[name];
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stepFields = useMemo(buildStepFields, []);

  const schema = useMemo(() => {
    return yup
      .object()
      .shape(stepFields.reduce(
        (prev, curr) => ({ ...prev, [curr.name]: curr.validation }),
        {},
      ));
  }, [stepFields]);

  const { control, handleSubmit, reset } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });

  const [page, setPage] = useState(1);

  const [rows, setRows] = useState([]);
  const [originalRows, setOriginalRows] = useState([]);

  const { activeLegalEntityId } = useContext(AppStateContext);

  const { get: getApplications, loading: loadingApplications, response: responseApplications } = useFetch();
  const { loading: loadingCreateApplication, post: postCreateApplication, response: responseCreateApplication } = useFetch('/partners/applications');

  useEffect(() => {
    if (activeLegalEntityId) {
      onGetApplications(activeLegalEntityId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLegalEntityId])

  const onGetApplications = useCallback(async (entityId) => {
    await getApplications(`/partners/applications?entity_id=${entityId}`);

    if (responseApplications?.data) {
      setOriginalRows(responseApplications.data);

      if (responseApplications.data.length) {
        window.addEventListener('scroll', throttle(checkPosition))
        window.addEventListener('resize', throttle(checkPosition))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseApplications]);

  const onToggleFilterBlock = () => {
    setShowFilter(!showFilter);
  };

  const renderChips = () => {
    let arrTitles = [];

    const generationFlags = type => {
      let arr = [];

      switch (type) {
        case 'accepted_title_filer':
          arr = ['lead', 'primary_consultation'];
          break;

        case 'kp_sent_title_filer':
          arr = ['proposal_sent', 'proposal_familiarized'];
          break;

        case 'collecting_documents_title_filer':
          arr = ['preparing_documents', 'leasing_sale', 'verification', 'additional_documents_request'];
          break;

        case 'decision_making_title_filer':
          arr = ['underwriting', 'governanceing', 'preapproved'];
          break;

        case 'approved_title_filer':
          arr = ['approved', 'preparing_deal', 'deal', 'partial_payment', 'advance_payment_received'];
          break;

        case 'completed_title_filer':
          arr = ['issued', 'extinguished'];
          break;

        case 'not_implemented_title_filer':
          arr = ['not_realized'];
          break;

        default:
          arr = [];
      }

      return arr;
    }

    if (filtersStatus?.length) {
      if (filtersStatus.includes('lead') && filtersStatus.includes('primary_consultation')) {
        arrTitles.push('accepted_title_filer');
      }

      if (filtersStatus.includes('proposal_sent') && filtersStatus.includes('proposal_familiarized')) {
        arrTitles.push('kp_sent_title_filer');
      }

      if (filtersStatus.includes('preparing_documents') && filtersStatus.includes('leasing_sale') && filtersStatus.includes('verification') && filtersStatus.includes('additional_documents_request')) {
        arrTitles.push('collecting_documents_title_filer');
      }

      if (filtersStatus.includes('underwriting') && filtersStatus.includes('governanceing') && filtersStatus.includes('preapproved')) {
        arrTitles.push('decision_making_title_filer');
      }

      if (filtersStatus.includes('approved') && filtersStatus.includes('preparing_deal') && filtersStatus.includes('deal') && filtersStatus.includes('partial_payment') && filtersStatus.includes('advance_payment_received')) {
        arrTitles.push('approved_title_filer');
      }

      if (filtersStatus.includes('issued') && filtersStatus.includes('extinguished')) {
        arrTitles.push('completed_title_filer');
      }

      if (filtersStatus.includes('not_realized')) {
        arrTitles.push('not_implemented_title_filer');
      }
    }

    return (
      arrTitles?.length > 0 && arrTitles?.map(title => (
        <Box key={title} sx={styles.activeFilterChipBox}>
          <FormattedMessage id={`partnership.applications.status.${title}`} />

          <IconButton onClick={onChangeFiltersStatus.bind('', generationFlags(title))} sx={styles.activeFilterRemoveButtonBox}>
            <Box component="img" src={iconRemoveFilter} />
          </IconButton>
        </Box>
      ))
    )
  };

  const [showModalClientTransfer, setShowModalClientTransfer] = useState(false);
  const [showModalClientTransferComplete, setShowModalClientTransferComplete] = useState(false);
  const [showModalClientTransferError, setShowModalClientTransferError] = useState(false);
  const [showModalFilter, setModalFilter] = useState(false);

  const handleToggleDialog = (type, status = false) => {
    switch (type) {
      case 'showModalClientTransfer':
        setShowModalClientTransfer(status);

        if (!status) {
          reset({ full_name: '' });
          reset({ inn: '' });
          reset({ phone: '' });
          reset({ email: '' });
          reset({ amount: '' });
          reset({ comment: '' });
        }

        break;

      case 'showModalClientTransferComplete':
        setShowModalClientTransferComplete(status);
        break;

      case 'showModalFilter':
        setModalFilter(status);
        break;

      case 'showModalClientTransferError':
        setShowModalClientTransferError(status);
        break;

      default:
        setShowModalClientTransfer(false);
        setShowModalClientTransferComplete(false);
    }
  };

  const phoneFormat = (s, plus = true) => {
    if (!s) return 'Телефон отсутствует';

    const startsWith = plus ? '+7' : '8';

    let phone = s.replace(/[^0-9]/g, '');
    if (phone.startsWith('7') && plus) {
      phone = phone.substr(1);
    }
    if (phone.startsWith('8')) {
      phone = phone.substr(1);
    }

    return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, `${startsWith} ($1) $2 $3 $4`);
  };

  const calcCount = statuses => {
    let total = 0;

    statuses.forEach(status => {
      total += originalRows?.filter(row => row.status === status)?.length;
    })

    return total;
  };

  const [search, setSearch] = useState('');

  const onChangeSearch = event => {
    let value = event?.target?.value;

    setSearch(value);
    setPage(1);
  };

  const [filtersStatus, setFiltersStatus] = useState([]);

  const onChangeFiltersStatus = types => {
    const cloneFiltersStatus = [...filtersStatus];

    types.forEach(type => {
      if (cloneFiltersStatus.includes(type)) {
        cloneFiltersStatus.splice(cloneFiltersStatus.indexOf(type), 1);
      } else {
        cloneFiltersStatus.push(type);
      }
    })

    setFiltersStatus(cloneFiltersStatus);
  }

  const [sorting, setSorting] = useState({ field: 'created_at', order: 'ASC' })

  const onChangeSort = (field, order) => {
    setSorting({ field, order })
  }

  const [filterSource, setFilterSource] = useState(null);

  const [filterData, setFilterData] = useState({
    startDate: null,
    endDate: null
  });


  useEffect(() => {
    if (!originalRows?.length) return false;

    const clearSearchAndValue = val => {
      const draft = val?.toString()?.replace(/[^0-9]/g, "");

      if (draft?.length) {
        return draft
      } else {
        return null
      }
    }

    let newRows = [];

    newRows = originalRows.filter(row =>
    (
      search?.length ? (
        row?.borrower_entity?.legal_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
        row?.borrower_entity?.inn?.toString()?.includes(search) ||
        row?.id?.toString()?.includes(search) ||
        clearSearchAndValue(row?.contact?.phone)?.includes(clearSearchAndValue(search)) ||
        clearSearchAndValue(row?.arenza_manager?.phone)?.includes(clearSearchAndValue(search))
      ) : row
    )
    ).filter(row => (
      filtersStatus?.length ? filtersStatus.includes(row?.status) : row
    )).filter(row => (
      filterSource?.length ? filterSource?.includes(row?.source) : row
    )
    ).filter(row => {
      if (filterData.startDate && filterData.endDate) {
        let startDate = dayjs(filterData.startDate).format("YYYY-MM-DD");
        let endDate = dayjs(filterData.endDate).format("YYYY-MM-DD");
        let rowDate = dayjs(row.created_at).format("YYYY-MM-DD");

        return dayjs(rowDate).isAfter(startDate) && dayjs(rowDate).isBefore(endDate)
      } else {
        return row
      }
    })

    if (sorting.order === 'ASC') {
      if (sorting.field === 'created_at') {
        newRows.sort((a, b) => new Date(a[sorting.field]) - new Date(b[sorting.field]))
      } else if (sorting.field === 'status') {
        newRows.sort((a, b) => {
          if (intl.formatMessage({ id: `partnership.applications.status.${a[sorting.field]}` }) > intl.formatMessage({ id: `partnership.applications.status.${b[sorting.field]}` })) {
            return 1;
          } else if (intl.formatMessage({ id: `partnership.applications.status.${a[sorting.field]}` }) < intl.formatMessage({ id: `partnership.applications.status.${b[sorting.field]}` })) {
            return -1
          } else {
            return 0;
          }
        })
      }
    } else {
      if (sorting.field === 'created_at') {
        newRows.sort((a, b) => new Date(b[sorting.field]) - new Date(a[sorting.field]))
      } else if (sorting.field === 'status') {
        newRows.sort((a, b) => {
          if (intl.formatMessage({ id: `partnership.applications.status.${a[sorting.field]}` }) < intl.formatMessage({ id: `partnership.applications.status.${b[sorting.field]}` })) {
            return 1
          } else if (intl.formatMessage({ id: `partnership.applications.status.${a[sorting.field]}` }) > intl.formatMessage({ id: `partnership.applications.status.${b[sorting.field]}` })) {
            return -1
          } else {
            return 0;
          }
        })
      }
    }

    setRows([...newRows.slice(0, (page * 30))]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filtersStatus, originalRows, sorting, page, filterSource, filterData]);

  const hasActiveFiltersStatus = useCallback(arr => {
    let arrBool = [];

    arr.forEach(el => {
      arrBool.push(filtersStatus.includes(el))
    })

    if (arrBool.every(el => el)) return 'active';
  }, [filtersStatus]);

  const [isLoaderPage, setLoaderPage] = useState(false);

  const throttle = (callee, timeout) => {
    setLoaderPage(true);
    let timer = null

    return function perform(...args) {
      if (timer) return

      timer = setTimeout(() => {
        callee(...args)

        clearTimeout(timer)
        timer = null
      }, timeout)
    }
  }

  const checkPosition = () => {
    const height = document.body.offsetHeight
    const screenHeight = window.innerHeight
    const scrolled = window.scrollY

    const threshold = height - screenHeight / 4
    const position = scrolled + screenHeight

    if (position >= threshold) {
      setPage(value => value + 1)
    }

    setLoaderPage(false);
  }

  async function onSubmit(data) {
    let formData = new FormData();

    formData.append('full_name', data.full_name);
    formData.append('phone', `+${data.phone.replace(/[^0-9]/g, "")}`);
    formData.append('requested_amount', data.amount);
    if (data?.email) {
      formData.append('email', data.email);
    }
    formData.append('inn', data.inn);

    if (data?.comment) {
      formData.append('comment', data.comment);
    }

    formData.append('source_id', activeLegalEntityId);
    if (files?.length) {
      formData.append('proposal_file', files[0]);
    }

    await postCreateApplication(formData);

    if (responseCreateApplication.ok) {
      handleToggleDialog('showModalClientTransfer', false);
      handleToggleDialog('showModalClientTransferComplete', true);

      if (activeLegalEntityId) {
        onGetApplications(activeLegalEntityId)
      }
    } else {
      handleToggleDialog('showModalClientTransfer', false);
      handleToggleDialog('showModalClientTransferError', true);
    }
  }

  const convertTime = times => {
    let min = times / 60;
    let hour = min / 60;
    let day = hour / 24;

    return (
      <Box>
        {Math.floor(day) > 0 && (
          <>{Math.floor(day)} дней {" "}</>
        )}
        {Math.floor(hour % 24)} час. {" "}
        {Math.floor(min % 60)} мин.
      </Box>
    )
  }

  const [filterDraft, setFilterDraft] = useState({
    source: null,
    startDate: null,
    endDate: null
  });

  const onChangeFiltersDraft = (type, event) => {
    const cloneFilterDraft = { ...filterDraft };

    if (type === "date") {
      if (event?.from) {
        cloneFilterDraft["startDate"] = event?.from;
      }

      if (event?.to) {
        cloneFilterDraft["endDate"] = event?.to;
      }
    } else {
      cloneFilterDraft[type] = event.target.value;
    }

    setFilterDraft(cloneFilterDraft)
  }

  const resetFilter = () => {
    setFilterData({
      startDate: null,
      endDate: null
    });

    setFilterSource(null);

    setFilterDraft({
      source: null,
      startDate: null,
      endDate: null
    });

    handleToggleDialog('showModalFilter', false);
  }

  const updateFilter = () => {
    if (filterDraft.source) {
      setFilterSource(filterDraft.source);
    }

    const cloneFilterData = { ...filterData }

    if (filterDraft.startDate) {
      cloneFilterData.startDate = filterDraft.startDate
    }

    if (filterDraft.endDate) {
      cloneFilterData.endDate = filterDraft.endDate
    }

    setFilterData(cloneFilterData);
    handleToggleDialog('showModalFilter', false);
  }

  const renderModalFilter = () => {
    const sources = ['partners_manager', 'partner_account', 'widget', 'partner_landing', 'arenza'];

    return (
      <Dialog onClose={handleToggleDialog.bind(this, 'showModalFilter', false)} open={true} sx={styles.modal}>
        <DialogTitle sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: {
            xs: 'space-between',
            sm: 'flex-end',
            md: 'flex-end',
            lg: 'flex-end',
          },
          paddingBottom: '11px',
        }}>
          <Typography sx={styles.modalTitleMob}>Передать клиента на лизинг</Typography>
          <IconButton aria-label="close" onClick={handleToggleDialog.bind(this, 'showModalFilter', false)}>
            <SvgIcon>
              <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd" />
            </SvgIcon>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography sx={styles.modalTitle}>Фильтр</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: { xs: 'wrap', lg: 'nowrap' } }}>
            <DateRageMaskInput onChange={onChangeFiltersDraft.bind(this, 'date')} />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: { xs: 'wrap', lg: 'nowrap' } }}>
            <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }} variant="filled">
              <InputLabel id="demo-simple-select-filled-label">Источник</InputLabel>
              <Select
                onChange={onChangeFiltersDraft.bind(this, "source")}
                sx={styles.select}
                value={filterDraft?.source}
              >
                <MenuItem disabled value="">
                  <em>Выберите</em>
                </MenuItem>

                {sources.map(source => (
                  <MenuItem key={source} value={source}>
                    <FormattedMessage id={`partnership.applications.source.${source}`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: "12px", marginBottom: '28px', marginTop: '35px' }}>
            <WrapperButton colorScheme={"grey"} onClick={resetFilter}>
              Сбросить
            </WrapperButton>

            <WrapperButton onClick={updateFilter}>
              Применить
            </WrapperButton>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <>
      <Box sx={styles.bannerContainer}>
        <Box sx={styles.banner}>
          <Box component="img" src={iconBanner} sx={styles.iconBanner} />
          <span>
            <b>Уважаемый партнер!</b> Предлагаем для развития и масштабирования вашего бизнеса оборудование в лизинг на специальных условиях. Подробности были отправлены вам на контактный email. Также их можно узнать у вашего персонального менеджера или на сайте. <Link href="https://arenza.ru/promotions/supplier?utm_source=cabinet&utm_medium=banner&utm_campaign=promo" target="_blank">Подробнее</Link>
          </span>
        </Box>
      </Box>
      <Box sx={{ background: '#fff' }}>
        <Box sx={styles.topWrapper}>
          <Box sx={{ display: 'flex', gap: '24px', flexWrap: { xs: 'wrap', sm: 'wrap', md: 'nowrap', lg: 'nowrap' } }}>
            <Typography sx={styles.titlePage}>Переданные клиенты</Typography>

            <WrapperButton colorScheme={'orangeStroke'} onClick={handleToggleDialog.bind(this, 'showModalClientTransfer', true)} >
              <Box component="img" src={iconPerson} />
              Передать клиента
            </WrapperButton>
          </Box>

          <IconButton onClick={onToggleFilterBlock} sx={styles.buttonIconFilter}>
            <Box component="img" src={iconArrowFilter} />
          </IconButton>
        </Box>

        <Box sx={styles.middleWrapperMob}>
          <Typography
            className={hasActiveFiltersStatus(['lead', 'primary_consultation', 'proposal_sent', 'proposal_familiarized'])}
            onClick={onChangeFiltersStatus.bind('', ['lead', 'primary_consultation', 'proposal_sent', 'proposal_familiarized'])}
          >
            <FormattedMessage id={"partnership.applications.status.work_on_request_filter_title_block"} />
            <Typography component={'span'}>
              {calcCount(['lead', 'primary_consultation', 'proposal_sent', 'proposal_familiarized'])}
            </Typography>
          </Typography>

          <Typography
            className={hasActiveFiltersStatus(['preparing_documents', 'leasing_sale', 'verification', 'additional_documents_request', 'underwriting', 'governanceing', 'preapproved', 'approved', 'preparing_deal', 'deal', 'partial_payment', 'advance_payment_received'])}
            onClick={onChangeFiltersStatus.bind('', ['preparing_documents', 'leasing_sale', 'verification', 'additional_documents_request', 'underwriting', 'governanceing', 'preapproved', 'approved', 'preparing_deal', 'deal', 'partial_payment', 'advance_payment_received'])}
          >
            <FormattedMessage id={"partnership.applications.status.customer_consideration_filter_title_block"} />
            <Typography component={'span'}>
              {calcCount(['preparing_documents', 'leasing_sale', 'verification', 'additional_documents_request', 'underwriting', 'governanceing', 'preapproved', 'approved', 'preparing_deal', 'deal', 'partial_payment', 'advance_payment_received'])}
            </Typography>
          </Typography>

          <Typography
            className={hasActiveFiltersStatus(['issued', 'extinguished', 'not_realized'])}
            onClick={onChangeFiltersStatus.bind('', ['issued', 'extinguished', 'not_realized'])}
          >
            <FormattedMessage id={"partnership.applications.status.completed_filter_title_block"} />
            <Typography component={'span'}>
              {calcCount(['issued', 'extinguished', 'not_realized'])}
            </Typography>
          </Typography>
        </Box>

        <Box sx={styles.middleWrapper}>
          <Box>
            <Typography sx={styles.topWrapperTitle}>
              <FormattedMessage id={"partnership.applications.status.work_on_request_filter_title_block"} />

              <Typography component={'span'} sx={{ background: '#FF780F' }}>{calcCount(['lead', 'primary_consultation', 'proposal_sent', 'proposal_familiarized'])}</Typography>
            </Typography>

            <Box
              className={hasActiveFiltersStatus(['lead', 'primary_consultation'])}
              onClick={onChangeFiltersStatus.bind('', ['lead', 'primary_consultation'])}
              sx={styles.topWrapperBox}>
              <Typography>
                <FormattedMessage id={"partnership.applications.status.accepted_title_filer"} />
              </Typography>
              <Typography>{calcCount(['lead', 'primary_consultation'])}</Typography>
            </Box>

            <Box
              className={hasActiveFiltersStatus(['proposal_sent', 'proposal_familiarized'])}
              onClick={onChangeFiltersStatus.bind('', ['proposal_sent', 'proposal_familiarized'])}
              sx={styles.topWrapperBox}
            >
              <Typography><FormattedMessage id={"partnership.applications.status.kp_sent_title_filer"} /></Typography>
              <Typography>{calcCount(['proposal_sent', 'proposal_familiarized'])}</Typography>
            </Box>
          </Box>

          <Box>
            <Typography sx={styles.topWrapperTitle}>
              <FormattedMessage id={"partnership.applications.status.customer_consideration_filter_title_block"} />

              <Typography component={'span'} sx={{ background: '#21A2FF' }}>
                {calcCount(['preparing_documents', 'leasing_sale', 'verification', 'additional_documents_request', 'underwriting', 'governanceing', 'preapproved', 'approved', 'preparing_deal', 'deal', 'partial_payment', 'advance_payment_received'])}
              </Typography>
            </Typography>

            <Box
              className={hasActiveFiltersStatus(['preparing_documents', 'leasing_sale', 'verification', 'additional_documents_request'])}
              onClick={onChangeFiltersStatus.bind('', ['preparing_documents', 'leasing_sale', 'verification', 'additional_documents_request'])}
              sx={styles.topWrapperBox}>
              <Typography>
                <FormattedMessage id={"partnership.applications.status.collecting_documents_title_filer"} />
              </Typography>
              <Typography>{calcCount(['preparing_documents', 'leasing_sale', 'verification', 'additional_documents_request'])}</Typography>
            </Box>

            <Box
              className={hasActiveFiltersStatus(['underwriting', 'governanceing', 'preapproved'])}
              onClick={onChangeFiltersStatus.bind('', ['underwriting', 'governanceing', 'preapproved'])}
              sx={styles.topWrapperBox}>
              <Typography>
                <FormattedMessage id={"partnership.applications.status.decision_making_title_filer"} />
              </Typography>
              <Typography>{calcCount(['underwriting', 'governanceing', 'preapproved'])}</Typography>
            </Box>

            <Box
              className={hasActiveFiltersStatus(['approved', 'preparing_deal', 'deal', 'partial_payment', 'advance_payment_received'])}
              onClick={onChangeFiltersStatus.bind('', ['approved', 'preparing_deal', 'deal', 'partial_payment', 'advance_payment_received'])}
              sx={styles.topWrapperBox}>
              <Typography>
                <FormattedMessage id={"partnership.applications.status.approved_title_filer"} />
              </Typography>
              <Typography>{calcCount(['approved', 'preparing_deal', 'deal', 'partial_payment', 'advance_payment_received'])}</Typography>
            </Box>
          </Box>

          <Box>
            <Typography sx={styles.topWrapperTitle}>
              <FormattedMessage id={"partnership.applications.status.completed_filter_title_block"} />

              <Typography component={'span'} sx={{ background: '#67BF3D' }}>{calcCount(['issued', 'extinguished', 'not_realized'])}</Typography>
            </Typography>

            <Box
              className={hasActiveFiltersStatus(['issued', 'extinguished'])}
              onClick={onChangeFiltersStatus.bind('', ['issued', 'extinguished'])}
              sx={styles.topWrapperBox}>
              <Typography>
                <FormattedMessage id={"partnership.applications.status.completed_title_filer"} />
              </Typography>
              <Typography>{calcCount(['issued', 'extinguished'])}</Typography>
            </Box>

            <Box
              className={hasActiveFiltersStatus(['not_realized'])}
              onClick={onChangeFiltersStatus.bind('', ['not_realized'])}
              sx={styles.topWrapperBox}>
              <Typography>
                <FormattedMessage id={"partnership.applications.status.not_implemented_title_filer"} />
              </Typography>
              <Typography>{calcCount(['not_realized'])}</Typography>
            </Box>

          </Box>
        </Box>

        <Box sx={{ ...styles.activeFilterBox, ...styles.activeFilterBoxMob }}>
          {renderChips()}
        </Box>

        <Box sx={styles.activeFilterBox}>
          {renderChips()}
        </Box>

        <Box sx={styles.searchBox}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box component="img" src={iconSearch} />
                </InputAdornment>
              ),
            }}
            onChange={onChangeSearch}
            placeholder={'Поиск'}
            sx={styles.inputSearch}
            value={search}
            variant="standard"
          />

          <WrapperButton colorScheme={'orangeStroke'} onClick={handleToggleDialog.bind(this, 'showModalFilter', true)}>
            Фильтр
          </WrapperButton>
        </Box>

        <Box sx={{ marginTop: '41px', marginBottom: '39px', display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex', } }}>
          <Typography sx={styles.searchBoxTitle}>Всего заявок:</Typography>
          <Typography sx={styles.searchBoxTitleCount}>{originalRows?.length}</Typography>
        </Box>

        {loadingApplications ? (
          <Box sx={{ height: '30vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          rows?.length > 0 ? (
            <>
              <TableContainer component={Paper} sx={styles.wrapperTable}>
                <Table aria-label="simple table" sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow sx={{ '& th': { color: '#9EA5AD' } }}>
                      <TableCell align="center">
                        <span
                          onClick={onChangeSort.bind('', 'created_at', sorting.order === 'DESC' ? 'ASC' : 'DESC')}
                          style={{ cursor: 'pointer', display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}
                        >
                          Заявка/Дата
                          {sorting.field === 'created_at' ? (
                            sorting.order === "DESC" ? (
                              <SvgIcon sx={{ height: "10px", width: "10px", color: '#FF780F' }} viewBox="0 0 8 8">
                                <path d="M4 7.5L0 0.5H8L4 7.5Z" fill="#FF780F" />
                              </SvgIcon>
                            ) : (
                              <SvgIcon sx={{ height: "10px", width: "10px", color: '#FF780F', transform: 'rotate(180deg)' }} viewBox="0 0 8 8">
                                <path d="M4 7.5L0 0.5H8L4 7.5Z" fill="#FF780F" />
                              </SvgIcon>
                            )
                          ) : null}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span
                          onClick={onChangeSort.bind('', 'status', sorting.order === 'DESC' ? 'ASC' : 'DESC')}
                          style={{ cursor: 'pointer', display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}
                        >
                          Текущий статус
                          {sorting.field === 'status' ? (
                            sorting.order === "DESC" ? (
                              <SvgIcon sx={{ height: "10px", width: "10px", color: '#FF780F' }} viewBox="0 0 8 8">
                                <path d="M4 7.5L0 0.5H8L4 7.5Z" fill="#FF780F" />
                              </SvgIcon>
                            ) : (
                              <SvgIcon sx={{ height: "10px", width: "10px", color: '#FF780F', transform: 'rotate(180deg)' }} viewBox="0 0 8 8">
                                <path d="M4 7.5L0 0.5H8L4 7.5Z" fill="#FF780F" />
                              </SvgIcon>
                            )
                          ) : null}
                        </span>
                      </TableCell>
                      <TableCell align="center">Компания</TableCell>
                      <TableCell align="center">Менеджер Аренза</TableCell>
                      <TableCell align="center">Контакт</TableCell>
                      <TableCell align="center">Источник</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rows.map(row => (
                      <TableRow key={row.id}>
                        <TableCell className={'number-data'} component="th" scope="row">
                          <Typography>№ {row?.id || '---'}</Typography>
                          {row?.created_at ? dayjs(row.created_at).format("DD.MM.YYYY") : '---'}
                        </TableCell>

                        <TableCell align="center" className={'status'}>
                          <Typography className={`${row?.status} type`}>
                            {row?.status ? (
                              <FormattedMessage id={`partnership.applications.status.${row.status}`} />
                            ) : (
                              '---'
                            )}
                          </Typography>

                          {convertTime(row?.time_in_status)}
                        </TableCell>

                        <TableCell align="center" className={'company'}>
                          {row?.borrower_entity?.legal_name ? (
                            <Tooltip title={row?.borrower_entity?.legal_name}>
                              <Typography className={'company_name'}>
                                {row?.borrower_entity?.legal_name?.slice(0, 64)}
                                {row?.borrower_entity?.legal_name?.length > 64 && "..."}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography className={'company_name'}>---</Typography>
                          )}

                          <Typography>ИНН: {row?.borrower_entity?.inn || '---'}</Typography>
                        </TableCell>

                        <TableCell align="center" className={'manager_arenza'}>
                          <Typography sx={{ fontWeight: '500  !important' }}>{row?.arenza_manager?.full_name || 'ФИО отсутствует'}</Typography>
                          <Typography>{phoneFormat(row?.arenza_manager?.phone)}</Typography>
                          {row?.arenza_manager?.email ? (
                            <Link href={`mailto:${row.arenza_manager.email}`} underline="none">{row.arenza_manager.email}</Link>
                          ) : (
                            <Typography>Почта отсутствует</Typography>
                          )}
                        </TableCell>

                        <TableCell align="center" className={'contact'}>
                          <Typography sx={{ fontWeight: '500  !important' }}>{row?.contact?.full_name || 'ФИО отсутствует'}</Typography>
                          <Typography>{phoneFormat(row?.contact?.phone)}</Typography>
                          {row?.contact?.email ? (
                            <Link href={`mailto:${row?.contact.email}`} underline="none">{row?.contact.email}</Link>
                          ) : (
                            <Typography>Почта отсутствует</Typography>
                          )}
                        </TableCell>

                        <TableCell align="center" className={'lead'}>
                          <Typography>
                            {row?.source ? (
                              <FormattedMessage id={`partnership.applications.source.${row?.source}`} />
                            ) : (
                              "---"
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {isLoaderPage && (
                <Box sx={{ marginTop: '65px', textAlign: "center" }}>
                  <CircularProgress />
                </Box>
              )}
            </>
          ) : (
            <Typography sx={{ marginTop: '30px' }}>Заявок пока нет</Typography>
          )
        )}

        <FooterInf />
      </Box>

      {showModalFilter && renderModalFilter()}

      {showModalClientTransfer && (
        <Dialog onClose={handleToggleDialog.bind(this, 'showModalClientTransfer', false)} open={true} sx={styles.modal}>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              xs: 'space-between',
              sm: 'flex-end',
              md: 'flex-end',
              lg: 'flex-end',
            },
            paddingBottom: '11px',
          }}>
            <Typography sx={styles.modalTitleMob}>Передать клиента на лизинг</Typography>
            <IconButton aria-label="close" onClick={handleToggleDialog.bind(this, 'showModalClientTransfer', false)}>
              <SvgIcon>
                <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd" />
              </SvgIcon>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Typography sx={styles.modalTitle}>Передать клиента на лизинг</Typography>

              {stepFields.map(({
                FieldComponent,
                componentProps,
                name,
              }) => (
                <FieldComponent
                  control={control}
                  key={name}
                  name={name}
                  sx={styles.modalInput}
                  {...componentProps}
                />
              ))}

              <Box sx={{ marginTop: '16px' }}>
                <UploadButton
                  label={"Прикрепите файл КП"}
                  onChange={onAttachFile}
                  paramsDropzone={{ multiple: false }}
                />
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '28px', marginTop: '35px' }}>
                <Button
                  disabled={loadingCreateApplication}
                  sx={styles.modalButton}
                  type={"submit"}>Передать</Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {showModalClientTransferComplete && (
        <Dialog onClose={handleToggleDialog.bind(this, 'showModalClientTransferComplete', false)} open={true} sx={styles.modalComplete}>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              xs: 'space-between',
              sm: 'flex-end',
              md: 'flex-end',
              lg: 'flex-end',
            },
            paddingBottom: '11px',
          }}>
            <IconButton aria-label="close" onClick={handleToggleDialog.bind(this, 'showModalClientTransferComplete', false)}>
              <SvgIcon>
                <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd" />
              </SvgIcon>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Box component="img" src={iconCompleteTransferClient} sx={styles.modalCompleteImg} />
            <Typography sx={styles.modalCompleteTitle}>Клиент успешно передан</Typography>
          </DialogContent>
        </Dialog>
      )}

      {showModalClientTransferError && (
        <Dialog onClose={handleToggleDialog.bind(this, 'showModalClientTransferError', false)} open={true} sx={styles.modalComplete}>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              xs: 'space-between',
              sm: 'flex-end',
              md: 'flex-end',
              lg: 'flex-end',
            },
            paddingBottom: '11px',
          }}>
            <IconButton aria-label="close" onClick={handleToggleDialog.bind(this, 'showModalClientTransferError', false)}>
              <SvgIcon>
                <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd" />
              </SvgIcon>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Typography sx={styles.modalCompleteTitle}>Произошла ошибка. Попробуйте позже.</Typography>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}