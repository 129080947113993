import { useWatch } from "react-hook-form";

export default function ConditionalRender({ children, control, dependsOn, when }) {
  const value = useWatch({
    control,
    name: dependsOn,
  });

  if (when(value)) {
    return children;
  }

  return null;
}
