import React, { useEffect, useMemo, useState, useCallback } from "react";

import { Box, Container, Typography, TextField, Button, CircularProgress } from "@material-ui/core";
import { FooterContainer } from "@product-site-frontend/gatsby-theme-blocks";
import { Layout } from "@product-site-frontend/landing";
import { navigate } from "gatsby";
import qs from "querystringify";
import useFetch from "use-http";

import { arenzaStorage, AUTH_TOKEN_PARTNER_STORAGE_KEY } from "../../core/storage";
import iconNoSee from "./img/no_see.svg";
import iconSee from "./img/see.svg";

export default function ActivatePartnerUser() {
  const styles = useMemo(() => ({
    title: {
      marginBottom: '35px',
      fontSize: '26px',
      fontWeight: 900,
    },
    input: {
      borderRadius: '8px',
      // overflow: 'hidden',
      maxWidth: '408px',
      width: '100%',
      height: '56px',

      '& fieldset': {
        border: 'none',
        background: '#F5F6F7',
      },
      '& img': {
        zIndex: 3,
      },
      '& input': {
        zIndex: 3,

        '&::-ms-clear, &::-ms-reveal':  {display: 'none'}
      }
    },
    button: {
      background: '#FF780F',
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: 500,
      borderRadius: '8px',
      height: '56px',
      paddingLeft: '24px',
      paddingRight: '24px',
      textTransform: 'capitalize',
      boxShadow: '0px 10px 24px rgba(255, 120, 15, 0.3)',

      '&:hover': {
        background: '#FF9012',
        boxShadow: '0px 6px 16px rgba(255, 120, 15, 0.3)',
      },
      '&:active': {
        background: '#E76F10',
        boxShadow: 'none',
      },
      '&:disabled': {
        background: '#CFD2D6',
        boxShadow: 'none',
        color: '#ffffff',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
  }), []);

  const { uid } = qs.parse(window.location.search);

  const { get: getCheckActivationToken, loading: loadingCheckActivationToken,  response: responseCheckActivationToken } = useFetch();

  useEffect(() => {
    if (!uid) {
      navigate('/partnership/login');
    }

    onCheckActivationToken(uid);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  const [showTokenError, setShowTokenError] = useState(false);
  const [textTokenError, setTextTokenError] = useState(null);
  const [showErrors, setShowErrors] = useState(false);

  const onCheckActivationToken = useCallback(async (uid) => {
    await getCheckActivationToken(`/partners/check_activation_token?token=${uid}`);

    if (responseCheckActivationToken?.data?.status === 'error') {
      switch (responseCheckActivationToken?.data?.message) {
        case 'Token is expired':
          setTextTokenError('Срок действия токена истек.')
          break;

        case 'Token already activated':
          setTextTokenError('Токен уже активирован.')
          break;

        case 'Invalid token':
          setTextTokenError('Недействительный токен.')
          break;

        default:
          setTextTokenError('Неопознанная ошибка по токену. Попробуйте позже.');
      }

      setShowTokenError(true);
    } else if (!responseCheckActivationToken.ok) {
      setShowErrors(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseCheckActivationToken]);

  const { post: postActivateUser, response: responseActivateUser } = useFetch(`/partners/activate_user`);

  const [passwordSavedSuccessfully, setPasswordSavedSuccessfully] = useState(false);

  async function sendActivate() {
    const result = await postActivateUser({
      'token': uid,
      'password': password,
      'password_confirmation': passwordConfirm,
    });

    if (responseActivateUser.ok) {
      setPasswordSavedSuccessfully(true);

      setTimeout(() => {
        if (result?.token) {
          arenzaStorage(AUTH_TOKEN_PARTNER_STORAGE_KEY, result.token);
          navigate('/partnership');
        } else {
          navigate('/partnership/login');
        }
      }, 3000);
    } else {
      setShowErrors(true);
    }
  }

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const onChangePassword = (type, event) => {
    let value = event?.target?.value.replace(/ /g,'');

    if (type === 'password') {
      setPassword(value);
    } else if (type === 'passwordConfirm') {
      setPasswordConfirm(value);
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);

  const onShownValue = type => {
    if (type === 'password') {
      setPasswordShown(true);
    } else if (type === 'passwordConfirm') {
      setPasswordConfirmShown(true);
    }
  };

  const onHideValue = () => {
    setPasswordShown(false);
    setPasswordConfirmShown(false);
  };

  const [allowSetPassword, setAllowSetPassword] = useState(false);

  useEffect(() => {
    if (password?.trim()?.length < 8) {
      return setAllowSetPassword(false);
    } else if (password !== passwordConfirm) {
      setAllowSetPassword(false);
    } else {
      setAllowSetPassword(true);
    }

  }, [password, passwordConfirm]);

  const renderContent = () => {
    if (loadingCheckActivationToken) {
      return  <CircularProgress />
    }

    if (showErrors) {
      return <Typography sx={ styles.title }>Произошла ошибка. Попробуйте позже.</Typography>
    }

    if (showTokenError) {
      return <Typography sx={ styles.title }>{textTokenError}</Typography>
    }

    if (passwordSavedSuccessfully) {
      return <Typography sx={ styles.title }>Пароль успешно сохранен.</Typography>
    }

    return  (
      <>
        <Typography sx={ styles.title }>Установите пароль</Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <TextField
            InputProps={{
              endAdornment: (
                <Box
                  alt={passwordShown ? "Скрыть пароль" : "Показать пароль"}
                  component="img"
                  height={24}
                  onMouseDown={onShownValue.bind(this, 'password')}
                  onMouseUp={onHideValue}
                  src={passwordShown? iconNoSee : iconSee}
                  sx={{ cursor: 'pointer' }}
                  width={24}
                />
              )
            }}
            onChange={onChangePassword.bind(this, 'password')}
            placeholder="Придумайте пароль"
            sx={styles.input}
            type={passwordShown ? 'text' : 'password'}
            value={password}
          />

          <TextField
            InputProps={{
              endAdornment: (
                <Box
                  alt={passwordConfirmShown ? "Скрыть пароль" : "Показать пароль"}
                  component="img"
                  height={24}
                  onMouseDown={onShownValue.bind(this, 'passwordConfirm')}
                  onMouseUp={onHideValue}
                  src={passwordConfirmShown? iconNoSee : iconSee}
                  sx={{ cursor: 'pointer' }}
                  width={24}
                />
              )
            }}
            onChange={onChangePassword.bind(this, 'passwordConfirm')}
            placeholder="Повторите пароль"
            sx={styles.input}
            type={passwordConfirmShown ? 'text' : 'password'}
            value={passwordConfirm}
          />
        </Box>

        <Box sx={{ marginTop: '35px' }}>
          <Button
            disabled={!allowSetPassword}
            onClick={sendActivate}
            sx={styles.button}
          >
            Установить
          </Button>
        </Box>
      </>
    )
  }


  return (
    <Layout>
      <Container maxWidth="md" sx={{ mt: 8, mb: "240px", textAlign: "center", minHeight: "100vh", }}>
        <Box>
          {renderContent()}
        </Box>

      </Container>
      <FooterContainer />
    </Layout>
  );
}