import React, { useEffect, useMemo, useState } from "react";

import useActiveLegalEntity from "@hooks/useActiveLegalEntity";
import {
  Box, Button,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Link } from 'gatsby';

import iconReturn from "../../img/arrReturn.svg";
import iconDownload from "../../img/download.svg";
import iconDesktop from "../../img/iconDesctop.svg";
import iconMobile from "../../img/iconMobile.svg";
import iconPad from "../../img/iconPad.svg";
import widgetPageDesktop from "../../img/widget-page-desktop.png";
import widgetPageMobile from "../../img/widget-page-mobile.png";
import widgetPagePad from "../../img/widget-page-pad.png";
import FooterInf from "../FooterInf";

export default function PageWidgetOnThePage() {
  const styles = useMemo(() => ({
    titlePage: {
      fontSize: '36px',
      fontWeight: 900,
      marginBottom: '48px',
    },
    wrapperContent: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '26px',

      '& > div': {
        width: {
          xs: '100%',
          lg: 'calc(50% - (26px / 2))',
        },
      },
    },
    title: {
      fontSize: '26px',
      fontWeight: 900,
    },
    psefUl: {
      marginTop: '24px',
      display: 'flex',
      gap: '10px',
      alignItems: 'center',

      '& p': {
        fontSize: '16px',
        fontWeight: 300,
      },

      '& span': {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#FF780F',
        color: '#ffffff',
        fontSize: '16px',
        fontWeight: '700',
        minWidth: '32px',
        width: '32px',
        minHeight: '32px',
        height: '32px',
        borderRadius: '20px',
      }
    },
    wrapperYoutubeBlock: {
      display: 'flex',
      flexWrap: {
        xs: 'wrap',
        md: 'nowrap',
      },
      gap: '24px',
      fontSize: '16px',
      fontWeight: '300',
      margin: '32px 0',

      '& .video': {
        minWidth: '240px',
        minHeight: '178px',
        width: '100%',
        height: '100%',
      }
    },
    wrapperButtonView: {
      display: 'flex',
      gap: '16px',
      justifyContent: 'center',
    },
    wrapperImg: {
      height: '360px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '33px',
      marginBottom: '33px',

      '& img': {
        height: '90%',
      },
    },
    buttonView: {
      width: '96px',
      height: '96px',
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '8px',
      fontSize: '16px',
      fontWeight: 500,
      borderRadius: '16px',
      background: '#F5F6F7',
      cursor: 'pointer',
      border: '1px solid #F5F6F7',
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

      '&:hover': {
        background: '#FFFFFF',
        border: '1px solid #FB8F10'
      },
      '&.active': {
        background: '#FFFFFF',
        border: '1px solid #FFFFFF',
        cursor: 'default',
        boxShadow: '0px 10px 32px 0px rgba(13, 29, 50, 0.10)',

        '& path': {
          fill: '#FB8F10',
        }
      },
    },
    buttonReturn: {
      width: '56px',
      height: '56px',
      border: '1px solid #D0D2D6',
      marginRight: '24px',
    },
    whiteButton: {
      background: '#ffffff',
      border: '1px solid #FFBB86',
      borderRadius: '8px',
      color: '#FF7810',
      fontSize: '16px',
      padding: '12px 20px',
      maxHeight: '48px',
      marginTop: '30px',
      textTransform: 'inherit',
      fontWeight: '500',
      marginBottom: '30px',

      '&:hover': {
        background: '#FFF5EC',
        color: '#FF7810',
        border: '1px solid #FFA157',
      },
      '&:active': {
        background: '#FFEEE1',
        color: '#D8670D',
        border: '1px solid #D8670D',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    codeInsert: {
      background: '#F5F6F7',
      color: '#6E7884',
      fontSize: '16px',
      fontWeight: '300',
      borderRadius: '8px',
      padding: '8px 16px',
      marginTop: '12px',
      marginLeft: '40px',
      wordWrap: 'break-word',
    },
    linkFile: {
      fontSize: '16px',
      fontWeight: 500,
      color: '#FF780F',
      display: 'flex',
      gap: '8px',
      marginTop: '16px',
      marginLeft: '40px',
      cursor: 'pointer',
    }
  }),[])

  const [ activeView, setActiveView ] = useState('desktop')

  const onToggleView = view => {
    setActiveView(view)
  }

  const [partnerToken, setPartnerToken] = useState('ТОКЕН');

  const activeLegalEntity = useActiveLegalEntity();

  useEffect(() => {
    if (activeLegalEntity?.data?.partnerToken) {
      setPartnerToken(activeLegalEntity?.data?.partnerToken);
    }
  }, [activeLegalEntity]);

  const createFile = () => {
    let content = `
      <html lang="">
        <head>
          <meta charset="UTF-8">
          <title>widget_arenza</title>
        </head>
        <body>
          <iframe id="arenzaWidget" src="${process.env.GATSBY_CRMPRO_URL}/partners/${partnerToken}/widget?iframe=true" height="100%" width="100%" frameborder="0"> </iframe>
          <script>
            window.addEventListener('message', function(e) {
              var iframe = document.getElementById('arenzaWidget');
              var eventName = e.data[0];
              var data = e.data[1];
              switch(eventName) {
              case 'setHeight':
                iframe.style.height = data + "px";
                break;
              }
            }, false);
          </script>
        </body>
      </html>

    `;

    let file = new File(["\ufeff"+content], 'widget_arenza.html', {type: "text/plain:charset=UTF-8"});
    let url = window.URL.createObjectURL(file);

    let a = document.createElement("a");
    a.style = "display: none";
    a.href = url;
    a.download = file.name;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  return (
    <>
      <Box sx={{ background: '#fff' }}>
        <Box>
          <Typography sx={ styles.titlePage }>
            <IconButton component={Link} sx={ styles.buttonReturn } to={"/partnership/marketing"}>
              <Box component={"img"} src={iconReturn} />
            </IconButton>

            Виджет - баннер
          </Typography>

          <Box sx={ styles.wrapperContent }>
            <Box sx={ styles.content }>
              <Typography sx={ styles.title }>Как работает виджет - баннер</Typography>

              <Box sx={ styles.wrapperYoutubeBlock }>
                <Box className={'video'}>
                  <iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen frameBorder="0" src="https://www.youtube.com/embed/DGVnQPZxHnI" title="YouTube video player" width="100%"></iframe>
                </Box>
                <Box>Виджет на странице – это окно для передачи заявки, которое размещается непосредственно на странице сайта и всегда находится в развернутом положении. Заявка на лизинг, отправленная с виджета, сразу попадает в CRM систему Arenza и фиксируется менеджерами.</Box>
              </Box>

              <Box>
                <Typography sx={ styles.title }>Как установить?</Typography>

                <Typography sx={ styles.psefUl }>
                  <span>1</span>

                  <Typography component={'p'}>Скачайте HTML-файл с виджетом или скопируйте HTML-код ниже:</Typography>
                </Typography>

                <Typography onClick={createFile} sx={ styles.linkFile }>
                  <Box alt={'Скачать виджет'} component={'img'} src={iconDownload} />
                  Скачать виджет
                </Typography>

                <Box sx={ styles.codeInsert }>
                  {`<iframe id="arenzaWidget" src="${process.env.GATSBY_CRMPRO_URL}/partners/${partnerToken}/widget?iframe=true" height="100%" width="100%" frameborder="0" style="height: 1207px;"> </iframe>`}<br />
                  <br />
                  {'<script>'}<br />
                  &nbsp;&nbsp;&nbsp;{'window.addEventListener(\'message\', function(e) {'}<br />
                  &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{'var iframe = document.getElementById(\'arenzaWidget\');'}<br />
                  &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{'var eventName = e.data[0];'}<br />
                  &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{'var data = e.data[1];'}<br />
                  <br />
                  &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{'switch(eventName) {'}<br />
                  &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{'case \'setHeight\':'}<br />
                  &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{'iframe.style.height = data + "px";'}<br />
                  &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{'break;'}<br />
                  &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{'}'}<br />
                  &nbsp;&nbsp;&nbsp;{'}, false);'}<br />
                  {'</script>'}
                </Box>

                <Typography sx={ styles.psefUl }>
                  <span>2</span>

                  <Typography component={'p'}>Добавьте HTML-код на страницу (внутрь тега &#60;body&#62;), либо в head сайта или страницы.</Typography>
                </Typography>

                <Typography sx={ styles.psefUl }>
                  <span>3</span>

                  <Typography component={'p'}>Готово! Благодаря уникальному токену, зашитому в HTML-код, мы сможем понять, откуда пришла заявка.</Typography>
                </Typography>
              </Box>

              <Box>
                Подробнее о виджете - баннере см. в инструкции:
                &nbsp;
                <Button
                  component="a"
                  download={true}
                  href={`${process.env.GATSBY_DOCUMENTS_PUBLIC_URL}/widget_instructions/widget_page.pdf`}
                  sx={ styles.whiteButton }
                  target="_blank">
                  <Box alt={'Скачать инструкцию'} component={'img'} src={iconDownload} />

                  Скачать инструкцию
                </Button>
              </Box>
            </Box>

            <Box>
              <Typography sx={{ ...styles.title, textAlign: 'center' }}>Вид виджета на различных устройствах</Typography>

              <Box sx={ styles.wrapperImg }>
                {activeView === "desktop" && (
                  <Box alt={'Desktop'} component={'img'} src={widgetPageDesktop} />
                )}

                {activeView === "pad" && (
                  <Box alt={'Pad'} component={'img'} src={widgetPagePad} />
                )}

                {activeView === "mobile" && (
                  <Box alt={'Mobile'} component={'img'} src={widgetPageMobile} />
                )}
              </Box>

              <Box sx={ styles.wrapperButtonView }>
                <Box className={activeView === 'desktop' ? 'active' : ''} onClick={onToggleView.bind(this, 'desktop')} sx={ styles.buttonView }>
                  <Box alt={"Desktop"} component={"img"} src={iconDesktop} />

                  Desktop
                </Box>

                <Box className={activeView === 'pad' ? 'active' : ''} onClick={onToggleView.bind(this, 'pad')} sx={ styles.buttonView }>
                  <Box alt={"Pad"} component={"img"} src={iconPad} />

                  Pad
                </Box>

                <Box className={activeView === 'mobile' ? 'active' : ''} onClick={onToggleView.bind(this, 'mobile')} sx={ styles.buttonView }>
                  <Box alt={"Mobile"} component={"img"} src={iconMobile} />

                  Mobile
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <FooterInf />
      </Box>
    </>
  );
}