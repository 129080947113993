import { responsiveFontSizes } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { theme } from "@product-site-frontend/shared";

export const THEME_CONFIG = {
  mixins: {
    toolbar: {
      [theme.breakpoints.up("sm")]: {
        minHeight: 72,
      },
      [`${theme.breakpoints.up("lg")} and (orientation: landscape)`]: {
        minHeight: 144,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 601,
      md: 1025,
      lg: 1385,
    },
  },
  typography: {
    // fontSize: 16,
    fontFamily: ["Museo Sans", "Helvetica", "sans-serif"].join(","),
    h1: {
      fontWeight: 600,
      fontSize: 24,
      "&.MuiTypography-gutterBottom": {
        marginBottom: 40,
      },
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,
      "&.MuiTypography-gutterBottom": {
        marginBottom: 16,
      },
    },
    h3: {
      fontSize: 18,
      fontWeight: 700,
      "&.MuiTypography-gutterBottom": {
        marginBottom: 16,
      },
    },
    h4: {
      fontSize: 16,
      fontWeight: 700,
      "&.MuiTypography-gutterBottom": {
        marginBottom: 16,
      },
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: 700,
    },
    body1: {
      fontWeight: 500,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: 400,
    },
    button: {
      fontWeight: 700,
    },
    overline: {
      textDecoration: "none",
    },
    link: {
      display: "inline-flex",
      textDecoration: "none",
      fontWeight: 500,
      transition: "opacity ease-in 0.2s",
      fontSize: 16,
      "&:hover": {
        opacity: 0.75,
      },
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "div",
          subtitle2: "div",
          body1: "div",
          body2: "div",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
          // padding: "10px 16px",
        },
        outlinedSecondary: {
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: "#ecf7fb",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              backgroundColor: "white",
            },
          },
        },
        outlinedPrimary: {
          color: "#505050",
          borderWidth: 2,
          "&:hover": {
            borderWidth: 2,
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              borderWidth: 2,
            },
          },
        },
        sizeLarge: {
          fontWeight: 600,
          fontSize: 16,
          padding: "12px 18px 13px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "legend&, legend&.Mui-focused": {
            fontWeight: 600,
            color: "#373737",
            marginBottom: 16,
          },
          "&$error": {
            color: "inherit",
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "normal",
      },
      styleOverrides: {
        marginNormal: {
          // marginTop: 4,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 16,
          fontWeight: 500,
          userSelect: "none",
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.MuiStepIcon-completed": {
            color: "#00c227",
          },
          "&.Mui-active": {
            color: "#00c227",
          },
        },
      },
    },

    MuiRadio: {
      defaultProps: {
        color: "primary",
      },
      styleOverrides: {
        root: {
          "&.Mui-checked + .MuiFormControlLabel-label": {
            color: "#2F2F2F",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        // helperText: " ",
        margin: "normal",
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          color: "rgba(0,0,0,0.87)",
          fontWeight: 500,
          backgroundColor: "#eef2f7",
          // backgroundColor: "#fcfcfb",
          // ---
          border: "1px solid #e2e2e1",
          overflow: "hidden",
          borderRadius: 4,
          //     transition: theme.transitions.create([
          //       'border-color',
          //       'background-color',
          //       'box-shadow',
          //     ]),
          // ---

          // "&:hover": {
          //   // backgroundColor: "#e4eaf2",
          //   backgroundColor: "transparent",
          // },
          // "&:hover:not(.Mui-disabled)": {
          //   backgroundColor: "#e4eaf2",
          //   // backgroundColor: "transparent",
          // },

          "&.Mui-focused": {
            backgroundColor: "white",
            // backgroundColor: "transparent",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "rgba(0,0,0,0.87)",
          fontWeight: 500,
        },
      },
    },
    MuiCssBaseline: {
      /* Выставляем основные настройки по-умолчанию для body */
      styleOverrides: {
        ":root": {
          "--swiper-theme-color": `#f6863a !important`,
          "--swiper-pagination-color": `var(--swiper-theme-color)`,
          "--swiper-navigation-color": `var(--swiper-theme-color)`,
        },
        // https://hankchizljaw.com/wrote/a-modern-css-reset/
        /* Указываем box sizing */
        "*, *::before, *::after": {
          boxSizing: "border-box",
        },

        /* Выставляем основные настройки по-умолчанию для body */
        body: {
          minHeight: "100vh",
          scrollBehavior: "smooth",
          textRendering: "optimizeSpeed",
          lineHeight: 1.5,
          backgroundColor: '#ffffff',
        },

        /* Убираем внешние отступы */
        "body, h1, h2, h3, h4, p, ul, ol, li, figure, figcaption, blockquote, dl, dd": {
          margin: 0,
        },

        /* Убираем внутренние отступы */
        /* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
        "ul, ol": {
          padding: 0,
          listStyle: "none",
        },

        /* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
        "a:not([class])": {
          textDecorationSkipInk: "auto",
        },

        /* Упрощаем работу с изображениями */
        img: {
          maxWidth: "100%",
          display: "block",
        },

        /* Указываем понятную периодичность в потоке данных у article*/
        "article > * + *": {
          marginTop: "1em",
        },

        // /* Наследуем шрифты для инпутов и кнопок */
        "input, button, textarea, select": {
          font: "inherit",
        },

        /* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
        "@media (prefers-reduced-motion: reduce)": {
          "*": {
            animationDuration: "0.01ms !important",
            animationIterationCount: "1 !important",
            transitionDuration: "0.01ms !important",
            scrollBehavior: "auto !important",
          },
        },

        a: {
          color: "unset",
          textDecoration: "unset",
        },
      },
    },
  },
};

const accountTheme = createTheme(theme, THEME_CONFIG);

export default responsiveFontSizes(accountTheme, { factor: 3 });
