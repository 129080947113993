import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from "react";

import FormattedCurrency from '@components/FormattedCurrency';
import { Box } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import TableInvoice from "./TableInvoice";
import TableShipping from './TableShipping';

const styles = {
    accordeonHeader: {
        padding: '16px 24px',
        backgroundColor: '#F5F6F7',
        borderTop: '1px solid #E7E8EA',
        boxSizing: 'border-box',
        display: 'grid',
        gridTemplateColumns: {
            xs: '1fr',
            lg: '1fr 128px 169px'
        },
        alignItems: 'center',
        gap: '8px',
        cursor: 'pointer',
    },
    title: {
        fontSize: '20px',
        lineHeight: '34px',
        fontWeight: '600'
    },
    thin: {
        fontSize: '16px',
        lineHeight: '24px 41px 24px 24px',
        fontWeight: '300'
    },
    thinGrey: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '300',
        color: '#9EA5AD'
    },
    arrow: {
        color: '#B6BBC2',
        textAlign: {
            xs: 'center',
            lg: 'right'
        },

        '& > svg': {
            width: '36px',
            height: 'auto'
        }
    }
};

const getMonthFromNumber = (number) => {
    const month = [
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь',
    ];

    return month[number];
};

export default function TableGroup({ data, startNumber }) {
    const [isOpen, setIsOpen] = useState(false);

    const onClickOpenToggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const elArrow = useMemo(() => {
        if (isOpen) {
            return <KeyboardArrowUpIcon />
        }

        return <KeyboardArrowDownIcon />
    }, [isOpen]);

    const getMonthString = (dateStr) => {
        const dateArr = dateStr.split('-');
        const year = dateArr[0];
        const month = getMonthFromNumber(parseInt(dateArr[1], 10) - 1);

        return `${month} ${year}`;
    };

    const countInvoicesSumm = (invoiceArr) => {
        if (invoiceArr?.length > 0) {
            let summFull = 0;
            let summVat = 0;

            invoiceArr.forEach((invoice) => {
                summFull += invoice.amount_including_vat;
                summVat += invoice.vat_amount;
            });

            return (
                <>
                    <Box sx={styles.thin}><FormattedCurrency value={summFull} />&nbsp;₽</Box>
                    <Box sx={styles.thinGrey}><FormattedCurrency value={summVat} />&nbsp;₽</Box>
                </>
            );
        }

        return null;
    };

    return (
        <Box>
            <Box onClick={onClickOpenToggle} sx={styles.accordeonHeader}>
                <Box sx={styles.title}>
                    Начисления за {data?.month && (getMonthString(data.month))}.
                </Box>
                <Box>
                    {countInvoicesSumm(data.invoices)}
                </Box>
                <Box sx={styles.arrow}>
                    {elArrow}
                </Box>
            </Box>

            {isOpen && (
                <>
                    {data.supplies?.length > 0 && (
                        data.supplies.map((supplie, id) => (
                            <TableShipping data={supplie} key={`supply_${id}`} />
                        ))
                    )}
                    {data.invoices?.length > 0 && (
                        data.invoices.map((invoice, id) => (
                            <TableInvoice data={invoice} key={`invoice_${id}`} number={startNumber + id} />
                        ))
                    )}
                </>
            )}
        </Box>
    );
};

TableGroup.propTypes = {
    data: PropTypes.object,
    startNumber: PropTypes.number
};
