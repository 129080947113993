import React, { useEffect, useState } from "react";

import iconNoSee from "@components/ActivePartnerUser/img/no_see.svg";
import iconSee from "@components/ActivePartnerUser/img/see.svg";
import { arenzaStorage, AUTH_TOKEN_PARTNER_STORAGE_KEY } from "@core/storage";
import { Container, Typography, Box, Button } from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { FooterContainer } from "@product-site-frontend/gatsby-theme-blocks";
import { Layout } from "@product-site-frontend/landing";
import { FormFieldContainer } from "@product-site-frontend/shared";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import useFetch from "use-http";

export default function PageLoginPartner() {
  useEffect(() => {
    if (arenzaStorage(AUTH_TOKEN_PARTNER_STORAGE_KEY)) {
      navigate("/partnership");
    }
  }, []);

  const [showRecover, setShowRecover] = useState(false);

  const onToggleRecover = () => {
    setShowRecover(!showRecover);
  }

  return (
    <Layout>
      <Container maxWidth="md" sx={{ mt: 8, mb: "240px", textAlign: "center" }}>
        {!showRecover ? (
          <>
            <Typography sx={{ mb: 3, fontWeight: 900, fontSize: "28px !important" }} variant="h1">
              Вход в личный кабинет партнёра Arenza
            </Typography>

            <Login onToggleRecover={onToggleRecover} />
          </>
        ): (
          <>
            <Typography sx={{ mb: 3, fontWeight: 900, fontSize: "28px !important" }} variant="h1">
              Восстановление пароля
            </Typography>

            <RecoverPass onToggleRecover={onToggleRecover} />
          </>
        )}

      </Container>

      <FooterContainer />
    </Layout>
  );
}

function Login(params) {
  const { loading, post, response } = useFetch(`/partners/login`);
  const { control, handleSubmit } = useForm({
    mode: "all",
    shouldFocusError: true,
  });

  const clock = () => {
    const { onToggleRecover } = params;
    if (onToggleRecover && typeof onToggleRecover === "function") {
      onToggleRecover();
    }
  };

  const [showError, setShowError] = useState(null);

  async function onSubmit(data) {
    const result = await post({ ...data });

    if (response.ok && result?.token) {
      arenzaStorage(AUTH_TOKEN_PARTNER_STORAGE_KEY, result.token);
      // window.location.reload();
      navigate("/partnership");
    } else {
      if (response.status === 403) {
        if (response?.data?.error === "User disabled") {
          setShowError('Доступ в ЛК ограничен. Обратитесь в поддержку Аренза.');
        } else {
          setShowError('Данный пользователь не найден или указан неверный пароль.');
        }
      } else {
        setShowError('При авторизации произошла ошибка. Попробуйте позже.');
      }
    }
  }

  const [passwordShown, setPasswordShown] = useState(false);

  const onShownValue = () => {
    setPasswordShown(true);
  };

  const onHideValue = () => {
    setPasswordShown(false);
  };

  return (
    <>
      <Container
        component="form"
        maxWidth="sm"
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <FormFieldContainer
          InputLabelProps={{ htmlFor: "form-application-login" }}
          control={control}
          fieldType="text"
          id="form-application-login"
          label="Электронная почта"
          name="login"
          rules={{
            required: "Укажите свою почту",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Введите корректную почту"
            }
          }}
          sx={{ mb: 3 }}
        />

        <FormFieldContainer
          InputLabelProps={{ htmlFor: "form-application-password" }}
          InputProps={{
            endAdornment: (
              <Box
                alt={passwordShown ? "Скрыть пароль" : "Показать пароль"}
                component="img"
                height={24}
                onMouseDown={onShownValue.bind(this, 'passwordConfirm')}
                onMouseUp={onHideValue}
                src={passwordShown? iconNoSee : iconSee}
                sx={{ cursor: 'pointer' }}
                width={24}
              />
            )
          }}
          control={control}
          fieldType="text"
          id="form-application-password"
          label="Пароль"
          name="password"
          rules={{
            required: "Укажите пароль",
            minLength: {
              value: 8,
              message: "Пароль должен быть минимум 8 символов",
            },
            pattern: {
              value: /^\S+$/i,
              message: "Пароль не может содержать пробелы",
            }
          }}
          sx={{ mb: 1 , '& input': { '&::-ms-clear, &::-ms-reveal':  {display: 'none'} }}}
          type={passwordShown ? 'text' : 'password'}
        />
        <Box sx={{ width: '100%', textAlign: 'left' }}>
          <Typography component={'span'} onClick={clock} sx={{ display: 'inline-block', color: '#ff6600', fontSize: '14px', cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}>Забыли пароль?</Typography>
        </Box>

        <div style={{ width: "296px", marginTop: '40px' }}>
          <LoadingButton
            disableElevation
            fullWidth
            loading={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            Продолжить
          </LoadingButton>
        </div>

        {showError && (
          <Typography sx={{ marginTop: '40px', fontSize: '20px' }}>{showError}</Typography>
        )}
      </Container>
    </>
  );
}

function RecoverPass(params) {
  const { loading, post, response } = useFetch(`/partners/reset_password`);
  const { control, handleSubmit } = useForm({
    mode: "all",
    shouldFocusError: true,
  });

  const [completeSendRecover, setCompleteSendRecover] = useState(false);
  const [errorSendRecover, setErrorSendRecover] = useState(false);
  const [login, setLogin] = useState(null);

  async function onSendRecover() {
    await post({
      "email": login,
    });

    if (response.ok) {
      setCompleteSendRecover(!completeSendRecover);
    } else {
      setErrorSendRecover(!errorSendRecover);
    }
  }

  const onChangeField = event => {
    const value = event?.field?.value || null;

    setLogin(value);
  }

  const returnAuthScreen = () => {
    const { onToggleRecover } = params;

    if (onToggleRecover && typeof onToggleRecover === 'function') {
      onToggleRecover(false);
    }
  }

  const returnRecoverScreen = () => {
    setErrorSendRecover(false);
  }

  const renderContenr = () => {
    if (errorSendRecover) {
      return (
        <>
          <Typography sx={{ mt: 6, mb: 3 }}>При восстановлении пароля произошла ошибка. Попробуйте позже.</Typography>

          <Button onClick={returnRecoverScreen}>
            Вернуться
          </Button>
        </>
      )
    }

    if (completeSendRecover) {
      return (
        <>
          <Typography sx={{ mt: 6, mb: 3 }}>Ссылка на восстановление пароля была отправлена на email.</Typography>

          <Button onClick={returnAuthScreen}>
            Вернуться
          </Button>
        </>
      )
    }

    return (
      <>
        <FormFieldContainer
          InputLabelProps={{ htmlFor: "form-application-login" }}
          control={control}
          fieldType="text"
          id="form-application-login"
          inputProps={{ value: params?.login || null, autoFocus: true }}
          label="Электронная почта"
          name="login"
          onChange={onChangeField}
          rules={{
            required: "Укажите свою почту",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Введите корректную почту"
            }
          }}
          sx={{ mt: 6, mb: 2 }}
          value={params?.login || null}
        />

        <div style={{ width: "296px", marginTop: '40px' }}>
          <LoadingButton
            disableElevation
            fullWidth
            loading={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            Продолжить
          </LoadingButton>
        </div>
      </>
    )
  }
  
  return (
    <>
      <Container
        component="form"
        maxWidth="sm"
        onSubmit={handleSubmit(onSendRecover)}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        {renderContenr()}
      </Container>
    </>
  );
}