import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Box, Button, CircularProgress, Container, Typography } from "@material-ui/core";
import { FormFieldContainer } from "@product-site-frontend/shared";
import { SmartCaptcha } from "@yandex/smart-captcha";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import useFetch from "use-http";
import { useTimer } from "use-timer";

import config from "../../../config/website";
import { arenzaStorage, AUTH_TOKEN_STORAGE_KEY } from "../../core/storage";

const OTP_LENGTH = 4;

function TimeoutMessage({ children, initialTime }) {
  const { reset, start, time } = useTimer({
    initialTime,
    timerType: "DECREMENTAL",
    autostart: true,
    endTime: 0,
  });

  useEffect(() => {
    reset();
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTime]);

  return children({ time });
}

FormOtp.propTypes = {
  loadingOtpSubmit: PropTypes.bool,
  onSubmit: PropTypes.func,
  phone: PropTypes.string,
  yandexCaptchaToken: PropTypes.string,
};

export default function FormOtp({ loadingOtpSubmit, onSubmit, phone, yandexCaptchaToken: propYandexCaptchaToken  }) {
  const [yandexCaptchaToken, setYandexCaptchaToken] = useState(propYandexCaptchaToken || '');

  const { control, handleSubmit, setError, watch } = useForm({
    mode: "onTouch",
    shouldFocusError: true,
  });

  const watchOtp = watch("otp");

  const { data, loading, post, response } = useFetch(`/login`, {
    cachePolicy: "no-cache",
    retries: 3,
    retryDelay: ({ attempt }) => Math.min(attempt > 0 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
    async retryOn({ attempt, error, response }) {
      // retry on any network error, or 5xx status codes
      if (error || response?.status >= 500) {
        if (attempt > 0) {
          arenzaStorage.remove(AUTH_TOKEN_STORAGE_KEY);
        }
        return true;
      }
    },
  });

  useEffect(() => {
    sendOtp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (watchOtp?.length === OTP_LENGTH) {
      handleSubmit(onOtpSubmit)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchOtp]);

  const [errorSendOtp, setErrorSendOtp] = useState('')

  async function sendOtp() {
    await post("/send_otp", { phone, smart_token: yandexCaptchaToken });

    setYandexCaptchaToken('');

    if (response?.data?.error === 'Invalid Captcha') {
      setErrorSendOtp('Ошибка при вводе капчи');
    }

    if (response.status === 404) {
      navigate(`${config.siteUrl}/?short_app`);
    }
  }

  if (errorSendOtp && errorSendOtp?.length) {
    return (
      <Container component="form" maxWidth="sm">
        <Typography sx={{ fontSize: '1.25rem', marginBottom: '20px' }}>
          {errorSendOtp}
        </Typography>
      </Container>
    )
  }

  async function onOtpSubmit(data) {
    const result = await onSubmit(data);

    if (result && result.status === 429) {
        setError("otp", { type: "manual", message: "Слишком много неудачных попыток. Попробуйте позже." })
    }

    if (result && result.status === 422) {
      setError("otp", { type: "manual", message: "Введенный код некорректный." })
    }
  }

  return (
    <Container component="form" maxWidth="sm">
      <FormFieldContainer
        InputLabelProps={{ htmlFor: "form-application-otp" }}
        control={control}
        // disabled={loading} NOTE: НЕЛЬЗЯ ИСПОЛЬЗОВАТЬ. СМ. Доки https://react-hook-form.com/api/useform/register. Вместо этого readOnly
        fieldType="text"
        id="form-application-otp"
        inputProps={{ maxLength: 4, inputMode: "numeric", autoFocus: true, readOnly: loading || loadingOtpSubmit }}
        label="Код из смс"
        name="otp"
        placeholder="0000"
        rules={{
          required: true,
          minLength: {
            value: 4,
            message: "Код из смс должен содержать 4 символа",
          },
        }}
        sx={{ mb: 1 }}
        type="tel"
      />

      {loading || loadingOtpSubmit ? (
        <CircularProgress />
      ) : (
        <TimeoutMessage initialTime={data?.timeout}>
          {({ time }) => {
            console.log("🚀 ~ file: FormOtp.jsx ~ line 100 ~ FormOtp ~ time", time);
            if (time > 0) {
              return (
                <Typography variant="caption">
                  Повторный запрос кода возможен через <b>{time} сек</b>
                </Typography>
              );
            }

            return (
              <>
                <Button
                  disabled={!yandexCaptchaToken.length}
                  onClick={sendOtp}
                  sx={{
                    my: 2,
                    textTransform: 'initial',
                    fontWeight: 400,
                    color: 'rgb(24, 113, 228)',

                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '& .MuiTouchRipple-root': {
                      display: 'none',
                    },
                  }}
                  underline="none"
                  variant="body2"
                >
                  Отправить еще раз
                </Button>

                <Box sx={{ marginBottom: '24px' }}>
                  <SmartCaptcha onSuccess={setYandexCaptchaToken} sitekey="ysc1_ukeyoNivym2HND6EMGFlIbLdbdmtpMHoOQ0MZ2CX092b1063" />
                </Box>
              </>
            );
          }}
        </TimeoutMessage>
      )}
    </Container>
  );
}
