import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';

import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogContentText, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { AlertError } from '@product-site-frontend/gatsby-theme-blocks';
import { ApplicationContext } from '@product-site-frontend/shared';
import * as Sentry from "@sentry/react";
import { useForm } from 'react-hook-form';
import store from 'store2';
import useFetch from 'use-http';
import * as yup from 'yup';

import { APPLICATION_FIELDS_CONFIG } from './fields-config';

const arenzaStorage = store.namespace('arenza');

const FIELDS_ORDER = [
  'full_name',
  'phone',
  'email',
  'inn',
  'amount',
  'attachments',
];

const DEFAULT_VALUES = {
  phone: '',
  email: '',
  full_name: '',
  attachments: [],
  amount: '',
};

FormApplication.propTypes = {
  entityId: PropTypes.string,
  onSuccess: PropTypes.func,
};
export default function FormApplication({ entityId, onSuccess }) {
  const { data: contextData } = useContext(ApplicationContext);

  const { amount, email, full_name, inn, phone } = contextData;

  function buildFormFields() {
    return FIELDS_ORDER.map((name) => APPLICATION_FIELDS_CONFIG[name]);
  }

  const formFields = useMemo(buildFormFields, []);

  const schema = useMemo(() => {
    return yup
      .object()
      .shape(formFields.reduce(
        (prev, curr) => ({ ...prev, [curr.name]: curr.validation }),
        {},
      ));
  }, [formFields]);

  const formMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });

  const {
    clearErrors,
    control,
    formState,
    getValues,
    handleSubmit,
    setError,
    setValue,
  } = formMethods;

  useEffect(() => setValue('amount', amount.toString()), [amount, setValue]);
  useEffect(() => setValue('inn', inn.toString()), [inn, setValue]);
  useEffect(() => setValue('phone', phone.toString()), [phone, setValue]);
  useEffect(() => setValue('full_name', full_name.toString()), [full_name, setValue]);
  useEffect(() => setValue('email', email.toString()), [email, setValue]);

  const fetchOptions = {
    cachePolicy: 'no-cache',
    onError: ({ error }) => {
      const values = getValues();

      Sentry.withScope(scope => {
        scope.setExtra("operation", "Серверная ошибка при отправке заявки")
        scope.setExtra("values", values)
        Sentry.captureException(error)
      });

      if (error.name === '422') {
        Object.entries(response.data.errors)
          .forEach(([fieldName, [message]]) =>
            setError(fieldName, {
              type: 'manual',
              message,
              shouldFocus: true,
            }),
          );
      } else {
        setError('FORM_ERROR', { message: error.message });
      }
    },
  };
  const { loading, post, response } = useFetch('/short_apps/full', fetchOptions);

  async function onSubmit({ attachments, ...data }) {
    let formData = new FormData();

    formData.append('entity_id', Number(entityId));

    formData.append('full_name', data.full_name);
    formData.append('amount', data.amount);
    formData.append('email', data.email);

    attachments.forEach((file) => formData.append('attachments[]', file));

    const result = await post(formData);

    if (response.ok) {
      arenzaStorage.session('application', result);
      onSuccess();
    } else if (response.status === 422) {
      Object.entries(response.data.errors).forEach(([fieldName, [message]]) =>
        setError(fieldName, { type: 'manual', message, shouldFocus: true }),
      );
    } else {
      Sentry.withScope(scope => {
        scope.setExtra("operation", 'Ошибка при отправке заявки')
        Sentry.captureException(result)
      });

      setError('FORM_ERROR', {
        message: JSON.stringify(result),
      });
    }
  }

  const Attachment = formFields.slice(-1)[0];

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        spellCheck="false"
      >
        {formFields.slice(0, -1).map(({
          FieldComponent,
          componentProps,
          name,
        }) => (
          <FieldComponent
            control={control}
            disabled={contextData?.[name] && contextData[name] !== ''}
            key={name}
            name={name}
            {...componentProps}
          />
        ))}
        <DialogContentText sx={{ fontWeight: 400 }}>
          Для ускорения процесса рассмотрения вашей заявки, Вы можете{' '}
          прикрепить файлы коммерческих предложений от поставщиков{' '}
          <Typography color="primary.main" component="span" variant="inherit">
            (в&nbsp;любом формате)
          </Typography>
          .
        </DialogContentText>
        <Attachment.FieldComponent
          control={control}
          key={Attachment.name}
          name={Attachment.name}
          {...Attachment.componentProps}
        />
        <AlertError
          message={formState.errors?.FORM_ERROR?.message}
          onClose={clearErrors}
          open={formState.errors?.FORM_ERROR ? true : false}
        />
        <LoadingButton
          color="primary"
          fullWidth
          loading={loading}
          size="large"
          sx={{ my: 4, mx: 'auto' }}
          type="submit"
          variant="contained"
        >
          Подать заявку
        </LoadingButton>
        <DialogContentText>
          <Typography variant="caption">
            Отправляя форму, вы даете согласие на обработку{' '}
            <Typography
              color="secondary"
              component="a"
              href="https://public-documents.arenza.ru/политика_обработки_персональных_данных.pdf"
              variant="inherit"
            >
              персональных данных
            </Typography>
            , указанных вами в заявке, в соответствии с требованиями{' '}
            Федерального закона №&nbsp;152-ФЗ от 27.07.2006{' '}
            «О персональных данных».
          </Typography>
        </DialogContentText>
      </form>
      <DevTool control={control} />
    </>
  );
}