import PropTypes from 'prop-types';
import React from 'react';

import { arenzaStorage, AUTH_TOKEN_STORAGE_KEY } from '@core/storage';
import { Provider } from 'use-http';

import FormApplication from './FormApplication';

const options = {
  headers: {
    Accept: 'application/json',
    Authorization: `Token ${arenzaStorage(AUTH_TOKEN_STORAGE_KEY)}`,
  },
  cachePolicy: 'no-cache',
};

ApplicationRoot.propTypes = {
  channel: PropTypes.string,
  entityId: PropTypes.string,
  onSuccess: PropTypes.func,
  url: PropTypes.string,
};
export default function ApplicationRoot({
  channel,
  entityId,
  onSuccess,
  url,
}) {
  return (
    <Provider options={options} url={url}>
      <FormApplication {...{ channel, entityId, onSuccess }} />
    </Provider>
  );
}