import PropTypes from 'prop-types';
import React from "react";

import FormattedCurrency from '@components/FormattedCurrency';
import { Box } from "@material-ui/core";
import ButtonIcon from '@material-ui/icons/NorthEastOutlined';
import { WrapperButton } from "@product-site-frontend/shared";
import dayjs from 'dayjs';

const styles = {
    accordeonElement: {
        padding: '16px 24px',
        backgroundColor: '#FFF',
        borderTop: '1px solid #E7E8EA',
        boxSizing: 'border-box',
        display: 'grid',
        gridTemplateColumns: {
            xs: '1fr',
            lg: '48px 1fr 110px 162px 128px 169px'
        },
        alignItems: 'center',
        gap: '8px'
    },
    icon: {
        fontWeight: '300',
        display: {
            xs: 'none',
            lg: 'block'
        }
    },
    mainData: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '300'
    },
    date: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '300'
    },
    leasing: {
        'a': {
            color: '#FF780F',
            fontWeight: '300',
            textDecoration: 'underline'
        }
    },
    thin: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '300'
    },
    thinGrey: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '300',
        color: '#9EA5AD'
    },
    lastColumn: {
        textAlign: 'right'
    }
};

export default function TableInvoice({ data, number }) {
    return (
        <Box sx={styles.accordeonElement}>
            <Box sx={styles.icon}>
                {number}
            </Box>
            <Box sx={styles.mainData}>
                Счет-фактура №{data.document_number}
            </Box>
            <Box sx={styles.date}>
                {dayjs(data.doc_date).format('DD/MM/YYYY')}
            </Box>
            <Box sx={styles.leasing}>
                <a href={`/leasing/deals/${data.loan_id}?charges=true`}>
                    {data.leasing_agreement} от {dayjs(data.leasing_agreement_date).format('DD/MM/YYYY')}
                </a>
            </Box>
            <Box>
                <Box sx={styles.thin}><FormattedCurrency value={data.amount_including_vat} />&nbsp;₽</Box>
                <Box sx={styles.thinGrey}><FormattedCurrency value={data.vat_amount} />&nbsp;₽</Box>
            </Box>
            <Box sx={styles.lastColumn}>
                {data?.edo_document_link && (
                    <WrapperButton colorScheme={'orange'} href={data.edo_document_link} sx={{ margin: '0 auto', fontSize: '12px', lineHeight: '17px', padding: '9px 12px' }} target="_blank">
                        Открыть в ЭДО <ButtonIcon />
                    </WrapperButton>
                )}
            </Box>
        </Box>
    );
};

TableInvoice.propTypes = {
    data: PropTypes.object,
    number: PropTypes.number
};
