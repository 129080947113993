import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from "react";

import { EMAIL_VALIDATION } from "@components/FormEntityQuestionnaire/constants/validations";
import { AppStateContext } from "@core/AppContextProvider";
import {
  Typography,
  Box,
  Link,
} from "@material-ui/core";
import { AvatarUser, maskedPhone } from "@product-site-frontend/shared";
import { FormattedMessage } from "react-intl";

import { noVerification } from "./const";

MainBlock.propTypes = {
  OWNER: PropTypes.string, // это владелец/руководитель. Если он есть, то запускаем проверку ceo_email
  fieldsForm: PropTypes.object,
  opf: PropTypes.oneOf(['company', 'ip']),
};

export default function MainBlock({ OWNER, fieldsForm, opf }) {
  const styles = useMemo(() => ({
    wrapper: {
      position: "sticky",
      background: '#FFFFFF',
      boxShadow: '0px 10px 32px rgba(47, 45, 40, 0.12)',
      borderRadius: '16px',
      padding: '24px',
      width: '312px',
      display: {
        lg: 'block',
        xs: 'none',
      }
    },
    title: {
      fontSize: '17px',
      fontWeight: 700,
      marginBottom: '17px',

      '& span': {
        color: '#67BF3D',
      },
    },
    line: {
      background: '#E7E8EA',
      borderRadius: '30px',
      width: '100%',
      height: '12px',
      marginBottom: '28px',
      overflow: 'hidden',
    },
    steps: {

    },
    step: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '62px',

      '&:last-child': {
        marginBottom: '0',

        '& .MuiBox-root:after': {
          display: 'none',
        }
      },
    },
    stepDots: {
      marginRight: '20px',
      position: 'relative',

      '&.completed_true': {
        '&:before': {
          background: '#67BF3D',
        },
        '&:after': {
          background: '#67BF3D',
        },
      },

      '&:before': {
        content: "''",
        background: '#e7e8ea',
        width: '10px',
        height: '10px',
        borderRadius: '20px',
        position: 'absolute',
        top: '-4px',
        transition: 'background 300ms'
      },
      '&:after': {
        content: "''",
        background: '#e7e8ea',
        width: '2px',
        height: '88px',
        position: 'absolute',
        top: '5px',
        left: '4px',
        display: 'block',
        transition: 'background 300ms'
      },
      '&.founders_company:after': {
        height: '94px',
      },
      '&.management_ip:after': {
        height: '84px',
      },
      '&.contacts_company:after': {
        height: '105px',
      },
    },
    stepTitle: {

    },
    managerWrapper: {
      marginTop: '24px',
      width: '312px',
      display: {
        lg: 'block',
        xs: 'none',
      }
    },
    managerDescription: {
      background: 'linear-gradient(#f09639, #ee8035)',
      color: '#ffffff',
      borderRadius: '24px',
      padding: '24px 16px',
      position: 'relative',
      boxShadow: '0px 10px 24px 0px rgba(255, 120, 15, 0.30)',
      marginTop: '24px',

      '&:after': {
        content: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAyOSAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjAyNTkgOC43NDMyNUMxOS4yNjExIDMuODY0NzIgMjEuNzM3NyAxLjMxNjE3ZS0wNiAyOC41IDIuODYxMDJlLTA2TDAuNDk5OTk5IDQuMTMxODVlLTA3QzcuMjEzMTMgNC42MzkxMWUtMDggOS42ODY2NCA0LjE5OTU4IDEyLjg5NzEgOC43OTc1MkMxMy42NzAxIDkuOTA0NTggMTUuMjc5NyA5Ljg2ODUxIDE2LjAyNTkgOC43NDMyNVoiIGZpbGw9IiNGRjc4MEYiLz4KPC9zdmc+Cg==)',
        width: '28px',
        height: '11px',
        display: 'block',
        position: 'absolute',
        bottom: '-3px',
        left: 'calc(50% - (28px / 2))',
      }
    },
    managerLink: {
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: '600',
      display: 'block',
      textDecoration: 'none',
    },
    managerAvatar: {
      marginTop: '36px',
      marginLeft: 'calc(50% - (122px / 2))',
    },
  }), []);

  const { leasingManager } = useContext(AppStateContext);

  const email = leasingManager?.email || '';
  const fullName = leasingManager?.fullName || '';
  const phone = leasingManager?.phone || '';

  let STEPS = []

  if (opf === 'ip') {
    STEPS.push('general');
    STEPS.push('managementIP');
    STEPS.push('management');
    STEPS.push('contacts');
    STEPS.push('agreement');
  } else if (opf === 'company') {
    STEPS.push('general');
    STEPS.push('management');
    STEPS.push('founders');
    STEPS.push('contacts');
    STEPS.push('agreement');
  }

  const REQUIRED_FIELDS_GENERAL = [
    'taxation_type',
    'description',
    'trademark',
    'employees_count',
  ];

  const REQUIRED_FIELDS_USER  = [
    'ceo_email',
  ];

  // дополнительные поля для ИП
  const ADDITIONAL_REQUIRED_FIELDS_IP_FOR_MANAGEMENT = [
    'ceo_marital_status',
    'is_business_partner_exists',
  ];

  const REQUIRED_FIELDS_CONTACTS = [
    'legal_address',
    'is_same_actual_address',
    'is_other_business_points_exists',
  ];

  if (fieldsForm?.is_same_actual_address === 'false') {
    REQUIRED_FIELDS_CONTACTS.push('actual_address')
  }

  const REQUIRED_FIELDS_AGREEMENT  = [
    'entity_money_freeze',
    'money_freeze',
    'money_freeze_judge',
    'ipdl'
  ];

  let REQUIRED_FIELDS;

  if (opf === 'company') {
    REQUIRED_FIELDS = [
      ...REQUIRED_FIELDS_GENERAL,
      ...REQUIRED_FIELDS_CONTACTS,
      ...REQUIRED_FIELDS_AGREEMENT
    ]

    if (fieldsForm?.founders?.length > 0) {
      fieldsForm?.founders.forEach((founder, index) => {
        if (noVerification(founder, OWNER) === false) {
          REQUIRED_FIELDS.push(`founders.${index}.email`);
        }
      })
    }
  }

  if (opf === 'ip') {
    REQUIRED_FIELDS = [
      ...REQUIRED_FIELDS_GENERAL,
      ...ADDITIONAL_REQUIRED_FIELDS_IP_FOR_MANAGEMENT,
      ...REQUIRED_FIELDS_CONTACTS,
      ...REQUIRED_FIELDS_AGREEMENT
    ];
  }

  if (OWNER) {
    REQUIRED_FIELDS.push( ...REQUIRED_FIELDS_USER );
  }

  const [total, setTotal] = useState(0)

  useEffect(() => {
    let total = 0;
    let steps = 100 / REQUIRED_FIELDS.length;

    if (Object.keys(fieldsForm).length > 0) {
      REQUIRED_FIELDS.forEach(key => {
        if (key.includes('founders')) {
          let val = key.split('.');

          if (fieldsForm[val[0]][val[1]][val[2]]?.length > 0) {
            if (val[2] === 'email') {
              if (fieldsForm[val[0]][val[1]][val[2]]?.length > 0 && EMAIL_VALIDATION.isValidSync(fieldsForm[val[0]][val[1]][val[2]])) total += steps
            }
          }
        } else {
          if (key === 'ceo_email') {
            if (fieldsForm[key]?.length > 0 && EMAIL_VALIDATION.isValidSync(fieldsForm[key]) === true) total += steps;
          } else if (fieldsForm[key]?.length > 0 ) {
            total += steps;
          }
        }
      });
    }

    setTotal(total);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsForm])

  const checkingFieldsComplete = fields => {
    let arr = [];

    fields.forEach(field => {
      if (fieldsForm[field] && fieldsForm[field]?.length > 0) {
        arr.push(true)
      } else {
        arr.push(false)
      }
    })

    return !arr?.includes(false);
  }

  return (
    <>
      <Box sx={styles.wrapper}>
        <Typography sx={ styles.title }>
          Заполнено <span>{total.toFixed(0)}%</span>
        </Typography>

        <Box sx={styles.line}>
          <Box sx={{ width: `${total.toFixed(0)}%`, background: '#67BF3D', height: '100%', transition: 'width 1s' }}></Box>
        </Box>

        <Box sx={ styles.steps }>
          {STEPS.map((step, index) => {
            let completedStep = false;

            if (step === 'general') {
              completedStep = checkingFieldsComplete(REQUIRED_FIELDS_GENERAL);
            }

            if (step === 'managementIP' && OWNER) {
              let arr = [];

              REQUIRED_FIELDS_USER.forEach(field => {
                if (field === 'ceo_email') {
                  if (fieldsForm['ceo_email']?.length > 0 && EMAIL_VALIDATION.isValidSync(fieldsForm['ceo_email'])) {
                    arr.push(true);
                  } else {
                    arr.push(false);
                  }
                }
              })

              completedStep = !arr?.includes(false);
            }

            if (step === 'management') {
              let arr = [];

              if (OWNER && opf === 'company') {
                REQUIRED_FIELDS_USER.forEach(field => {
                  if (field === 'ceo_email') {
                    if (fieldsForm['ceo_email']?.length > 0 && EMAIL_VALIDATION.isValidSync(fieldsForm['ceo_email'])) {
                      arr.push(true);
                    } else {
                      arr.push(false);
                    }
                  }
                })
              }

              if (opf === 'ip') {
                ADDITIONAL_REQUIRED_FIELDS_IP_FOR_MANAGEMENT.forEach(field => {
                  if (fieldsForm[field] && fieldsForm[field]?.length > 0) {
                    arr.push(true)
                  } else {
                    arr.push(false)
                  }
                })
              }

              completedStep = !arr?.includes(false);
            }

            if (step === 'founders') {
              let arr=[];

              fieldsForm['founders']?.forEach(founder => {
                if (noVerification(founder, OWNER) === false) {
                  if (founder?.email?.length > 0 && EMAIL_VALIDATION.isValidSync(founder?.email)) {
                    arr.push(true)
                  } else {
                    arr.push(false)
                  }
                }
              })

              completedStep = !arr?.includes(false);
            }

            if (step === 'contacts') {
              if (fieldsForm?.is_same_actual_address === 'false') {
                REQUIRED_FIELDS_CONTACTS.push('actual_address')
              }

              completedStep = checkingFieldsComplete(REQUIRED_FIELDS_CONTACTS);
            }

            if (step === 'agreement') {
              completedStep = checkingFieldsComplete(REQUIRED_FIELDS_AGREEMENT);
            }

            return (
              <Box key={step} sx={styles.step}>
                {/* eslint-disable-next-line no-useless-concat */}
                <Box className={step + '_' + opf + " " + 'completed' + '_' + completedStep} sx={styles.stepDots}></Box>
                <FormattedMessage id={`entity.questionnaire.${opf}.steps.${step}.title`} sx={styles.stepTitle} />
              </Box>
            )
          })}
        </Box>
      </Box>

      {fullName && (
        <Box sx={ styles.managerWrapper }>
          <Box sx={ styles.managerDescription }>
            <Typography>
              Привет! <br />
              Я {fullName}, <br />
              ваш персональный менеджер! Обращайтесь ко мне по любым вопросам.
            </Typography>

            <Box sx={{ marginTop: '28px' }}>
              <Link href={`tel:${phone}`} sx={ styles.managerLink }>
                {maskedPhone.resolve(phone)}
              </Link>

              <Link href={`mailto:${email}}`} sx={ styles.managerLink }>
                {email}
              </Link>
            </Box>
          </Box>

          <Box sx={ styles.managerAvatar }>
            <AvatarUser firstName={fullName.split(' ')[1]} lastName={fullName.split(' ')[0]} size={122} />
          </Box>
        </Box>
      )}
    </>
  );
}
