import React, { useMemo } from 'react';

import DialogBoxApplication from '@components/DialogBoxApplication';
import DialogBoxApplicationRepeat from '@components/DialogBoxApplicationRepeat';
import useActiveLegalEntity from '@hooks/useActiveLegalEntity';
import useApplicationsFetch from '@hooks/useApplicationsFetch';
import { Alert, AlertTitle, Box, Typography } from '@material-ui/core';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { Link as RouterLink } from 'gatsby';

import {
  CardLeasingApplication,
  LeasingApplication,
} from '../PageLeasingApplications';

export default function WidgetApplications() {
  const { applications, error, loading } = useApplicationsFetch();
  const { data: activeLegalEntityData, meta } = useActiveLegalEntity();

  const applicationButton = useMemo(() => {
    if (loading) {
      return null;
    }

    if (!activeLegalEntityData?.roles?.includes('customer')) {
      return <DialogBoxApplication />;
    }

    if (meta.userConfirmed) {
      return <DialogBoxApplicationRepeat />;
    }

    return null;
  }, [activeLegalEntityData, meta, loading]);

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <Typography
          component={RouterLink}
          noWrap
          sx={{ mr: 5 }}
          to="/leasing/applications"
          variant="h1"
        >
          Заявки на лизинг{' '}
          <ChevronRightRoundedIcon
            fontSize="larger"
            sx={{ verticalAlign: 'middle' }}
          />
        </Typography>
        {applicationButton}
      </Box>
      <Box
        sx={{
          display: 'flex',
          overflowX: { xs: 'scroll', md: 'inherit' },
        }}
      >
        {error ? (
          <Alert severity="error" sx={{ width: 1 }}>
            <AlertTitle>Ошибка</AlertTitle>
            {error.message}
          </Alert>
        ) : loading ? (
          [...Array(2)].map((e, index) => (
            <Box
              key={index}
              sx={{ ml: index ? 4 : 0, width: '100%', maxWidth: '360px' }}
            >
              <CardLeasingApplication />
            </Box>
          ))
        ) : (
          applications.slice(0, 2).map((application, index) => (
            <Box
              key={application.id}
              sx={{ ml: index ? 4 : 0, width: '100%', maxWidth: '360px' }}
            >
              <LeasingApplication {...application} />
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
}