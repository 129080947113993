import PropTypes from "prop-types";
import React, { useState } from "react";

import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    TextField,
    Select,
    MenuItem,
    Checkbox,
    IconButton
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import OpenInNew from '@material-ui/icons/OpenInNewOutlined';

const styles = {
    documentsBox: {
        border: '1px solid #E7E8EA',
        padding: '16px',
        boxSizing: 'border-box',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
    },
    documentLabel: {
        fontSize: '12px',
        fontWeight: '300',
        lineHeight: '18px',
        marginBottom: '8px'
    },
    documentsBoxControl: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    documentsFieldWithHelper: {
        '& > span': {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 300,

            '& > a': {
                color: '#FF780F',
                textDecoration: 'underline',
                display: 'inline-flex',
                flexWrap: 'wrap',
                alignItems: 'center'
            },

            'svg': {
                width: '16px',
                height: 'auto',
                marginLeft: '4px'
            }
        }
    },
    radioLabel: {
        '& .MuiFormControlLabel-label': {
            fontSize: '13px'
        }
    },
    formControl: {
        marginTop: 0,

        '& label': {
            background: '#fff'
        },
        '& > .Mui-focused fieldset.MuiOutlinedInput-notchedOutline ': {
            border: 'none'
        }
    },
    select: {
        background: '#f5f6f7',
        border: '1px solid #f5f6f7',
        borderRadius: '8px',
        width: '100%',

        '&.Mui-focused': {
            border: '1px solid #ee8035',
        },
        '&:hover': {
            background: '#ecedef',
            border: '1px solid #ecedef',
        }
    },
    input: {
        background: '#F5F6F7',
        borderRadius: '8px',
        padding: '12px 18px',
        margin: 0,
        fontSize: '16px',
        fontWeight: '500',
        color: '#6E7884',
        width: '100%',

        '& > div:before': {
            display: 'none',
        },
        '& > div:after': {
            display: 'none',
        },
    },
    button: {
        border: '1px solid #67BF3D',
        color: '#67BF3D',
        fontWeight: '400',

        '&:hover': {
            border: '1px solid #67BF3D77',
            color: '#67BF3D77',
        }
    },
    error: {
        color: '#F00',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 300
    }
};

const helperSystem = {
    diadoc: 'Диадок',
    sbis: 'СБИС'
};

const helperURLs = {
    diadoc: 'https://www.diadoc.ru/docs/faq/faq-191',
    sbis: 'https://sbis.ru/help/edo/id_edo'
};

export default function EDOSelector({ error, onChange, onDelete, onSave, value }) {
    const [edoSystem, setEdoSystem] = useState(value.system || '');
    const [edoIdentifier, setEdoIdentifier] = useState(value.value || '');
    const [isMain, setIsMain] = useState(value.main || false);

    const onChangeEdo = (event) => {
        setEdoSystem(event.target.value);

        if (onChange) {
            onChange({
                value: edoIdentifier,
                system: event.target.value,
                main: isMain,
                id: value.id
            });
        }
    };

    const onChangeEdoIdentifier = (event) => {
        setEdoIdentifier(event.target.value);

        if (onChange) {
            onChange({
                value: event.target.value,
                system: edoSystem,
                main: isMain,
                id: value.id
            });
        }
    };

    const onChangeEdoIsMain = (event) => {
        setIsMain(event.target.checked);

        if (onChange) {
            onChange({
                value: edoIdentifier,
                system: edoSystem,
                main: event.target.checked,
                id: value.id
            });
        }
    };

    const onClickDelete = (number) => () => {
        if (onDelete) {
            onDelete(number);
        }
    };

    const onClickSave = () => {
        if (onSave) {
            onSave({
                value: edoIdentifier,
                system: edoSystem,
                main: isMain,
                id: value?.id ? value.id : undefined
            });
        }
    };

    return (
        <Box sx={styles.documentsBox}>
            <Box>
                <Box sx={styles.documentLabel}>Выберите систему ЭДО:</Box>
                <FormControl fullWidth sx={styles.formControl}>
                    <Select
                        labelId="documentLabel"
                        onChange={onChangeEdo}
                        sx={styles.select}
                        value={edoSystem}
                    >
                        <MenuItem value="sbis">
                            СБИС
                        </MenuItem>
                        <MenuItem value="diadoc">
                            ДИАДОК
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box sx={styles.documentsFieldWithHelper}>
                <Box sx={styles.documentLabel}>Введите идентификатор вашей компании (СБИС/Диадок):</Box>
                <TextField
                    onChange={onChangeEdoIdentifier}
                    placeholder={'Идентификатор'}
                    sx={styles.input}
                    value={edoIdentifier}
                    variant="standard"
                />
                <span>
                    <a href={helperURLs[edoSystem]} rel="noreferrer" target="_blank">Узнать ваш идентификатор {helperSystem[edoSystem]}<OpenInNew /></a>
                </span>
            </Box>
            <Box sx={styles.documentsBoxControl}>
                <FormControlLabel checked={isMain} control={<Checkbox name="main-system" />} label="Сделать основным" onChange={onChangeEdoIsMain} sx={styles.radioLabel} />
                <IconButton aria-label="delete" onClick={onClickDelete(value.id)} size="small">
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </Box>
            <Button disabled={!edoIdentifier} onClick={onClickSave} size="large" sx={styles.button} variant="outlined">Сохранить</Button>
            {error && (<Box sx={styles.error}>{error}</Box>)}
        </Box>
    );
}

EDOSelector.propTypes = {
    error: PropTypes.string,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    onSave: PropTypes.func,
    value: PropTypes.object
};