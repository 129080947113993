import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Tabs, Typography } from "@material-ui/core";

import useActiveLegalEntity from '../../hooks/useActiveLegalEntity';
import MenuProfile from './MenuProfile';
import TabLink from './TabLink';

const TABS = ['leasing', 'partnership', 'entity', 'investments'];

NavPrimary.propTypes = {
  primary: PropTypes.string,
};
export default function NavPrimary({ primary = 'leasing' }) {
  const activeLegalEntity = useActiveLegalEntity();
  const activeTab = useMemo(
    () => TABS.includes(primary) ? primary : false,
    [primary],
  );

  return (
    <React.Fragment>
      <Tabs
        scrollButtons={false}
        sx={{ '.MuiTab-root:not(:last-child)': { mr: { xs: 3, md: 4 } } }}
        value={activeTab}
        variant="scrollable"
      >
        <TabLink href="/" label="Клиент" value="leasing" />

        <TabLink href="/partnership" label="Партнер" value="partnership" />

        {/*{activeLegalEntity.data?.roles?.includes('investor') && (*/}
        {/*  <TabLink label="Доход" to="/investments" value="investments" />*/}
        {/*)}*/}

        <TabLink
          label={
            <Typography noWrap sx={{ maxWidth: 240 }} variant="inherit">
              {activeLegalEntity.data.shortLegalName}
            </Typography>
          }
          to="/entity"
          value="entity"
        />
      </Tabs>
      <MenuProfile />
    </React.Fragment>
  );
}
