import React, { useMemo } from 'react';

import { gql, useQuery } from '@apollo/client';
import useActiveLegalEntity from '@hooks/useActiveLegalEntity';
import { Stack, Typography } from '@material-ui/core';
import _groupBy from 'lodash.groupby';
import { FormattedMessage } from 'react-intl';

import CardDocumentsGroup from './CardDocumentsGroup';
import CardDocumentStatementForLastYear from "./CardDocumentStatementForLastYear";

const DOCS = gql`
  query Docs($entityId: Number!) {
    entityDocuments(entityId: $entityId)
      @rest(type: "EntityDocument", path: "/entities/{args.entityId}/entity_documents") {
      category
      createdAt
      filename
      id
    }
  }
`;

export default function PageEntityDocs() {
  const { activeLegalEntityId, meta } = useActiveLegalEntity();

  const { data, refetch } = useQuery(DOCS, {
    variables: { entityId: activeLegalEntityId },
  });

  const entityDocumentsByCategory = useMemo(() => {
    return _groupBy(data?.entityDocuments, 'category');
  }, [data]);

  const commonCardProps = {
    entityId: activeLegalEntityId,
    opf: meta.opf,
    refetch,
  };

  const renderContentForOPF = type_user => {
    if (type_user === "company") {
      return (
        <>
          <Typography gutterBottom variant="h1">
            <FormattedMessage id={`entity.documents.${meta.opf}`} />
          </Typography>

          <Stack spacing={3} sx={{ mb: 8 }}>
            <CardDocumentsGroup
              category="ustav"
              uploadedDocs={entityDocumentsByCategory['ustav']}
              {...commonCardProps}
            />

            <CardDocumentsGroup
              category="polnomochia_rukovoditelya"
              uploadedDocs={entityDocumentsByCategory['polnomochia_rukovoditelya']}
              {...commonCardProps}
            />
          </Stack>

          <Typography gutterBottom variant="h1">
            Финансовые показатели и договор аренды
          </Typography>

          <Stack spacing={3} sx={{ mb: 8 }}>
            <CardDocumentStatementForLastYear {...commonCardProps} />

            <CardDocumentsGroup
              category="dogovor_arendy"
              uploadedDocs={entityDocumentsByCategory['dogovor_arendy']}
              {...commonCardProps}
            />
          </Stack>
        </>
      )
    } else if (type_user === "ip") {
      return (
        <>
          <Typography gutterBottom variant="h1">
            <FormattedMessage id={`entity.documents.${meta.opf}`} />
          </Typography>
          <Stack spacing={3} sx={{ mb: 8 }}>
            <CardDocumentsGroup
              category="svidetelstvo_inn"
              uploadedDocs={entityDocumentsByCategory['svidetelstvo_inn']}
              {...commonCardProps}
            />
          </Stack>

          <Typography gutterBottom variant="h1">
            Финансовые показатели и договор аренды
          </Typography>
          <Stack spacing={3} sx={{ mb: 8 }}>
            <CardDocumentStatementForLastYear {...commonCardProps} />

            <CardDocumentsGroup
              category="dogovor_arendy"
              uploadedDocs={entityDocumentsByCategory['dogovor_arendy']}
              {...commonCardProps}
            />
          </Stack>
        </>
      )
    } else {

    }
  }

  return renderContentForOPF(meta.opf);
}