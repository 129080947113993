import React from 'react';

import { Box, Card, Typography } from '@material-ui/core';

import Styled from './PageInvestments.styled';

export default function FinancialPerformance() {
  return (
    <Card sx={Styled.FinancialCard}>
      <Typography gutterBottom variant="h4">
        Финансовые показатели «Аренза-ПРО»
      </Typography>

      <Box sx={Styled.FinancialCardData}>
        <Typography
          color="text.secondary"
          gutterBottom
          sx={Styled.FinancialCardDataKey}
          variant="caption"
        >
          Портфель
        </Typography>
        <Typography
          sx={Styled.FinancialCardDataValue}
          variant="h4"
        >
          811 382 422 руб
        </Typography>
      </Box>

      <Box sx={Styled.FinancialCardData}>
        <Typography
          color="text.secondary"
          gutterBottom
          sx={Styled.FinancialCardDataKey}
          variant="caption"
        >
          Уставной капитал
        </Typography>
        <Typography
          sx={Styled.FinancialCardDataValue}
          variant="h4"
        >
          153 022 273 руб
        </Typography>
      </Box>
    </Card>
  );
}
