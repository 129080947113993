import React from "react";

import { Box, Tab, Typography } from "@material-ui/core";
import { Link as RouterLink } from "gatsby";

export default function TabLink(props) {
  if (props?.development) {
    return (
      <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', color: '#6E7884', fontSize: '13px', fontWeight: '700', cursor: 'default' }}>
        {props.label}

        <Typography sx={{ background: '#67BF3D', borderRadius: '6px', color: '#ffffff', fontSize: '12px', padding: '2px 4px' }}>
          Скоро!
        </Typography>
      </Box>
    )
  } else {
    return (
      <Tab
        component={RouterLink}
        sx={{
          fontSize: 13,
          textTransform: "none",
          p: 0,
          minWidth: "48px !important",
          ":not(:last-child)": {
            marginRight: 4,
          },
        }}
        {...props}
      />
    )
  }
}