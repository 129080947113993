import React, { useState, useEffect, useCallback } from 'react';

import EdoBanner from '@components/EdoBanner';
import { AzDayPicker, AzSelect } from '@Development/ui-kit';
import useActiveLegalEntity from '@hooks/useActiveLegalEntity';
import {
  Box,
  Button,
  Typography
} from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import {
  Chips,
  WrapperButton,
  DialogApplicationCreate
} from "@product-site-frontend/shared";
import dayjs from 'dayjs';
import useFetch from "use-http";

import TableGroup from './TableGroup';

const styles = {
  header: {
    fontSize: '48px !important',
    lineHeight: '56px',
    fontWeight: '700',
    marginBottom: '32px'
  },
  filtersContainer: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: {
      xs: 'wrap',
      lg: 'nowrap'
    }
  },
  filters: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    flexWrap: {
      xs: 'wrap',
      lg: 'nowrap'
    }
  },
  dates: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center'
  },
  buttonSetButton: {
    width: {
      xs: '100%',
      md: 'auto'
    },
    textTransform: 'none',
    fontWeight: 400
  },
  tableContainer: {
    padding: {
      xs: '16px',
      lg: '32px'
    },
    boxShadow: '0px 10px 32px 0px #0D1D321A',
    borderRadius: '32px',
    marginTop: '32px'
  },
  tableContent: {
    maxHeight: '680px',
    overflowY: 'auto'
  },
  tableHeader: {
    padding: '24px',
    color: '#9EA5AD',
    boxSizing: 'border-box',
    display: {
      xs: 'none',
      lg: 'grid'
    },
    gridTemplateColumns: '48px 1fr 110px 162px 128px 169px',
    alignItems: 'center',
    gap: '8px'
  }
}


export default function PageAccounting() {
  const { data } = useActiveLegalEntity();

  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [showModalPaymentsGraphic, setShowModalPaymentsGraphic] = useState(false);
  const [accData, setAccData] = useState([]);
  const [loans, setLoans] = useState([]);
  const [startNumbers, setStartNumbers] = useState([]);

  const { get, response } = useFetch(`/entities/${data.id}/accounting/invoices_schedule`);
  const loansList = useFetch(`/entities/${data.id}/loans`);

  async function getData() {
    let filterString = '';

    if (dateStart || dateEnd || selectValue) {
      filterString += '?';
    }

    if (dateStart) {
      filterString += `filter%5Bdate_from%5D=${dateStart}`;
    }

    if (dateEnd) {
      if (dateStart) {
        filterString += '&';
      }

      filterString += `filter%5Bdate_to%5D=${dateEnd}`;
    }

    if (selectValue) {
      if (dateStart || dateEnd) {
        filterString += '&';
      }

      filterString += `filter%5Bloan_ids%5D%5B%5D=${selectValue}`;
    }

    const accountingData = await get(filterString ? filterString : undefined);

    if (response.ok) {
      setAccData(accountingData);
    }
  };

  async function getLoans() {
    const loansData = await loansList.get();

    if (loansList.response.ok) {
      if (loansData?.length) {
        const newLoansList = loansData.map((loan) => (
          {
            id: loan.id,
            name: `${loan.number} от ${dayjs(loan.date).format('DD/MM/YYYY')}`
          }
        ));

        setLoans(newLoansList);
      }
    }
  };

  const toggleShowModalPaymentsGraphic = () => {
    setShowModalPaymentsGraphic(!showModalPaymentsGraphic);
  };

  const onChangeDateStart = useCallback((event) => {
    const formattedDateStart = dayjs(event).format('YYYY-MM-DD');
    setDateStart(formattedDateStart);
  }, []);

  const onChangeDateEnd = useCallback((event) => {
    const formattedDateEnd = dayjs(event).format('YYYY-MM-DD');
    setDateEnd(formattedDateEnd);
  }, []);

  const onChangeOption = useCallback((event) => {
    setSelectValue(event);
  }, []);

  const calculateStartNumbers = () => {
    const newStartNumbers = [];
    let nextStartData = 1;

    if (accData?.length > 0) {
      for (let index = 0; index < accData.length; index++) {
        newStartNumbers.push(nextStartData);

        if (accData[index].invoices?.length) {
          nextStartData += accData[index].invoices?.length;
        }
      }
    }

    setStartNumbers(newStartNumbers);
  };

  useEffect(() => {
    getLoans();
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dateStart || dateEnd || selectValue) {
      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd, selectValue]);

  useEffect(() => {
    calculateStartNumbers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accData]);

  return (
    <>
      <Typography sx={styles.header} variant="h1">
        Бухгалтерия
      </Typography>
      <EdoBanner />
      <Box>
        <Chips
          chips={[
            'Закрывающие документы'
          ]}
          sx={{
            chip: {
              '&.active': {
                background: '#0D1D32',
                color: '#fff'
              }
            }
          }}
        />
      </Box>
      <Box sx={styles.filtersContainer}>
        <Box sx={styles.filters}>
          <Box sx={styles.dates}>
            <AzDayPicker
              label="С"
              onChangeInput={onChangeDateStart}
              onClickDP={onChangeDateStart}
              placeholder="С"
            />
            &nbsp;—&nbsp;
            <AzDayPicker
              label="По"
              onChangeInput={onChangeDateEnd}
              onClickDP={onChangeDateEnd}
              placeholder="По"
            />
          </Box>
          <Box>
            <AzSelect
              label={"Номер договора"}
              loading={false}
              onClickOption={onChangeOption}
              optionKey={"id"}
              optionLabel={"name"}
              optionValue={"id"}
              options={loans}
              placeholder={"Номер договора"}
            />
          </Box>
        </Box>
        <Box>
          <Button
            onClick={toggleShowModalPaymentsGraphic}
            size="large"
            sx={{
              ...styles.buttonSetButton,
              color: '#ff6600',
              borderWidth: '0px',

              '&:hover': {
                borderWidth: '0px',
              }
            }}
            variant="outlined"
          >
            <HelpOutlineOutlinedIcon sx={{ marginRight: '8px' }} />Как формируется график начислений?
          </Button>
        </Box>
      </Box>

      <Box sx={styles.tableContainer}>
        <Box sx={styles.tableHeader}>
          <Box>№</Box>
          <Box>Наименование документа</Box>
          <Box>Дата</Box>
          <Box>Договор лизинга</Box>
          <Box>Сумма/НДС</Box>
          <Box>Ссылка в ЭДО</Box>
        </Box>
        <Box sx={styles.tableContent}>
          {accData?.length > 0 && (accData.map((accElement, id) => (
            <TableGroup data={accElement} key={id} startNumber={startNumbers[id]} />
          )))}

          {(!accData || accData?.length === 0) && (
            <span style={{ textAlign: 'center', fontSize: '24px', display: 'block', backgroundColor: '#F5F6F7', padding: '24px 0' }}>Начисления пока отсутствуют</span>
          )}
        </Box>
      </Box>

      <DialogApplicationCreate
        content={
          <Box sx={{ alignItems: 'flex-start', display: "flex", flexDirection: 'column' }}>
            <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '30px', lineHeight: '38px', fontStyle: 'normal', fontWeight: '700', marginBottom: '24px' }}>График начислений</Typography>

            <Typography sx={{ fontSize: '14px', fontWeight: '300', marginBottom: '24px' }}>График начисления составляется в момент поставки предмета лизинга, является неотъемлемой частью договора лизинга и является основанием для ежемесячного выставления счет-фактур.</Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: '300', marginBottom: '24px' }}>Первоначальный аванс предусмотренный договором лизинга распределяется и зачитывается на весь срок договора лизинга, если иное не согласовано сторонами. В месяцах зачета первоначальных авансовых платежей счет-фактура выставляется на общую сумму месячного лизингового платежа из графика к договору лизинга и месячной суммы аванса, рассчитанной пропорционально сроку зачета первоначального аванса.</Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: '600', marginBottom: '24px' }}>Если отгрузка предмета лизинга происходит несколькми частями, счета-фактуры оформляются на суммы:</Typography>
            <ol style={{ listStylePosition: 'outside', listStyleType: 'decimal', paddingLeft: '24px', fontSize: '14px', fontWeight: '300', marginBottom: '24px' }}>
              <li>
                при отгрузке первой части предмета лизинга: месячная часть первой части поставленного предмета лизинга, рассчитанная пропорционально сроку действия договора лизинга и месячная часть авансового платежа;
              </li>
              <li>
                при каждой последующей отгрузке к сумме, рассчитанной при первой отгрузке, добавляются суммы месячной части каждой далее поставленной части предмета лизинга, рассчитанной пропорционально оставшемуся сроку договора лизинга.
              </li>
            </ol>
            <Typography sx={{ fontSize: '14px', fontWeight: '300', marginBottom: '24px' }}>Если произошла реструктуризация, счета-фактуры выставляются на суммы в размере месячной части оставшейся на дату вступления в силу соглашения о реструктуризации общей суммы к погашению по договору лизинга, рассчитанной пропорционально оставшемуся сроку погашения задолженности по соглашению о реструктуризации.</Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: '300', marginBottom: '24px' }}>На выкупную стоимость формируется отдельный универсально-передаточный документ (УПД).</Typography>

            <WrapperButton colorScheme={'orange'} onClick={toggleShowModalPaymentsGraphic} sx={{ margin: '0 auto' }}>
              Закрыть
            </WrapperButton>
          </Box>
        }
        maxWidth="sm"
        onClose={toggleShowModalPaymentsGraphic}
        open={showModalPaymentsGraphic}
      />
    </>
  );
}