import React, { useMemo } from "react";

import {
  Box,
  Typography,
} from "@material-ui/core";
import { Link } from 'gatsby';

import pseLink from "../img/pse_link.svg";
import widgetFour from "../img/widget_four.png";
import widgetOne from "../img/widget_one.png";
import widgetThree from "../img/widget_three.png";
import widgetTwo from "../img/widgets_two.png";

export default function Widgets() {
  const styles = useMemo(() => ({
    selectionWidget: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '24px',

      '& > div': {
        width: {
          md: '100%',
          lg: 'calc(50% - (24px / 2))',
        },
      },
    },
    img: {
      margin: '0 auto',
    },
    title: {
      fontSize: '22px',
      fontWeight: 900,
      marginBottom: '22px',
      marginTop: '37px',
    },
    descriptions: {
      fontSize: '16px',
      fontWeight: '300',
    },
    psevLink: {
      color: '#FF780F',
      fontSize: '16px',
      fontWeight: '500',
      marginTop: '22px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '6px',

      '&:hover': {
        textDecoration: 'underline',
      }
    },
  }), [])

  return (
    <Box sx={styles.selectionWidget}>
      <Box>
        <Box component={"img"} src={widgetOne} sx={styles.img} />

        <Typography sx={styles.title}>Виджет “На странице”</Typography>
        <Typography sx={styles.descriptions}>
          Виджет на странице – это окно для передачи заявки, которое размещается непосредственно на странице сайта и всегда находится в развернутом положении. Заявка на лизинг, отправленная с виджета, сразу попадает в CRM систему Arenza и фиксируется менеджерами.
        </Typography>

        <Typography component={Link} sx={styles.psevLink} to={"/partnership/marketing/widget-on-the-page"}>
          Подробнее

          <Box component={"img"} src={pseLink} />
        </Typography>
      </Box>

      <Box>
        <Box component={"img"} src={widgetTwo} sx={{ ...styles.img, marginTop: '21px' }} />

        <Typography sx={styles.title}>Виджет “Кнопка”</Typography>
        <Typography sx={styles.descriptions}>
          Виджет-кнопка встраивается в кнопку &quot;Купить в лизинг&quot;. В данном виджете есть функционал подтягивания данных о цене товара и составе корзины. С помощью виджета-кнопки вся информация о заказе будет передана менеджеру Arenza, не требуя уточнений по заказу.
        </Typography>

        <Typography component={Link} sx={styles.psevLink} to={"/partnership/marketing/widget-button"}>
          Подробнее

          <Box component={"img"} src={pseLink} />
        </Typography>
      </Box>

      <Box>
        <Box component={"img"} src={widgetThree} sx={{ ...styles.img, marginTop: '21px' }} />

        <Typography sx={styles.title}>Виджет “Облако”</Typography>
        <Typography sx={styles.descriptions}>
          Установите на свой сайт виджет, мгновенно передающий заявку на лизинг в Arenza. Мы обработаем заявку в кратчайшие сроки и будем держать вас в курсе всех этапов заключения сделки. Благодаря виджету вам больше не придется передавать заявки вручную, что сбережет время ваших сотрудников и клиентов.
        </Typography>

        <Typography component={Link} sx={styles.psevLink} to={"/partnership/marketing/widget-button-cloud"}>
          Подробнее

          <Box component={"img"} src={pseLink} />
        </Typography>
      </Box>

      <Box>
        <Box component={"img"} src={widgetFour} sx={{ ...styles.img, marginTop: '21px' }} />

        <Typography sx={styles.title}>Виджет “Растяжка”</Typography>
        <Typography sx={styles.descriptions}>
          Установите на свой сайт виджет, мгновенно передающий заявку на лизинг в Arenza. Мы обработаем заявку в кратчайшие сроки и будем держать вас в курсе всех этапов заключения сделки. Благодаря виджету вам больше не придется передавать заявки вручную, что сбережет время ваших сотрудников и клиентов.
        </Typography>

        <Typography component={Link} sx={styles.psevLink} to={"/partnership/marketing/widget-button-banner"}>
          Подробнее

          <Box component={"img"} src={pseLink} />
        </Typography>
      </Box>
    </Box>
  );
}