import PropTypes from 'prop-types';
import React from 'react';

import BlockDocTypeNumberDate from '@components/BlockDocTypeNumberDate';
import FormattedCurrency from '@components/FormattedCurrency';
import { formatDate } from '@core/helpers';
import useLeasingDealsFetch from '@hooks/useLeasingDealsFetch';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Skeleton,
  Typography,
  Alert,
  AlertTitle,
} from '@material-ui/core';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { Link as RouterLink } from 'gatsby';
import _upperFirst from 'lodash.upperfirst';

export default function WidgetLeasingDeals(props) {
  const { deals, error, loading } = useLeasingDealsFetch();

  return (
    <>
      {deals?.length ? (
        <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
          <Typography component={RouterLink} to="/leasing/deals" variant="h1">
            Лизинговые сделки{" "}
            <ChevronRightRoundedIcon fontSize="larger" sx={{ verticalAlign: "middle" }} />
          </Typography>
        </Box>
      ) : null}
      <Box sx={{ display: "flex", overflowX: { xs: "scroll", md: "inherit" } }}>
        {error ? (
          <Alert severity="error">
            <AlertTitle>Ошибка</AlertTitle>
            {error.message}
          </Alert>
        ) : loading ? (
          <Loading />
        ) : (
          deals.slice(0, 2).map((deal, index) => (
            <Box key={deal.id} sx={{ ml: index ? 4 : 0, width: '100%', maxWidth: '360px' }}>
              <CardDeal {...deal} />
            </Box>
          ))
        )}
        {/* <Card sx={{ ml: 4 }} variant="outlined">
          <CardContent>Все сделки</CardContent>
        </Card> */}
      </Box>
    </>
  );
}

function Loading() {
  return [...Array(2)].map((e, index) => (
    <Box key={index} sx={{ ml: index ? 4 : 0, flex: 1 }}>
      <SkeletonCard />
    </Box>
  ));
}
function SkeletonCard() {
  return (
    <Card variant="outlined">
      <CardContent>
        <Skeleton sx={{ maxWidth: 240 }} />
        <Skeleton height={28} />
        <Skeleton sx={{ maxWidth: 512 }} />
        <Skeleton sx={{ maxWidth: 512 }} />
      </CardContent>
      <CardActions>
        <Skeleton height={36} sx={{ maxWidth: 104, mx: 2, width: 1 }} />
      </CardActions>
    </Card>
  );
}

// TODO: сделать один компонент Card c packages/account/src/components/PageLeasingDeals/CardLeasingDeals.jsx
CardDeal.propTypes = {
  date: PropTypes.any,
  id: PropTypes.any,
  number: PropTypes.any,
  requestedAmount: PropTypes.any,
  subject: PropTypes.any,
  term: PropTypes.any,
};
function CardDeal({ date, id, number, requestedAmount, subject, term }) {
  return (
    <Card sx={{
      height: '100%',
      boxShadow: { xs: 'none', md: '0 1px 50px 1px rgba(208, 208, 208, 0.5)' },
      border: { xs: '1px solid #e0e0e0', md: '0' }
    }}>
      <CardContent>
        <BlockDocTypeNumberDate date={formatDate(date, "full")} docType="Договор" number={number} />
        <Typography sx={{ my: 2 }} variant="h4">
          {_upperFirst(subject)}
        </Typography>
        <Box sx={{ maxWidth: 512 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography color="text.secondary" variant="body2">
              Срок лизинга
            </Typography>
            <Typography>{term} мес</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Typography color="text.secondary" variant="body2">
              Стоимость предмета лизинга&nbsp;
            </Typography>
            <Typography sx={{ fontWeight: 500, whiteSpace: "nowrap" }}>
              <FormattedCurrency hideCurrency value={requestedAmount} />
              &nbsp;руб
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Button component={RouterLink} to={`/leasing/deals/${id}`}>
          Подробнее
        </Button>
      </CardActions>
    </Card>
  );
}
