import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { arenzaStorage, AUTH_TOKEN_STORAGE_KEY } from '@core/storage';
import useUpload from '@hooks/useUpload';
import { Box } from '@material-ui/core';
import DownloadingIcon from '@material-ui/icons/Downloading';
import ErrorIcon from '@material-ui/icons/Error';
import * as Sentry from "@sentry/react";

import LayoutFileRow from './LayoutFileRow';
import LinearProgressWithLabel from './LinearProgressWithLabel';

ContainerFileUpload.propTypes = {
  category: PropTypes.string,
  entityId: PropTypes.number,
  file: PropTypes.shape({ name: PropTypes.string }),
  refetch: PropTypes.func,
};
export default function ContainerFileUpload({ category, entityId, file, refetch }) {
  const [upload, uploadState] = useUpload(({ files }) => {
    let formData = new FormData();
    formData.append('document_file', files[0]);
    formData.append('document_category', category);

    return {
      method: 'POST',
      url: `${process.env.GATSBY_FRONTOFFICE_API}/entities/${entityId}/entity_documents`,
      body: formData,
      headers: { Authorization: `Token ${arenzaStorage(AUTH_TOKEN_STORAGE_KEY)}` },
    };
  });

  const { data, done, error, loading, progress } = uploadState;

  useEffect(() => {
    upload({ files: [file] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Sentry.withScope(scope => {
      scope.setExtra("operation", "Произошла ошибка при загрузке файла")
      Sentry.captureException(error)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (done && data && data.filename) {
      refetch();
    }
  }, [done, data, refetch]);

  if (loading && progress) {
    return (
      <Box>
        <LayoutFileRow
          filename={file.name}
          icon={<DownloadingIcon color="action" sx={{ transform: "rotate(180deg)" }} />}
        />
        <LinearProgressWithLabel value={progress} />
      </Box>
    );
  }

  if (error) {
    return <LayoutFileRow filename={data.filename} icon={<ErrorIcon color="error" />} />;
  }

  return null;
}
