import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Box } from '@material-ui/core';
import dayjs from "dayjs";

TimelineShipments.propTypes = {
  data: PropTypes.array
};

TimelineShipments.defaultProps = {
  data: []
}

export default function TimelineShipments({ data }) {
  const styles = useMemo(() => ({
    container: {
      padding: '24px',
      borderRadius: '16px',
      boxShadow: '0px 20px 64px 0px #0D1D321A',
      boxSizing: 'border-box'
    },
    titleTable: {
      fontSize: '20px',
      fontWeight: 800,
      lineHeight: '32px',
      letterSpacing: '0.02em'
    },
    table: {
      marginTop: '22px',
      marginRight: '-16px',
      maxHeight: '170px',
      overflowY: 'auto',
      boxSizing: 'border-box',
      paddingRight: '16px',

      '& > div': {
        borderBottom: '1px solid #E7E8EA',

        '&:last-child': {
          borderBottom: 'none'
        }
      }
    },
    tableItem: {
      display: 'grid',
      gridTemplateColumns: {
        sx: '1fr',
        md: '1fr 125px'
      },
      gap: '8px',
      padding: '8px 0'
    },
    tableItemRight: {
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: '22px',
      color: '#000',
      textAlign: {
        sx: 'left',
        md: 'right'
      }
    },
    tableItemTitle: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '22px',
      color: '#000',

      '& > span': {
        color: '#FF780F'
      }
    },
    tableItemSupplier: {
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: '22px',
      color: '#000'
    }
  }), []);

  const renderItems = () => {
    return data.map(item => (
      <Box key={item?.number + '_' + item?.date} sx={styles.tableItem}>
        <Box>
          <Box sx={styles.tableItemTitle}>
            Отгрузка №{item.number || " не определён"} <span>от {item?.date ? dayjs(item.date).format("DD/MM/YYYY") : "---"}</span>
          </Box>
          <Box sx={styles.tableItemSupplier}>
            Поставщик {item?.supplier?.shortLegalName || "не определён"} ИНН {item?.supplier?.tin || "не определён"}
          </Box>
        </Box>
        <Box sx={styles.tableItemRight}>
          Заказ №{item?.supplyingOrder?.number || "не определён"} от {item?.supplyingOrder?.createdAt ? dayjs(item.supplyingOrder.createdAt).format("DD/MM/YYYY") : "---"}
        </Box>
      </Box>
    ))
  }

  return (
    <Box sx={styles.container}>
      <Box>
        <Box sx={styles.titleTable}>
          Отгрузки от поставщика:
        </Box>
      </Box>

      <Box sx={styles.table}>
        {data.length > 0 ? renderItems() : "На данный момент список пуст"}
      </Box>
    </Box>
  );
}
