import PropTypes from "prop-types";
import React from "react";

import { Typography } from "@material-ui/core";

BlockDocTypeNumberDate.propTypes = {
  date: PropTypes.string,
  docType: PropTypes.string,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default function BlockDocTypeNumberDate({ date, docType, number }) {
  return (
    <Typography sx={{ display: "flex", whiteSpace: "nowrap" }} variant="body2">
      <Typography color="text.secondary" variant="inherit">
        {docType}
      </Typography>
      &nbsp;
      <Typography sx={{ fontWeight: 500 }} variant="inherit">
        {number}
      </Typography>
      &nbsp;
      <Typography color="text.secondary" variant="inherit">
        от
      </Typography>
      &nbsp;
      <Typography sx={{ fontWeight: 500 }} variant="inherit">
        {date}
      </Typography>
    </Typography>
  );
}
