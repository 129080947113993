/* eslint-disable react/jsx-filename-extension */
import React from "react";

import { AmountMaskInput, PhoneMaskInput } from "@product-site-frontend/shared";
import { isEmpty } from "lodash";
import * as yup from "yup";

import { noVerification } from "../../namespaces/customer/components/PageEntityQuestionnaire/const";
import Founders from "../../namespaces/customer/components/PageEntityQuestionnaire/Founders";
import FieldContainer from "../FormFields/FieldContainer";
import FieldSocialAccounts from "../FormFields/FieldSocialAccounts";
import {
  ACTUAL_BUSINESS_OWNERSHIP_VALUES,
  CUSTOMER_TYPES_VALUES,
  YES_NO_OPTIONS,
  INDUSTRY_TYPES,
  NATIONALITY_TYPES,
  TAX_SYSTEMS,
  ACCOUNTING_VALUES,
  MARITAL_STATUS_VALUES,
  YES_NO_ADDITIONAL_INFORMATION,
} from "./constants/select-options";
import { EMAIL_VALIDATION, FIO_VALIDATION, PHONE_VALIDATION } from "./constants/validations";
import SelectOptions from "./SelectOptions";

const DOMAIN_REGEX = /^(?:http(s)?:\/\/)?[\wа-яА-ЯёЁ.-]+(?:\.[\wа-яА-ЯёЁ.-]+)+[\wа-яА-ЯёЁ\-._~:/?#[\]@!$&'()*+,;=.]+$/gi;

function buildShareValue(share) {
  if (share?.type === "PERCENT" || share?.type === "DECIMAL") {
    return share.value;
  }
  if (share?.type === "FRACTION") {
    return `${share.numerator}/${share.denominator}`;
  }
}

yup.addMethod(yup.string, 'uniqueProperties', function(propertyNames) {
  return this.test('unique', '', function(value) {
    let parentValue;

    if (this.path === 'ceo_phone' || this.path === 'ceo_email') {
      parentValue = this.from[0].value;
    } else {
      parentValue = this.from[1].value;
    }

    let arr = [];

    arr.push({
      path: 'ceo_',
      email: parentValue.ceo_email,
      phone: parentValue.ceo_phone,
    })

    parentValue?.founders?.length && parentValue?.founders.forEach((founder, index) => {
      if (noVerification(founder, this.options.context.owner) === false) {
        let obj = {}

        obj.path = `founders[${index}].`;
        obj.email = founder.email;
        obj.phone = founder.phone;

        arr.push(obj)
      }
    });

    let count = Object.create(null);
    let used = new Set();
    let dups = [];

    if (propertyNames[0] === 'email' || propertyNames[0] === 'ceo_email') {
      for (let {email} of arr) {
        // eslint-disable-next-line
        if ((count[email] = ~~count[email] + 1) == 2) {
          used.add(email)
        }
      }

      for (let x of arr) {
        if (used.has(x.email)) {
          x.duplicate = true
          dups.push(x)
        }
      }
    }

    if (propertyNames[0] === 'phone' || propertyNames[0] === 'ceo_phone') {
      for (let {phone} of arr) {
        // eslint-disable-next-line
        if ((count[phone] = ~~count[phone] + 1) == 2) {
          used.add(phone)
        }
      }

      for (let x of arr) {
        if (used.has(x.phone)) {
          x.duplicate = true
          dups.push(x)
        }
      }
    }

    const errors = [];

    dups.forEach(dup => {
      let path;

      if (propertyNames[0] === 'email' || propertyNames[0] === 'ceo_email') {
        path = dup.path + 'email';
      }

      if (propertyNames[0] === 'phone' || propertyNames[0] === 'ceo_phone') {
        path = dup.path + 'phone';
      }

      errors.push(
        this.createError({
          path,
          message: propertyNames[1],
        })
      );
    });

    if (!isEmpty(errors)) {
      throw new yup.ValidationError(errors);
    }

    return true;
  });
});


export const FIELDS_CONFIG = {
  // Первый шаг,
  // ─── GENERAL ────────────────────────────────────────────────────────────────────
  //
  entity_inn: {
    name: "entity_inn",
    defaultValue: ({ activeLegalEntity }) => activeLegalEntity.inn,
    FieldComponent: FieldContainer,
    componentProps: {
      disabled: true,
      fieldType: "text",
      saveEvent: "none",
    },
  },
  industry_type: {
    name: "industry_type",
    validation: yup.string().required("Выберите один из вариантов"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      SelectProps: {
        native: true,
      },
      fieldType: "text",
      saveEvent: "change",
      select: true,
      // eslint-disable-next-line react/react-in-jsx-scope
      children: <SelectOptions options={INDUSTRY_TYPES} />,
    },
  },
  description: {
    name: "description",
    validation: yup.string().trim().required("Опишите ваш бизнес"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "Описание вашего бизнеса",
      saveEvent: "change",
      multiline: true,
      minRows: 3,
    },
  },
  trademark: {
    name: "trademark",
    validation: yup.string().trim().required("Укажите название бренда"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "Макдональдс",
      saveEvent: "change",
    },
  },
  actual_business_ownership: {
    name: "actual_business_ownership",
    validation: yup.string().required("Выберите один из вариантов"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "radio",
      options: ACTUAL_BUSINESS_OWNERSHIP_VALUES,
      saveEvent: "change",
    },
  },
  employees_count: {
    name: "employees_count",
    validation: yup.string().required("Укажите количество сотрудников"),
    defaultValue: ({ activeLegalEntity, defaultValues }) =>
      defaultValues?.employees_count || activeLegalEntity.dadata?.employee_count?.toString() || "",
    FieldComponent: FieldContainer,
    componentProps: {
      InputProps: {
        inputComponent: AmountMaskInput,
      },
      fieldType: "text",
      inputProps: {
        inputMode: "numeric",
        pattern: "[0-9]*",
        mask: /^\d+$/,
      },
      saveEvent: "change",
    },
  },
  customer_types: {
    name: "customer_types",
    validation: yup.array().min(1, "Выберите один или несколько вариантов"),
    defaultValue: [],
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "checkboxGroup",
      options: CUSTOMER_TYPES_VALUES,
      saveEvent: "change",
    },
  },
  site: {
    name: "site",
    validation: yup.string().matches(DOMAIN_REGEX, {
      message: "Укажите корректный адрес сайта",
      excludeEmptyString: true,
    }),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "arenza.ru",
      saveEvent: "change",
      sx: { mb: 5 },
    },
  },
  social_accounts: {
    FieldComponent: FieldSocialAccounts,
    name: "social_accounts",
    defaultValue: [],
  },
  // Второй шаг,
  // ─── MANAGEMENT ─────────────────────────────────────────────────────────────────
  //
  ceo_name: {
    name: "ceo_name",
    validation: FIO_VALIDATION.required("Укажите фамилию, имя и отчество через пробел"),
    defaultValue: ({ activeLegalEntity, defaultValues }) =>
      defaultValues.ceo_name || activeLegalEntity.dadata?.management?.name || "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "nameAutocomplete",
      placeholder: "Фёдоров Михаил Сергеевич",
      saveEvent: "change",
    },
  },
  ceo_phone: {
    name: "ceo_phone",
    validation: PHONE_VALIDATION.min(18, 'Номер телефона должен состоять из 10 цифр').required("Укажите номер телефона").uniqueProperties(['ceo_phone', 'Данный телефон уже используется']),
    defaultValue: ({ activeLegalEntity, defaultValues }) =>
      defaultValues.ceo_phone || activeLegalEntity.dadata?.management?.phone || "",
    FieldComponent: FieldContainer,
    componentProps: {
      InputProps: {
        inputComponent: PhoneMaskInput,
      },
      fieldType: "text",
      placeholder: "+7 (***) ***-**-**",
      saveEvent: "change",
      type: "tel",
    },
  },
  additional_information: {
    name: "additional_information",
    validation: yup.string().required("Выберите один из вариантов"),
    defaultValue: true,
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "radio",
      options: YES_NO_ADDITIONAL_INFORMATION,
      saveEvent: "change",
    },
  },
  additional_information_series_number_passport: {
    name: "additional_information_series_number_passport",
    validation: yup.string().required("Укажите серию и номер паспорта"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "0000-000000",
      saveEvent: "blur",
    },
  },
  additional_information_date_issue_passport: {
    name: "additional_information_date_issue_passport",
    validation: yup.string().required("Укажите дату выдачи паспорта"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "23.08.200",
      saveEvent: "blur",
    },
  },
  additional_information_issued_by_passport: {
    name: "additional_information_issued_by_passport",
    validation: yup.string().required("Укажите кем был выдан паспорт"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
      multiline: true,
    },
  },
  additional_information_division_code: {
    name: "additional_information_division_code",
    validation: yup.string().required("Укажите код подразделения"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  additional_information_date_birth: {
    name: "additional_information_date_birth",
    validation: yup.string().required("Укажите дату рождения"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  additional_information_location_birth: {
    name: "additional_information_location_birth",
    validation: yup.string().required("Укажите место рождения"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
      multiline: true,
    },
  },
  additional_information_nationality: {
    name: "additional_information_nationality",
    validation: yup.string().required("Выберите один из вариантов"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      SelectProps: {
        native: true,
      },
      fieldType: "text",
      saveEvent: "change",
      select: true,
      // eslint-disable-next-line react/react-in-jsx-scope
      children: <SelectOptions options={NATIONALITY_TYPES} />,
    },
  },
  additional_information_country: {
    name: "additional_information_country",
    validation: yup.string().required("Укажите страну"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  additional_information_city: {
    name: "additional_information_city",
    validation: yup.string().required("Укажите город"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  additional_information_street: {
    name: "additional_information_street",
    validation: yup.string().required("Укажите улицу"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  additional_information_house: {
    name: "additional_information_house",
    validation: yup.string().required("Укажите номер дома"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  additional_information_apartment: {
    name: "additional_information_apartment",
    validation: yup.string().required("Укажите номер квартиры"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  additional_information_education: {
    name: "additional_information_education",
    validation: yup.string().notRequired(),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      SelectProps: {
        native: true,
      },
      fieldType: "text",
      saveEvent: "change",
      select: true,
      children: <SelectOptions options={MARITAL_STATUS_VALUES} />,
    },
  },
  additional_information_family_status: {
    name: "additional_information_family_status",
    validation: yup.string().notRequired(),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      SelectProps: {
        native: true,
      },
      fieldType: "text",
      saveEvent: "change",
      select: true,
      children: <SelectOptions options={MARITAL_STATUS_VALUES} />,
    },
  },
  additional_information_number_children: {
    name: "additional_information_number_children",
    validation: yup.string().notRequired(),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  additional_information_employment_type: {
    name: "additional_information_employment_type",
    validation: yup.string().notRequired(),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  additional_information_company_name: {
    name: "additional_information_company_name",
    validation: yup.string().notRequired(),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  additional_information_total_income: {
    name: "additional_information_total_income",
    validation: yup.string().notRequired(),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  additional_information_obligatory_payments: {
    name: "additional_information_obligatory_payments",
    validation: yup.string().notRequired(),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  additional_information_contact_type: {
    name: "additional_information_contact_type",
    validation: yup.string().notRequired(),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      SelectProps: {
        native: true,
      },
      fieldType: "text",
      saveEvent: "change",
      select: true,
      children: <SelectOptions options={MARITAL_STATUS_VALUES} />,
    },
  },
  additional_information_contact_name: {
    name: "additional_information_contact_name",
    validation: yup.string().notRequired(),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  additional_information_contact_phone: {
    name: "additional_information_contact_phone",
    validation: PHONE_VALIDATION.notRequired(),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      InputProps: {
        inputComponent: PhoneMaskInput,
      },
      fieldType: "text",
      placeholder: "+7 (***) ***-**-**",
      saveEvent: "blur",
      type: "tel",
    },
  },
  ceo_email: {
    name: "ceo_email",
    validation: EMAIL_VALIDATION.min(1, 'Укажите адрес электронной почты').required("Укажите адрес электронной почты").uniqueProperties(['ceo_email', 'Данный е-mail уже используется']),
    defaultValue: ({ activeLegalEntity, defaultValues }) =>
      defaultValues.ceo_email || activeLegalEntity.dadata?.management?.email || "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "ivanov@example.ru",
      saveEvent: "change",
    },
  },
  ceo_marital_status: {
    name: "ceo_marital_status",
    validation: yup.string().required("Выберите один из вариантов"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      SelectProps: {
        native: true,
      },
      fieldType: "text",
      saveEvent: "change",
      select: true,
      children: <SelectOptions options={MARITAL_STATUS_VALUES} />,
    },
  },
  founders: {
    name: "founders",
    validation: yup.array().of(
      yup.object().shape({
        email: EMAIL_VALIDATION.notRequired().when(['$owner', 'name', 'type', 'share', 'isCompany'], {
          is: (owner, name, type, share, isCompany) => {
            let founder = { name, type, share, isCompany };
            return noVerification(founder, owner)
          },
          then: yup.string().notRequired(),
          otherwise: yup.string().min(1, 'Укажите адрес электронной почты').required("Укажите адрес электронной почты"),
        }).uniqueProperties(['email', 'Данный е-mail уже используется']),
      }),
    ),
    defaultValue: ({ activeLegalEntity, defaultValues }) =>
      defaultValues.founders ||
      (activeLegalEntity.dadata?.founders || []).map(founder => {
        let owner = false;
        let isCompany = false;

        if (founder.ogrn || founder.hasOwnProperty("ogrn")) {
          isCompany = true;
        }

        if (founder.type === "PHYSICAL") {
          let name = ""

          if (founder?.fio?.surname) {
            name = founder.fio.surname
          }
          if (founder?.fio?.name) {
            name += " " + founder.fio.name
          }

          if (founder?.fio?.patronymic) {
            name += " " + founder.fio.patronymic
          }

          if (activeLegalEntity?.dadata?.management?.name === name) {
            owner = true;
          }

          return {
            name,
            share: buildShareValue(founder?.share),
            type: founder?.share?.type,
            isCompany,
            owner,
          };
        }

        if (founder.type === "LEGAL") {
          if (activeLegalEntity?.dadata?.management?.name === founder.name) {
            owner = true;
          }

          return {
            name: founder.name,
            share: buildShareValue(founder?.share),
            type: founder?.share?.type,
            isCompany,
            owner,
          };
        }

        return { name: "", share: "", type: "", isCompany, owner };
      }),
    FieldComponent: Founders,
  },
  series_number_passport: {
    name: "series_number_passport",
    validation: yup.string().required("Укажите серию и номер паспорта"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "0000-000000",
      saveEvent: "blur",
    },
  },
  date_issue_passport: {
    name: "date_issue_passport",
    validation: yup.string().required("Укажите дату выдачи паспорта"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "23.08.200",
      saveEvent: "blur",
    },
  },
  issued_by_passport: {
    name: "issued_by_passport",
    validation: yup.string().required("Укажите кем был выдан паспорт"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  division_code: {
    name: "division_code",
    validation: yup.string().required("Укажите код подразделения"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  date_birth: {
    name: "division_code",
    validation: yup.string().required("Укажите дату рождения"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  location_birth: {
    name: "location_birth",
    validation: yup.string().required("Укажите место рождения"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  nationality: {
    name: "nationality",
    validation: yup.string().required("Выберите один из вариантов"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      SelectProps: {
        native: true,
      },
      fieldType: "text",
      saveEvent: "change",
      select: true,
      // eslint-disable-next-line react/react-in-jsx-scope
      children: <SelectOptions options={NATIONALITY_TYPES} />,
    },
  },
  country: {
    name: "country",
    validation: yup.string().required("Укажите страну"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  city: {
    name: "city",
    validation: yup.string().required("Укажите город"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  street: {
    name: "street",
    validation: yup.string().required("Укажите улицу"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  house: {
    name: "house",
    validation: yup.string().required("Укажите номер дома"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  apartment: {
    name: "apartment",
    validation: yup.string().required("Укажите номер квартиры"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "",
      saveEvent: "blur",
    },
  },
  is_founders_correct: {
    name: "is_founders_correct",
    validation: yup
      .boolean()
      .required("Подтвердите корректность данных")
      .oneOf([true], "Подтвердите корректность данных"),
    defaultValue: false,
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "checkbox",
      saveEvent: "change",
    },
  },
  is_founders_ready_to_be_guarantors: {
    name: "is_founders_ready_to_be_guarantors",
    validation: yup.string().required("Выберите один из вариантов"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "radio",
      options: YES_NO_OPTIONS,
      saveEvent: "change",
    },
  },
  is_business_partner_exists: {
    name: "is_business_partner_exists",
    validation: yup.string().required("Выберите один из вариантов"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "radio",
      saveEvent: "change",
      options: YES_NO_OPTIONS,
    },
  },
  attorney_name: {
    name: "attorney_name",
    validation: FIO_VALIDATION,
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "nameAutocomplete",
      placeholder: "Иванов Иван Валентинович",
      saveEvent: "change",
    },
  },
  beneficiary_name: {
    name: "beneficiary_name",
    validation: FIO_VALIDATION,
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      adornmentTooltip: "Конечный владелец бизнеса",
      fieldType: "nameAutocomplete",
      placeholder: "Петров Алексей Иванович",
      saveEvent: "change",
    },
  },
  // Третий шаг
  // ─── FINANCES ─────────────────────────────────────────────────────────────────
  //
  is_edoc_using: {
    name: "is_edoc_using",
    validation: yup.string().required("Выберите один из вариантов"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "radio",
      options: YES_NO_OPTIONS,
      saveEvent: "change",
    },
  },
  taxation_type: {
    name: "taxation_type",
    validation: yup.string().required("Выберите один из вариантов"),
    // defaultValue: ({ activeLegalEntity, defaultValues }) => (
    //   defaultValues.taxation_type || activeLegalEntity.dadata?.finance?.tax_system || ""
    // ),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      SelectProps: {
        native: true,
      },
      fieldType: "text",
      saveEvent: "change",
      select: true,
      children: <SelectOptions options={TAX_SYSTEMS} />,
    },
  },
  ceo_income: {
    name: "ceo_income",
    validation: yup
      .string()
      .required("Укажите доход руководителя в месяц в среднем за последний год"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      InputProps: {
        inputComponent: AmountMaskInput,
      },
      adornmentTooltip: "В среднем за последний год",
      fieldType: "text",
      inputProps: { inputMode: "numeric", pattern: "[0-9]*" },
      saveEvent: "blur",
    },
  },
  net_profit: {
    name: "net_profit",
    validation: yup.string().required("Укажите чистую прибыль в месяц"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      InputProps: {
        inputComponent: AmountMaskInput,
      },
      adornmentTooltip: "В среднем за последний год",
      fieldType: "text",
      inputProps: {
        inputMode: "numeric",
        pattern: "[0-9]*",
      },
      saveEvent: "blur",
    },
  },
  accounting_maintainer_type: {
    name: "accounting_maintainer_type",
    validation: yup.string().required("Выберите один из вариантов"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "radio",
      options: ACCOUNTING_VALUES,
      saveEvent: "change",
    },
  },
  accounting_maintainer_phone: {
    name: "accounting_maintainer_phone",
    validation: PHONE_VALIDATION.notRequired().when("accounting_maintainer_type", {
      is: val => val === "staff" || val === "outsourced",
      then: yup.string().required("Укажите номер телефона"),
      otherwise: yup.string().notRequired(),
    }),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      InputProps: {
        inputComponent: PhoneMaskInput,
      },
      fieldType: "text",
      placeholder: "+7 (***) ***-**-**",
      saveEvent: "blur",
    },
  },
  accounting_maintainer_email: {
    name: "accounting_maintainer_email",
    validation: EMAIL_VALIDATION.notRequired().when("accounting_maintainer_type", {
      is: val => val === "staff" || val === "outsourced",
      then: yup.string().required("Укажите адрес электронной почты"),
      otherwise: yup.string().notRequired(),
    }),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      placeholder: "ivanova@example.ru",
      saveEvent: "blur",
    },
  },
  // Четвертый шаг
  // ─── CONTACTS ─────────────────────────────────────────────────────────────────
  //
  legal_address: {
    name: "legal_address",
    validation: yup.string().trim().required("Укажите адрес"),
    defaultValue: ({ activeLegalEntity, defaultValues }) => activeLegalEntity?.dadata?.opf?.short === "ИП" ? defaultValues.legal_address || "" : defaultValues.legal_address || activeLegalEntity.dadata?.address.value || "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      saveEvent: "change",
      placeholder: "127015, Г.Москва, ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ БУТЫРСКИЙ, УЛ НОВОДМИТРОВСКАЯ, Д. 2",
      hiddenLabel: true,
    },
  },
  is_same_actual_address: {
    name: "is_same_actual_address",
    validation: yup.string().required("Выберите один из вариантов"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "radio",
      options: YES_NO_OPTIONS,
      saveEvent: "change",
    },
  },
  actual_address: {
    name: "actual_address",
    validation: yup
      .string()
      .notRequired()
      .when("is_same_actual_address", {
        is: val => val === "false",
        then: yup.string().trim().required("Укажите фактический адрес"),
        otherwise: yup.string().notRequired(),
      }),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      saveEvent: "change",
    },
  },
  is_warehouse_exists: {
    name: "is_warehouse_exists",
    validation: yup.string().required("Выберите один из вариантов"),
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "radio",
      options: YES_NO_OPTIONS,
      saveEvent: "change",
    },
  },
  is_other_business_points_exists: {
    name: "is_other_business_points_exists",
    validation: yup.string().required("Выберите один из вариантов"),
    // eslint-disable-next-line
    defaultValue: ({ activeLegalEntity, defaultValues }) => defaultValues.is_other_business_points_exists || new Boolean(activeLegalEntity.dadata?.branch_count).toString() || "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "radio",
      options: YES_NO_OPTIONS,
      saveEvent: "change",
    },
  },
  entity_money_freeze: {
    name: "entity_money_freeze",
    validation: yup.string().required("Выберите один из вариантов"),
    defaultValue: '',
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "radio",
      options: YES_NO_OPTIONS,
      saveEvent: "change",
    },
  },
  money_freeze: {
    name: "money_freeze",
    validation: yup.string().required("Выберите один из вариантов"),
    defaultValue: '',
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "radio",
      options: YES_NO_OPTIONS,
      saveEvent: "change",
    },
  },
  money_freeze_judge: {
    name: "money_freeze_judge",
    validation: yup.string().required("Выберите один из вариантов"),
    defaultValue: '',
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "radio",
      options: YES_NO_OPTIONS,
      saveEvent: "change",
    },
  },
  ipdl: {
    name: "ipdl",
    validation: yup.string().trim().required("Укажите требуемую информацию"),
    defaultValue: ({ defaultValues }) => defaultValues.ipdl || "Нет",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      saveEvent: "change",
      placeholder: "Нет",
      hiddenLabel: true,
    },
  },
  phone: {
    name: "phone",
    validation: PHONE_VALIDATION,
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      InputProps: {
        inputComponent: PhoneMaskInput,
      },
      fieldType: "text",
      placeholder: "+7 (***) ***-**-**",
      saveEvent: "change",
      type: "tel",
    },
  },
  email: {
    name: "email",
    validation: EMAIL_VALIDATION,
    defaultValue: "",
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "text",
      name: "email",
      placeholder: "info@example.ru",
      saveEvent: "change",
    },
  },
  is_accept_privacy_policy: {
    name: "is_accept_privacy_policy",
    validation: yup
      .boolean()
      .required("Подтвердите согласие с политикой обработки персональных данных")
      .oneOf([true], "Подтвердите согласие с политикой обработки персональных данных"),
    defaultValue: false,
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "checkbox",
      saveEvent: "change",
    },
  },
  is_accept_making_loan_request: {
    name: "is_accept_making_loan_request",
    validation: yup
      .boolean()
      .required("Подтвердите согласие с политикой обработки персональных данных")
      .oneOf([true], "Подтвердите согласие с политикой обработки персональных данных"),
    defaultValue: false,
    FieldComponent: FieldContainer,
    componentProps: {
      fieldType: "checkbox",
      saveEvent: "change",
    },
  },
};
