import PropTypes from 'prop-types';
import React from 'react';

import { Box, Container, Paper } from '@material-ui/core';
import { Router } from '@reach/router';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import Page404 from '../components/Page404';
import PageContracts from '../components/PageContracts';
import PageEntities from '../components/PageEntities';
import PageEntity from '../components/PageEntity';
// import PageEntityVerification from '../components/PageEntityVerification';
import PageUserProfile from '../components/PageUserProfile';
import CustomerRoutes from '../namespaces/customer/Routes';
import InvestorRoutes from '../namespaces/investor/Routes';
import PartnershipRoutes from '../namespaces/partnership/Routes';
import Appbar from './Appbar';
import AppContextProvider from './AppContextProvider';
import ContainerActiveLegalEntity from './ContainerActiveLegalEntity';

AppRoutes.propTypes = {
  authToken: PropTypes.string,
  authTokenPartner: PropTypes.string,
};
export default function AppRoutes({ authToken, authTokenPartner }) {
  const { pathname, search } = useLocation();


  if (pathname.includes('/inactive-link')) {
    navigate(`/inactive-link`);
    return null;
  }

  if (pathname.includes('/activate_partner_user')) {
    navigate(`/activate-partner-user${search}`);
    return null;
  }

  if (pathname.includes('/set_new_password')) {
    navigate(`/recover-password${search}`);
    return null;
  }

  if (pathname.includes('/partnership') && !authTokenPartner) {
    navigate('/partnership/login');
    return null;
  }

  if (!authToken && !pathname.includes('/partnership')) {
    navigate('/login');
    return null;
  }

  return (
    <AppContextProvider>
      <Paper component={Router} elevation={4} primary={false} square>
        <Appbar path="/*" />
      </Paper>
      <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
        <ContainerActiveLegalEntity>
          <Box component={Router} primary={false}>
            <CustomerRoutes path="/*" />
            <InvestorRoutes path="/investments/*" />

            <PartnershipRoutes path="/partnership/*" />

            <PageContracts path="/contracts" />
            <PageEntity path="/entity" />
            {/* <PageEntityVerification path="/entity/verification" /> */}
            <PageUserProfile path="/profile" />
            <PageEntities path="/entities" />
            <Page404 default />
          </Box>
        </ContainerActiveLegalEntity>
      </Container>
    </AppContextProvider>
  );
}
