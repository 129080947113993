import deepmerge from 'deepmerge';

import { baseConfig } from '../base.config';

export const partnershipConfig = deepmerge(baseConfig, {
  nav: {
    'index': {
      label: 'Заявки',
      to: '/partnership',
    },
    'profile': {
      label: 'Профиль компании',
      to: '/partnership/profile',
    },
    'marketing': {
      label: 'Маркетинг',
      // development: true,
      to: '/partnership/marketing',
    },
  },
});