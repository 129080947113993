import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import { gql, useQuery } from '@apollo/client';
import { Box, CircularProgress } from '@material-ui/core';
import useFetch from 'use-http';

import useActiveLegalEntity from '../hooks/useActiveLegalEntity';
import { AppDispatchContext, AppStateContext } from './AppContextProvider';

const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party';
const token = '640f03ef7ad1b62d099385d9b724257b51376ee7';
const options = {
  method: 'POST',
  mode: 'cors',
  cachePolicy: 'cache-first',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Token ${token}`,
  },
};

const FETCH_ACTIVE_ENTITY = gql`
  query FetchActiveEntity($entityId: Number!) {
    entity(entityId: $entityId) @rest(type: "Entity", path: "/entities/{args.entityId}") {
      actionStatuses
      bankStatementsCount
      estimatedAmount
      id
      inn
      latePaymentsAmount
      legalName
      nextPaymentAmount
      nextPaymentDate
      roles
      shortLegalName
      staff
      partnerToken
    }
  }
`;

ContainerActiveLegalEntity.propTypes = {
  children: PropTypes.node,
};
export default function ContainerActiveLegalEntity({ children }) {
  const { activeLegalEntityId } = useContext(AppStateContext);

  const { loading } = useQuery(FETCH_ACTIVE_ENTITY, {
    variables: { entityId: activeLegalEntityId },
    onCompleted: handleCompleted,
  });

  function handleCompleted({ entity }) {
    dispatch({
      type: 'UPDATE_LEGAL_ENTITY',
      payload: entity,
      meta: {
        entityId: entity.id,
      },
    });

    dispatch({
      type: 'SET_ACTIVE_LEGAL_ENTITY_PERMISSIONS',
      payload: { roles: entity?.roles || [] },
    });

    dispatch({
      type: 'SET_INITIALIZING_STATE',
      payload: { activeLegalEntity: 'loaded' },
    });
  }

  const dispatch = useContext(AppDispatchContext);
  const { user } = useContext(AppStateContext);

  const activeLegalEntity = useActiveLegalEntity();

  const dadataRequest = useFetch(url, options);

  // eslint-disable-next-line no-unused-vars
  const { data: dataSubscribeDocs, get: getSubscribeDocs, loading: loadingSubscribeDocs } = useFetch();

  useEffect(() => {
    // TODO:  arenza sign
    // getSubscribeDocs(`/entities/${activeLegalEntityId}/contracts`)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLegalEntityId])

  useEffect(() => {
    if (dataSubscribeDocs?.length >= 0) {
      dispatch({ type: 'SET_SUBSCRIBE_DOCS', payload: dataSubscribeDocs });
    }
    dispatch({ type: 'SET_PERMISSIONS_SUBSCRIBE_DOCS', payload: user });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSubscribeDocs, activeLegalEntityId])

  useEffect(() => {
    fetchEntityDadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLegalEntityId, activeLegalEntity.meta]);

  async function fetchEntityDadata() {
    const result = await dadataRequest.post({
      query: activeLegalEntity.data.inn,
    });

    dispatch({
      type: 'UPDATE_LEGAL_ENTITY',
      payload: { dadata: result?.suggestions[0]?.data },
      meta: {
        entityId: activeLegalEntityId,
      },
    });
  }

  if (loading || loadingSubscribeDocs) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: 320,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return children;
}