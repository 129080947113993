import PropTypes from 'prop-types';
import React from 'react';

import { Box, Card, CardContent, Skeleton, Typography } from '@material-ui/core';

CardLeasingApplication.propTypes = {
  amountNode: PropTypes.node,
  numberDateNode: PropTypes.node,
  statusNode: PropTypes.node,
  subjectNode: PropTypes.node,
  termNode: PropTypes.node,
};
export default function CardLeasingApplication({
  amountNode = <Skeleton width={140} />,
  numberDateNode = <Skeleton width={220} />,
  statusNode = <Skeleton width={220} />,
  subjectNode = <Skeleton />,
  termNode = <Skeleton width={80} />,
}) {
  return (
    <Card sx={{
      height: '100%',
      boxShadow: { xs: 'none', md: '0 1px 50px 1px rgba(208, 208, 208, 0.5)' },
      border: { xs: '1px solid #e0e0e0', md: '0' }
    }}
    >
      <CardContent sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: '100%'
      }}
      >
        <Box>
          <Typography color="primary">{statusNode}</Typography>
          <Box
            sx={{
              display: "flex",
              maxWidth: 512,
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            {numberDateNode}
          </Box>
          <Typography sx={{ my: 2 }} variant="h4">
            {subjectNode}
          </Typography>
        </Box>
        <Box sx={{ maxWidth: 512 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Typography color="text.secondary" variant="body2">
              Срок лизинга
            </Typography>
            <Typography>{termNode}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Typography color="text.secondary" variant="body2">
              Сумма&nbsp;
            </Typography>
            <Typography sx={{ fontWeight: 500, whiteSpace: "nowrap" }}>{amountNode}</Typography>
          </Box>
        </Box>
      </CardContent>
      <span /> {/* hack last-child, чтобы убрать лишний padding у MuiCardContent  */}
    </Card>
  );
}
