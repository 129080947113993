import "@arenza/typefaces-museo-sans";
import React, { useMemo } from "react";

import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import RecoverPassword from "@components/RecoverPassword";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { PageTechnicalWork } from "@product-site-frontend/shared";
import { Router, useLocation } from "@reach/router";
import * as Sentry from "@sentry/react";
import { RestLink } from "apollo-link-rest";
import camelCase from "camelcase";
import { navigate } from "gatsby";
import qs from "querystringify";
import { ErrorBoundary } from "react-error-boundary";
import { Helmet } from "react-helmet";
import { IntlProvider } from "react-intl";
import { snakeCase } from "snake-case";
import { Provider } from "use-http";

import '@Development/ui-kit/dist/cjs/index.css';

import config from "../../config/website";
import ActivatePartnerUser from "../components/ActivePartnerUser/ActivatePartnerUser";
import InactiveShortLink from "../components/InactiveShortLink";
import PageLogin from "../components/PageLogin/PageLogin";
import PageLoginPartner from "../components/PageLogin/PageLoginPartner";
import Viewport from "../components/Viewport";
import AlertAppError from "../core/AlertAppError";
import AppRoutes from "../core/AppRoutes";
import messages from "../core/ru";
import { arenzaStorage, AUTH_TOKEN_PARTNER_STORAGE_KEY, AUTH_TOKEN_STORAGE_KEY } from "../core/storage";
import theme from "../core/theme";

if (global.Headers == null) {
  const fetch = require("node-fetch");
  global.Headers = fetch.Headers;
}

if (process.env.GATSBY_ACTIVE_ENV === 'prod' || process.env.GATSBY_ACTIVE_ENV === 'stage') {
  Sentry.init({
    dsn: "https://34ea6e49958e155dfddeebbe174c265c@sentry.arenza.ru/8",
    environment: process.env.GATSBY_ACTIVE_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    denyUrls: [
      /captcha-api\.yandex\.ru/i,
      /google-analytics\.com/i,
      /mc\.yandex\.ru/i,
      /analytics\.google\.com/i
    ],
    // denyUrls: [/https?:\/\/((cdn|www)\.)?example\.com/], denyUrls
  });
}

const styleRouter = { height: "100%" };

const authRestLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    let authToken = null;

    if (window?.location?.pathname?.includes('/partnership')) {
      authToken = arenzaStorage(AUTH_TOKEN_PARTNER_STORAGE_KEY);
    } else {
      const { auth_token } = qs.parse(window.location.search);

      if (auth_token) {
        arenzaStorage(AUTH_TOKEN_STORAGE_KEY, auth_token);
      }

      authToken = auth_token || arenzaStorage(AUTH_TOKEN_STORAGE_KEY);
    }

    return {
      headers: {
        ...headers,
        Accept: "application/json",
        Authorization: `Token ${authToken}`,
      },
    };
  });
  return forward(operation);
});

const errorLink = onError(({ forward, graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    Sentry.withScope(scope => {
      scope.setExtra("[graphQL error]", operation.operationName)
      scope.setExtra("operation", operation)
      Sentry.captureException(graphQLErrors)
    });

    graphQLErrors.forEach(error => {
      console.log("🚀 ~ file: WrapperApollo.jsx ~ line 35 ~ errorLink ~ error", error);
    });
  }
  if (networkError) {
    Sentry.withScope(scope => {
      scope.setExtra("[Network error]", operation.operationName)
      scope.setExtra("operation", operation)
      Sentry.captureException(networkError)
    });

    console.log(`[Network error]: ${networkError}`);
  }
});

async function customFetch(requestInfo, init) {
  const response = await fetch(requestInfo, init);
  if (response.status === 404) {
    throw new Error("404 File Not Found");
  }
  if (response.status === 401) {
    if (window.location.pathname.includes('/partnership/login')) return false;

    if (window.location.pathname.includes('/partnership')) {
      arenzaStorage.remove(AUTH_TOKEN_PARTNER_STORAGE_KEY);
      navigate("/partnership/login");
    } else {
      arenzaStorage.remove(AUTH_TOKEN_STORAGE_KEY);
      navigate("/login");
    }
  }
  return response;
}

const restLink = new RestLink({
  uri: process.env.GATSBY_FRONTOFFICE_API,
  customFetch: (requestInfo, init) => customFetch(requestInfo, init),
  fieldNameNormalizer: key => camelCase(key),
  fieldNameDenormalizer: key => snakeCase(key),
});

const client = new ApolloClient({
  link: ApolloLink.from([authRestLink, errorLink, restLink]),
  cache: new InMemoryCache(),
});

function buildFetchOptions(authToken) {
  return {
    headers: {
      Accept: "application/json",
    },
    cachePolicy: "no-cache",
    interceptors: {
      request: async ({ options, url }) => {
        if (authToken && url.includes("arenza.ru")) {
          options.headers.Authorization = `Token ${authToken}`;
        }
        return options;
      },
      response: async ({ response }) => {
        if (response.status === 401) {
          if (window.location.pathname.includes('/partnership/login')) return false;

          if (window.location.pathname.includes('/partnership')) {
            arenzaStorage.remove(AUTH_TOKEN_PARTNER_STORAGE_KEY);
            navigate("/partnership/login");
          } else {
            arenzaStorage.remove(AUTH_TOKEN_STORAGE_KEY);
            navigate("/login");
          }
        }
        return response;
      },
    },
  };
}

export default function App() {
  const isSSR = typeof window === "undefined";
  const { pathname } = useLocation();

  const { authToken, authTokenPartner, options } = useMemo(() => {
    if (!isSSR) {
      const { auth_token } = qs.parse(window.location.search);

      if (auth_token) {
        arenzaStorage(AUTH_TOKEN_STORAGE_KEY, auth_token);
      }

      const authToken = auth_token || arenzaStorage(AUTH_TOKEN_STORAGE_KEY);

      const authTokenPartner = arenzaStorage(AUTH_TOKEN_PARTNER_STORAGE_KEY);

      let options = null;

      if (pathname.includes('/partnership')) {
        options = buildFetchOptions(authTokenPartner);
      } else {
        options = buildFetchOptions(authToken);
      }

      return { authToken, authTokenPartner, options };
    }
    return {};
  }, [isSSR, pathname]);

  return (
    JSON.parse(process.env.GATSBY_ENGINEERING_WORKS) ? (
      <PageTechnicalWork />
    ) : (
      !isSSR && (
        <React.Suspense fallback={<div />}>
          <Helmet
            htmlAttributes={{
              lang: config.lang,
            }}
          >
            <meta content="noindex, nofollow" name="robots" />
          </Helmet>
          <Viewport />
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ApolloProvider client={client}>
              <Provider options={options} url={process.env.GATSBY_FRONTOFFICE_API}>
                <IntlProvider defaultLocale="ru" locale="ru" messages={messages}>
                  <ErrorBoundary FallbackComponent={AlertAppError} onError={handleError}>
                    <Router style={styleRouter}>
                      <PageLoginPartner path="partnership/login" />
                      <ActivatePartnerUser path="activate-partner-user" />
                      <InactiveShortLink path="inactive-link" />
                      <RecoverPassword path="recover-password" />

                      <PageLogin path="login" />
                      <AppRoutes authToken={authToken} authTokenPartner={authTokenPartner} path="/*" />
                    </Router>
                  </ErrorBoundary>
                </IntlProvider>
              </Provider>
            </ApolloProvider>
          </ThemeProvider>
        </React.Suspense>
      )
    )
  );
}

function handleError(error, info) {
  Sentry.withScope(scope => {
    scope.setExtra("operation", "Ошибка при рендере приложения")
    scope.setExtra("info", info)
    Sentry.captureException(error)
  });
}
