import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";

import { arenzaStorage, AUTH_TOKEN_STORAGE_KEY } from "@core/storage";
import useUpload from "@hooks/useUpload";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import DownloadingIcon from "@material-ui/icons/Downloading";
import ErrorIcon from "@material-ui/icons/Error";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import { useDropzone } from 'react-dropzone';
import useFetch from "use-http";

import DropzoneDocuments from './DropzoneDocuments';
import LayoutFileRow from './LayoutFileRow';
import LinearProgressWithLabel from "./LinearProgressWithLabel";

CardDocumentStatementForLastYear.propTypes = {
  entityId: PropTypes.any,
  opf: PropTypes.oneOf(['ip', 'company']),
};
export default function CardDocumentStatementForLastYear({ entityId }) {
  const { acceptedFiles, getInputProps, getRootProps } = useDropzone({
    accept: ".txt",
  });
  const { data = [], loading, get, error } = useFetch(`/entities/${entityId}/bank_statements`, []);

  const headerTitle = (
    <Typography sx={{ display: 'flex', alignItems: 'center', gap: '6px' }} variant="h4">
      Выписка с расчетного счета за последний год

      <Tooltip arrow enterTouchDelay={100} title={'Выписка должна быть в формате 1С (текстовый файл с расширением .txt)'}>
        <HelpOutlineRoundedIcon sx={{ color: '#B6BBC2', cursor: 'pointer' }} />
      </Tooltip>
    </Typography>
  );

  return (
    <Card elevation={5}>
      <CardHeader title={headerTitle} />

      {error && (
        <Box sx={{ padding: ' 0 16px 16px' }}>
          При загрузке выписок произошла ошибка
        </Box>
      )}

      {loading && (
        <Box sx={{ padding: ' 0 16px 16px' }}>
          <Skeleton />
        </Box>
      )}

      {data?.length || acceptedFiles?.length ? (
        <CardContent sx={{ pt: 0 }}>
          <Stack spacing={1}>
            {data?.map(({ created_at, filename, id }) => (
              <LayoutFileRow
                createdAt={created_at}
                disabledDownload={true}
                entityId={entityId}
                fileId={id}
                filename={filename}
                icon={<CheckRoundedIcon color="success" />}
                key={id}
              />
            ))}

            {acceptedFiles?.map((file, index) => (
              <FileUpload
                {...{ entityId, file, get }}
                key={index}
              />
            ))}
          </Stack>
        </CardContent>
      ) : null}
      <Divider />

      <DropzoneDocuments
        {...{ getInputProps, getRootProps }}
        title="Загрузить"
      />
    </Card>
  );
}

FileUpload.propTypes = {
  entityId: PropTypes.number,
  file: PropTypes.shape({ name: PropTypes.string }),
  get: PropTypes.func,
};
function FileUpload({ entityId, file, get }) {
  const [upload, uploadState] = useUpload(({ files }) => {
    let formData = new FormData();
    formData.append('statement_file', files[0]);

    return {
      method: 'POST',
      url: `${process.env.GATSBY_FRONTOFFICE_API}/entities/${entityId}/bank_statements`,
      body: formData,
      headers: { Authorization: `Token ${arenzaStorage(AUTH_TOKEN_STORAGE_KEY)}` },
    };
  });

  const [errorUpload, setErrorUpload] = useState(false);

  const { data, done, loading, progress } = uploadState;

  useEffect(() => {
    upload({ files: [file] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (done && data && data.filename) {
      get();
    } else if (data?.error) {
      setErrorUpload(true);
    }
  }, [done, data, get]);

  if (loading && progress) {
    return (
      <Box>
        <LayoutFileRow
          filename={file.name}
          icon={<DownloadingIcon color="action" sx={{ transform: "rotate(180deg)" }} />}
        />
        <LinearProgressWithLabel value={progress} />
      </Box>
    );
  }

  if (errorUpload) {
    return (
      <Box sx={{ color: 'red', display: 'flex', alignItems: 'center', gap: '15px' }}>
        <ErrorIcon />

        При загрузке файла произошла ошибка. Проверьте корректность файла или попробуйте загрузить позже.
      </Box>
    )
  }

  return null;
}
