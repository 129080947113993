import React from "react";

import { Card, Typography } from "@material-ui/core";

import { formatDate } from "../core/helpers";
import useActiveLegalEntity from "../hooks/useActiveLegalEntity";
import FormattedCurrency from "./FormattedCurrency";

const styles = {
  title: {
    color: '#6E7884',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 300
  },
  text: {
    color: '#0D1D32',
    fontSize: '21px',
    lineHeight: '32px',
    fontWeight: '700',
    paddingTop: '8px'
  },
  textDashed: {
    color: '#0D1D32',
    fontSize: '21px',
    lineHeight: '32px',
    fontWeight: '700',
    borderBottom: '1px dashed #E7E8EA',
    paddingBottom: '8px'
  },
}

export default function WidgetBalance() {
  const { data, sx } = useActiveLegalEntity();

  return (
    <Card
      sx={{
        boxShadow: '0 1px 50px 1px rgba(208, 208, 208, 0.5)',
        borderRadius: '4px',
        p: 4,
        marginBottom: '24px',

        ...sx
      }}
    >
      <Typography color="text.secondary" gutterBottom sx={styles.title} variant="body2">
        Предодобренная сумма
      </Typography>
      <Typography gutterBottom sx={styles.textDashed} variant="h4">
        <FormattedCurrency value={data.estimatedAmount} />
        &nbsp;руб
      </Typography>
      <Typography color="text.secondary" gutterBottom sx={styles.title} variant="body2">
        Сумма задолженности
      </Typography>
      <Typography gutterBottom sx={styles.textDashed} variant="h4">
        <FormattedCurrency value={data.latePaymentsAmount} />
        &nbsp;руб
      </Typography>
      {data.nextPaymentDate && data.nextPaymentAmount !== '0.0' && (
        // FIXME: if we need to check by date
        // new Date(data.nextPaymentDate).getTime() >= new Date().getTime() && (
        <>
          <Typography color="text.secondary" gutterBottom sx={styles.title} variant="body2">
            Следующий платеж
          </Typography>
          <Typography sx={styles.text} variant="h4">
            {formatDate(data.nextPaymentDate, "full")}
          </Typography>
          <Typography gutterBottom sx={styles.text} variant="h4">
            <FormattedCurrency value={data.nextPaymentAmount} />
            &nbsp;руб
          </Typography>
        </>
      )}
    </Card>
  );
}
