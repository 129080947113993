import { useState } from 'react';

import useFetch from 'use-http';

export default function useDownloadDoc({ entityId, fileId, filename }) {
  const [error, setError] = useState(null);

  const { get, loading, response } = useFetch(
    `/entities/${entityId}/entity_documents/${fileId}/download`,
    { cachePolicy: 'no-cache', responseType: 'blob' },
  );

  const onDownload = async () => {
    setError(null);
    await get();
    if (response.ok) {
      const blob = response.data;

      // Url to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );

      // Link node
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);

      // Simulate a click
      link.click();

      link.parentNode.removeChild(link);
    } else {
      setError(`Файл ${filename} не найден!`);
    }
  };

  return { loading, onDownload, error };
}