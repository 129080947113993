import { normalize, schema } from 'normalizr';

import { ACTIVE_ENTITY_ID, arenzaStorage } from './storage';

const legalEntity = new schema.Entity('legalEntities');
const legalEntitySchema = [legalEntity];

const supplyingOrderClassificator = new schema.Entity('classificators');
const classificatorsSchema = [supplyingOrderClassificator];

// const loanEntity = new schema.Entity('loans');
// const loanEntitySchema = [loanEntity];

export const initialState = {
  initializingState: {},
  entities: {
    legalEntities: {
      data: {},
      meta: {},
    },
    classificators: {
      data: {},
      meta: {},
    },
  },
  lists: {
    legalEntities: [],
    classificators: [],
  },
};

export function reducer(state, action) {
  switch (action.type) {
    case 'SET_USER': {
      return {
        ...state,
        initializingState: {
          ...state.initializingState,
          user: 'loaded',
        },
        user: action.payload,
      };
    }

    case 'SET_INITIALIZING_STATE': {
      return {
        ...state,
        initializingState: {
          ...state.initializingState,
          ...action.payload,
        },
      };
    }

    case 'SET_USER_ENTITIES': {
      const meta = action.payload?.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.id]: {
            userConfirmed: state?.user?.confirmedEntitiesIds?.includes(curr.id),
            opf: curr.inn.length === 12 ? 'ip' : 'company',
          },
        }),
        {},
      );

      const { entities, result } = normalize(action.payload, legalEntitySchema);

      const storedActiveLegalEntityId = arenzaStorage(ACTIVE_ENTITY_ID);
      const activeLegalEntityId = result.includes(storedActiveLegalEntityId)
        ? storedActiveLegalEntityId
        : result[0];

      return {
        ...state,
        initializingState: {
          ...state.initializingState,
          userEntities: 'loaded',
        },
        activeLegalEntityId,
        entities: {
          ...state.entities,
          legalEntities: {
            data: entities.legalEntities,
            meta,
          },
        },
        lists: {
          ...state.lists,
          legalEntities: result,
        },
      };
    }

    case 'SET_DICTIONARIES': {
      const { entities, result } = normalize(action.payload, classificatorsSchema);

      return {
        ...state,
        initializingState: {
          ...state.initializingState,
          dictionaries: 'loaded',
        },
        entities: {
          ...state.entities,
          classificators: {
            data: entities.classificators,
          },
        },
        lists: {
          ...state.lists,
          classificators: result,
        },
      };
    }

    case 'SET_ACTIVE_QUESTIONNAIRE_ID': {
      return {
        ...state,
        activeQuestionnaireId: action.payload.id,
      };
    }

    case 'SET_ACTIVE_LEGAL_ENTITY': {
      arenzaStorage(ACTIVE_ENTITY_ID, action.payload.activeLegalEntityId);

      return {
        ...state,
        activeLegalEntityId: action.payload.activeLegalEntityId,
      };
    }

    case 'SET_ACTIVE_LEGAL_ENTITY_PERMISSIONS': {
      const roles = action.payload?.roles || [];

      const hasCustomerAccess = roles.includes('customer');
      // const hasInvestorAccess = roles.includes('investor');
      // const hasPartnerAccess = roles.includes('partner');

      const permissions = {
        canViewBankStatements: hasCustomerAccess,
        canViewDocs: hasCustomerAccess,
        canViewQuestionnaire: hasCustomerAccess,
        canViewAccounting: hasCustomerAccess
      };

      return {
        ...state,
        activeLegalEntityPermissions: permissions,
      };
    }

    case 'SET_PERMISSIONS_SUBSCRIBE_DOCS': {
      let canElectronicSignatureSubscribeDocs = false;

      if (action.payload?.hasPersonalElectronicSignature?.includes(state?.activeLegalEntityId)) {
        canElectronicSignatureSubscribeDocs = true;
      }

      return {
        ...state,
        canElectronicSignatureSubscribeDocs,
      };
    }

    case 'SET_SUBSCRIBE_DOCS': {
      let subscribeDocs = [],
        subscribeDocsCounter = 0;

      action.payload.forEach(doc => {
        if (doc.status === 'awaiting_signing') {
          subscribeDocsCounter++
        }

        if (!subscribeDocs.includes(doc.application_id) && doc.status === 'awaiting_signing') {
          subscribeDocs.push(doc.application_id)
        }
      })

      return {
        ...state,
        subscribeDocs,
        subscribeDocsCounter,
      };
    }

    case 'UPDATE_LEGAL_ENTITY': {
      return {
        ...state,
        entities: {
          ...state.entities,
          legalEntities: {
            ...state.entities.legalEntities,
            data: {
              ...state.entities.legalEntities.data,
              [action.meta.entityId]: {
                ...state.entities.legalEntities.data[action.meta.entityId],
                ...action.payload,
              },
            },
          },
        },
      };
    }

    case 'SET_LEASING_MANAGER': {
      return {
        ...state,
        leasingManager: action.payload,
      };
    }

    default:
      return state;
  }
}