import PropTypes from "prop-types";
import { useEffect, useState } from "react";

Timer.propTypes = {
  onTimeout: PropTypes.func,
  timeout: PropTypes.number,
};

Timer.defaultProps = {
  timeout: 60,
}

export default function Timer({ onTimeout, timeout }) {
  const [count, setCount] = useState(timeout);

  const onTimer = () => {
    if (count > 0) {
      setTimeout(() => {
        let sec = count - 1;
        setCount(sec);
      }, 1000)
    } else {
      if (typeof onTimeout === "function") {
        onTimeout(0)
      }
    }
  }

  useEffect(() => {
    onTimer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onTimer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  return count;
}