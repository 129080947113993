import React, { useContext } from 'react';

import { useQuery } from '@apollo/client';
import { AppStateContext } from '@core/AppContextProvider';
import { Box, Typography } from '@material-ui/core';

import AccountInvestments from './AccountInvestments';
import FinancialPerformance from './FinancialPerformance';
import Styled from './PageInvestments.styled';
import PersonalManager from './PersonalManager';
import { GET_INVESTMENTS } from './queries';

export default function PageInvestments() {
  const { activeLegalEntityId } = useContext(AppStateContext);

  const { data, loading } = useQuery(GET_INVESTMENTS, {
    variables: { entityId: activeLegalEntityId },
  })

  return (
    <Box sx={Styled.Page}>
      <Box sx={Styled.Sidebar}>
        <FinancialPerformance />
        <PersonalManager />
      </Box>
      <Box sx={Styled.Content}>
        <Typography noWrap sx={{ mr: 5, pl: { xs: 3 } }} variant="h1">
          Ваши инвестиции
        </Typography>
        <AccountInvestments investments={data?.investments} loading={loading} />
      </Box>
    </Box>
  );
}
