import PropTypes from "prop-types";
import * as React from "react";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

CircularProgressWithLabel.propTypes = {
  value: PropTypes.any,
};

export default function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography color="textSecondary" component="div" variant="caption">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
