import React from 'react';

import { Skeleton } from '@material-ui/core';

export default function AccountInvestmentsSkeleton() {
  return (
    <>
      <Skeleton
        height={280}
        sx={{ mr: 4, transform: 'scale(1, 1)' }}
        width={360}
      />
      <Skeleton
        height={280}
        sx={{ transform: 'scale(1, 1)' }}
        width={360}
      />
    </>
  );
}