import React from 'react';

import { Typography } from '@material-ui/core';

import CardLeasingDeals from './CardLeasingDeals';

export default function PageLeasingDeals(props) {
  return (
    <>
      <Typography gutterBottom variant="h1">
        Лизинговые сделки
      </Typography>
      <CardLeasingDeals />
    </>
  );
}
