import React, { useMemo } from "react";

import { Box, Link } from "@material-ui/core";

import iconLogo from './img/logo-new.svg'

export default function InactiveLink() {
  const styles = useMemo(() => ({
    container: {
      textAlign: 'center',
      padding: {
        xs: '24px 10px',
        md: '40px',
        lg: '60px 48px',
      },
    },
    notFound: {
      display: 'inline-block',
      verticalAlign: 'middle',
      textAlign: 'left',
      width: {
        xs: '320px',
        md: '435px',
        lg: '100%',
      },
      maxWidth: '483px',
      color: '#0D1D32',
      background: '#FFFFFF',
      boxShadow: '0px 10px 32px rgba(13, 29, 50, 0.1)',
      borderRadius: '32px',
      padding: {
        xs: '24px 32px',
        md: '48px',
        lg: '64px',
      },
      minWidth: '300px',
      boxSizing: 'border-box',
    },
    header: {
      marginBottom: '34px',
      display: 'flex',
      gap: '16px',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    title: {
      fontSize: {
        xs: '19px',
        md: '22px',
        lg: '26px',
      },
      fontWeight: '700',
      lineHeight: '34px',
      textAlign: 'center',
    },
    body: {
      textAlign: 'center',
    },
    description: {
      fontSize: {
        xs: '16px',
        lg: '20px'
      },
      fontWeight: 300,
      lineHeight: '32px',
      marginBottom: '28px',
      textAlign: 'center',
    },
    link: {
      textAlign: 'center',
      fontSize: {
        xs: '14px',
        lg: '16px',
      },
      fontWeight: 500,
      lineHeight: {
        xs: '40px',
        lg: '48px',
      },
      color: '#FFFFFF',
      background: '#FF7810',
      width: '100%',
      display: 'inline-block',
      marginBottom: '68px',
      padding: '4px 8px',
      boxSizing: 'border-box',
      borderRadius: '8px',
      boxShadow: '0px 10px 24px 0px rgba(255, 120, 15, 0.30)',
      textDecoration: 'none',
      transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

      '&:hover': {
        background: '#E06A0E',
        boxShadow: 'none',
      },

      '&:active': {
        background: '#FF8C33',
        boxShadow: 'none',
      }
    },
    footer: {
      textAlign: 'center',
      marginTop: '40px',
      color: '#7D8897',
      fontSize: '14px',

      '& a': {
        color: '#FF7810',
        textDecoration: 'none',
      }
    },
    logo: {
      width: '135px',
    }
  }), []);

  return (
      <Box sx={ styles.container }>
        <Box sx={ styles.notFound }>
          <Box sx={ styles.header }>
            <Box sx={ styles.title }>К сожалению, данная ссылка больше не активна</Box>
          </Box>

          <Box sx={ styles.body }>
            <Box sx={ styles.description }>Пожалуйста, перейдите в личный кабинет по кнопке:</Box>

            <Link href={`${process.env.GATSBY_ACCOUNT_URL}/login`} rel="noreferrer" sx={ styles.link }>Личный кабинет</Link>

            <Link href={process.env.GATSBY_ROOT_URL} rel="noreferrer" sx={{ display: 'inline-block' }}>
              <Box component={'img'} src={iconLogo} sx={styles.logo} />
            </Link>
          </Box>

          <Box sx={ styles.footer }>
            <b>ООО &quot;АРЕНЗА-ПРО&quot;</b>
            <br />
            <span>ИНН: 7703413614 / ОГРН: 1167746687316</span>
            <br />
            <br />
            <span>Если у вас остались вопросы - свяжитесь, пожалуйста, с нами по телефону</span>
            <br />
            <br />
            <a href="tel:+74951254344" rel="noreferrer" target="_blank">+7 (495) 125-4344</a>
          </Box>
        </Box>
      </Box>
  );
}