export function getDefaultValues({ activeLegalEntity, defaultValues, stepFields }) {
  return stepFields.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.name]:
        typeof curr.defaultValue === "function"
          ? curr.defaultValue({ activeLegalEntity, defaultValues })
          : defaultValues[curr.name] || curr.defaultValue,
    }),
    {},
  );
}
