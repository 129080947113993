import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { PHONE_VALIDATION } from "@components/FormEntityQuestionnaire/constants/validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Container } from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { FormTextField, FormFieldContainer, PhoneMaskInput, maskedPhone } from "@product-site-frontend/shared";
import { SmartCaptcha } from "@yandex/smart-captcha";
import { useForm } from "react-hook-form";
import * as yup from "yup";

FormPhone.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  phone: PropTypes.string,
  setYandexCaptchaToken: PropTypes.func,
  yandexCaptchaToken: PropTypes.string,
};

export default function FormPhone({ loading, onSubmit, phone, setYandexCaptchaToken, yandexCaptchaToken }) {
  const FIELDS_CONFIG = {
    phone: {
      name: 'phone',
      validation: PHONE_VALIDATION.required('Укажите номер телефона'),
      defaultValue: '',
      FieldComponent: FormFieldContainer,
      componentProps: {
        label: 'Номер телефона *',
        InputProps: {
          inputComponent: PhoneMaskInput,
        },
        fieldType: 'text',
        placeholder: '+7 (***) ***-**-**',
        type: 'tel',
      },
    }
  };

  const FIELDS_ORDER = [
    'phone'
  ];

  const stepFields = useMemo(() => (
    FIELDS_ORDER.map((name) => {
      return FIELDS_CONFIG[name];
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const schema = useMemo(() => {
    return yup
      .object()
      .shape(stepFields.reduce(
        (prev, curr) => ({ ...prev, [curr.name]: curr.validation }),
        {},
      ));
  }, [stepFields]);

  const {
    control,
    handleSubmit,
  } = useForm({
    mode: 'onTouch',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      phone: maskedPhone.resolve(phone),
    },
    resolver: yupResolver(schema),
  });

  return (
    <Container
      component="form"
      maxWidth="sm"
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <FormTextField
        InputLabelProps={{ htmlFor: "form-application-phone" }}
        InputProps={{
          inputComponent: PhoneMaskInput,
        }}
        control={control}
        id="form-application-phone"
        label="Мобильный номер телефона"
        name="phone"
        placeholder="+7 (***) ***-**-**"
        sx={{ mb: 3 }}
        type="tel"
        variant="filled"
      />

      <Box sx={{ marginBottom: '24px' }}>
        <SmartCaptcha onSuccess={setYandexCaptchaToken} sitekey="ysc1_ukeyoNivym2HND6EMGFlIbLdbdmtpMHoOQ0MZ2CX092b1063" />
      </Box>

      <div style={{ width: "296px" }}>
        <LoadingButton
          disableElevation
          disabled={loading || !yandexCaptchaToken?.length}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Продолжить
        </LoadingButton>
      </div>
    </Container>
  );
}

// const MESSAGES = {
//   invalid: "Введён неверный код",
// };
