import React, { useEffect, useMemo, useState } from "react";

import useActiveLegalEntity from "@hooks/useActiveLegalEntity";
import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Link } from "gatsby";

import iconReturn from "../../img/arrReturn.svg";
import iconDownload from "../../img/download.svg";
import iconDesktop from "../../img/iconDesctop.svg";
import iconMobile from "../../img/iconMobile.svg";
import iconPad from "../../img/iconPad.svg";
import widgetButtonDesktop from "../../img/widget-button-desktop.png";
import widgetButtonMobile from "../../img/widget-button-mobile.png";
import widgetButtonPad from "../../img/widget-button-pad.png";
import FooterInf from "../FooterInf";

export default function PageWidgetButton() {
  const styles = useMemo(() => ({
    titlePage: {
      fontSize: '36px',
      fontWeight: 900,
      marginBottom: '48px',
    },
    wrapperContent: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '26px',

      '& > div': {
        width: {
          xs: '100%',
          lg: 'calc(50% - (26px / 2))',
        },
      },
    },
    title: {
      fontSize: '26px',
      fontWeight: 900,
    },
    psefUl: {
      marginTop: '24px',
      display: 'flex',
      gap: '10px',
      alignItems: 'center',

      '& p': {
        fontSize: '16px',
        fontWeight: 300,
      },

      '& span': {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#FF780F',
        color: '#ffffff',
        fontSize: '16px',
        fontWeight: '700',
        minWidth: '32px',
        width: '32px',
        minHeight: '32px',
        height: '32px',
        borderRadius: '20px',
      }
    },
    wrapperYoutubeBlock: {
      display: 'flex',
      flexWrap: {
        xs: 'wrap',
        md: 'nowrap'
      },
      gap: '24px',
      fontSize: '16px',
      fontWeight: '300',
      margin: '32px 0',

      '& .video': {
        minWidth: '240px',
        minHeight: '178px',
        width: '100%',
        height: '100%',
      }
    },
    wrapperImg: {
      height: '360px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '33px',
      marginBottom: '33px',

      '& img': {
        height: '90%',
      },
    },
    wrapperButtonView: {
      display: 'flex',
      gap: '16px',
      justifyContent: 'center',
    },
    buttonView: {
      width: '96px',
      height: '96px',
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '8px',
      fontSize: '16px',
      fontWeight: 500,
      borderRadius: '16px',
      background: '#F5F6F7',
      cursor: 'pointer',
      border: '1px solid #F5F6F7',
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

      '&:hover': {
        background: '#FFFFFF',
        border: '1px solid #FB8F10'
      },
      '&.active': {
        background: '#FFFFFF',
        border: '1px solid #FFFFFF',
        cursor: 'default',
        boxShadow: '0px 10px 32px 0px rgba(13, 29, 50, 0.10)',

        '& path': {
          fill: '#FB8F10',
        }
      },
    },
    buttonReturn: {
      width: '56px',
      height: '56px',
      border: '1px solid #D0D2D6',
      marginRight: '24px',
    },
    whiteButton: {
      background: '#ffffff',
      border: '1px solid #FFBB86',
      borderRadius: '8px',
      color: '#FF7810',
      fontSize: '16px',
      padding: '12px 20px',
      maxHeight: '48px',
      marginTop: '30px',
      textTransform: 'inherit',
      fontWeight: '500',
      marginBottom: '30px',

      '&:hover': {
        background: '#FFF5EC',
        color: '#FF7810',
        border: '1px solid #FFA157',
      },
      '&:active': {
        background: '#FFEEE1',
        color: '#D8670D',
        border: '1px solid #D8670D',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    codeInsert: {
      background: '#F5F6F7',
      color: '#6E7884',
      fontSize: '16px',
      fontWeight: '300',
      borderRadius: '8px',
      padding: '8px 16px',
      marginTop: '12px',
      marginLeft: '40px',
      wordWrap: 'break-word',
    },
  }),[])

  const [ activeView, setActiveView ] = useState('desktop')

  const onToggleView = view => {
    setActiveView(view)
  }

  const [partnerToken, setPartnerToken] = useState('ТОКЕН');

  const activeLegalEntity = useActiveLegalEntity();

  useEffect(() => {
    if (activeLegalEntity?.data?.partnerToken) {
      setPartnerToken(activeLegalEntity?.data?.partnerToken);
    }
  }, [activeLegalEntity]);

  return (
    <>
      <Box sx={{ background: '#fff' }}>
        <Box>
          <Typography sx={ styles.titlePage }>
            <IconButton component={Link} sx={ styles.buttonReturn } to={"/partnership/marketing"}>
              <Box component={"img"} src={iconReturn} />
            </IconButton>

            Виджет - кнопка
          </Typography>

          <Box  sx={ styles.wrapperContent }>
            <Box className={'content'} sx={ styles.content }>
              <Typography sx={ styles.title }>Как работает виджет - кнопка</Typography>

              <Box sx={ styles.wrapperYoutubeBlock }>
                <Box className={'video'}>
                  <iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen frameBorder="0" src="https://www.youtube.com/embed/DGVnQPZxHnI" title="YouTube video player" width="100%"></iframe>
                </Box>
                <Box>Виджет-кнопка встраивается в кнопку &quot;Купить в лизинг&quot;. В данном виджете есть функционал подтягивания данных о цене товара и составе корзины. С помощью виджета-кнопки вся информация о заказе будет передана менеджеру Arenza, не требуя уточнений по заказу.</Box>
              </Box>

              <Box>
                <Typography sx={ styles.title }>Как установить?</Typography>

                <Typography sx={ styles.psefUl }>
                  <span>1</span>

                  <Typography component={'p'}>Перед закрывающим тегом <strong style={{ fontWeight: 700 }}>&#60;/body&#62;</strong> на странице вашего сайта добавьте подключение кода виджета:</Typography>
                </Typography>

                <Box sx={ styles.codeInsert }>
                  {'<script src="https://arenza.ru/arenza-partner-widget.min.js"></script>'}
                </Box>

                <Typography sx={ styles.psefUl }>
                  <span>2</span>

                  <Typography component={'p'}>Сразу далее, после вышеуказанного тега <strong style={{ fontWeight: 700 }}>&#60;script&#62;</strong>, добавьте код инициализации виджета:</Typography>
                </Typography>

                <Box sx={ styles.codeInsert }>
                  {'<script>'} <br />
                  &nbsp;&nbsp;&nbsp;{'function widgetInit() {'}<br />
                  &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{'ArenzaPartnerWidget.init({'} <br />
                  &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; token: {partnerToken}, <br />
                  &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{'});'} <br />
                  &nbsp;&nbsp;&nbsp; {' }'} <br />
                  {'</script>'}
                </Box>

                <Typography sx={ styles.psefUl }>
                  <span>3</span>

                  <Typography component={'p'}>Для того, чтобы вызвать виджет, добавьте, например, событие <strong style={{ fontWeight: 700 }}>onclick</strong> на кнопку:</Typography>
                </Typography>
              </Box>

              <Box sx={ styles.codeInsert }>
                {'<button onclick="widgetInit()">Открыть виджет</button>'}
              </Box>

              <Typography sx={{ marginTop: '30px' }}>Подробнее о методах, поддерживаемых виджетом, а также примеры использования см. в инструкции:</Typography>

              <Button
                component="a"
                download={true}
                href={`${process.env.GATSBY_DOCUMENTS_PUBLIC_URL}/widget_instructions/widget_button.pdf`}
                sx={ styles.whiteButton }
                target="_blank">
                <Box alt={'Скачать инструкцию'} component={'img'} src={iconDownload} />

                Скачать инструкцию
              </Button>
            </Box>

            <Box>
              <Typography sx={{ ...styles.title, textAlign: 'center' }}>Вид виджета на различных устройствах</Typography>

              <Box sx={ styles.wrapperImg }>
                {activeView === "desktop" && (
                  <Box alt={'Desktop'} component={'img'} src={widgetButtonDesktop} />
                )}

                {activeView === "pad" && (
                  <Box alt={'Pad'} component={'img'} src={widgetButtonPad} />
                )}

                {activeView === "mobile" && (
                  <Box alt={'Mobile'} component={'img'} src={widgetButtonMobile} />
                )}
              </Box>

              <Box sx={ styles.wrapperButtonView }>
                <Box className={activeView === 'desktop' ? 'active' : ''} onClick={onToggleView.bind(this, 'desktop')} sx={ styles.buttonView }>
                  <Box alt={"Desktop"} component={"img"} src={iconDesktop} />

                  Desktop
                </Box>

                <Box className={activeView === 'pad' ? 'active' : ''} onClick={onToggleView.bind(this, 'pad')} sx={ styles.buttonView }>
                  <Box alt={"Pad"} component={"img"} src={iconPad} />

                  Pad
                </Box>

                <Box className={activeView === 'mobile' ? 'active' : ''} onClick={onToggleView.bind(this, 'mobile')} sx={ styles.buttonView }>
                  <Box alt={"Mobile"} component={"img"} src={iconMobile} />

                  Mobile
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <FooterInf />
      </Box>
    </>
  );
}