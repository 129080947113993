import React, { useMemo } from "react";

import { Box, Typography } from "@material-ui/core";

export default function FooterInf() {
  const styles = useMemo(() => ({
    description: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: { xs: '32px', lg: '104px' },
      justifyContent: 'center',

      '& > div': {
        textAlign: 'center',
      },
    },
    descriptionBlockOne: {
      color: '#253446',
      fontWeight: 300,
      fontSize: { xs: '12px', lg: '14px' },
      lineHeight: { xs: '18px', lg: '28px' },
      width: '100%',

      '& span': {
        fontWeight: 400
      }
    },
    descriptionBlockTwo: {
      marginTop: '31px',
      color: '#253446',
      fontWeight: 300,
      width: '100%',
      lineHeight: { xs: '18px', lg: '28px' },
      fontSize: { xs: '14px', lg: '16px' },

      '& span': {
        fontWeight: 400
      }
    },
    descriptionNumber: {
      fontSize: '26px',
      marginTop: '20px',
      fontWeight: '300',
    },
  }), []);

  return (
    <Box sx={ styles.description }>
      <Box sx={styles.descriptionBlockOne}>
        <span>Компания Аренза</span> — надежный поставщик лизинговых сервисов.<br />
        Сохранность ваших персональных данных гарантирована Политикой конфиденциальности.
      </Box>
      <Box sx={styles.descriptionBlockTwo}>
        <span>Если у вас остались вопросы</span><br />
        — свяжитесь, пожалуйста, с нами по телефону
        <Typography sx={ styles.descriptionNumber }>
          +7 (495) 125-43-44 / 8 (800) 775-76-85
        </Typography>
      </Box>
    </Box>
  );
}