import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import {
  Box,
  ClickAwayListener,
  FormLabel,
  IconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { vk, youtube } from '@product-site-frontend/shared';
import { useFieldArray, useWatch } from 'react-hook-form';
import useFetch from 'use-http';

import { AppStateContext } from '../../core/AppContextProvider';
import FieldContainer from './FieldContainer';

FieldSocialAccounts.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
};

export default function FieldSocialAccounts({ control, name }) {
  const { activeQuestionnaireId } = useContext(AppStateContext);

  const [, setEditMode] = useState(false);
  const { append, fields } = useFieldArray({ control, name });

  const socialsArray = useWatch({ control, name });

  const { patch } = useFetch(
    `/entity_questionnaires/${activeQuestionnaireId}`,
  );

  async function saveValues(variables) {
    await patch({ data: variables });
  }

  function handleAppendClick(name) {
    return function () {
      append({ name });
    };
  }

  function handleClickAway() {
    setEditMode(false);
  }

  function handleBlur(index) {
    return function ({ field }) {
      if (!field.value) {
        saveValues({ [name]: socialsArray.filter((item, i) => i !== index) });
      } else {
        saveValues({ [name]: socialsArray });
      }
    };
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Box sx={{ mb: 3 }}>
          <FormLabel sx={{ mr: "auto", fontWeight: 600, color: "#373737" }}>
            Профили в социальных сетях вашей компании
          </FormLabel>
          <Typography sx={{ mb: 3 }} variant="body2">
            Нажмите на иконку, чтобы быстро добавить ссылку на профиль
          </Typography>
          <IconButton onClick={handleAppendClick('vk')} sx={{ p: 0, mr: 5 }}>
            <Box alt="vk" component="img" height={36} src={vk} />
          </IconButton>
          <IconButton onClick={handleAppendClick('youtube')} sx={{ p: 0 }}>
            <Box alt="youtube" component="img" height={36} src={youtube} />
          </IconButton>
        </Box>
        {fields.map((field, index) => (
          <Box key={field.id} sx={{ display: 'flex', '&>div': { flex: 1 } }}>
            <FieldContainer
              InputProps={{
                ...(!['instagram', 'facebook'].includes(field.name)
                  ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          alt={field.name}
                          component="img"
                          height={20}
                          src={{ vk, youtube }[field.name]}
                        />
                      </InputAdornment>
                    ),
                  }
                  : {}
                ),
              }}
              control={control}
              disabled={field.name === 'instagram' || field.name === 'facebook'}
              fieldType="text"
              label={
                {
                  facebook: "Профиль в Facebook",
                  instagram: "Профиль в Instagram",
                  vk: 'Профиль ВКонтакте',
                  youtube: 'Канал в YouTube',
                }[field.name]
              }
              name={`${name}.${index}.url`}
              onBlur={handleBlur(index)}
              saveEvent="none"
            />
          </Box>
        ))}
      </Box>
    </ClickAwayListener>
  );
}