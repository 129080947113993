/* eslint-disable react/jsx-filename-extension */
import { AmountMaskInput } from '@product-site-frontend/shared';
import FormFieldContainer from '@product-site-frontend/shared/components/FormFieldContainer';
import * as yup from 'yup';

// Сроки к количеству заявок.

// не указано => 24893,
// 18=>2135,
// 12=>2043,
//  6=>333,
//  9=>178,
// 24=>189
// 36=>16,
// 15=>10,
// все остальные меньше 10.
// 6, 12, 18, 24, 36
// 6 мес, 1 год, 1,5 года, 2 года, 3 года

// Аванс к количеству заявок.

//      0.0=>25452
//      20.0=>1485
//      10.0=>565
//      17.0=>383
//      30.0=>170
//      25.0=>113
//      27.0=>93
//      16.666666666666668=>72
//      15.0=>69
//      5.0=>42
//      40.0=>31
//      50.0=>25
//      49.0=>24

// 10, 20, 30, 40, 49

export const APPLICATION_FIELDS_CONFIG = {
  amount: {
    name: 'amount',
    validation: yup.string().required('Укажите стоимость оборудования'),
    defaultValue: '',
    FieldComponent: FormFieldContainer,
    componentProps: {
      InputProps: {
        inputComponent: AmountMaskInput,
      },
      label: 'Стоимость оборудования',
      fieldType: 'text',
      inputProps: {
        inputMode: 'numeric',
        pattern: '[0-9]*',
      },
    },
  },
  advance_sum: {
    name: 'advance_sum',
    validation: yup.string().required('Укажите срок'),
    defaultValue: '',
    FieldComponent: FormFieldContainer,
    componentProps: {
      InputProps: {
        inputComponent: AmountMaskInput,
      },
      label: 'Авансовый платеж',
      fieldType: 'text',
      inputProps: {
        inputMode: 'numeric',
        pattern: '[0-9]*',
      },
    },
  },
  term: {
    name: 'term',
    validation: yup.string().required('Укажите срок'),
    defaultValue: '',
    FieldComponent: FormFieldContainer,
    componentProps: {
      label: 'Срок',
      fieldType: 'radio',
    },
  },
  subjectClassificator: {
    name: 'subjectClassificator',
    defaultValue: null,
    FieldComponent: FormFieldContainer,
    componentProps: {
      getOptionLabel: option => option.name,
      label: 'Категория оборудования',
      fieldType: 'autocomplete',
    },
  },
  attachments: {
    name: 'attachments',
    defaultValue: [],
    FieldComponent: FormFieldContainer,
    componentProps: {
      label: 'Коммерческое предложение',
      fieldType: 'file',
    },
  },
};
