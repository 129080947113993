import PropTypes from 'prop-types';
import React from 'react';

import { Tabs } from '@material-ui/core';

import { useNavTabs } from '../../hooks/useNavTabs';
import TabLink from './TabLink';

NavSecondary.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
  tertiary: PropTypes.string,
};
export default function NavSecondary({
  primary = 'leasing',
  secondary,
  tertiary,
}) {
  const { activeTab, tabs } = useNavTabs(primary, secondary, tertiary);

  if (!tabs || !tabs?.length) return null;

  return (
    <Tabs
      scrollButtons={false}
      value={activeTab}
      variant="scrollable"
    >
      {tabs.map((tab, idx) => {
        if (!tab?.label) return false;

        return (
          <TabLink development={tab.development} key={idx} label={tab.label} to={tab.to} />
        )
      })}
    </Tabs>
  );
}