import React, { useCallback, useState } from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Paper,
  Typography,
} from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/Logout';
import ArenzaFullLogo from '@product-site-frontend/shared/components/ArenzaFullLogo';
import useFetch from 'use-http';

import { arenzaStorage, AUTH_TOKEN_STORAGE_KEY } from '../../core/storage';
import FormAddEntity from './FormAddEntity';

export default function BlockAddEntity() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const { post: addEntity, response } = useFetch(
    '/entities/add_by_inn',
    { headers: { 'Content-Type': 'application/json' } },
  );

  const handleLogout = useCallback(() => {
    arenzaStorage.remove(AUTH_TOKEN_STORAGE_KEY);

    // TODO: нужно обновить чтобы сбросить memoized значение токена 
    // в packages/account/src/pages/index.jsx.
    // Подумать, как это лучше сделать.
    // Напрямую делать navigate("/login") нельзя
    window.location.reload();
  }, []);

  const handleSubmit = useCallback(async (inn) => {
    setIsSubmitting(true);
    setErrorText(null);

    await addEntity({ inn });

    if (response.ok) {
      window.location.reload();
    } else {
      setErrorText(
        `Возникла ошибка при добавлении компании. Код ошибки: ${response.status}.`,
      );
    }

    setIsSubmitting(false);
  }, [response, addEntity]);

  return (
    <Box>
      <Paper elevation={4} primary="false" square>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 48,
            }}
          >
            <Box sx={{ height: 24, width: { xs: 108, sm: 114 } }}>
              <ArenzaFullLogo color="primary" />
            </Box>
            <Button
              endIcon={<LogoutIcon fontSize="small" />}
              onClick={handleLogout}
              size="small"
              sx={{ fontSize: 14, textTransform: 'none' }}
              variant="contained"
            >
              Выйти
            </Button>
          </Box>
        </Container>
      </Paper>
      <Container maxWidth="md" sx={{ mt: '70px', textAlign: 'center' }}>
        <Typography
          sx={{ mb: 3, fontWeight: 900, fontSize: '28px !important' }}
          variant="h1"
        >
          Добавить компанию
        </Typography>
        <Typography
          sx={{ fontWeight: 500, fontSize: 14, color: '#7b7b7b', mb: 3 }}
        >
          Для дальнейшего пользования личным кабинетом необходимо добавить{' '}
          компанию.
          <br />
          Укажите название компании, ИП или ИНН, и нажмите Продолжить.
        </Typography>
        <FormAddEntity isSubmitting={isSubmitting} onSubmit={handleSubmit} />
        {Boolean(errorText) && (
          <Alert
            severity="error"
            sx={{
              mt: 3,
              maxWidth: theme => theme.breakpoints.values.sm,
              mx: 'auto',
              textAlign: 'left',
            }}
          >
            <AlertTitle>Произошла ошибка</AlertTitle>
            {errorText}
            <br />
            Мы уже знаем об этом и скоро исправим. Попробуйте повторить позднее.
          </Alert>
        )}
      </Container>
    </Box>
  );
}