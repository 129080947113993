import React from 'react';

import { Box, Card, Link, Typography } from '@material-ui/core';
import { maskedPhone } from '@product-site-frontend/shared/components/PhoneMaskInput';

import Styled from './PageInvestments.styled';

export default function PersonalManager() {
  return (
    <Card sx={Styled.ManagerCard}>
      <Typography color="text.secondary" gutterBottom variant="caption">
        Ваш персональный менеджер
      </Typography>
      <Typography gutterBottom sx={{ mt: 1 }} variant="h4">
        Дмитрий Будняев
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Link
          href="tel:+79260733300"
          sx={Styled.ManagerContact}
          underline="none"
          variant="body2"
        >
          {maskedPhone.resolve('+79260733300')}
        </Link>
        <br />
        <Link
          href="mailto:dmitriy.budnyaev@arenza.ru"
          sx={Styled.ManagerContact}
          variant="body2"
        >
          dmitriy.budnyaev@arenza.ru
        </Link>
      </Box>
    </Card>
  );
}
