import React, { useMemo, useEffect, useState } from 'react';

import {
    Box,
    Typography,
} from '@material-ui/core';
import dayjs from "dayjs";
import useFetch from "use-http";

import useActiveLegalEntity from "../../hooks/useActiveLegalEntity";
import NoticeElement from './NoticeElement';

export default function ChangeChargeNotice() {
    const styles = useMemo(() => ({
        noticeHeader: {
            marginBottom: '32px'
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const [entityService, setEntityService] = useState(false);

    const { data } = useActiveLegalEntity();
    const getEntity = useFetch(`/entities/${data.id}`);

    async function getEntityData() {
        const entityData = await getEntity.get();

        if (getEntity.response.ok) {
            if (entityData) {
                setEntityService(entityData);
            }
        } else {
            console.error('Ошибка запроса entity service');
        }
    }

    useEffect(() => {
        getEntityData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showNotices, setShowNotices] = useState([]);

    useEffect(() => {
        if (entityService?.deal_changes?.length) {
            let arr = [];

            entityService?.deal_changes?.forEach((notice) => {
                let diffDay = notice?.applied_at && dayjs().diff(notice.applied_at, "day");

                if (notice?.applied_at === null || (diffDay >= 0 && diffDay <= 14)) {
                    arr.push(true);
                } else {
                    arr.push(false);
                }
            })

            setShowNotices(arr);
        }

    }, [entityService.deal_changes]);

    if (entityService?.deal_changes && Array.isArray(entityService?.deal_changes) && entityService?.deal_changes.length) {
        return (
            <>
                {showNotices.includes(true) && (
                  <Box sx={styles.noticeHeader}>
                      <Typography variant="h1">Уведомления об изменении размера лизинговых платежей</Typography>
                  </Box>
                )}

                {(entityService?.deal_changes.map((notice, idx) => (
                  showNotices[idx] && (
                    <NoticeElement
                      data={notice}
                      entityId={data.id}
                      key={`${notice.id}_${notice.loan_id}`}
                      next_payment_date={data.next_payment_date}
                    />
                  )
                )))}
            </>
        );
    }

    return null;
}