import React, { useCallback } from 'react';

import { Box, CircularProgress } from '@material-ui/core';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import useActiveLegalEntity from './useActiveLegalEntity';

export default function useAccessControl({
  noAccessRedirectUrl = '/',
  notConfirmedRedirectUrl = '/',
  role = '',
}) {
  const { data: activeLegalEntityData, meta } = useActiveLegalEntity();
  const { pathname } = useLocation();

  const redirect = useCallback((url = '/') => {
    if (pathname !== url) {
      navigate(url);
    }
  }, [pathname]);

  if (process.env.GATSBY_ACTIVE_ENV === 'prod') {
    redirect(notConfirmedRedirectUrl);
    return null;
  }

  if (!activeLegalEntityData?.roles) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: 320,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!activeLegalEntityData.roles?.includes(role)) {
    redirect(noAccessRedirectUrl);
    return null;
  }

  if (!meta?.userConfirmed) {
    redirect(notConfirmedRedirectUrl);
    return null;
  }
}