import { useContext } from 'react';

import { AppStateContext } from '../core/AppContextProvider';

export default function useActiveLegalEntity() {
  const {
    activeLegalEntityId,
    activeLegalEntityPermissions,
    canElectronicSignatureSubscribeDocs,
    entities,
    subscribeDocs,
    subscribeDocsCounter,
  } = useContext(AppStateContext);

  const data = entities.legalEntities.data[activeLegalEntityId];
  const meta = entities.legalEntities.meta[activeLegalEntityId];

  return {
    activeLegalEntityId,
    data,
    meta,
    permissions: {
      ...activeLegalEntityPermissions,
      canElectronicSignatureSubscribeDocs,
    },
    subscribeDocs,
    subscribeDocsCounter,
  };
}
