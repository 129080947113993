import React, { useCallback, useContext, useMemo, useState } from 'react';

import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListSubheader,
  Menu,
  MenuItem,
  Typography,
  Link,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LogoutIcon from '@material-ui/icons/Logout';
import PhoneIcon from '@material-ui/icons/Phone';
import { navigate, Link as RouterLink } from 'gatsby';
import { find } from 'lodash';

import { AppDispatchContext, AppStateContext } from '../AppContextProvider';
import { arenzaStorage, AUTH_TOKEN_PARTNER_STORAGE_KEY, AUTH_TOKEN_STORAGE_KEY } from "../storage";

export default function MenuProfile() {
  const {
    activeLegalEntityId,
    entities,
    lists,
    user,
  } = useContext(AppStateContext);

  const userFIO = useMemo(() => {
    const lastName = user?.lastName || '';
    const firstName = user?.firstName || '';
    const middleName = user?.middleName || '';
    return `${lastName} ${firstName} ${middleName}`.trim();
  }, [user]);

  const dispatch = useContext(AppDispatchContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleLogout = useCallback(() => {
    handleClose();
    if (window.location.pathname.includes('/partnership')) {
      arenzaStorage.remove(AUTH_TOKEN_PARTNER_STORAGE_KEY);
    } else {
      arenzaStorage.remove(AUTH_TOKEN_STORAGE_KEY);
    }

    // TODO: нужно обновить чтобы сбросить memoized значение токена 
    // в packages/account/src/pages/index.jsx.
    // Подумать, как это лучше сделать.
    // Напрямую делать navigate("/login") нельзя
    window.location.reload();
  }, [handleClose]);

  const handleLegalEntitySelect = useCallback((id) => {
    dispatch({
      type: 'SET_ACTIVE_LEGAL_ENTITY',
      payload: { activeLegalEntityId: id },
    });
    if (window.location.pathname.includes('/partnership')) {
      navigate('/partnership');
    } else {
      navigate('/');
    }
    setAnchorEl(null);
  }, [dispatch]);

  return (
    <>
      <IconButton edge="end" onClick={handleOpen} sx={{ ml: 2, minWidth: 48 }}>
        <AccountCircleIcon />
      </IconButton>
      <Menu
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'auto',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: '5px',
            pb: 2,

            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 10,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ListSubheader
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="inherit">Профиль</Typography>
          <Link
            component={RouterLink}
            onClick={handleClose}
            to="/profile"
            underline="none"
          >
            Настройки
          </Link>
        </ListSubheader>
        <Box sx={{ pb: 2 }} variant="body2">
          <Typography sx={{ px: 3 }} variant="h6">{userFIO}</Typography>
          <Typography
            color="text.secondary"
            component="div"
            sx={{ fontWeight: 600, px: 3, my: 1 }}
            variant="caption"
          >
            <PhoneIcon fontSize="inherit" sx={{ mr: 2, mb: "-2px" }} />
            {user?.phone}
          </Typography>
          {user?.email && (
            <Typography
              color="text.secondary"
              component="div"
              sx={{ fontWeight: 600, px: 3, my: 1 }}
              variant="caption"
            >
              <AlternateEmailIcon
                fontSize="inherit"
                sx={{ mr: 2, mb: "-2px" }}
              />
              {user.email}
            </Typography>
          )}
        </Box>

        <Divider />
        <ListSubheader
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="inherit">Компании</Typography>
          {/* TODO: <Link
            component={RouterLink}
            onClick={handleClose}
            to="/entities"
            underline="none"
          >
            Настройки
          </Link> */}
        </ListSubheader>
        {lists.legalEntities.map((id) => {
          return (
            <MenuItem
              disableRipple
              disabled={find(user?.status, (u) => u.entityId === id)?.values === 'disabled'}
              key={id}
              onClick={() => handleLegalEntitySelect(id)}
              selected={id === activeLegalEntityId}
            >
              <Typography noWrap variant="inherit">
                {entities.legalEntities.data[id].shortLegalName}
              </Typography>
            </MenuItem>
          );
        })}
        <Divider sx={{ my: 1 }} />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Выйти
        </MenuItem>
      </Menu>
    </>
  );
}
