import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import CircularProgressWithLabel from '@components/CircularProgressWithLabel';
import { formatDate } from '@core/helpers';
import { arenzaStorage, AUTH_TOKEN_STORAGE_KEY } from '@core/storage';
import useActiveLegalEntity from '@hooks/useActiveLegalEntity';
import { Box, Card, CardContent, Skeleton, Typography } from '@material-ui/core';
import { AlertError } from '@product-site-frontend/gatsby-theme-blocks';
import useFetch from 'use-http';

const gridTemplateColumns = '1fr minmax(auto, 20%) minmax(auto, 20%)';

CardUploadedFiles.propTypes = {
  acceptedFiles: PropTypes.array,
};
export default function CardUploadedFiles({ acceptedFiles = [] }) {
  const { activeLegalEntityId } = useActiveLegalEntity();
  const { data = [], loading, error } = useFetch(
    `/entities/${activeLegalEntityId}/bank_statements`,
    [],
  );

  if (error) {
    return <AlertError message={error.message} onClose={() => { }} open />;
  }

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: { xs: "none", md: "grid" },
            gridTemplateColumns,
            mt: 2,
            mb: 3,
          }}
        >
          <Typography variant="subtitle2">Название файла</Typography>
          <Typography variant="subtitle2">Статус</Typography>
          <Typography variant="subtitle2">Дата загрузки</Typography>
        </Box>
        {acceptedFiles.map(file => (
          <ContainerUploadedFile file={file} key={file.path} />
        ))}
        {loading ? (
          <Skeleton />
        ) : (
          data.map(({ created_at, filename, id, status }) => (
            <GridRowBankStatementUploads
              createdAt={created_at}
              filename={filename}
              key={id}
              status={status}
            />
          ))
        )}
      </CardContent>
    </Card>
  );
}

ContainerUploadedFile.propTypes = {
  file: PropTypes.shape({
    path: PropTypes.string,
  }),
};
function ContainerUploadedFile({ file }) {
  const { activeLegalEntityId } = useActiveLegalEntity();

  const [uploadedFile, setUploadedFile] = useState(null);

  const [uploadProgress, setUploadProgress] = useState(0); // from 0 to 100
  const [fetchStatus, setFetchStatus] = useState("none"); //none, fetching, loaded, error

  const sendFile = useCallback((body) => {
    let xhr = new XMLHttpRequest();
    setUploadProgress(0);

    xhr.upload.onloadstart = () => {
      setFetchStatus("fetching");
    };
    xhr.upload.onprogress = e => {
      const uploadProgressMax = e.total;
      const progress =
        (e.loaded / uploadProgressMax) * 100 < 100
          ? (e.loaded / uploadProgressMax) * 100
          : 99;
      setUploadProgress(Math.ceil(parseInt(progress)));
    };

    // TODO: вынести в envs
    const url = `${process.env.GATSBY_FRONTOFFICE_API}/entities/${activeLegalEntityId}/bank_statements`;

    // send request
    xhr.open("POST", url, true);
    xhr.setRequestHeader(
      'Authorization',
      `Token ${arenzaStorage(AUTH_TOKEN_STORAGE_KEY)}`,
    );

    xhr.send(body);
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) return;

      if (xhr.status === 201) {
        setUploadProgress(100);
        setFetchStatus("loaded");
        setUploadedFile(JSON.parse(xhr.response));
      } else {
        setFetchStatus("error");
      }
    };
  }, [activeLegalEntityId]);

  useEffect(() => {
    let formData = new FormData();
    formData.append("statement_file", file);
    sendFile(formData);
  }, [file, sendFile]);

  return (
    <GridRowBankStatementUploads
      createdAt={uploadedFile?.created_at}
      filename={uploadedFile ? uploadedFile.filename : file.path}
      status={
        !uploadedFile && (
          <>
            {fetchStatus === "fetching" ? (
              <Box alignItems="center" display="flex">
                <Typography color="text.secondary" sx={{ mr: 2 }}>
                  Загрузка
                </Typography>
                <CircularProgressWithLabel value={Math.round(uploadProgress)} />
              </Box>
            ) : (
              <Typography color="#d0021b">Ошибка</Typography>
            )}
          </>
        )
      }
    />
  );
}

GridRowBankStatementUploads.propTypes = {
  createdAt: PropTypes.string,
  filename: PropTypes.string,
  status: PropTypes.node,
};

function GridRowBankStatementUploads({ createdAt, filename, status }) {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "2fr 1fr", md: "1fr minmax(auto, 20%) minmax(auto, 20%)" },
        alignItems: "center",
        mb: 2,
      }}
    >
      <Typography noWrap sx={{ gridArea: "1/1", mr: 3 }}>
        {filename}
      </Typography>
      <Typography
        color="text.secondary"
        sx={{ gridArea: { xs: "2/1", md: "1/3" }, typography: { xs: "caption", md: "body1" } }}
      >
        <Typography component="span" sx={{ display: { md: "none" } }} variant="inherit">
          Дата загрузки&nbsp;
        </Typography>
        {formatDate(createdAt)}
      </Typography>
      <Box sx={{ gridArea: "1/2/3" }}>
        {status || <Typography color="#56bf44">Загружено</Typography>}
      </Box>
    </Box>
  );
}
