import React from 'react';

import { Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';

import CardUploadedFiles from './CardUploadedFiles';
import DropzoneBankStatements from './DropzoneBankStatements';

export default function PageEntityBankStatements() {
  const { acceptedFiles, getInputProps, getRootProps } = useDropzone();

  return (
    <>
      <Typography gutterBottom variant="h1">
        Банковские выписки
      </Typography>
      <DropzoneBankStatements {...{ getInputProps, getRootProps }} />
      <CardUploadedFiles {...{ acceptedFiles }} />
    </>
  );
}
