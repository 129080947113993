import PropTypes from "prop-types";
import React from "react";

SelectOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

export default function SelectOptions({ options }) {
  return (
    <>
      <option disabled value="" />

      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </>
  );
}
