import React, { useContext, useEffect, useState } from "react";

import { AppStateContext } from "@core/AppContextProvider";
import useActiveLegalEntity from "@hooks/useActiveLegalEntity";
import useApplicationsFetch from "@hooks/useApplicationsFetch";
import { Box, Skeleton, Typography } from "@material-ui/core";
import useFetch from "use-http";

import FormCompany from "./forms/FormCompany";
import FormIP from "./forms/FormIP";
import MainBlock from "./MainBlock";

export default function PageEntityQuestionnaire() {
  // eslint-disable-next-line no-unused-vars
  const { applications } = useApplicationsFetch(); //костыль для подгрузки менеджера

  const activeLegalEntity = useActiveLegalEntity();
  const { activeQuestionnaireId } = useContext(AppStateContext);

  const { data: questionnaire, error, loading } = useFetch(
    `/entity_questionnaires/${activeQuestionnaireId}`,
    { headers: { 'Content-Type': 'application/json' } },
    [activeQuestionnaireId],
  );

  const [ owner, setOwner ] = useState(null);

  const [nameCompany] = useState(activeLegalEntity?.data?.shortLegalName || 'Загрузить не удалось');

  useEffect(() => {
    if (activeLegalEntity.meta.opf === 'ip') {
      setOwner(activeLegalEntity?.data?.legalName || activeLegalEntity?.data?.dadata?.name?.full)
    }

    if (activeLegalEntity.meta.opf === 'company') {
      setOwner(activeLegalEntity?.data?.dadata?.management?.name)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderHeadPage = () => {
    const inn = activeLegalEntity?.data?.dadata?.inn || 'Загрузить не удалось',
          kpp = activeLegalEntity?.data?.dadata?.kpp || 'Загрузить не удалось';

    return (
      <>
        <Typography gutterBottom sx={{ fontSize: '40px !important', fontWeight: 300, color: '#0D1D32', marginBottom: '12px !important' }} variant="h1">
          Анкета компании: <Typography component={"span"} sx={{ fontSize: '40px', fontWeight: 500 }}>{nameCompany}</Typography>
        </Typography>

        <Box sx={{ background: '#E7E8EA', borderRadius: '4px', display: 'inline-flex', width: 'auto', fontSize: '18px', padding: '3px 7px', marginBottom: '34px' }}>
          <Typography sx={{fontWeight: 700, paddingRight: '6px'}}>ИНН:</Typography>
          <Typography sx={{fontWeight: 300}}>{inn}</Typography>
          <Typography sx={{ padding: '0 6px', fontWeight: 300 }}> / </Typography>
          <Typography sx={{fontWeight: 700, paddingRight: '6px'}}>КПП:</Typography>
          <Typography sx={{fontWeight: 300}}>{kpp}</Typography>
        </Box>
      </>
    );
  }

  const [ fieldsForm, setFieldsForm ] = useState([]);

  const renderIP = () => {
    //Индивидуальный предприниматель

    return (
      <FormIP
        activeLegalEntity={activeLegalEntity?.data}
        activeQuestionnaireId={activeQuestionnaireId}
        data={questionnaire?.data}
        nameCompany={nameCompany}
        setFieldsForm={setFieldsForm}
      />
    )
  }

  const renderCompany = () => {
    //ЮР лицо

    return (
      <FormCompany
        activeLegalEntity={activeLegalEntity?.data}
        activeQuestionnaireId={activeQuestionnaireId}
        data={questionnaire?.data}
        nameCompany={nameCompany}
        setFieldsForm={setFieldsForm}
      />
    )
  }

  if (error) {
    return (
      <Box sx={{ mb: 8, display: "flex", flexDirection: "column", mt: 4 }}>
        <Box>
          {renderHeadPage()}

          <Box sx={{ display: "flex" }}>
            {error.message}
          </Box>
        </Box>
      </Box>
    )
  }

  if (loading) {
    return (
      <Box sx={{ mb: 8, display: "flex", flexDirection: "column", mt: 4 }}>
        <Box>
          {renderHeadPage()}

          <Box sx={{ display: "flex" }}>
            <Skeleton height={58} sx={{ mb: "34px" }} variant="rectangular" />
            <Skeleton height={58} sx={{ mb: "34px" }} variant="rectangular" />
            <Skeleton height={58} sx={{ mb: "34px" }} variant="rectangular" />
            <Skeleton height={58} sx={{ mb: "34px" }} variant="rectangular" />
            <Skeleton height={58} sx={{ mb: "34px" }} variant="rectangular" />
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{ mb: 8, display: "flex", flexDirection: "column", mt: 4 }}>
      <Box>
        {renderHeadPage()}

        <Box sx={{ display: "flex" }}>
          <Box sx={{ maxWidth: '984px', width: '100%' }}>
            {!activeLegalEntity?.meta?.opf && (
              <Typography>
                Мы не смогли определить Ваш правовой статус. Попробуй обратиться к Вашему менеджеру.
              </Typography>
            )}

            {activeLegalEntity.meta.opf === 'ip' && renderIP()}
            {activeLegalEntity.meta.opf === 'company' && renderCompany()}
          </Box>

          <Box sx={{ display: { xs: "none", md: "block" }, ml: 8 }}>
            <MainBlock
              OWNER={owner}
              fieldsForm={fieldsForm}
              opf={activeLegalEntity.meta.opf}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}