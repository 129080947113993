import PropTypes from 'prop-types';
import React from 'react';
import { useContext, useEffect } from 'react';

import { AppDispatchContext, AppStateContext } from '@core/AppContextProvider';
import { Box, CircularProgress } from '@material-ui/core';
import * as Sentry from "@sentry/react";
import { navigate } from 'gatsby';
import useFetch from 'use-http';

WrapperEntityQuestionnaire.propTypes = {
  children: PropTypes.node,
  inn: PropTypes.string,
};

export default function WrapperEntityQuestionnaire({ children, location }) {
  const {
    activeLegalEntityId,
    activeQuestionnaireId,
  } = useContext(AppStateContext);
  const dispatch = useContext(AppDispatchContext);

  const questionnairesRequest = useFetch(
    `/entity_questionnaires?entity_id=${activeLegalEntityId}`,
  );

  async function getFirstOrCreate() {
    const getResult = await questionnairesRequest.get();
    if (questionnairesRequest.response.ok) {
      if (!!getResult.length) {
        // NOTE: Пока берем первую анкету,
        // но возможно, следует фильтровать по статусу

        const completedQnr = getResult
          .find(({ state }) => state === 'completed');

        const acceptedQnr = getResult
          .find(({ state }) => state === 'accepted');

        if (acceptedQnr) {
          navigate('/');
        } else if (completedQnr) {
          dispatch({
            type: 'SET_ACTIVE_QUESTIONNAIRE_ID',
            payload: { id: completedQnr.id },
          });
          navigate('/');
        } else {
          const { id } = getResult.sort((a, b) => b.id - a.id)[0];
          dispatch({
            type: 'SET_ACTIVE_QUESTIONNAIRE_ID',
            payload: { id },
          });
        }
      } else {
        const postResult = await questionnairesRequest.post();
        if (questionnairesRequest.response.ok) {
          const { id } = postResult;
          dispatch({ type: 'SET_ACTIVE_QUESTIONNAIRE_ID', payload: { id } });
          navigate('/leasing/questionnaire');
        }
      }
    } else {
      Sentry.captureMessage("Ошибка при запросе списка анкет")
      // TODO: сделать обработку ошибки при запросе списка анкет или создании
    }
  }

  useEffect(() => {
    getFirstOrCreate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLegalEntityId]);

  if (!activeQuestionnaireId) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: 320,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // https://github.com/reach/router/issues/163#issuecomment-451798848
  return children;
}
