import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from "react";

import { formatDate } from '@core/helpers';
import useDownloadDoc from '@hooks/useDownloadDoc';
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DownloadingIcon from '@material-ui/icons/Downloading';

LayoutFileRow.propTypes = {
  createdAt: PropTypes.string,
  disabledDownload: PropTypes.bool,
  entityId: PropTypes.number,
  fileId: PropTypes.number,
  filename: PropTypes.node,
  icon: PropTypes.node,
};
export default function LayoutFileRow({
  createdAt,
  disabledDownload,
  entityId,
  fileId,
  filename,
  icon
}) {
  const styles = useMemo(() => ({
    link: {
      '&:hover': {
        color: '#ff6600'
      },
      '&:disabled:hover': {
        color: '#2F2F2F',
        cursor: 'default',
      },
    }
  }), [])
  const { error, loading, onDownload } = useDownloadDoc({
    entityId,
    fileId,
    filename,
  });
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => setOpenDialog(false);

  useEffect(() => {
    if (!!error) {
      setOpenDialog(true);
    }
  }, [error]);

  return (
    <Stack direction="row" spacing={3}>
      {loading
        ? (
          <DownloadingIcon
            color="action"
            sx={{ transform: 'rotate(180deg)' }}
          />
        )
        : icon
      }
      <Link
        color="text.primary"
        component="button"
        disabled={loading || disabledDownload}
        onClick={onDownload}
        sx={ styles.link }
        type="button"
        underline="none"
        variant="body1"
      >
        {filename}
      </Link>&nbsp;
      {createdAt && (
        <Typography>от {formatDate(createdAt, 'full')}</Typography>
      )}
      {openDialog && (
        <Dialog onClose={handleCloseDialog} open>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Typography>Ошибка</Typography>
            <IconButton aria-label="close" onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Alert severity="error">{error}</Alert>
          </DialogContent>
        </Dialog>
      )}
    </Stack>
  );
}