import React from 'react';

import useAccessControl from '@hooks/useAccessControl';
import { Box } from '@material-ui/core';
import { Router } from '@reach/router';

import PageInvestments from './components/PageInvestments';

export default function Routes() {
  useAccessControl({ role: 'investor' });

  return (
    <Box component={Router}>
      <PageInvestments path="/" />
    </Box>
  );
}