import React, { useState, useEffect, useContext, useCallback, useRef, useMemo } from "react";

import { AppStateContext } from '@core/AppContextProvider';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
  Alert,
  AlertTitle,
  IconButton
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { styled } from '@material-ui/styles';
import Breadcrumbs from '@product-site-frontend/shared/components/Breadcrumbs';
import useFetch from "use-http";

import useActiveLegalEntity from "../../hooks/useActiveLegalEntity";
import EDOSelector from "./EDOSelector";

const styles = {
  breadcrumbsLine: {
    borderTop: 'none',
    paddingLeft: 0
  },
  sectionContainer: {
    paddingLeft: {
      xs: 0,
      md: 0,
      lg: 0
    }
  },
  header: {
    fontSize: {
      xs: '24px',
      sm: '36px',
      md: '48px'
    },
    lineHeight: {
      xs: '36px',
      sm: '48px',
      md: '56px'
    },
    fontWeight: '700',
    marginTop: '6px',
    marginBottom: {
      xs: '24px',
      sm: '40px'
    }
  },
  card: {
    padding: '24px',
    boxShadow: '0px 3px 4px 0px #0D1D321A',
    borderRadius: '24px',

    '&.cardOrange': {
      boxShadow: '0px 5px 6px 0px #FF780F45',
    }
  },
  cardSendData: {
    gap: '16px'
  },
  cardForm: {
    padding: '24px',
    boxShadow: '0px 3px 4px 0px #0D1D321A',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',

    '& > span': {
      fontSize: '16px',
      fontWeight: '300',
      lineHeight: '24px'
    }
  },
  cardHeader: {
    marginBottom: '16px',
    padding: 0,
    marginTop: '24px',

    '&:first-child': {
      marginTop: 0
    },

    '& span': {
      fontSize: {
        xs: '16px',
        md: '16px'
      },
      fontWeight: '600',
      lineHeight: {
        xs: '19px',
        md: '19px'
      },
    }
  },
  cardContent: {
    padding: 0,

    '&:last-child': {
      paddingBottom: 0
    }
  },
  cardBox: {
    marginBottom: '4px',
    display: 'flex',
    gap: '4px',
    flexDirection: {
      xs: 'column',
      lg: 'row'
    },

    '& a': {
      color: '#ff6600'
    }
  },
  cardSubtitle: {
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '24px'
  },
  cardText: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    textDecoration: 'none',
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px'
  },
  edoControl: {
    margin: 0,

    '& > span + span': {
      marginLeft: '16px',
      fontSize: '15px'
    },

    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: '#2F2F2F',
      cursor: 'pointer'
    }
  },
  documentsHeader: {
    padding: 0,

    '& span': {
      fontSize: {
        xs: '26px',
        md: '26px'
      },
      fontWeight: '700',
      lineHeight: {
        xs: '34px',
        md: '34px'
      },
      letterSpacing: '0.02em',
    }
  },
  documentsBox: {
    border: '1px solid #E7E8EA',
    padding: '16px',
    boxSizing: 'border-box',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  },
  documentsBoxMain: {
    border: '1px solid #67BF3D',
    padding: '16px',
    boxSizing: 'border-box',
    borderRadius: '16px',
    position: 'relative',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '300',
    overflowWrap: 'break-word',

    '& > span': {
      background: '#FFF',
      padding: '2px 7px',
      color: '#67BF3D',
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '18px',
      position: 'absolute',
      left: '18px',
      top: '-10px'
    }
  },
  documentsBoxGrey: {
    background: '#f5f6f7',
    padding: '16px',
    boxSizing: 'border-box',
    borderRadius: '16px',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '300',
    overflowWrap: 'break-word',
  },
  documentBoxTitle: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '600'
  },
  documentsBoxControl: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  documentsBoxControlRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  validated: {
    fontSize: '13px',
    lineHEight: '15px',
    fontWeight: '400',
    padding: '4px 6px',
    color: '#67BF3D',
    background: '#84C61A22',
    borderRadius: '4px',
    marginTop: '8px',
    display: 'inline-block'
  },
  notValidated: {
    fontSize: '13px',
    lineHEight: '15px',
    fontWeight: '400',
    padding: '4px 6px',
    color: '#868E98',
    background: '#0D1D3211',
    borderRadius: '4px',
    marginTop: '8px',
    display: 'inline-block'
  },
  documentLabel: {
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '18px',
    marginBottom: '8px'
  },
  formControl: {
    marginTop: 0,

    '& label.Mui-focused': {
      background: '#fff'
    },
    '& > .Mui-focused fieldset.MuiOutlinedInput-notchedOutline ': {
      border: 'none'
    }
  },
  select: {
    background: '#f5f6f7',
    border: '1px solid #f5f6f7',
    borderRadius: '8px',
    width: '100%',

    '&.Mui-focused': {
      border: '1px solid #ee8035',
    },
    '&:hover': {
      background: '#ecedef',
      border: '1px solid #ecedef',
    }
  },
  input: {
    background: '#F5F6F7',
    borderRadius: '8px',
    padding: '12px 18px',
    margin: 0,
    fontSize: '16px',
    fontWeight: '500',
    color: '#6E7884',
    width: '100%',

    '& > div:before': {
      display: 'none',
    },
    '& > div:after': {
      display: 'none',
    },
  },
  button: {
    border: '1px solid #67BF3D',
    color: '#67BF3D',
    fontWeight: '400',

    '&:hover': {
      border: '1px solid #67BF3D77',
      color: '#67BF3D77',
    }
  },
  buttonGrey: {
    border: '1px solid #CFD2D6',
    color: '#6E7884',
    fontWeight: '400',

    '&:hover': {
      border: '1px solid #CFD2D677',
      color: '#6E788477'
    }
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    textAlign: 'center',
    padding: '56px 0'
  },
  footerBlock: {
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '28px',
    textAlign: 'center',
    color: '#6E7884',

    '& span': {
      fontWeight: '600',
    }
  },
  footerPhones: {
    fontSize: '26px',
    fontWeight: '300',
    lineHeight: '28px',
    textAlign: 'center',

    '& a': {
      textDecoration: 'none',
      color: '#0D1D32',

      '&:hover': {
        color: '#ff6600'
      }
    }
  },
  error: {
    color: '#F00',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 300
  }
};

const edoNames = {
  diadoc: 'Диадок',
  sbis: 'СБИС'
}

const defaultEDOElement = {
  value: '',
  system: 'diadoc',
  main: true
};

export default function PageEntity() {
  const { data } = useActiveLegalEntity();
  const { user } = useContext(AppStateContext);

  const formRef = useRef(null);

  const [isFirstView, setIsFirstView] = useState(true);
  const [isEditMode, setIsEditMode] = useState(null);
  const [isAddedByUser, setIsAddedByUser] = useState(false);
  const [isDeletedByUser, setIsDeletedByUser] = useState(false);
  const [isToggleClickedOnFirstview, setIsToggleClickedOnFirstview] = useState(false);
  const [edoList, setEdoList] = useState([]);
  const [hasMain, setHasMain] = useState(null);
  const [error, setError] = useState(null);
  const [entityService, setEntityService] = useState(false);
  const [entityServiceError, setEntityServiceError] = useState(null);
  const [questionaire, setQuestionaire] = useState(null);
  const [entityServiceWasUnchecked, setEntityServiceWasUnchecked] = useState(false);
  const [canViewEDO, setCanViewEdo] = useState(false);

  const { del, get, patch, post, response } = useFetch(`/entities/${data.id}/edo_identifiers`);
  const updateEntityService = useFetch(`/entities/${data.id}/update_service`);
  const getEntity = useFetch(`/entities/${data.id}`);
  const getQuestionaireList = useFetch(`/entity_questionnaires?entity_id=${data.id}`);

  const checkIsHasMain = (edoArray) => {
    if (!edoArray || !edoArray?.length) {
      return null;
    } else {
      const listHasMain = edoArray.find((element) => element.main === true);
      if (listHasMain) {
        return listHasMain.value;
      }

      return null;
    }
  };

  async function getEntityQuestionaireList() {
    const questionaires = await getQuestionaireList.get();

    if (getQuestionaireList.response.ok && Array.isArray(questionaires) && questionaires.length > 0) {
      const element = questionaires.find((element) => (element.state === 'accepted'));

      if (element) {
        setQuestionaire(element.data);
      }
    }
  }

  async function fetchEdoList() {
    const getResult = await get();

    if (response.ok) {
      setEdoList(getResult);
      setError(null);
    } else {
      setError(getResult?.error ? getResult.error : 'Ошибка при выполнении запроса. Проверьте введённые данные или попробуйте позже');
    }
  };

  async function changeEdoElement(element) {
    const newList = [...edoList];
    const changeId = newList.findIndex((edo) => (edo.id === element.id));
    newList[changeId] = element;

    if (!checkIsHasMain(newList) && !element.main) {
      const patchResult = await patch(`${element.id}`,
        {
          identifier: element,
          auto_send_edo_closing_document_service: false
        }
      );

      if (response.ok) {
        setIsEditMode(null);
        setEdoList(patchResult);
        setError(null);
        setEntityService(false);
        setEntityServiceError(null);
      } else {
        setError(patchResult?.error ? patchResult.error : 'Ошибка при выполнении запроса. Проверьте введённые данные или попробуйте позже');
      }
    } else {
      if (isToggleClickedOnFirstview) {
        const patchResult = await patch(`${element.id}`,
          {
            identifier: element,
            auto_send_edo_closing_document_service: true
          }
        );

        if (response.ok) {
          setIsEditMode(null);
          setEdoList(patchResult);
          setError(null);
          setEntityService(true);
          setEntityServiceError(null);
        } else {
          setError(patchResult?.error ? patchResult.error : 'Ошибка при выполнении запроса. Проверьте введённые данные или попробуйте позже');
        }
      } else {
        const patchResult = await patch(`${element.id}`,
          {
            identifier: element,
            auto_send_edo_closing_document_service: entityService
          }
        );

        if (response.ok) {
          setIsEditMode(null);
          setEdoList(patchResult);
          setError(null);
        } else {
          setError(patchResult?.error ? patchResult.error : 'Ошибка при выполнении запроса. Проверьте введённые данные или попробуйте позже');
        }
      }
    }
  };

  async function createEDOElement(element) {
    if (!checkIsHasMain(edoList) && !element.main) {
      const postResult = await post(
        {
          identifier: element,
          auto_send_edo_closing_document_service: false
        }
      );

      if (response.ok) {
        setIsEditMode(null);
        setEdoList(postResult);
        setError(null);
        setEntityService(false);
        setEntityServiceError(null);
      } else {
        setError(postResult?.error ? postResult.error : 'Ошибка при выполнении запроса. Проверьте введённые данные или попробуйте позже');
      }
    } else {
      if (isToggleClickedOnFirstview) {
        const postResult = await post(
          {
            identifier: element,
            auto_send_edo_closing_document_service: true
          }
        );

        if (response.ok) {
          setIsEditMode(null);
          setEdoList(postResult);
          setError(null);
          setEntityService(true);
          setEntityServiceError(null);
        } else {
          setError(postResult?.error ? postResult.error : 'Ошибка при выполнении запроса. Проверьте введённые данные или попробуйте позже');
        }
      } else {
        const postResult = await post(
          {
            identifier: element,
            auto_send_edo_closing_document_service: entityService
          }
        );

        if (response.ok) {
          setIsEditMode(null);
          setEdoList(postResult);
          setError(null);
        } else {
          setError(postResult?.error ? postResult.error : 'Ошибка при выполнении запроса. Проверьте введённые данные или попробуйте позже');
        }
      }
    }
  };

  async function getEntityData() {
    const entityData = await getEntity.get();

    if (getEntity.response.ok) {
      if (entityData?.services?.auto_send_edo_closing_document_service) {
        setEntityService(entityData?.services?.auto_send_edo_closing_document_service);
      }
    }
  };

  const onClickSaveEdoElement = (element) => {
    if (element.id) {
      changeEdoElement(element);
    } else {
      createEDOElement(element);
    }

    setIsFirstView(true);
    setIsAddedByUser(false);
  };

  const onClickAddElement = (addedByButton) => () => {
    const id = edoList.length;
    const list = [...edoList];

    setIsFirstView(false);
    setIsEditMode(id);
    list.push(defaultEDOElement);

    setEdoList(list);

    if (addedByButton) {
      setIsAddedByUser(true);
    }
  };

  const onClickEditElement = (id) => () => {
    setIsEditMode(id);
  }

  async function onClickChangeEntityService(event) {
    const postResult = await updateEntityService.patch({
      service: {
        name: 'auto_send_edo_closing_document_service',
        value: event?.target?.checked ? true : false
      }
    });

    if (updateEntityService.response.ok) {
      setIsEditMode(null);
      setError(null);
      setEntityService(postResult?.services?.auto_send_edo_closing_document_service);
      setEntityServiceWasUnchecked(event?.target?.checked ? true : false);
      setEntityServiceError(null);
    } else {
      setEntityServiceError('Ошибка выполнения запроса');
    }
  };

  async function deleteEdoElement(id) {
    if (id) {
      const delId = edoList.findIndex((element) => (element.id === id));
      const newEdoList = [...edoList];
      newEdoList.splice(delId, 1);

      if (newEdoList.length < 1 || !checkIsHasMain(newEdoList)) {
        const delResult = await del(`${id}?auto_send_edo_closing_document_service=false`);

        if (response.ok) {
          setHasMain(false);
          setEntityService(false);
          setEntityServiceError(null);
          setIsEditMode(null);
          setIsDeletedByUser(true);

          if (delResult !== null) {
            setEdoList(delResult);
          } else {
            setEdoList([]);
          }
        } else {
          setError(delResult?.error ? delResult.error : 'Ошибка при выполнении запроса. Проверьте введённые данные или попробуйте позже');
        }
      } else {
        const delResult = await del(`${id}?auto_send_edo_closing_document_service=${entityService}`);

        if (response.ok) {
          setIsEditMode(null);
          setIsDeletedByUser(true);
          if (delResult !== null) {
            setEdoList(delResult);
          } else {
            setEdoList([]);
            setHasMain(false);
            setEntityService(false);
            setEntityServiceError(null);
          }
        } else {
          setError(delResult?.error ? delResult.error : 'Ошибка при выполнении запроса. Проверьте введённые данные или попробуйте позже');
        }
      }
    } else {
      const list = [...edoList];

      list.splice(isEditMode, 1);

      setIsEditMode(null);

      const newHasMain = checkIsHasMain(list);
      setHasMain(newHasMain);
      setIsDeletedByUser(true);
      setEdoList(list);
    }
  }

  const onClickSwitch = (e) => {
    if (!!isEditMode) {
      e.preventDefault();
    }

    if (!!isFirstView && !hasMain) {
      e.preventDefault();

      setIsFirstView(false);

      const id = edoList.length;
      const list = [...edoList];

      setIsEditMode(id);
      list.push(defaultEDOElement);

      setEdoList(list);
      setIsToggleClickedOnFirstview(true);

      if (formRef?.current) {
        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  const checkCanViewEdo = () => {
    const entityElement = user?.abilities?.find((element) => element.entityId === data.id);
    if (entityElement?.values?.includes('access_to_entity_account')) {
      setCanViewEdo(true);
    }
  };

  useEffect(() => {
    checkCanViewEdo();

    if (canViewEDO) {
      fetchEdoList();
    }

    getEntityData();
    getEntityQuestionaireList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id, canViewEDO]);

  useEffect(() => {
    const newHasMain = checkIsHasMain(edoList);
    setHasMain(newHasMain);
  }, [edoList]);

  const IOSSwitch = styled((props) => (
    <Switch disableRipple {...props} focusVisibleClassName=".Mui-focusVisible" />
  ))(() => ({
    width: 42,
    height: 26,
    padding: 0,

    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#ff6600',
          opacity: 1,
          border: 0,
        },
      },
    },

    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },

    '& .MuiSwitch-switchBase.Mui-disabled': {
      cursor: 'pointer',
      color: '#fff'
    },

    '& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track': {
      opacity: '1',
    },

    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: 'background-color 500'
    }
  }));

  const elEdoRender = useMemo(() => (
    <>
      {edoList.map((edoElement, id) => {
        if (id === isEditMode) {
          return (
            <EDOSelector
              error={error}
              key={id}
              onDelete={deleteEdoElement}
              onSave={onClickSaveEdoElement}
              value={edoElement}
            />
          );
        };

        if (edoElement.main) {
          return (
            <Box key={id} sx={styles.documentsBoxMain}>
              <span>Основной</span>
              <Typography sx={styles.documentBoxTitle} variant="body1">
                {edoNames[edoElement.system]}
              </Typography>
              {edoElement.value}
              <Box sx={styles.documentsBoxControlRight}>
                <IconButton aria-label="delete" onClick={onClickEditElement(id)} size="small">
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
          );
        }

        return (
          <Box key={id} sx={styles.documentsBoxGrey}>
            <Typography sx={styles.documentBoxTitle} variant="body1">
              {edoNames[edoElement.system]}
            </Typography>
            {edoElement.value}
            <Box sx={styles.documentsBoxControlRight}>
              <IconButton aria-label="delete" onClick={onClickEditElement(id)} size="small">
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        );
      })}
      {error && !isEditMode === null && (<Box sx={styles.error}>{error}</Box>)}
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [edoList, isEditMode, error]);

  const elFoundersName = useCallback((founder) => {
    if (founder?.name) {
      return founder.name;
    }

    if (founder?.fio) {
      if (founder.fio?.patronymic) {
        return `${founder.fio?.surname} ${founder.fio?.name} ${founder.fio.patronymic}`;
      }

      return `${founder.fio?.surname} ${founder.fio?.name}`;
    }
  }, []);

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            href: '/',
            text: 'Главная'
          },
          {
            text: 'Моя компания'
          }
        ]}
        sx={{
          breadcrumbsLine: styles.breadcrumbsLine,
          sectionContainer: styles.sectionContainer
        }}
      />
      <Typography sx={styles.header} variant="h1">
        {data.shortLegalName}
      </Typography>
      <Grid container spacing={4}>
        <Grid
          item
          lg={canViewEDO ? 9 : 12}
          md={canViewEDO ? 6 : 12}
          sm={12}
          xs={12}
        >
          <Card sx={styles.card} variant="elevation">
            <CardHeader sx={styles.cardHeader} title="Реквизиты:" />
            <CardContent sx={styles.cardContent}>
              <Box sx={styles.cardBox}>
                <Typography color="text.secondary" sx={styles.cardSubtitle} variant="body1">
                  ИНН:
                </Typography>
                <Typography sx={styles.cardText} variant="body1">
                  {data.inn}
                </Typography>
              </Box>
              {data.dadata?.kpp && (
                <Box sx={styles.cardBox}>
                  <Typography color="text.secondary" sx={styles.cardSubtitle} variant="body1">
                    КПП:
                  </Typography>
                  <Typography sx={styles.cardText} variant="body1">
                    {data.dadata?.kpp}
                  </Typography>
                </Box>
              )}
              {/* <Box sx={styles.cardBox}>
                <Typography color="text.secondary" sx={styles.cardSubtitle} variant="body1">
                  Сайт поставщика:
                </Typography>
                <a href="https://www.stepan-group.ru" style={styles.cardText} variant="body1">
                  www.stepan-group.ru
                </a>
              </Box>
              <Box sx={styles.cardBox}>
                <Typography color="text.secondary" sx={styles.cardSubtitle} variant="body1">
                  Счет поставщика:
                </Typography>
                <Typography sx={styles.cardText} variant="body1">
                  3456735736573652323
                </Typography>
              </Box>
              <Box sx={styles.cardBox}>
                <Typography color="text.secondary" sx={styles.cardSubtitle} variant="body1">
                  Банк:
                </Typography>
                <Typography sx={styles.cardText} variant="body1">
                  ООО “Банк Точка”
                </Typography>
              </Box>
              <Box sx={styles.cardBox}>
                <Typography color="text.secondary" sx={styles.cardSubtitle} variant="body1">
                  БИК:
                </Typography>
                <Typography sx={styles.cardText} variant="body1">
                  044525104
                </Typography>
              </Box>
              <Box sx={styles.cardBox}>
                <Typography color="text.secondary" sx={styles.cardSubtitle} variant="body1">
                  Корр. счет:
                </Typography>
                <Typography sx={styles.cardText} variant="body1">
                  3456735736573652323
                </Typography>
              </Box> */}
            </CardContent>

            {questionaire?.description && (
              <>
                <CardHeader sx={styles.cardHeader} title="Описание бизнеса:" />
                <CardContent sx={styles.cardContent}>
                  <Box sx={styles.cardBox}>
                    <Typography color="text.secondary" sx={styles.cardSubtitle} variant="body1">
                      {questionaire.description}
                    </Typography>
                  </Box>
                </CardContent>
              </>
            )}

            {questionaire?.ceo_name && (
              <>
                <CardHeader sx={styles.cardHeader} title="Руководитель:" />
                <CardContent sx={styles.cardContent}>
                  <Box sx={styles.cardBox}>
                    <Typography color="text.secondary" sx={styles.cardSubtitle} variant="body1">
                      {questionaire.ceo_name}
                    </Typography>
                  </Box>
                </CardContent>
              </>
            )}

            {!questionaire?.ceo_name && data?.dadata?.managers?.length > 0 && (
              <>
                <CardHeader sx={styles.cardHeader} title="Руководитель:" />
                <CardContent sx={styles.cardContent}>
                  <Box sx={styles.cardBox}>
                    <Typography color="text.secondary" sx={styles.cardSubtitle} variant="body1">
                      {elFoundersName(data.dadata.managers[0])}
                    </Typography>
                  </Box>
                </CardContent>
              </>
            )}

            {data.dadata?.founders && data.dadata?.founders?.length > 0 && (
              <>
                <CardHeader sx={styles.cardHeader} title="Учредители:" />

                <CardContent sx={styles.cardContent}>
                  {data.dadata.founders.map((founder, idx) => (
                    <Box key={`founder-${idx}`} sx={styles.cardBox}>
                      <Typography color="text.secondary" sx={styles.cardSubtitle} variant="body1">
                        {elFoundersName(founder)}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
              </>
            )}

            {questionaire?.legal_address && (
              <>
                <CardHeader sx={styles.cardHeader} title="Юридический адрес:" />
                <CardContent sx={styles.cardContent}>
                  <Typography color="text.secondary" sx={styles.cardSubtitle} variant="body1">
                    {questionaire.legal_address}
                  </Typography>
                </CardContent>
              </>
            )}

            {!questionaire?.legal_address && data?.dadata?.address?.value && (
              <>
                <CardHeader sx={styles.cardHeader} title="Юридический адрес:" />
                <CardContent sx={styles.cardContent}>
                  <Typography color="text.secondary" sx={styles.cardSubtitle} variant="body1">
                    {data.dadata.address.value}
                  </Typography>
                </CardContent>
              </>
            )}

            {questionaire?.actual_address && (
              <>
                <CardHeader sx={styles.cardHeader} title="Фактический адрес:" />
                <CardContent sx={styles.cardContent}>
                  <Typography color="text.secondary" sx={styles.cardSubtitle} variant="body1">
                    {questionaire.actual_address}
                  </Typography>
                </CardContent>
              </>
            )}

            {/* {data.dadata?.address?.value && (
              <>
                <CardHeader sx={styles.cardHeader} title="Физический адрес:" />
                <CardContent sx={styles.cardContent}>
                  {data.dadata.founders.map((founder, idx) => (
                    <Box key={`founder-${idx}`} sx={styles.cardBox}>
                      <Typography sx={styles.cardSubtitle} variant="body1">
                        {data.dadata?.address?.value}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
              </>
            )} */}
          </Card>
        </Grid>

        {canViewEDO && (
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Box sx={styles.rightColumn}>
              <Card className={((isFirstView && !hasMain) || entityServiceWasUnchecked) ? 'cardOrange' : ''} sx={styles.card} variant="elevation">
                <FormGroup sx={styles.cardSendData}>
                  {(((edoList && edoList?.length === 0) || (isEditMode !== null && (hasMain === null || hasMain === '')) || (!isEditMode && hasMain === null)) && !isFirstView && !isAddedByUser && !isDeletedByUser) && (
                    <Alert severity="warning" sx={{ fontSize: '14px', lineHeight: '18px', color: '#FF780F', borderRadius: '8px' }}>
                      <AlertTitle sx={{ fontSize: '14px', lineHeight: '18px', marginBottom: '4px' }}>Нет основного идентификатора ЭДО</AlertTitle>
                      Введите минимум один идентификатор ЭДО и выберите основной.
                    </Alert>
                  )}
                  <FormControlLabel
                    checked={entityService}
                    control={<IOSSwitch />}
                    disabled={!hasMain}
                    label="Хочу автоматически получать закрывающие документы по сделкам в ЭДО"
                    onChange={onClickChangeEntityService}
                    onClick={onClickSwitch}
                    sx={styles.edoControl}
                  />
                  {entityServiceError && (<Box sx={styles.error}>{error}</Box>)}
                </FormGroup>
              </Card>
              <Card sx={styles.cardForm} variant="elevation">
                <CardHeader ref={formRef} sx={styles.documentsHeader} title="Система ЭДО" />
                {(edoList && edoList?.length === 0) && (
                  <Alert severity="warning" sx={{ fontSize: '14px', lineHeight: '18px', color: '#FF780F', borderRadius: '8px' }}>
                    <AlertTitle sx={{ fontSize: '14px', lineHeight: '18px', marginBottom: '4px' }}>Нет идентификатора ЭДО</AlertTitle>
                    Выберите систему документооборота и введите идентификатор
                  </Alert>
                )}
                {edoList && edoList?.length > 0 && (elEdoRender)}
                {isEditMode === null && (<Button onClick={onClickAddElement(true)} size="large" sx={styles.buttonGrey} variant="outlined"><AddIcon sx={{ marginRight: 16 }} />Добавить</Button>)}
              </Card>
            </Box>
          </Grid>
        )}
      </Grid>
      <Box sx={styles.footer}>
        <Box sx={styles.footerBlock}>
          <span>Компания Аренза</span> — надежный поставщик лизинговых сервисов.<br />
          Сохранность ваших персональных данных гарантирована Политикой конфиденциальности.
        </Box>
        <Box sx={styles.footerBlock}>
          <span>Если у вас остались вопросы</span><br />
          — свяжитесь, пожалуйста, с нами по телефону
        </Box>
        <Box sx={styles.footerPhones}>
          <a href="tel:+74951254344">+7 (495) 125-43-44</a>/<a href="tel:88007757685">8 (800) 775-76-85</a>
        </Box>
      </Box>
    </>
  );
}
