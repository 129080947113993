import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import FormattedCurrency from '@components/FormattedCurrency';
import { formatDate } from '@core/helpers';
import { Box, Card, Typography } from '@material-ui/core';

import Styled from './AccountInvestments.styled';
import AccountInvestmentsSkeleton from './AccountInvestmentsSkeleton';

AccountInvestment.propTypes = {
  investment: PropTypes.shape({
    amount: PropTypes.string,
    createdAt: PropTypes.string,
    endDate: PropTypes.string,
    id: PropTypes.number,
    interestBalance: PropTypes.string,
    interestRate: PropTypes.number,
    startDate: PropTypes.string,
    status: PropTypes.oneOf(['active', 'closed']),
    term: PropTypes.number,
  }),
};
function AccountInvestment({ investment }) {
  return (
    <Box sx={Styled.InvestBox}>
      <Card sx={Styled.InvestCard}>
        <Typography
          color={investment.status === 'active' ? '#00c227' : '#9299a2'}
          sx={{ fontSize: 14, fontWeight: 700 }}
          variant="caption"
        >
          {investment.status === 'active' ? 'Активна' : 'Завершена'}
        </Typography>
        <br />

        <Typography
          color="#7b7b7b"
          gutterBottom
          sx={{ fontSize: 13, fontWeight: 500 }}
          variant="caption"
        >
          Номинальный счет №{investment?.id}
        </Typography>

        <Box sx={Styled.InvestTable}>
          <Box sx={Styled.InvestTableRow}>
            <Typography sx={Styled.InvestTableKey} variant="body2">
              Сумма инвестиции
            </Typography>
            <Typography sx={Styled.InvestTableValue} variant="subtitle2">
              <FormattedCurrency hideCurrency value={investment?.amount} /> руб
            </Typography>
          </Box>
          {investment?.interestRate && (
            <Box sx={Styled.InvestTableRow}>
              <Typography sx={Styled.InvestTableKey} variant="body2">
                Ставка
              </Typography>
              <Typography sx={Styled.InvestTableValue} variant="subtitle2">
                {investment.interestRate}%
              </Typography>
            </Box>
          )}
          <Box sx={Styled.InvestTableRow}>
            <Typography sx={Styled.InvestTableKey} variant="body2">
              Срок
            </Typography>
            <Typography sx={Styled.InvestTableValue} variant="subtitle2">
              {investment?.term} мес
            </Typography>
          </Box>
          {investment?.startDate && (
            <Box sx={Styled.InvestTableRow}>
              <Typography sx={Styled.InvestTableKey} variant="body2">
                Дата открытия
              </Typography>
              <Typography sx={Styled.InvestTableValue} variant="subtitle2">
                {formatDate(investment.startDate)}
              </Typography>
            </Box>
          )}
          {investment?.endDate && (
            <Box sx={Styled.InvestTableRow}>
              <Typography sx={Styled.InvestTableKey} variant="body2">
                Дата окончания
              </Typography>
              <Typography sx={Styled.InvestTableValue} variant="subtitle2">
                {formatDate(investment.endDate)}
              </Typography>
            </Box>
          )}
          {investment?.interestBalance && (
            <Box sx={Styled.InvestTableRow}>
              <Typography sx={Styled.InvestTableKey} variant="body2">
                Доход
              </Typography>
              <Typography sx={Styled.InvestTableAccentValue} variant="subtitle2">
                + <FormattedCurrency hideCurrency value={investment.interestBalance} /> руб
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
}

AccountInvestments.propTypes = {
  investments: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.string,
    createdAt: PropTypes.string,
    endDate: PropTypes.string,
    id: PropTypes.number,
    interestBalance: PropTypes.string,
    interestRate: PropTypes.number,
    startDate: PropTypes.string,
    status: PropTypes.oneOf(['active', 'closed']),
    term: PropTypes.number,
  })),
  loading: PropTypes.bool,
};
export default function AccountInvestments({ investments, loading }) {
  const filteredInvestments = useMemo(
    () => investments?.reduce(
      (acc, value) => {
        acc[value.status].push(value);
        return acc;
      },
      { active: [], closed: [] },
    ),
    [investments],
  );

  if (loading) {
    return (
      <Box sx={Styled.InvestContainer}>
        <AccountInvestmentsSkeleton />
      </Box>
    );
  }

  return (
    <Box sx={Styled.InvestContainer}>
      {filteredInvestments.active.map((investment) =>
        <AccountInvestment investment={investment} key={investment.id} />
      )}

      {filteredInvestments.closed.map((investment) =>
        <AccountInvestment investment={investment} key={investment.id} />
      )}
    </Box>
  );
}
