import PropTypes from "prop-types";
import React, { useMemo, useState } from 'react';

import { Stack, Chip } from '@material-ui/core';

Chips.propTypes = {
  chips: PropTypes.array.isRequired, //examply:  ['COMMENTS', 'NOTES']
  defaultActiveTab: PropTypes.number, //examply:  0
  onChange: PropTypes.func,
  sx: PropTypes.object,
};

Chips.defaultProps = {
  defaultActiveTab: 0,
  sx: {
    wrapperChips: {},
    chip: {},
  },
  chips: [],
}

export default function Chips({ chips, defaultActiveTab, onChange, sx }) {
  const styles = useMemo(() => ({
    wrapperChips: {
      display: 'flex',
      gap: '16px',
      paddingBottom: '15px',
      marginBottom: '5px',
      overflow: 'auto',

      ...sx.wrapperChips,
    },
    chips: {
      background: '#e7e8ea',
      color: '#6E7884',
      boxShadow: 'none',
      fontSize: '14px',
      padding: '0 20px',
      height: '32px',
      borderRadius: '30px',

      '& span': {
        padding: 0,
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '&.active': {
        background: '#ee8035',
        color: '#ffffff',
      },
      ...sx.chip,
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [])

  const [activeChip, setActiveChip] = useState(defaultActiveTab);

  const onToggleChip = (value, event) => {
    setActiveChip(value);

    if (onChange && typeof onChange === 'function') {
      onChange(value);
    }
  }

  return (
    <Stack direction="row" sx={styles.wrapperChips}>
      {chips.map((chip, index) => {
        return (
          <Chip
            className={index === activeChip ? 'active' : null}
            key={index}
            label={chip}
            onClick={onToggleChip.bind(this, index)}
            sx={styles.chips}
          />
        )
      })}
    </Stack>
  );
}