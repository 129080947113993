module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Лизинг оборудования для малого бизнеса: взять оборудование в лизинг, условия для юридических лиц и ИП","short_name":"Arenza","description":"Аренза – лизинговая компания для малого бизнеса, юридических лиц и ИП ✅ Взять оборудование в лизинг онлайн из любой точки России ✅ Выгодные условия, минимальные проценты","start_url":"/","lang":"ru","background_color":"#232d3e","theme_color":"#FE5200","display":"standalone","icon":"static/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e05db29644a909eb941e225c7b6ba75e"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/shli4ara/Developer/Arenza/product-site-frontend/packages/account/src/core","languages":["ru"],"defaultLanguage":"ru","redirect":false,"redirectDefaultLanguageToRoot":false,"ignoredPaths":[],"fallbackLanguage":"en"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
