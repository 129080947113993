import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { gql, useQuery } from '@apollo/client';
import FormattedCurrency from '@components/FormattedCurrency';
import useActiveLegalEntity from '@hooks/useActiveLegalEntity';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Paper,
  Skeleton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Button
} from "@material-ui/core";
import DownloadOutlinedIcon from '@material-ui/icons/DownloadOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import {
  Tabs,
  Chips,
  WrapperButton,
  DialogApplicationCreate,
} from "@product-site-frontend/shared";
import dayjs from "dayjs";
import _upperFirst from 'lodash.upperfirst';
import queryString from 'query-string';
import useFetch from 'use-http';

import iconCirclePaid from "./img/circlePaid.svg";
import TimelinePayments from './TimelinePayments';
import TimelinePaymentSchedule from './TimelinePaymentSchedule';
import TimelineShipments from './TimelineShipments';

const FETCH_LEASING_DEAL = gql`
  query FetchLeasingDeal($entityId: Number!, $dealId: Number!) {
    deal(entityId: $entityId, dealId: $dealId)
      @rest(type: "Deal", path: "/entities/{args.entityId}/loans/{args.dealId}") {
      advanceRate
      date
      fullDebtAmount
      id
      monthlyPaymentAmount
      nextPaymentDate
      number
      requestedAmount
      schedule
      subject
      supplies
      taxAccrualsSchedule
      subjectClassificatorId
      term
    }
  }
`;

PageLeasingDeal.propTypes = {
  dealId: PropTypes.string,
};


export default function PageLeasingDeal({ dealId }) {
  const styles = useMemo(() => ({
    custLink: {
      fontSize: '16px',
      padding: '16px 24px',
      color: '#FF780F',
      border: '1px solid #FF780F',
      textDecoration: 'none',
      borderRadius: '8px',
      marginTop: '24px',

      '&:hover': {
        background: '#fdf4ed',
      },
      '&:active': {
        background: '#fcefe3',
        border: '1px solid #D9660D',
        color: '#D9660D',
      },
    },
    wrapperTable: {
      boxShadow: 'none',
      marginTop: '24px',
      color: '#0D1D32',

      '& div': {
        fontSize: '14px',
        fontWeight: 300,
        whiteSpace: 'nowrap',
      },
      '& .download': {
        width: '82px',
      },
      '& .name': {
        color: '#0D1D32',
        fontSize: '20px',
        fontWeight: '300',
        textDecoration: 'underline',
        wordBreak: 'break-all',
        whiteSpace: 'break-spaces',
      },
      '& .date': {
        color: '#6E7884',
        fontSize: '20px',
        fontWeight: '300',
      },
      '& .content': {
        minWidth: '320px',
        maxWidth: '320px'
      },

      '&.cus': {
        borderRadius: '24px',
        border: '1px solid #e7e8ea',

        '& .name': {
          fontSize: '16px',
        },
        '& .date': {
          fontSize: '16px',
          color: '#0D1D32',
        },
        '& .amount': {
          fontSize: '16px',
          color: '#000',
        },
        '& .download': {
          color: '#6E7884',
          fontSize: '14px',
          width: '116px',
        },
        '& tbody tr:last-child td': {
          border: 'none',
        }
      }
    },
    tabsStyles: {
      wrapperTabs: {
        paddingBottom: '48px',

        '& .MuiTabs-flexContainer': {
          gap: '0px',
          borderRadius: '8px'
        },

        '& .MuiTabs-indicator': {
          display: 'none'
        },
      },
      tab: {
        padding: {
          xs: '8px 10px',
          md: '10px 16px'
        },
        fontSize: {
          xs: '14px',
          md: '18px'
        },
        lineHeight: {
          xs: '20px',
          md: '36px'
        },
        borderBottom: 'none',
        border: '1px solid #E7E8EA',
        boxSizing: 'border-box',
        boderLeft: '1px solid transparent',

        '&:first-child': {
          borderRadius: '8px 0 0 8px',
          boderLeft: '1px solid #E7E8EA'
        },

        '&:last-child': {
          borderRadius: '0 8px 8px 0'
        },

        '&.Mui-selected': {
          color: '#FFF',
          background: '#FF780F',
          borderColor: '#FF780F'
        }
      }
    },
    buttonSet: {
      display: 'flex',
      flexWrap: {
        xs: 'wrap',
        md: 'nowrap'
      },
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '24px',
      gap: '24px'
    },
    buttonSetButton: {
      width: {
        xs: '100%',
        md: 'auto'
      },
      textTransform: 'none',
      fontWeight: 400
    },
    sheduleContainer: {
      gridArea: "schedule",
      maxWidth: {
        xs: '100%',
        md: "312px"
      }
    },
    sheduleCard: {
      borderRadius: '10px',
      boxShadow: '0px 20px 64px 0px #0D1D321A'
    },
    sheduleCardContent: {
      display: 'grid',
      gridTemplateColumns: {
        xs: '1fr 1fr',
        md: '1fr'
      },
      gap: {
        xs: '8px',
        md: 0
      },
      padding: {
        xs: '16px',
        md: '24px'
      }
    },
    sheduleItem: {
      padding: {
        xs: '8px',
        md: '8px 0'
      },
      background: {
        xs: '#F5F6F7',
        md: 'transparent'
      },
      borderRadius: {
        xs: '8px',
        md: 0
      }
    },
    sheduleItemTitle: {
      color: '#6E7884',
      fontSize: {
        xs: '12px',
        md: '16px'
      },
      lineHeight: {
        xs: '16px',
        md: '24px'
      },
      fontWeight: '300'
    },
    sheduleItemContent: {
      color: '#0D1D32',
      fontSize: {
        xs: '16px',
        md: '20px'
      },
      lineHeight: {
        xs: '24px',
        md: '32px'
      },
      fontWeight: '600'
    }
  }), []);

  const urlState = queryString.parse(window.location.search);

  const { activeLegalEntityId } = useActiveLegalEntity();

  const { data, loading } = useQuery(FETCH_LEASING_DEAL, {
    variables: { entityId: activeLegalEntityId, dealId },
  });

  const [activeTab, setActiveTab] = useState(0);
  const [activeSubTab, setActiveSubTab] = useState(urlState?.charges === 'true' ? 1 : 0);
  const [activeDoc, setActiveDoc] = useState(0);
  const [showModalSendDoc, setShowModalSendDoc] = useState(false);
  const [showModalPaymentsGraphic, setShowModalPaymentsGraphic] = useState(false);
  const [rowsTreaties, setRowsTreaties] = useState([]);
  const [showModalErrors, setShowModalErrors] = useState(false);

  const { get: downloadAttachment, loading: loadingDownloadAttachment, response: responseDownloadAttachment } = useFetch();
  const { data: dataTreaties, get: getTreaties, loading: loadingTreaties } = useFetch();
  const { get: getPdf } = useFetch(
    `${process.env.GATSBY_FRONTOFFICE_API}/entities/${activeLegalEntityId}/loans/${dealId}/download_tax_accruals_schedule_pdf`,
    { cachePolicy: 'no-cache', responseType: 'blob' });

  const onChangeTab = event => {
    setActiveTab(event);
  };

  const onChangeSubTab = event => {
    setActiveSubTab(event);
  };

  const onChangeDoc = event => {
    setActiveDoc(event);
  };

  const toggleShowModalSendDoc = () => {
    setShowModalSendDoc(!showModalSendDoc);
  };

  const toggleShowModalPaymentsGraphic = () => {
    setShowModalPaymentsGraphic(!showModalPaymentsGraphic);
  };

  const downloadFiles = useCallback(async (type, id, name) => {
    if (loadingDownloadAttachment) return false;

    if (type === 'actual_attachment') {
      await downloadAttachment(`/entities/${activeLegalEntityId}/contracts/${id}/actual_attachment`);
    } else {
      await downloadAttachment(`entities/${activeLegalEntityId}/accrual_documents/${id}/download`);
    }

    if (responseDownloadAttachment.ok) {
      let base64URL = '';

      if (type === 'actual_attachment') {
        base64URL = responseDownloadAttachment.data.actual_attachment;
      } else {
        base64URL = responseDownloadAttachment.data.pdf_file;
      }

      const binary = atob(base64URL.replace(/\s/g, ''));
      const len = binary.length;
      const buffer = new ArrayBuffer(len);
      const view = new Uint8Array(buffer);

      for (let i = 0; i < len; i += 1) {
        view[i] = binary.charCodeAt(i);
      }

      const blob = new Blob([view], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      if (type === 'actual_attachment') {
        a.download = `${name}.pdf`;
      } else {
        a.download = `${name}`;
      }
      a.target = '_blank';
      a.click();
    } else {
      toggleModalErrors();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseDownloadAttachment, activeLegalEntityId, downloadAttachment]);

  const createFile = async () => {
    let content = await getPdf();

    let file = new File([content], 'document.pdf', { type: "application/pdf" });
    let url = window.URL.createObjectURL(file);

    let a = document.createElement("a");
    a.style = "display: none";
    a.href = url;
    a.download = file.name;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const toggleModalErrors = () => {
    setShowModalErrors(!showModalErrors);
  };

  useEffect(() => {
    getTreaties(`/entities/${activeLegalEntityId}/contracts?loan_id=${dealId}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLegalEntityId, dealId]);

  useEffect(() => {
    if (dataTreaties?.length > 0) {
      setRowsTreaties(dataTreaties);
    }
  }, [dataTreaties]);

  return (
    <>
      <Box sx={{ mb: 5 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
          <Typography variant="h1">
            Лизинговый договор №{data?.deal.number}
          </Typography>
        </Box>

        <Tabs
          defaultActiveTab={activeTab}
          onChange={onChangeTab}
          sx={styles.tabsStyles}
          tabs={[
            'Общая информация',
            'Документы по сделке',
          ]}
        />

        {activeTab === 0 && activeSubTab === 0 && (
          <Chips
            chips={[
              'График платежей',
              'График начислений',
            ]}
            defaultActiveTab={activeSubTab}
            onChange={onChangeSubTab}
            sx={{
              chip: {
                '&.active': {
                  background: '#0D1D32',
                  color: '#fff'
                }
              }
            }}
          />
        )}

        {activeTab === 0 && activeSubTab === 0 && (
          <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
            <Typography gutterBottom variant="h3">
              {loading ? <Skeleton /> : _upperFirst(data?.deal.subject)}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            display: "grid",
            gridAutoFlow: 'row dense',
            gridTemplateAreas: {
              xs: `"schedule"
               "content"`,
              md: `"content schedule"`,
            },
            gridTemplateColumns: {
              xs: '100% 100%',
              md: "2fr 1fr",
            },
            gap: 4,
          }}
        >
          {activeTab === 0 && activeSubTab === 0 && (
            <Box sx={{ gridArea: "content", border: '1px solid #E7E8EA', borderRadius: '16px' }}>
              <Typography gutterBottom sx={{ margin: "24px !important" }} variant="h3">
                График платежей
              </Typography>

              <TimelinePaymentSchedule data={data?.deal.schedule} nexDayPay={data?.deal.next_payment_date} />
            </Box>
          )}

          {activeTab === 0 && activeSubTab === 1 && (
            // График начислений
            <Box sx={{ gridArea: "content" }}>
              <Chips
                chips={[
                  'График платежей',
                  'График начислений',
                ]}
                defaultActiveTab={activeSubTab}
                onChange={onChangeSubTab}
                sx={{
                  chip: {
                    '&.active': {
                      background: '#0D1D32',
                      color: '#fff'
                    }
                  }
                }}
              />

              <Box sx={styles.buttonSet}>
                <Button
                  onClick={toggleShowModalPaymentsGraphic}
                  size="large"
                  sx={{
                    ...styles.buttonSetButton,
                    color: '#ff6600',
                    borderWidth: '1px',

                    '&:hover': {
                      borderWidth: '1px',
                    }
                  }}
                  variant="outlined"
                >
                  <HelpOutlineOutlinedIcon sx={{ marginRight: '8px' }} />Как формируется график начислений?
                </Button>

                <Button
                  onClick={createFile}
                  size="large"
                  sx={{ ...styles.buttonSetButton, color: '#6E7884' }}
                >
                  <DownloadOutlinedIcon sx={{ marginRight: '4px' }} />Скачать в PDF
                </Button>
              </Box>

              <TimelineShipments data={data?.deal?.supplies} />
              <TimelinePayments data={data?.deal?.taxAccrualsSchedule} />
            </Box>
          )}

          {activeTab === 1 && (
            <Box sx={{ gridArea: "content", marginTop: "24px" }}>
              <Chips
                chips={[
                  'Договоры',
                ]}
                onChange={onChangeDoc}
              />

              {activeDoc === 0 && (
                loadingTreaties ? (
                  <CircularProgress />
                ) : (
                  rowsTreaties?.length > 0 ? (
                    <>
                      <TableContainer component={Paper} sx={styles.wrapperTable}>
                        <Table aria-label="simple table">
                          <TableBody>
                            {rowsTreaties.map(row => (
                              <TableRow key={row.id}>
                                <TableCell align="left" className={'download'} component="th" scope="row">
                                  <WrapperButton colorScheme={"white"} disabled={loadingDownloadAttachment} onClick={downloadFiles.bind(this, 'actual_attachment', row.actual_attachment.contract_id, row?.title)} sx={{ padding: '11px' }}>
                                    <SvgIcon>
                                      <path d="M12 4C12.5523 4 13 4.44772 13 5V13.5858L16.2929 10.2929C16.6834 9.90237 17.3166 9.90237 17.7071 10.2929C18.0976 10.6834 18.0976 11.3166 17.7071 11.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L6.29289 11.7071C5.90237 11.3166 5.90237 10.6834 6.29289 10.2929C6.68342 9.90237 7.31658 9.90237 7.70711 10.2929L11 13.5858V5C11 4.44772 11.4477 4 12 4Z" />
                                      <path d="M5 18C4.44772 18 4 18.4477 4 19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18H5Z" />
                                    </SvgIcon>
                                  </WrapperButton>
                                </TableCell>

                                <TableCell align="left" className={'content'}>
                                  <Typography className={'name'}>{row?.title || '---'}</Typography>

                                  <Typography className={'date'}>{row?.actual_attachment?.created_at ? dayjs(row.actual_attachment.created_at).format("DD.MM.YYYY") : '---'}</Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <Typography sx={{ marginTop: '30px' }}>Документов пока нет</Typography>
                  )
                )
              )}
            </Box>
          )}

          <Box sx={styles.sheduleContainer}>
            <Card sx={styles.sheduleCard}>
              <CardContent sx={styles.sheduleCardContent}>
                <Box sx={styles.sheduleItem}>
                  <Typography color="text.secondary" sx={styles.sheduleItemTitle} variant="body2" >
                    Стоимость предмета лизинга
                  </Typography>
                  <Typography sx={styles.sheduleItemContent}>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      <>
                        <FormattedCurrency
                          hideCurrency
                          value={data?.deal.requestedAmount}
                        /> руб
                      </>
                    )}
                  </Typography>
                </Box>
                <Box sx={styles.sheduleItem}>
                  <Typography color="text.secondary" sx={styles.sheduleItemTitle} variant="body2">
                    Срок лизинга
                  </Typography>
                  <Typography sx={styles.sheduleItemContent}>
                    {loading ? <Skeleton /> : <>{data?.deal.term} мес</>}
                  </Typography>
                </Box>
                <Box sx={styles.sheduleItem}>
                  <Typography color="text.secondary" sx={styles.sheduleItemTitle} variant="body2">
                    Размер аванса
                  </Typography>
                  <Typography sx={styles.sheduleItemContent}>
                    {loading ? <Skeleton /> : data?.deal.advanceRate + "%"}
                  </Typography>
                </Box>
                <Box sx={styles.sheduleItem}>
                  <Typography color="text.secondary" sx={styles.sheduleItemTitle} variant="body2">
                    Ежемесячный платеж
                  </Typography>
                  <Typography sx={styles.sheduleItemContent}>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      <>
                        <FormattedCurrency
                          hideCurrency
                          value={data?.deal.monthlyPaymentAmount}
                        /> руб
                      </>
                    )}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>

      <DialogApplicationCreate
        content={
          <Box sx={{ alignItems: 'flex-start', display: "flex", flexDirection: 'column' }}>
            <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '30px', lineHeight: '38px', fontStyle: 'normal', fontWeight: '700', marginBottom: '24px' }}>График начислений</Typography>

            <Typography sx={{ fontSize: '14px', fontWeight: '300', marginBottom: '24px' }}>График начисления составляется в момент поставки предмета лизинга, является неотъемлемой частью договора лизинга и является основанием для ежемесячного выставления счет-фактур.</Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: '300', marginBottom: '24px' }}>Первоначальный аванс предусмотренный договором лизинга распределяется и зачитывается на весь срок договора лизинга, если иное не согласовано сторонами. В месяцах зачета первоначальных авансовых платежей счет-фактура выставляется на общую сумму месячного лизингового платежа из графика к договору лизинга и месячной суммы аванса, рассчитанной пропорционально сроку зачета первоначального аванса.</Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: '600', marginBottom: '24px' }}>Если отгрузка предмета лизинга происходит несколькми частями, счета-фактуры оформляются на суммы:</Typography>
            <ol style={{ listStylePosition: 'outside', listStyleType: 'decimal', paddingLeft: '24px', fontSize: '14px', fontWeight: '300', marginBottom: '24px' }}>
              <li>
                при отгрузке первой части предмета лизинга: месячная часть первой части поставленного предмета лизинга, рассчитанная пропорционально сроку действия договора лизинга и месячная часть авансового платежа;
              </li>
              <li>
                при каждой последующей отгрузке к сумме, рассчитанной при первой отгрузке, добавляются суммы месячной части каждой далее поставленной части предмета лизинга, рассчитанной пропорционально оставшемуся сроку договора лизинга.
              </li>
            </ol>
            <Typography sx={{ fontSize: '14px', fontWeight: '300', marginBottom: '24px' }}>Если произошла реструктуризация, счета-фактуры выставляются на суммы в размере месячной части оставшейся на дату вступления в силу соглашения о реструктуризации общей суммы к погашению по договору лизинга, рассчитанной пропорционально оставшемуся сроку погашения задолженности по соглашению о реструктуризации.</Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: '300', marginBottom: '24px' }}>На выкупную стоимость формируется отдельный универсально-передаточный документ (УПД).</Typography>

            <WrapperButton colorScheme={'orange'} onClick={toggleShowModalPaymentsGraphic} sx={{ margin: '0 auto' }}>
              Закрыть
            </WrapperButton>
          </Box>
        }
        maxWidth="sm"
        onClose={toggleShowModalPaymentsGraphic}
        open={showModalPaymentsGraphic}
      />

      <DialogApplicationCreate
        content={
          <Box sx={{ alignItems: 'center', display: "flex", flexDirection: 'column' }}>
            <Box component={"img"} src={iconCirclePaid} sx={{ width: '94px', height: '94px', display: 'inline', marginBottom: '24px' }} />

            <Typography sx={{ fontSize: '26px', fontStyle: 'normal', fontWeight: '700', marginBottom: '24px' }}>Ваш запрос принят</Typography>

            <Typography sx={{ fontSize: '20px', fontWeight: '300', marginBottom: '8px' }}>Документ</Typography>
            <Typography sx={{ fontSize: '20px', fontWeight: '300', marginBottom: '8px' }}>название документа</Typography>
            <Typography sx={{ fontSize: '20px', fontWeight: '300', marginBottom: '24px' }}>будет направлен в систему ЭДО вашей компании.</Typography>

            <WrapperButton colorScheme={'orangeStroke'} onClick={toggleShowModalSendDoc}>
              Ок
            </WrapperButton>
          </Box>
        }
        maxWidth="sm"
        onClose={toggleShowModalSendDoc}
        open={showModalSendDoc}
      />

      <DialogApplicationCreate
        content={
          <Box sx={{ alignItems: 'center', display: "flex", flexDirection: 'column' }}>
            <Typography sx={{ textAlign: 'center', fontSize: '26px', fontWeight: 700 }}>Произошла ошибка. Попробуйте позже.</Typography>

            Код ошибки: ${responseDownloadAttachment?.status} ${responseDownloadAttachment?.data?.error}
          </Box>
        }
        maxWidth="sm"
        onClose={toggleModalErrors}
        open={showModalErrors}
      />
    </>
  );
}

// http://crm-stage.arenza.ru/entities/13606
// http://crm-stage.arenza.ru/entities/652
// http://crm-stage.arenza.ru/entities/14304