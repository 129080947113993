import deepmerge from 'deepmerge';

import { baseConfig } from '../base.config';

export const customerConfig = deepmerge(baseConfig, {
  nav: {
    '/': {
      label: 'Главная',
      to: '/',
    },
    applications: {
      label: 'Заявки',
      to: '/leasing/applications',
    },
    deals: {
      label: 'Сделки',
      to: '/leasing/deals',
    },
    questionnaire: {
      label: 'Анкета',
      to: '/leasing/questionnaire',
    },
    'bank-statements': {
      label: 'Банковские выписки',
      to: '/leasing/bank-statements',
    },
    docs: {
      label: 'Документы компании',
      to: '/leasing/docs',
    },
    accounting: {
      label: 'Бухгалтерия',
      to: '/leasing/accounting'
    }
  },
}); 