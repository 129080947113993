import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Autocomplete,
  Box,
  Container,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import LoadingButton from '@material-ui/lab/LoadingButton';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import debounce from 'lodash/debounce';
import useFetch from 'use-http';

const SUGGESTIONS_URL =
  'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party';
const TOKEN = '640f03ef7ad1b62d099385d9b724257b51376ee7';

const getHighlights = (value, source) => {
  const matches = match(value, source)
  return parse(value, matches).map(
    ({ highlight, text }, idx) => (
      <span key={idx} style={{ fontWeight: highlight ? 700 : 400 }}>
        {text}
      </span>
    ));
};

FormAddEntity.propTypes = {
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func,
};
export default function FormAddEntity({ isSubmitting, onSubmit }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [options, setOptions] = useState([]);
  const [noOptionsText, setNoOptionsText] = useState('');

  const { loading: isFetching, post: fetchSuggestions } = useFetch(
    SUGGESTIONS_URL,
    {
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${TOKEN}`,
      },
    },
  );

  const handleChange = useCallback((event, newOption) => {
    if (newOption) {
      setSelectedOption(newOption);
    } else {
      setOptions([]);
      setSelectedOption(undefined);
    }
  }, []);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    onSubmit(selectedOption?.data?.inn || '');
  }, [onSubmit, selectedOption]);

  const debouncedFetch = useMemo(
    () =>
      debounce((body, cb) => {
        fetchSuggestions(body).then((result) => cb(result?.suggestions));
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!searchTerm) {
      setNoOptionsText('Введите ИНН или название вашей компании / ИП');
      setSelectedOption(undefined);
      setOptions([]);
      return undefined;
    }

    debouncedFetch(
      { query: searchTerm, status: ['ACTIVE'] },
      (results) => {
        setNoOptionsText('Поиск ...');
        let newOptions = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        if (!newOptions.length) {
          setNoOptionsText('По данному запросу ничего не найдено');
        }

        setOptions(newOptions);
      }
    )

    return () => {
      setNoOptionsText('Введите ИНН или название вашей компании / ИП');
    }
  }, [searchTerm, debouncedFetch]);

  return (
    <Container
      component="form"
      maxWidth="sm"
      onSubmit={handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 16,
        marginBottom: 16,
        gap: 16,
      }}
    >
      <Autocomplete
        autoComplete
        filterOptions={(x) => x}
        forcePopupIcon={false}
        getOptionLabel={(option) => option?.value || ''}
        inputValue={searchTerm}
        isOptionEqualToValue={
          (option, value) => option?.data?.inn === value?.data?.inn
        }
        noOptionsText={noOptionsText}
        onChange={handleChange}
        onInputChange={
          (event, newInputValue) => setSearchTerm(newInputValue)
        }
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={isFetching || isSubmitting}
            fullWidth
            helperText={
              selectedOption ? `ИНН: ${selectedOption.data?.inn}` : ''
            }
            label="Название компании, ИП или ИНН"
            sx={{ mb: 3 }}
            variant="filled"
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          const TextPrimary = (
            <Typography noWrap sx={{ fontWeight: 400 }} variant="inherit">
              {getHighlights(option?.value || '', inputValue)}
            </Typography>
          );

          const TextSecondary = (
            <>{getHighlights(`ИНН ${option?.data?.inn}`, inputValue)}</>
          );

          return (
            <ListItem {...props} dense>
              <ListItemText primary={TextPrimary} secondary={TextSecondary} />
            </ListItem>
          );
        }}
        sx={{ width: 1 }}
      />
      <Box sx={{ width: '296px' }}>
        <LoadingButton
          disableElevation
          disabled={isSubmitting || !selectedOption}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Продолжить
        </LoadingButton>
      </Box>
    </Container>
  );
}