import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from "react";

import FormattedCurrency from '@components/FormattedCurrency';
import { Box } from '@material-ui/core';
import dayjs from "dayjs";

TimelineShipments.propTypes = {
  data: PropTypes.shape({
    taxAccrualsCharges: PropTypes.array,
    taxAccrualsRedemption: PropTypes.shape({
      amountIncludingVat: PropTypes.number,
      vatAmount: PropTypes.number,
    })
  })
};

TimelineShipments.defaultProps = {
  data: {
    taxAccrualsCharges: [],
    taxAccrualsRedemption: {
      amountIncludingVat: 0,
      vatAmount: 0,
    }
  }
};

export default function TimelineShipments({ data }) {
  const styles = useMemo(() => ({
    container: {
      borderRadius: '16px',
      boxShadow: '0px 20px 64px 0px #0D1D321A',
      boxSizing: 'border-box',
      marginTop: '24px',
      overflowX: 'auto'
    },
    scrollXContainer: {
      minWidth: '650px'
    },
    titleTable: {
      display: 'grid',
      gridTemplateColumns: '43px 1fr 130px 130px 130px',
      alignItems: 'center',
      gap: '8px',
      padding: '17px 40px 17px 24px',
      boxSizing: 'border-box'
    },
    titleTableItem: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14px',
      color: '#9EA5AD',
    },
    table: {
      marginRight: {
        xs: 0,
        md: '8px'
      },
      maxHeight: {
        xs: 'auto',
        md: '196px'
      },
      overflowY: 'auto',
      boxSizing: 'border-box',
      padding: {
        xs: '0 40px 0 24px',
        md: '0 16px 0 24px'
      },

      '& > div': {
        borderBottom: '1px solid #E7E8EA',

        '&:last-child': {
          borderBottom: 'none'
        }
      }
    },
    tableItem: {
      display: 'grid',
      gridTemplateColumns: '43px 1fr 130px 130px 130px',
      alignItems: 'center',
      gap: '8px',
      padding: '4px 0'
    },
    tableFooter: {
      display: 'grid',
      boxSizing: 'border-box',
      gridTemplateColumns: '1fr 130px 130px 130px',
      gap: '8px',
      color: '#FF780F',
      background: 'rgba(255, 120, 15, 0.08)',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '22px',
      padding: '16px 40px 16px 24px'
    }
  }), []);

  const [sumAmountIncludingVat, setSumAmountIncludingVat] = useState(0);
  const [sumAdvancePaymentIncludingVat, setSumAdvancePaymentIncludingVat] = useState(0);
  const [sumVatAmount, setSumVatAmount] = useState(0);

  const onReduce = type => data?.taxAccrualsCharges?.reduce(
    (accumulator, item) => (accumulator + item[type]),
    0,
  )

  useEffect(() => {
    setSumAmountIncludingVat(onReduce("amountIncludingVat") + data?.taxAccrualsRedemption?.amountIncludingVat);
    setSumAdvancePaymentIncludingVat(onReduce("advancePaymentIncludingVat"));
    setSumVatAmount(onReduce("vatAmount") + data?.taxAccrualsRedemption?.vatAmount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.taxAccrualsRedemption, data?.taxAccrualsCharges]);

  const renderItems = () => {
    return data?.taxAccrualsCharges.map((item, index) => (
      <Box key={item?.chargeDate + '_' + item?.vatAmount} sx={styles.tableItem}>
        <Box>
          {index + 1}
        </Box>
        <Box>
          {item?.chargeDate ? dayjs(item?.chargeDate).format("DD/MM/YYYY") : '---'}
        </Box>
        <Box>
          <FormattedCurrency value={item?.amountIncludingVat} />
        </Box>
        <Box>
          <FormattedCurrency value={item?.advancePaymentIncludingVat} />
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <FormattedCurrency value={item?.vatAmount} />
        </Box>
      </Box>
    )
    )
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.scrollXContainer}>
        <Box sx={styles.titleTable}>
          <Box sx={styles.titleTableItem}>№</Box>
          <Box sx={styles.titleTableItem}>Дата</Box>
          <Box sx={styles.titleTableItem}>Сумма начислений, +НДС (руб)</Box>
          <Box sx={styles.titleTableItem}>В том числе зачет аванса, +НДС (руб)</Box>
          <Box sx={{ ...styles.titleTableItem, textAlign: 'center' }}>Сумма НДС (руб)</Box>
        </Box>

        <Box sx={styles.table}>
          {data?.taxAccrualsCharges?.length > 0 ? renderItems() : "На данный момент список пуст"}
          <Box sx={styles.tableItem}>
            <Box></Box>

            <Box>
              Выкуп
            </Box>

            <Box>
              <FormattedCurrency value={data?.taxAccrualsRedemption?.amountIncludingVat || 0} />
            </Box>

            <Box></Box>

            <Box sx={{ textAlign: 'center' }}>
              <FormattedCurrency value={data?.taxAccrualsRedemption?.vatAmount || 0} />
            </Box>
          </Box>
        </Box>

        {data?.taxAccrualsCharges?.length && (
          <Box sx={styles.tableFooter}>
            <Box sx={{ color: '#0D1D32' }}>Итого:</Box>
            <Box>
              <FormattedCurrency value={sumAmountIncludingVat || 0} />
            </Box>
            <Box>
              <FormattedCurrency value={sumAdvancePaymentIncludingVat || 0} />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <FormattedCurrency value={sumVatAmount || 0} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
