import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { AppStateContext } from "@core/AppContextProvider";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import useActiveLegalEntity from "@hooks/useActiveLegalEntity";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Paper,
  SvgIcon,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { PhoneMaskInput, FormFieldContainer } from "@product-site-frontend/shared";
import { findIndex } from "lodash";
import { unstable_batchedUpdates } from "react-dom";
import { useForm } from "react-hook-form";
import { FormattedMessage } from 'react-intl';
import useFetch from "use-http";
import * as yup from "yup";

import iconCompleteAddManager from "../../img/completeAddManager.svg";
import iconCompleteSettingManager from "../../img/completeSettingManager.svg";
import iconGear from "../../img/gear.svg";
import iconInf from "../../img/inf.svg";
import iconPlus from "../../img/plus.svg";
import FooterInf from "../FooterInf";

export default function PageProfileCompany() {
  const styles = useMemo(() => ({
    titlePage: {
      fontSize: { xs: '24px', sm: '32px', md: '36px', lg: '36px' },
      fontWeight: 900,
    },
    tabs: {
      display: 'inline-flex',
      marginTop: '30px',
      marginBottom: '43px',
      borderRadius: '8px',

      '& button': {
        position: 'relative',
        zIndex: 5,
        fontSize: {
          xs: '14px',
          sm: '14px',
          md: '18px',
          lg: '18px',
        },
        fontWeight: 500,
        color: '#6E7884',
        textTransform: 'inherit',
        borderTop: '1px solid #CFD2D6',
        borderBottom: '1px solid #CFD2D6',
        padding: {
          xs: '8px 10px',
          sm: '12px 16px',
          md: '12px 16px',
          lg: '12px 16px',
        },
        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

        '&:first-child': {
          borderLeft: '1px solid #CFD2D6',
          borderRadius: '8px 0px 0px 8px',
        },

        '&:last-child': {
          borderRight: '1px solid #CFD2D6',
          borderRadius: '0px 8px 8px 0px'
        }
      },
      '& button.Mui-selected': {
        color: '#fff',
        borderColor: '#FF780F',
      },
      '& .MuiTabs-indicator': {
        height: '100%',
      },
    },
    wrapperBox: {
      display: 'flex',
      gap: '24px',
      flexWrap: {
        xs: 'wrap',
        sm: 'wrap',
        md: 'nowrap',
        lg: 'nowrap',
      },
    },
    content: {
      width: {
        xs: '100%',
        sm: '100%',
        md: 'calc(100% - 32%)',
        lg: 'calc(100% - 32%)',
      },
    },
    nameCompany: {
      fontSize: '16px',
      fontWeight: 700,
      marginBottom: '16px',
    },
    infShortCompany: {
      display: 'flex',
      flexDirection: {
        xs: 'column',
        sm: 'row',
        md: 'row',
        lg: 'row'
      },
      gap: {
        xs: 0,
        sm: 0,
        md: '10px',
        lg: 0
      },
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      color: '#6E7884',
      fontSize: '16px',
      fontWeight: 300,

      '& span': {
        fontWeight: 500,
        color: '#0D1D32',
        marginTop: '4px',
        display: {
          xs: 'inline',
          sm: 'block',
          md: 'inline',
          lg: 'inline'
        }
      }
    },
    infBank: {
      background: '#F5F6F7',
      borderRadius: '8px',
      alignSelf: 'baseline',
      minWidth: {
        xs: 'unset',
        sm: 'unset',
        md: '300px',
        lg: '340px',
      },
      width: {
        xs: '100%',
        sm: '100%',
        md: '32%',
        lg: '32%',
      },
      padding: '16px',
    },
    infBankTitle: {
      fontSize: '22px',
      fontWeight: 700,
      marginBottom: '16px',
    },
    infBankBlock: {
      marginBottom: '16px',
      fontWeight: '300',
      color: '#6E7884',

      '& div': {
        fontWeight: 500,
        color: '#0D1D32',
      },
    },
    wrapperFilters: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: {
        xs: 'wrap',
        sm: 'nowrap',
        md: 'nowrap',
        lg: 'nowrap'
      },
      alignItems: 'center',
    },
    filters: {
      display: 'flex',
      gap: '8px',
      overflowX: 'auto',
      overflowY: 'hidden',

      '& > div': {
        padding: {
          xs: '7px 8px',
          sm: '7px 8px',
          md: '4px 8px',
          lg: '4px 8px',
        },
        height: 'auto',
        color: '#6E7884',
        fontSize: {
          xs: '16px',
          sm: '12px',
          md: '16px',
          lg: '16px',
        },
        fontWeight: 500,
        cursor: 'pointer',

        '& > span': {
          fontSize: 'inherit',
          color: '#B6BBC2',
          paddingLeft: '4px',
        },
      },
      '.active': {
        background: 'rgba(255, 120, 15, 0.1)',
        borderRadius: '4px',
        color: '#FF780F',

        '& > span': {
          color: '#FF780F',
        }
      }
    },
    buttonOrange: {
      border: '1px solid rgba(255, 120, 15, 0.5)',
      color: '#FF780F',
      fontSize: {
        xs: '12px',
        sm: '12px',
        md: '16px',
        lg: '16px',
      },
      fontWeight: 500,
      borderRadius: '8px',
      textTransform: 'initial',
      width: {
        xs: '100%',
        sm: '171px',
        md: '236px',
        lg: '236px',
      },
      height: {
        xs: '56px',
        sm: '36px',
        md: '56px',
        lg: '56px',
      },
      display: 'inline-flex',
      marginTop: {
        xs: '24px',
        sm: 0,
        md: 0,
        lg: 0
      },

      '& .MuiTouchRipple-root': {
        display: 'none'
      },

      '& img': {
        marginRight: {
          xs: '12px',
          sm: '6px',
          md: '12px',
          lg: '12px',
        },
        width: '20px',
        height: '20px',
      },
      '&:hover': {
        background: 'rgba(255, 120, 15, 0.08)',
        border: '1px solid; border-image-source: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), linear-gradient(0deg, #FF780F, #FF780F)',
      },
      '&:active': {
        color: '#D9660D',
        background: 'rgba(255, 120, 15, 0.12)',
        border: '1px solid; border-image-source: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), linear-gradient(0deg, #FF780F, #FF780F)',
      },
      '&:disbled': {
        color: '#CFD2D6',
        background: '#ffffff',
        border: '#CFD2D6',
      },
    },
    modal: {
      '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: '682px',
        margin: {
          xs: '0',
          sm: '32px',
        }
      },
      '& .MuiDialogContent-root': {
        paddingLeft: {
          xs: '24px',
          sm: '24px',
          md: '64px',
          lg: '64px',
        },
        paddingRight: {
          xs: '24px',
          sm: '24px',
          md: '64px',
          lg: '64px',
        },
      }
    },
    modalTitle: {
      fontSize: '26px',
      fontWeight: 700,
      marginBottom: '39px',
      display: {
        xs: 'none',
        sm: 'block',
        md: 'block',
        lg: 'block',
      },
    },
    modalTitleMob: {
      fontSize: '18px',
      fontWeight: 700,
      display: {
        xs: 'block',
        sm: 'none',
      },
    },
    modalInput: {
      marginTop: 0,
      borderRadius: '8px',
      overflow: 'hidden',
      paddingRight: 0,
      marginBottom: '24px',

      '& .MuiFilledInput-root': {
        border: 'none',
        background: '#F5F6F7',

        '&.Mui-focused': {
          background: '#F5F6F7',
        }
      },
      '& fieldset': {
        border: 'none',
      },
      '& .MuiInputAdornment-root': {
        display: 'none',
      },
      '& input': {
        // background: '#F5F6F7',
        borderRadius: '8px',
        padding: '16.5px 14px',
      }
    },
    modalButton: {
      background: '#FF780F',
      boxShadow: '0px 10px 24px rgba(255, 120, 15, 0.3)',
      borderRadius: '8px',
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: '500',
      textTransform: 'initial',
      padding: '14px 24px',
      width: {
        xs: '100%',
        sm: 'auto',
        md: 'auto',
        lg: 'auto',
      },

      '&:hover': {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #FF780F',
        boxShadow: 'none'
      },
      '&:active': {
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #FF780F',
        boxShadow: 'none'
      },
      '&:disabled': {
        background: '#E7E8EA',
        boxShadow: 'none',
        color: '#B6BBC2',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
    modalDescription: {
      fontSize: '16px',
      color: '#21A2FF',
      fontWeight: 300,
      marginTop: '0',
      marginBottom: '35px',
      background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #21A2FF',
      borderRadius: '8px',
      padding: '16px',
      display: 'flex',

      '& img': {
        marginRight: '16px',
      }
    },
    modalComplete: {
      '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: '456px',
      },
    },
    modalCompleteImg: {
      margin: '0 auto',
      paddingTop: '43px',
      paddingBottom: '14px',
    },
    modalCompleteTitle: {
      fontSize: '22px',
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: '90px',
    },
    wrapperTable: {
      boxShadow: 'none',
      marginTop: '24px',

      '& div': {
        fontSize: '14px',
        fontWeight: 300,
        whiteSpace: 'nowrap',
      },

      '& .fio': {
        minWidth: '436px',
        width: '436px',
        fontWeight: 700,
        fontSize: '16px',
      },
      '& .contact': {

      },
      '& .status': {
        width: '170px',
        fontSize: '14px',
        fontWeight: 500,

        '& .registration': {
          color: '#B6BBC2'
        },
        '& .active': {
          color: '#67BF3D'
        }
      },
      '& .access': {
        minWidth: '270px',

        '& ul': {
          listStyleType: 'disc',
          marginLeft: '30px',

          '& li': {
            fontSize: '16px',
            fontWeight: 300,
            color: '#0D1D32',
          }
        }
      },
      '& .gear': {
        width: '30px',
        padding: '0',

        '& button': {
          width: '54px',
          height: '54px',

          '&:disabled': {
            opacity: '0.3'
          }
        }
      }
    },
    greenBG: {
      padding: {
        xs: '16px',
        sm: 0,
        md: 0,
        lg: 0,
      },
      borderRadius: {
        xs: '8px',
        sm: 0,
        md: 0,
        lg: 0,
      },
      background: {
        xs: '#F5F6F7',
        sm: '#ffffff',
        md: '#ffffff',
        lg: '#ffffff',
      }
    },
  }),[]);

  const schema = yup.object({
    full_name: yup
      .string()
      .required('Укажите фамилию, имя и отчество через пробел')
      .max(256, 'Укажите фамилию, имя и отчество через пробел')
      .min(2, 'Укажите фамилию, имя и отчество через пробел'),
    phone: yup
      .string()
      .required('Укажите номер телефона')
      .matches(/(^\+7\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{2}-[0-9]{2}$)/, {
        message: 'Номер телефона должен состоять из 10 цифр',
      }),
    email: yup
      .string()
      .required('Укажите email')
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, {
        message: 'Email должен быть корректным',
      }),
  });

  const { control, handleSubmit, reset } = useForm({
    mode: "onTouch",
    resolver: yupResolver(schema)
  });

  const { activeLegalEntityId, user: userGlob  } = useContext(AppStateContext);

  const [allowManagers, setAllowManagers] = useState(false);

  useEffect(() => {
    let partner_admin, manage_partner_users;

    userGlob?.roles.forEach(role => {
      if (role?.entityId === activeLegalEntityId && role?.values.includes('partner_admin')) {
        partner_admin = true;
      }
    });

    userGlob?.abilities.forEach(abilitie => {
      if (abilitie?.entityId === activeLegalEntityId && abilitie?.values.includes('manage_partner_users')) {
        manage_partner_users = true;
      }
    })

    setAllowManagers(partner_admin || manage_partner_users)
  }, [activeLegalEntityId, userGlob])

  const [users, setUsers] = useState([]);
  const { get: getUsers, loading: loadingUsers, response: responseUsers } = useFetch();

  useEffect(() => {
    if (activeLegalEntityId) {
      onGetUsers(activeLegalEntityId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLegalEntityId])

  const onGetUsers = useCallback(async (entityId) => {
    await getUsers(`/partners/users?entity_id=${entityId}`);

    if (responseUsers?.data) {
      setUsers(responseUsers?.data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseUsers]);

  const { loading: loadingCreateUser, post: postCreateUser, response: responseCreateUser  } = useFetch(`/partners/users`);

  const [activeTab, setActiveTab] = useState(0);
  const handleChangeTabs = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [showModalManager, setShowModalManager] = useState(false);
  const [showModalSettingManager, setShowModalSettingManager] = useState(false);
  const [showModalManagerComplete, setShowModalComplete] = useState(false);
  const [showModalSettingManagerComplete, setShowModalSettingManagerComplete] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [textError, setTextError] = useState(null);


  const handleToggleDialog = (type, status) => {
    switch (type) {
      case 'showModalManager':
        setShowModalManager(status);

        if (!status) {
          reset({ full_name: '' });
          reset({ phone: '' });
          reset({ additional_phone_part: '' });
          reset({ email: '' });
        }

        break;

      case 'showModalSettingManager':
        unstable_batchedUpdates(() => {
          setShowModalSettingManager(status);

          if (!status) {
            setSettingManagerIndex(null)
          }
        })
        break;

      case 'showModalManagerComplete':
        setShowModalComplete(status);
        break;

      case 'showModalError':
        setShowModalError(status);
        break;

      case 'showModalSettingManagerComplete':
        setShowModalSettingManagerComplete(status);
        break;

      default:
        unstable_batchedUpdates(() => {
          setShowModalSettingManager(false);
          setShowModalManager(false);
          setShowModalComplete(false);
          setShowModalSettingManagerComplete(false);
          setShowModalError(false);
          setSettingManagerIndex(null)
        })
    }
  }

  async function onSubmit(data) {
    const cloneData = { ...data };
    cloneData.phone = `+${data.phone.replace(/[^0-9]/g,"")}`;
    cloneData.entity_id = activeLegalEntityId;

    await postCreateUser({ ...cloneData });

    handleToggleDialog('showModalManager', false);

    if (responseCreateUser.ok) {
      handleToggleDialog('showModalManagerComplete', true);

      if (activeLegalEntityId) {
        onGetUsers(activeLegalEntityId)
      }
    } else {
      setTextError('При добавлении менеджера произошла ошибка. Попробуйте позже.')
      handleToggleDialog('showModalError', true);
    }
  }

  const countStatus = type => {
    let total = 0;

    users?.forEach(user => {
      if (user?.status[finIndexType(user?.status)].values === type) {
        total++;
      }
    })

    return total;
  }

  const [settingManagerIndex, setSettingManagerIndex] = useState({
    id_user: null,
    crm_user_id: null,
    status: null,
    roles: null,
    manage_partner_users: null,
    access_to_partners_applications: null,
    access_to_entity_account: null,
  });

  const finIndexType = type => {
    return findIndex(type, ['entity_id', activeLegalEntityId]);
  }

  const onShowModalSettingManager = id => {
    const cloneSettingManagerIndex = { ...settingManagerIndex };
    let index = findIndex(users, ['id', id]);

    cloneSettingManagerIndex.id_user = id;
    cloneSettingManagerIndex.crm_user_id = users[index]?.crm_user_id;
    cloneSettingManagerIndex.status = users[index]?.status[finIndexType(users[index]?.status)]?.values;
    cloneSettingManagerIndex.roles = users[index]?.roles[finIndexType(users[index]?.roles)]?.values;
    cloneSettingManagerIndex.manage_partner_users = users[index]?.abilities[finIndexType(users[index]?.abilities)]?.values?.includes('manage_partner_users');
    cloneSettingManagerIndex.access_to_partners_applications = users[index]?.abilities[finIndexType(users[index]?.abilities)]?.values?.includes('access_to_partners_applications');
    cloneSettingManagerIndex.access_to_entity_account = users[index]?.abilities[finIndexType(users[index]?.abilities)]?.values?.includes('access_to_entity_account');

    setSettingManagerIndex(cloneSettingManagerIndex);

    handleToggleDialog('showModalSettingManager', true);
  };

  const onChangeSettingManager = type => {
    const cloneSettingManagerIndex = { ...settingManagerIndex };

    if (type === "status") {
      cloneSettingManagerIndex[type] = cloneSettingManagerIndex[type] === 'active' ? 'no_active' : 'active';
    } else {
      cloneSettingManagerIndex[type] = !cloneSettingManagerIndex[type];
    }

    setSettingManagerIndex(cloneSettingManagerIndex);
  }

  const { loading: loadingUpdateUser, patch: patchUpdateUser, response: responseUpdateUser } = useFetch();

  async function onSaveManager() {
    if (!settingManagerIndex?.id_user) {
      handleToggleDialog('showModalSettingManager', false);
      setTextError('В момент обновления менеджера произошла ошибка. Попробуйте позже.');
      handleToggleDialog('showModalError', true);
    }

    await patchUpdateUser(`/partners/users/${settingManagerIndex.id_user}`, {
      entity_id: activeLegalEntityId,
      crm_user_id: settingManagerIndex.crm_user_id,
      active: settingManagerIndex.status === 'active',
      abilities: {
        access_to_partners_applications: settingManagerIndex.access_to_partners_applications,
        manage_partner_users: settingManagerIndex.manage_partner_users,
      }
    })

    handleToggleDialog('showModalSettingManager', false);

    if (responseUpdateUser.ok) {
      handleToggleDialog('showModalSettingManagerComplete', true);

      if (activeLegalEntityId) {
        onGetUsers(activeLegalEntityId)
      }
    } else {
      setTextError('В момент обновления менеджера произошла ошибка. Попробуйте позже.');
      handleToggleDialog('showModalError', true);
    }
  }

  const [filter, setFilter] = useState('all');
  const tableContent = user => {
    return (
      <TableRow key={user?.crm_user_id}>
        <TableCell className={'fio'}>
          {user?.last_name && (
            <>
              {user?.last_name}{" "}
            </>
          )}

          {user?.first_name && (
            <>
              {user?.first_name}{" "}
            </>
          )}

          {user?.middle_name && (
            <>
              {user?.middle_name}
            </>
          )}

          {!user?.last_name && !user?.first_name && !user?.middle_name && (
            "ФИО отсутствует"
          )}
        </TableCell>

        <TableCell className={'contact'}>
          {user?.email && (
            <Link href={`mailto:${user?.email}`} underline="none">{user?.email}</Link>
          )}

          <br />

          <Typography sx={{ whiteSpace: 'nowrap', fontSize: '13px', fontWeight: 300 }}>
            {user?.phone ? (
              user?.phone
            ) : (
              "Телефон отсутствует"
            )}
          </Typography>

        </TableCell>

        <TableCell align="center" className={'status'}>
          {user?.status?.length ? (
            <Typography className={user?.status[finIndexType(user?.status)]?.values}>
              <FormattedMessage id={`partnership.profileCompany.status.${user?.status[finIndexType(user?.status)]?.values}`} />
            </Typography>
          ) : (
            "Активность отсутствует"
          )}
        </TableCell>

        <TableCell className={'access'}>
          {user?.abilities[finIndexType(user?.abilities)]?.values?.length ? (
            user?.abilities[finIndexType(user?.abilities)]?.values?.map(value => (
              <li key={value}>
                <FormattedMessage id={`partnership.profileCompany.accesses.${value}`} />
              </li>
            ))
          ) : ('Настройки профиля и доступ отсутствуют')}
        </TableCell>

        <TableCell align="right" className={'gear'}>
          <Button
            disabled={user?.status[finIndexType(user?.status)]?.values === 'registration' || user?.id === userGlob?.id }
            onClick={onShowModalSettingManager.bind(this, user?.id)}
          >
            <Box component="img" src={iconGear}/>
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  const { data: dataInfCompany  } = useActiveLegalEntity();

  const renderShare = share => {
    let val;

    if (share.type === "PERCENT") {
      val = `${share.value}%`;
    } else if (share.type === "DECIMAL") {
      val = share.value;
    } else if (share.type === "FRACTION") {
      val = `${share.numerator}/${share.denominator}`;
    }

    return `(Доля в компании ${val})`
  }

  return (
    <>
      <Box sx={{ background: '#fff' }}>
        <Box>
          <Box sx={styles.titlePage}>
            Профиль компании
          </Box>

          <Tabs onChange={handleChangeTabs} sx={styles.tabs} value={activeTab}>
            <Tab label="Основная информация"  />
            <Tab disabled={!allowManagers} label="Менеджеры" />
          </Tabs>

          {activeTab === 0 && (
            <Box sx={styles.wrapperBox}>
              <Box sx={styles.content}>
                <Box sx={{ ...styles.greenBG, marginBottom: '24px' }}>
                  <Typography sx={ styles.nameCompany}>{dataInfCompany?.dadata?.name?.short_with_opf || "---"}</Typography>

                  <Box sx={ styles.infShortCompany }>
                    <Box>ИНН: <Typography component={'span'}>{dataInfCompany?.dadata?.inn || '---'}</Typography></Box>
                    <Box>КПП: <Typography component={'span'}>{dataInfCompany?.dadata?.kpp || '---'}</Typography></Box>
                    <Box>ОКПО: <Typography component={'span'}>{dataInfCompany?.dadata?.okpo || '---'}</Typography></Box>
                    <Box>ОГРН: <Typography component={'span'}>{dataInfCompany?.dadata?.ogrn || '---'}</Typography></Box>
                  </Box>
                </Box>

                <Box sx={{ ...styles.greenBG, marginBottom: '24px', color: '#3E4B5B', fontSize: '16px', fontWeight: 300, }}>
                  <Typography sx={{ fontWeight: 700, marginBottom: '16px' }}>Руководитель:</Typography>
                  {dataInfCompany?.dadata?.management?.name || '---'}
                </Box>

                <Box sx={{ ...styles.greenBG, marginBottom: '24px', color: '#3E4B5B', fontSize: '16px', fontWeight: 300, }}>
                  <Typography sx={{ fontWeight: 700, marginBottom: '16px' }}>Учредители:</Typography>
                  {dataInfCompany?.dadata?.founders?.length ? (
                    dataInfCompany?.dadata?.founders?.map(founder => {
                      return (
                        <div key={founder?.inn}>
                          {founder.type === "LEGAL" ? (
                            <>
                              {founder?.name} {" "}
                            </>
                          ) : (
                            <>
                              {founder?.fio?.surname && (
                                <>
                                  {founder?.fio?.surname} {" "}
                                </>
                              )}

                              {founder?.fio?.name && (
                                <>
                                  {founder?.fio?.name} {" "}
                                </>
                              )}

                              {founder?.fio?.patronymic && (
                                <>
                                  {founder?.fio?.patronymic} {" "}
                                </>
                              )}
                            </>
                          )}

                          {renderShare(founder.share)}
                        </div>
                      )
                    })
                  ) : (
                    '---'
                  )}
                </Box>

                <Box sx={{ ...styles.greenBG, marginBottom: '24px', color: '#3E4B5B', fontSize: '16px', fontWeight: 300, }}>
                  <Typography sx={{ fontWeight: 700, marginBottom: '16px' }}>Юридический адрес:</Typography>
                  {dataInfCompany?.dadata?.address?.value || "---"}
                </Box>
              </Box>

              {false && (
                <Box sx={styles.infBank}>
                  <Typography sx={ styles.infBankTitle }>Тинькофф банк</Typography>

                  <Box sx={ styles.infBankBlock }>
                    Банк получатель:
                    <Typography>АО “Тинкофф банк”</Typography>
                  </Box>

                  <Box sx={ styles.infBankBlock }>
                    БИК:
                    <Typography>0455678965</Typography>
                  </Box>

                  <Box sx={ styles.infBankBlock }>
                    Кор. счет:
                    <Typography>30101810100000000723</Typography>
                  </Box>

                  <Box sx={ styles.infBankBlock }>
                    Номер счета:
                    <Typography>408 02 810 8 2320 0003298</Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}

          {activeTab === 1 && (
            <Box>
              <Box sx={ styles.wrapperFilters }>
                <Box sx={ styles.filters }>
                  <Box className={ filter === 'all' ? 'active' : null} onClick={() => {setFilter('all')}}>
                    Все
                    <Typography component={'span'}>{users.length}</Typography>
                  </Box>

                  <Box className={ filter === 'active' ? 'active' : null} onClick={() => { setFilter('active')}}>
                    Активные
                    <Typography component={'span'}>{countStatus('active')}</Typography>
                  </Box>

                  <Box className={ filter === 'disabled' ? 'active' : null} onClick={() => {setFilter('disabled')}}>
                    Заблокированы
                    <Typography component={'span'}>{countStatus('disabled')}</Typography>
                  </Box>
                </Box>

                <Button onClick={handleToggleDialog.bind(this, 'showModalManager', true)} sx={styles.buttonOrange}>
                  <Box component="img" src={iconPlus} />
                  Добавить менеджера
                </Button>
              </Box>

              {loadingUsers ? (
                <Box sx={{height: '30vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <CircularProgress />
                </Box>
              ) : (
                users?.length ? (
                  <TableContainer component={Paper} sx={ styles.wrapperTable }>
                    <Table aria-label="simple table" sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow sx={{ '& th': { color: '#9EA5AD' } }}>
                          <TableCell>ФИО</TableCell>
                          <TableCell>Контакты</TableCell>
                          <TableCell align="center">Активность</TableCell>
                          <TableCell>Настройки профиля, доступ</TableCell>
                          <TableCell> </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {filter === 'all' ? (
                          users.map(user => (
                            tableContent(user)
                          ))
                        ) : (
                          users.filter(user => user?.status[finIndexType(user?.status)]?.values === filter).map(el => tableContent(el))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  "На данный момент менеджеров нет."
                )
              )}
            </Box>
          )}
        </Box>

        <FooterInf />
      </Box>

      {showModalManager && (
        <Dialog onClose={handleToggleDialog.bind(this, 'showModalManager', false)} open={true} sx={ styles.modal }>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              xs: 'space-between',
              sm: 'flex-end',
              md: 'flex-end',
              lg: 'flex-end',
            },
            paddingBottom: '11px',
          }}>
            <Typography sx={ styles.modalTitleMob }>Данные менеджера</Typography>

            <IconButton aria-label="close" onClick={handleToggleDialog.bind(this, 'showModalManager', false)}>
              <SvgIcon>
                <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd"/>
              </SvgIcon>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography sx={ styles.modalTitle }>Данные менеджера</Typography>

            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormFieldContainer
                control={control}
                fieldType="text"
                fullWidth
                name="full_name"
                placeholder="ФИО менеджера *"
                register={"full_name"}
                sx={ styles.modalInput }
              />

              <Box sx={{ display: 'flex', gap: '24px' }}>
                <FormFieldContainer
                  InputProps={{ inputComponent: PhoneMaskInput }}
                  control={control}
                  fieldType="text"
                  name="phone"
                  placeholder="Основной телефон *"
                  register={"phone"}
                  sx={{ ...styles.modalInput, width: { xs: '60%', sm: '70%' } }}
                />

                <FormFieldContainer
                  control={control}
                  fieldType="text"
                  name="additional_phone_part"
                  placeholder="Доб. номер"
                  register={"additional_phone_part"}
                  sx={{ ...styles.modalInput, width: { xs: '40%', sm: '30%' } }}
                  type={"number"}
                />
              </Box>

              <FormFieldContainer
                control={control}
                fieldType="text"
                fullWidth
                name="email"
                placeholder="Email *"
                register={"email"}
                sx={ styles.modalInput }
              />

              <Typography sx={ styles.modalDescription }>
                <Box component="img" src={iconInf} />
                Сотруднику на email будет отправлена ссылка регистрации в личном кабинете на сайте Arenza.ru
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '28px' }}>
                <LoadingButton
                  loading={loadingCreateUser}
                  sx={ styles.modalButton }
                  type="submit">
                  Добавить
                </LoadingButton>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {showModalSettingManager && (
        <Dialog onClose={handleToggleDialog.bind(this, 'showModalSettingManager', false)} open={true} sx={styles.modal}>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              xs: 'space-between',
              sm: 'flex-end',
              md: 'flex-end',
              lg: 'flex-end',
            },
            paddingBottom: '11px',
          }}>
            <Typography sx={ styles.modalTitleMob }>Настройки менеджера</Typography>
            <IconButton aria-label="close" onClick={handleToggleDialog.bind(this, 'showModalSettingManager', false)}>
              <SvgIcon>
                <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd"/>
              </SvgIcon>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Typography sx={ styles.modalTitle }>Настройки менеджера</Typography>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                checked={ settingManagerIndex.status === 'active' }
                onChange={onChangeSettingManager.bind(this, 'status')}
              />
              <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>Активен</Typography>
            </Box>

            <Typography sx={{ fontSize: '22px', fontWeight: '700', marginBottom: '24px', marginTop: '35px', color: '#0D1D32', }}>Доступ</Typography>

            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={ settingManagerIndex.access_to_partners_applications } />}
                label={<FormattedMessage id={'partnership.profileCompany.accesses.access_to_partners_applications'} /> }
                onChange={onChangeSettingManager.bind(this, 'access_to_partners_applications')}
                sx={{ '& .MuiFormControlLabel-label': { fontWeight: '400', fontSize: '16px', color: '#3E4B5B' } }}
              />

              <FormControlLabel
                control={<Checkbox checked={ settingManagerIndex.manage_partner_users } />}
                label={<FormattedMessage id={'partnership.profileCompany.accesses.manage_partner_users'} /> }
                onChange={onChangeSettingManager.bind(this, 'manage_partner_users')}
                sx={{ '& .MuiFormControlLabel-label': { fontWeight: '400', fontSize: '16px', color: '#3E4B5B' } }}
              />
            </FormGroup>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '28px', marginTop: '35px' }}>
              <LoadingButton
                loading={loadingUpdateUser}
                onClick={onSaveManager}
                sx={ styles.modalButton }>
                Сохранить
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {showModalManagerComplete && (
        <Dialog onClose={handleToggleDialog.bind(this, 'showModalManagerComplete', false)} open={true} sx={styles.modalComplete}>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              xs: 'space-between',
              sm: 'flex-end',
              md: 'flex-end',
              lg: 'flex-end',
            },
            paddingBottom: '11px',
          }}>
            <IconButton aria-label="close" onClick={handleToggleDialog.bind(this, 'showModalManagerComplete', false)}>
              <SvgIcon>
                <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd"/>
              </SvgIcon>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Box component="img" src={iconCompleteAddManager} sx={ styles.modalCompleteImg } />
            <Typography sx={ styles.modalCompleteTitle }>Менеджер успешно добавлен</Typography>
          </DialogContent>
        </Dialog>
      )}

      {showModalSettingManagerComplete && (
        <Dialog onClose={handleToggleDialog.bind(this, 'showModalSettingManagerComplete', false)} open={true} sx={styles.modalComplete}>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              xs: 'space-between',
              sm: 'flex-end',
              md: 'flex-end',
              lg: 'flex-end',
            },
            paddingBottom: '11px',
          }}>
            <IconButton aria-label="close" onClick={handleToggleDialog.bind(this, 'showModalSettingManagerComplete', false)}>
              <SvgIcon>
                <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd"/>
              </SvgIcon>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Box component="img" src={iconCompleteSettingManager} sx={ styles.modalCompleteImg } />
            <Typography sx={ styles.modalCompleteTitle }>Настройки менеджера сохранены</Typography>
          </DialogContent>
        </Dialog>
      )}

      {showModalError && (
        <Dialog onClose={handleToggleDialog.bind(this, 'showModalError', false)} open={true} sx={styles.modalComplete}>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              xs: 'space-between',
              sm: 'flex-end',
              md: 'flex-end',
              lg: 'flex-end',
            },
            paddingBottom: '11px',
          }}>
            <Typography sx={ styles.modalTitleMob }>Произошла ошибка</Typography>

            <IconButton aria-label="close" onClick={handleToggleDialog.bind(this, 'showModalError', false)}>
              <SvgIcon>
                <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd"/>
              </SvgIcon>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Typography sx={{ ...styles.modalCompleteTitle, marginTop: '33px' }}>{textError}</Typography>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}