import React, { useMemo } from 'react';

import { gql, useMutation } from '@apollo/client';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import useActiveLegalEntity from '@hooks/useActiveLegalEntity';
import { FETCH_LEASING_APPLICATIONS } from '@hooks/useApplicationsFetch';
import { Box, Typography } from '@material-ui/core';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { APPLICATION_FIELDS_CONFIG } from './fields-config';

// CRM-1649 disable subjectClassificator param
const FIELDS_ORDER = [
  // 'subjectClassificator',
  'amount',
  'attachments',
];

const CREATE_REPEAT_LEASING_APPLICATION = gql`
  mutation CreateRepeatLeasingApplication(
    $entityId: Number!
    $amount: String!
    # $subjectClassificatorId: Number
    $attachments: Array
    $formSerializer: any
  ) {
    leasingApplicaiton(
      input: {
        entityId: $entityId
        amount: $amount
        # subjectClassificatorId: $subjectClassificatorId
        attachments: $attachments
      }
    )
      @rest(
        method: "POST"
        type: "LeasingShortApplication"
        path: "/short_apps/repeat"
        bodySerializer: $formSerializer
      ) {
      advanceRate
      amount
      borrowerEntityId
      clientManager
      createdAt
      crmApplicationId
      id
      status
      subject
      subjectClassificatorId
      term
      urgency
    }
  }
`;

// const GET_CLASSIFICATORS = gql`
//   query GetClassificators {
//     classificators {
//       id
//       name
//       parentId
//     }
//   }
// `;

const formSerializer = ({ attachments, ...data }, headers) => {
  const formData = new FormData();
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }

  formData.append('channel', 'customer_account');

  attachments.forEach(file => {
    formData.append('attachments[]', file);
  });

  return { body: formData, headers };
};

export default function FormApplicationRepeat() {
  const { activeLegalEntityId } = useActiveLegalEntity();

  // const getClassificatorsResult = useQuery(GET_CLASSIFICATORS, { fetchPolicy: "cache-only" });
  // const classificators = getClassificatorsResult.data?.classificators;

  const [post, { data, loading }] = useMutation(
    CREATE_REPEAT_LEASING_APPLICATION,
    {
      refetchQueries: [FETCH_LEASING_APPLICATIONS],
      onError: handleError,
    },
  );

  const stepFields = useMemo(() => {
    return FIELDS_ORDER.map(name => {
      // if (name === "subjectClassificator") {
      //   return {
      //     ...APPLICATION_FIELDS_CONFIG[name],
      //     componentProps: {
      //       ...APPLICATION_FIELDS_CONFIG[name].componentProps,
      //       options: Object.values(classificators),
      //     },
      //   };
      // }
      return APPLICATION_FIELDS_CONFIG[name];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schema = useMemo(() => {
    return yup
      .object()
      .shape(stepFields.reduce(
        (prev, curr) => ({ ...prev, [curr.name]: curr.validation }),
        {},
      ));
  }, [stepFields]);

  const formMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      attachments: [],
      // subject_classificator_id: null,
      // term: 12,
      // amount: '',
    },
  });
  const { control, formState, handleSubmit, setError } = formMethods;

  function handleError(error) {
    // FIXME: сервер не возвращает нормальный ошибки.
    // Только 400 в формате { error: 'urgency does not have a valid value' }
    setError('FORM_ERROR', { message: error.networkError?.result?.error });
  }

  async function onSubmit({ amount, attachments, subjectClassificator }) {
    await post({
      variables: {
        entityId: activeLegalEntityId,
        amount,
        // subjectClassificatorId: subjectClassificator?.id,
        attachments,
        formSerializer,
      },
    });
  }

  if (formState.isSubmitSuccessful) {
    return (
      <Box sx={{ pb: 5, pt: 3 }}>
        <Box sx={{ textAlign: "center", mb: 3, fontSize: 48 }}>
          <CheckCircleTwoToneIcon color="primary" fontSize="inherit" />
        </Box>
        <Typography align="center" gutterBottom variant="h3">
          Заявка принята в работу
        </Typography>
        <Typography align="center" color="text.secondary" gutterBottom>
          Номер вашей заявки{' '}
          <Typography color="text.primary" component="b" variant="inherit">
            {data?.leasingApplicaiton.crmApplicationId}
          </Typography>
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        spellCheck="false"
      >
        {stepFields.map(({ FieldComponent, componentProps, name }) => (
          <FieldComponent
            control={control}
            key={name}
            name={name}
            {...componentProps}
          />
        ))}
        <Box mb={4} mt={5}>
          <LoadingButton
            fullWidth
            loading={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            Подать заявку
          </LoadingButton>
        </Box>
      </Box>
      <DevTool control={control} />
    </>
  );
}
