import PropTypes from 'prop-types';
import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from '@material-ui/core';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage, useIntl } from 'react-intl';

import ContainerFileUpload from './ContainerFileUpload';
import DropzoneDocuments from './DropzoneDocuments';
import LayoutFileRow from './LayoutFileRow';

CardDocumentsGroup.propTypes = {
  category: PropTypes.any,
  entityId: PropTypes.any,
  opf: PropTypes.oneOf(['ip', 'company']),
  refetch: PropTypes.func,
  uploadedDocs: PropTypes.array,
};
export default function CardDocumentsGroup({
  category,
  entityId,
  opf,
  refetch,
  uploadedDocs = [],
}) {
  const intl = useIntl();

  const { acceptedFiles, getInputProps, getRootProps } = useDropzone();

  const headerTitle = (
    <Typography variant="h4">
      <FormattedMessage
        defaultMessage={
          intl.formatMessage({ id: `entity.documents.category.${category}` })
        }
        id={`entity.documents.category.${category}.${opf}`}
      />
    </Typography>
  );

  return (
    <Card elevation={5}>
      <CardHeader title={headerTitle} />
      {uploadedDocs.length || acceptedFiles.length ? (
        <CardContent sx={{ pt: 0 }}>
          <Stack spacing={1}>
            {uploadedDocs.map(({ createdAt, filename, id }) => (
              <LayoutFileRow
                createdAt={createdAt}
                entityId={entityId}
                fileId={id}
                filename={filename}
                icon={<CheckRoundedIcon color="success" />}
                key={id}
              />
            ))}
            {acceptedFiles.map((file, index) => (
              <ContainerFileUpload
                {...{ category, entityId, file, refetch }}
                key={index}
              />
            ))}
          </Stack>
        </CardContent>
      ) : null}
      <Divider />
      <DropzoneDocuments
        {...{ getInputProps, getRootProps }}
        title="Загрузить"
      />
    </Card>
  );
}
