import PropTypes from 'prop-types';
import React from "react";

import useActiveLegalEntity from '@hooks/useActiveLegalEntity';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@material-ui/core';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { Link as RouterLink } from 'gatsby';
import { FormattedMessage } from 'react-intl';

import AccountBalanceRoundedIcon from "./img/AccountBalanceRoundedIcon.svg"
import DescriptionOutlinedIcon from "./img/DescriptionOutlinedIcon.svg"
import FormatListNumberedRoundedIcon from "./img/FormatListNumberedRoundedIcon.svg"
import SignatureOfDocuments from "./img/SignatureOfDocuments.svg"

const ACTION_TO_PROPS = {
  uploadBankStatement: {
    to: 'leasing/bank-statements',
    Icon: AccountBalanceRoundedIcon,
  },
  passVerification: {
    to: 'entity/verification',
    Icon: VerifiedUserOutlinedIcon,
  },
  uploadDocuments: {
    to: 'leasing/docs',
    Icon: DescriptionOutlinedIcon,
  },
  fillQuestionnaire: {
    to: 'leasing/questionnaire',
    Icon: FormatListNumberedRoundedIcon,
  },
  documentSignature: {
    to: `/leasing/deals/detail/`,
    Icon: SignatureOfDocuments,
  },
};

export default function WidgetActions({ hideTitle }) {
  const { data, permissions, subscribeDocs } = useActiveLegalEntity();

  const showFillQnrAction = permissions?.canViewQuestionnaire &&
    ['not_exists', 'draft'].includes(data.actionStatuses?.questionnaire);

  const showUploadBankStmntAction = permissions?.canViewBankStatements;
  // ['not_exists'].includes(data.actionStatuses?.bankStatement);

  const showUploadDocsAction = permissions?.canViewDocs;

  return (
    <React.Fragment>
      {!hideTitle && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <Typography variant="h1">Действия</Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: { xs: 'nowrap', md: 'wrap' },
          gap: '24px',
          overflowX: { xs: 'auto', md: 'inherit' },
          py: 2,
        }}
      >
        <CardAction
          action="fillQuestionnaire"
          disabled={!showFillQnrAction}
        />
        <CardAction
          action="uploadBankStatement"
          disabled={!showUploadBankStmntAction}
        />
        {/* TODO: <CardAction action="passVerification" /> */}
        <CardAction
          action="uploadDocuments"
          disabled={!showUploadDocsAction}
        />

        {
          // TODO: ниже Sign
        }
        {false && (
          subscribeDocs?.length > 0 && (
            subscribeDocs.map(id => (
              <CardAction
                action="documentSignature"
                disabled={false}
                entityId={data.id}
                key={id}
                subscribeDocsId={id}
              />
            ))
          )
        )}
      </Box>
    </React.Fragment>
  );
}

WidgetActions.propTypes = {
  hideTitle: PropTypes.bool
}

function CardAction({ action, disabled = true, entityId, subscribeDocsId }) {
  if (!ACTION_TO_PROPS[action]) {
    return null;
  }
  const { Icon, to } = ACTION_TO_PROPS[action];

  return (
    <Card
      elevation={3}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        width: 200,
        height: 120,
        flexShrink: 0,
        borderRadius: '11px',
        boxShadow: {
          xs: 'none',
          md: '0 1px 50px 1px rgba(208, 208, 208, 0.5)',
        },
        border: { xs: '1px solid #e0e0e0', md: '0' },
        ...(disabled ? { filter: 'grayscale(1)' } : {}),
      }}
    >
      <CardActionArea
        component={disabled ? Box : RouterLink}
        disabled={disabled}
        sx={{ height: 1 }}
        to={disabled ? undefined : entityId ? `${to}${entityId}` : to}
      >
        <Box
          sx={{
            position: 'absolute',
            bgcolor: 'primary.main',
            borderTopLeftRadius: '100%',
            right: 0,
            bottom: 0,
            width: 90,
            height: 70,
          }}
        >
          <Box alt={<FormattedMessage id={`action.${action}`} />} component={"img"} src={Icon} sx={{ right: 16, bottom: 12, position: 'absolute' }} />
        </Box>
        <CardContent sx={{ position: 'relative' }}>
          <Typography component="div" variant="subtitle2">
            <FormattedMessage id={`action.${action}`} />
            {subscribeDocsId && (
              <Typography sx={{ color: '#FF780F', fontSize: '14px', fontWeight: '700', display: 'inline' }}>
                {" "}
                №{subscribeDocsId}
              </Typography>
            )}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

CardAction.propTypes = {
  action: PropTypes.string,
  disabled: PropTypes.bool,
  entityId: PropTypes.string,
  subscribeDocsId: PropTypes.string
};