import React from 'react';

import DialogBoxApplicationRepeat from '@components/DialogBoxApplicationRepeat';
import useActiveLegalEntity from '@hooks/useActiveLegalEntity';
import { Typography, Box } from '@material-ui/core';

import ListLeasingApplications from './ListLeasingApplications';

export default function PageLeasingApplications() {
  const { meta } = useActiveLegalEntity();

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
        <Typography noWrap sx={{ mr: 5 }} variant="h1">
          Заявки на лизинг
        </Typography>
        {meta.userConfirmed && <DialogBoxApplicationRepeat />}
      </Box>
      <ListLeasingApplications />
    </Box>
  );
}
