import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import Timer from "@components/Timer";
import { AppDispatchContext, AppStateContext } from "@core/AppContextProvider";
import { formatDate } from "@core/helpers";
import useActiveLegalEntity from "@hooks/useActiveLegalEntity";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Checkbox,
  TextField,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { Link as RouterLink } from "gatsby";
import { findIndex, cloneDeep, sortBy } from 'lodash';
import useFetch from 'use-http';

import Tabs from "../../../../components/Tabs";
import arrow from "./img/arrow.svg";
import download from "./img/download.svg";
import iconModalClose from "./img/modalClose.svg";
import iconSubscribeDoc from "./img/subscribe.svg";
import iconSubscribeComplete from "./img/subscribeComplete.svg";
import iconImgWarning from "./img/warning.svg";

PageSignature.propTypes = {
  dealId: PropTypes.string.isRequired,
};

export default function PageSignature({ dealId }) {
  const { user } = useContext(AppStateContext);
  const dispatch = useContext(AppDispatchContext);

  const styles = useMemo(() => ({
    returnLink: {
      width: '56px',
      height: '56px',
      background: '#FFFFFF',
      border: '1px solid #CFD2D6',
      borderRadius: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    titlePage: {
      fontSize: '24px',
      fontWeight: 700,
      color: '#000000',
    },
    wrapperContent: {
      background: '#FFFFFF',
      boxShadow: '0px 10px 32px rgba(13, 29, 50, 0.08)',
      borderRadius: '16px',
      marginTop: '34px',
    },
    wrapperTable: {
      // marginTop: '24px',
      padding: '32px',
      minHeight: '400px',

      '& .MuiDataGrid-row': {
        '& > div:first-child': {
          paddingLeft: '24px',
        },
        '& > div:last-child': {
          paddingRight: '24px',
        },
      },
      '& .MuiDataGrid-root': {
        background: '#FFFFFF',
        border: '1px solid #E7E8EA',
        borderRadius: '16px',
        overflow: 'hidden',
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none !important'
      },
      '& .MuiDataGrid-menuIcon': {
        display: 'none !important'
      },
      '& .MuiDataGrid-columnHeader': {
        background: '#F5F6F7',
        outline: 'none !important',
        fontSize: '12px',
        fontWeight: '700'
      },
      '& .MuiDataGrid-cell': {
        outline: 'none !important',
        fontSize: '14px',
        fontWeight: '700',
      },
      '& .MuiDataGrid-columnHeadersInner': {
        '& > div .MuiDataGrid-columnHeader': {
          '&:first-child': {
            paddingLeft: '24px',
          },
          '&:last-child': {
            paddingRight: '24px',
          },
        },
      },
    },
    buttonSubscribe: {
      background: '#FF780F',
      borderRadius: '8px',
      color: '#ffffff',
      fontSize: '14px',
      fontWeight: 500,
      textTransform: 'capitalize',

      '& .MuiTouchRipple-root': {
        display: 'none'
      },
      '&:hover': {
        background: '#FF9C14',
        boxShadow: '0px 10px 24px rgba(255, 120, 15, 0.3)',
      },
      '&:active': {
        background: '#CF660F',
        boxShadow: 'none',
      },
      '&:disabled': {
        background: '#CFD2D6',
        color: '#FFFFFF',
      }
    },
    buttonDownload: {
      display: 'flex',
      color: '#6E7884',
      fontSize: '14px',
      textDecoration: 'none',
      textTransform: 'capitalize',
      fontWeight: '700',
    },

  }), []);

  const activeLegalEntity = useActiveLegalEntity();

  const stylesTabs = useMemo(() => ({
    wrapperTabs: {
      borderBottom: '1px solid #E7E8EA',

      '& .MuiTabs-scroller': {
        overflow: 'auto !important',
      },
    },
  }), []);

  const [rowsAwaitingSigning, setRowsAwaitingSigning] = useState([]);
  const [rowsSubscribe, setRowsSubscribe] = useState([]);

  const { post, response } = useFetch();
  const { get: downloadAttachment, loading: loadingDownloadAttachment, response: responseDownloadAttachment} = useFetch();
  const { data, get: getDocs, loading: loadingDocs, response: responseDocs } = useFetch();

  useEffect(() => {
    getDocs(`/entities/${dealId}/contracts`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [erorrs, setErorrs] = useState({});

  useEffect(() => {
    if (responseDocs.ok) {
      let subscribeDoc = [],
        awaitingSigningDoc = [];

      if (data.length) {
        awaitingSigningDoc = data.filter(doc => { return doc.status === 'awaiting_signing' });
        subscribeDoc = data.filter(doc => { return doc.status === 'signed' });
      }

      setRowsAwaitingSigning(awaitingSigningDoc);
      setRowsSubscribe(subscribeDoc);

      setErorrs({});
    } else if (!responseDocs.ok) {
      setErorrs({
        status: responseDocs?.status,
        error: data?.error,
      })
    }
  }, [responseDocs, data])

  const [activeTab, setActiveTab] = useState(0)
  const [showTreaty, setShowTreaty] = useState(false);
  const [subscribeContractId, setSubscribeContract] = useState(null);

  const [attachment, setAttachment] = useState(null);
  const toggleShowTreaty = useCallback( async (id = false, idDoc = false) => {
    if (id && Number.isInteger(id)) {
      setSubscribeContract(id);
    }

    setChecked(false);

    if (idDoc && Number.isInteger(idDoc)) {
      await downloadAttachment(`/entities/${dealId}/contracts/${idDoc}/actual_attachment`);

      if (responseDownloadAttachment.ok) {
        setAttachment(`data:application/pdf;base64,${responseDownloadAttachment.data.actual_attachment}`);
        setShowTreaty(!showTreaty);
      } else {
        toggleModalErrors();
        setTextError(`Код ошибки: ${response.status} ${response.data.error}`);
      }
    } else {
      setShowTreaty(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseDownloadAttachment, downloadAttachment, dealId]);

  const [timer, setTimer] = useState(60);

  const [showModalSMS, setShowModalSMS] = useState(false);

  const onSubscribeContract = useCallback(async () => {
    if (subscribeContractId) {
      await post(`/entities/${dealId}/contracts/${subscribeContractId}/send_otp_code`);

      if (response.ok) {
        toggleShowModalSMS();
      } else {
        toggleShowTreaty();
        toggleModalErrors();
        setTextError(`Код ошибки: ${response.status} ${response.data.error}`);
      }
    } else {
      toggleModalErrors();
      setTextError(`Произошла потеря номера договора`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, post, subscribeContractId]);

  const onSendCodeSMS = useCallback(async (code) => {
    if (subscribeContractId) {
      await post(`/entities/${dealId}/contracts/${subscribeContractId}/sign`, {
        "otp_code": code.toString(),
      })

      setShowModalSMS(false);

      if (response.ok) {
        toggleModalSubscribeComplete();

        passiveUpdateRows(response.data.id, response.data.status);
      } else {
        toggleModalErrors();
        setTextError(`Код ошибки: ${response.status} ${response.data.error}`);
      }
    } else {
      toggleModalErrors();
      setTextError(`Произошла потеря номера договора`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, post, subscribeContractId]);

  const passiveUpdateRows = (id, status) => {
    const indexRow = findIndex(rowsAwaitingSigning, ["id", id]);

    const cloneRowsAwaitingSigning = cloneDeep(rowsAwaitingSigning);
    const cloneRowsSubscribe = cloneDeep(rowsSubscribe);

    const row = cloneRowsAwaitingSigning.splice(indexRow, 1);
    row[0].status = status;

    cloneRowsSubscribe.push(...row);

    setRowsAwaitingSigning(cloneRowsAwaitingSigning);
    setRowsSubscribe(sortBy(cloneRowsSubscribe, 'id'));

    dispatch({ type: 'SET_SUBSCRIBE_DOCS', payload: cloneRowsAwaitingSigning })
  }

  const toggleShowModalSMS = () => {
    setShowTreaty(false);
    setShowModalSMS(!showModalSMS);
    setTimer(60);
  }

  const [checked, setChecked] = useState(false);
  const toggleChecked = () => {
    setChecked(!checked);
  }

  const [code, setCode] = useState();
  const onChangeCode = event => {
    setCode(event.target.value);

    if (event.target.value.length === 4) {
      onSendCodeSMS(event.target.value)
    }
  }

  const [showModalSubscribeComplete, setShowModalSubscribeComplete] = useState(false);
  const toggleModalSubscribeComplete = () => {
    setShowModalSubscribeComplete(!showModalSubscribeComplete)
    setCode();
  }

  const [showModalErrors, setShowModalErrors] = useState(false);
  const [textError, setTextError] = useState(false);
  const toggleModalErrors = () => {
    setShowModalErrors(!showModalErrors);

    if (!showModalErrors) {
      setTextError(null);
    }
  }

  const onDownloadAttachment = useCallback( async (id, name) => {
    await downloadAttachment(`/entities/${dealId}/contracts/${id}/actual_attachment`);

    if (responseDownloadAttachment.ok) {
      const linkSource = `data:application/pdf;base64,${responseDownloadAttachment.data.actual_attachment}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = `${name}.pdf`;
      downloadLink.click();
    } else {

    }
  }, [responseDownloadAttachment, downloadAttachment, dealId]);

  const columns = [
    {
      field: 'title',
      headerName: 'Наименование документа',
      sortable: false,
      width: 724,
    },
    {
      field: 'date',
      headerName: 'Дата загрузки',
      sortable: false,
      width: 140,
      align: 'left',
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <Typography sx={{ fontSize: '14px', fontWeight: 300, color: '#0D1D32' }}>
          {params.row?.actual_attachment?.created_at ? (
            formatDate(params.row.actual_attachment.created_at, "short_date_time")
          ) : '---'}
        </Typography>
      ),
    },
    {
      field: 'download',
      headerName: '',
      description: '',
      sortable: false,
      width: 160,
      align: 'center',
      // eslint-disable-next-line react/display-name
      renderCell: (params) => {
        if (!activeLegalEntity?.permissions?.canElectronicSignatureSubscribeDocs) return false;

        let disabledLink = true;

        if (params.row.status === "signed") {
          disabledLink = false;
        }

        return (
          <Button disabled={disabledLink || loadingDownloadAttachment} onClick={onDownloadAttachment.bind(this, params.row.id, params.row.title)} sx={styles.buttonDownload}>
            <Box alt={"Скачать"} component={"img"} src={download} sx={{ marginRight: "8px" }} />
            Скачать
          </Button>
        );
      },
    },
    {
      field: 'subscribe',
      headerName: '',
      description: '',
      sortable: false,
      align: 'right',
      width: 210,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => {
        let title, disabled;

        if (params.row.status === "awaiting_signing") {
          if (!activeLegalEntity?.permissions?.canElectronicSignatureSubscribeDocs) {
            title = "Требуется подпись уполномоченного лица.";
            disabled = true;
          } else {
            title = "Документ ожидает подписания с Вашей стороны.";
            disabled = false;
          }

          return (
            <Tooltip arrow title={title}>
              <span>
                <Button disabled={disabled} onClick={toggleShowTreaty.bind(this, params.row.id, params.row.id)} sx={styles.buttonSubscribe}>
                  Подписать
                </Button>
              </span>
            </Tooltip>
          )
        } else if (params.row.status === "signed") {
          return (
            <Box sx={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
              <Box alt={"Документ подписан."} component={"img"} src={iconSubscribeDoc} />

              <Typography sx={{ color: '#67BF3D', fontSize: '14px', fontWeight: '700' }}>Документ подписан</Typography>
            </Box>
          )
        }
      },
    },
  ];

  const onChangeTab = event => {
    setActiveTab(event);
  };

  const reRequestSMS = useCallback(async () => {
    if (subscribeContractId) {
      await post(`/entities/${dealId}/contracts/${subscribeContractId}/send_otp_code`);

      if (response.ok) {
        setTimer(60);
      } else {
        toggleShowTreaty();
        toggleModalErrors();
        setTextError(`Код ошибки: ${response.status} ${response.data.error}`);
      }
    } else {
      toggleModalErrors();
      setTextError(`Произошла потеря номера договора`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, post, subscribeContractId]);

  const renderPhone = phone => {
    return `+${phone[1]} (${phone[2]}${phone[3]}${phone[4]}) ***-**-${phone[10]}${phone[11]}`
  }

  const renderContent = (arr, textPromt) => {
    let preparingContent = [];

    arr?.forEach(el => {
      if (findIndex(preparingContent, ['application_id', el.application_id]) !== -1) {
        preparingContent[findIndex(preparingContent, ['application_id', el.application_id])].content.push(el)
      } else {
        preparingContent.push({
          application_id: el.application_id,
          content: [el],
        })
      }
    })

    return (
      preparingContent?.length > 0 ? (
        preparingContent.map(elem => (
          <Box key={elem.application_id}>
            <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>Заявка №{elem.application_id}</Typography>

            <Box sx={{ marginLeft: '20px', marginTop: '20px', marginBottom: '40px' }}>
              <DataGrid
                autoHeight={true}
                checkboxSelection={false}
                columns={columns}
                hideFooter={true}
                pagination={false}
                rowHeight={86}
                rows={elem.content}
              />
            </Box>
          </Box>
        ))
      ) : (
        <Box sx={{ background: 'linear-gradient(0deg, rgba(255, 120, 15, 0.1), rgba(255, 120, 15, 0.1)), #FFFFFF', border: '1px solid #FF780F', borderRadius: '8px', height: '99px', display: 'flex', alignItems: 'center', padding: '24px', }}>
          <Box alt={"Подписанных документов нет."} component={"img"} src={iconImgWarning} sx={{ marginRight: '24px' }} />
          <Typography sx={{ fontSize: '18px', fontWeight: 700, color: '#FF780F' }}>
            {textPromt}
          </Typography>
        </Box>
      )
    )
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', }}>
        <RouterLink
          style={ styles.returnLink }
          to={"/"}
        >
          <Box alt={"Вернуться на страницу Лизинговые сделки"} component={"img"} src={arrow} />
        </RouterLink>

        <Typography sx={styles.titlePage} variant="h1">
          Документы на подписание
        </Typography>
      </Box>

      <Box sx={styles.wrapperContent}>
        <Tabs
          defaultActiveTab={activeTab}
          onChange={onChangeTab}
          sx={stylesTabs}
          tabs={[
            'Документы на подписание',
            'Подписанные документы',
          ]}
        />

        <Box sx={ styles.wrapperTable }>
          {loadingDocs && (
            <Box sx={{ display: 'flex', width: '100%', minHeight: '400px', alignItems: 'center', justifyContent: 'center'}}>
              <CircularProgress />
            </Box>
          )}

          {activeTab === 0 && (
            !loadingDocs && (
              Object.keys(erorrs).length > 0 && erorrs?.status ? (
                `При загрузке произошла ошибка. ${erorrs.status} ${erorrs.error}`
              ) : (
                renderContent(rowsAwaitingSigning, 'Документов на подписание нет!')
              )
            )
          )}

          {activeTab === 1 && (
            !loadingDocs && (
              Object.keys(erorrs).length > 0 && erorrs?.status ? (
                `При загрузке произошла ошибка. ${erorrs.status} ${erorrs.error}`
              ) : (
                renderContent(rowsSubscribe, 'Подписанных документов нет!')
              )
            )
          )}
        </Box>
      </Box>

      {showTreaty && (
        <Dialog onClose={toggleShowTreaty} open={true} sx={{ '& .MuiPaper-root': { width: '100%' } }}>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Typography sx={{ fontSize: '25px', fontWeight: 700 }}>
              {/* eslint-disable-next-line array-callback-return */}
              {rowsAwaitingSigning.map(doc => { if (doc?.id === subscribeContractId) return doc.title})}
            </Typography>
            <IconButton aria-label="close" onClick={toggleShowTreaty} sx={{ marginLeft: '10px' }}>
              <Box alt={"Закрыть"} component={"img"} src={iconModalClose} />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Box sx={{ background: '#E7E8EA', width: '100%', height: '442px' }}>
              <iframe height="100%" src={attachment} title={"Просмотр документа"} width="100%"></iframe>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '45px' }}>
              <Box onClick={toggleChecked} sx={{ cursor: 'pointer' }}>
                <Checkbox checked={checked} />
                С документом ознакомлен
              </Box>

              <Button disabled={!checked} onClick={onSubscribeContract} sx={ styles.buttonSubscribe }>Подписать</Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {showModalSMS && (
        <Dialog onClose={toggleShowModalSMS} open={true} sx={{ '& .MuiDialog-paper': { width: "596px", height: "343px" } }}>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
            <IconButton aria-label="close" onClick={toggleShowModalSMS}>
              <Box alt={"Закрыть"} component={"img"} src={iconModalClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ fontSize: '32px', fontWeight: 700, textAlign: 'center' }}>Код из СМС</Typography>
            <Typography sx={{ color: '#6E7884', fontSize: '16px', fontWeight: 500, marginTop: '10px', textAlign: 'center' }}>
              Мы отправили СМС с кодом на номер {renderPhone(user.phone)}
            </Typography>

            <Box
              sx={{
                textAlign: 'center',
                marginTop: '24px',

                '& .MuiFormControl-root': {
                  marginTop: '0',
                  marginBottom: '0',
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: "#6E7884 !important",
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: "#6E7884 !important",
                }
              }}
            >
              <TextField id="outlined-basic" label="Код из СМС" onChange={onChangeCode} placeholder={"****"} value={code} variant="outlined" />
            </Box>

            <Box sx={{ height: '20px', marginTop: '24px' }}>
              {timer > 0 && (
                <Typography sx={{ color: '#B6BBC2', fontSize: '14px', fontWeight: 500, textAlign: 'center' }}>
                  Запросить код повторно можно через {<Timer onTimeout={setTimer} timeout={timer} />} секунд
                </Typography>
              )}
            </Box>

            <Box sx={{ textAlign: 'center', marginTop: '10px', }}>
              <Button disabled={timer > 0} onClick={reRequestSMS} sx={{ textDecoration: 'none', fontSize: '14px', textTransform: 'initial', fontWeight: 500, '&:hover': { textDecoration: 'underline' } }}>
                Не приходит СМС
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {showModalSubscribeComplete && (
        <Dialog onClose={toggleModalSubscribeComplete} open={true} sx={{ '& .MuiDialog-paper': { width: "596px", height: "343px" } }}>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
            <IconButton aria-label="close" onClick={toggleModalSubscribeComplete}>
              <Box alt={"Закрыть"} component={"img"} src={iconModalClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box alt={"Документ успешно подписан!"} component={"img"} src={iconSubscribeComplete} sx={{ margin: "22px auto 23px" }} />

            <Typography sx={{ textAlign: 'center', fontSize: '26px', fontWeight: 700 }}>Документ успешно подписан!</Typography>

            <Box sx={{ textAlign: 'center', marginTop: '16px' }}>
              <RouterLink to={"/"}>
                Вернуться в личный кабинет
              </RouterLink>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {showModalErrors && (
        <Dialog onClose={toggleModalErrors} open={true} sx={{ '& .MuiDialog-paper': { width: "596px", height: "343px" } }}>
          <DialogTitle sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
            <IconButton aria-label="close" onClick={toggleModalErrors}>
              <Box alt={"Закрыть"} component={"img"} src={iconModalClose} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ textAlign: 'center', fontSize: '26px', fontWeight: 700 }}>Произошла ошибка. Попробуйте позже.</Typography>

            {textError && (
              <Box sx={{ textAlign: 'center', marginTop: '16px' }}>
                {textError}
              </Box>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}