import React, { useMemo, useEffect, useState } from 'react';

import { Box, Link } from '@material-ui/core';
import useFetch from "use-http";

import useActiveLegalEntity from "../../hooks/useActiveLegalEntity";

export default function EdoBanner() {
    const styles = useMemo(() => ({
        bannerWrapper: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '24px',
            gap: '24px',
            background: '#21A2FF14',
            marginBottom: '24px',
            borderRadius: '16px',
            fontSize: '16px'
        },
        subText: {
            fontSize: '14px',
            marginTop: '8px',
            color: '#a2a2a2'
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const [entityService, setEntityService] = useState(false);

    const { data } = useActiveLegalEntity();
    const getEntity = useFetch(`/entities/${data.id}`);

    async function getEntityData() {
        const entityData = await getEntity.get();

        if (getEntity.response.ok) {
            if (entityData?.services?.auto_send_edo_closing_document_service) {
                setEntityService(entityData?.services?.auto_send_edo_closing_document_service);
            }
        } else {
            console.error('Ошибка запроса entity service');
        }
    };

    useEffect(() => {
        getEntityData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!entityService) {
        return (
            <Box sx={styles.bannerWrapper}>
                <svg
                    fill="none"
                    height="48"
                    viewBox="0 0 48 48"
                    width="48"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M22 16H26V26H22V16Z" fill="#21A2FF" />
                    <path d="M20 18.8359C16.4682 20.3791 14 23.9032 14 28.0039C14 33.5267 18.4772 38.0039 24 38.0039C29.5228 38.0039 34 33.5267 34 28.0039C34 23.9032 31.5318 20.3791 28 18.8359V23.5316C29.2275 24.6303 30 26.2269 30 28.0039C30 31.3176 27.3137 34.0039 24 34.0039C20.6863 34.0039 18 31.3176 18 28.0039C18 26.2269 18.7725 24.6303 20 23.5316V18.8359Z" fill="#21A2FF" />
                    <path
                        clipRule="evenodd"
                        d="M24.9669 2.00016C27.0043 1.99862 28.5511 1.99745 29.9819 2.56632C31.4127 3.13519 32.5364 4.19805 34.0166 5.5981L37.8701 9.23745C39.4726 10.7485 40.6898 11.8962 41.3466 13.4199C42.0034 14.9436 42.002 16.6165 42.0002 18.8191L42 32.1415C42.0001 34.8497 42.0001 37.0829 41.7625 38.8504C41.513 40.7061 40.9691 42.345 39.6569 43.6572C38.3447 44.9694 36.7058 45.5133 34.8501 45.7628C33.0826 46.0005 30.8494 46.0004 28.1411 46.0003H19.8589C17.1507 46.0004 14.9174 46.0005 13.1499 45.7628C11.2942 45.5133 9.65537 44.9694 8.34316 43.6572C7.03094 42.345 6.487 40.7061 6.23751 38.8504C5.99988 37.083 5.99994 34.8497 6 32.1415V15.8591C5.99994 13.1509 5.99988 10.9177 6.23751 9.15019C6.487 7.29453 7.03094 5.65568 8.34316 4.34347C9.65537 3.03125 11.2942 2.48731 13.1499 2.23783C14.9174 2.00019 17.1506 2.00025 19.8589 2.00032L24.9669 2.00016ZM28.5041 6.28332C27.8749 6.03316 27.1532 6.00032 24.6408 6.00032H20C17.115 6.00032 15.1525 6.00457 13.6829 6.20216C12.2708 6.39201 11.6167 6.72675 11.1716 7.17189C10.7264 7.61704 10.3917 8.27108 10.2018 9.68319C10.0043 11.1528 10 13.1154 10 16.0003V32.0003C10 34.8853 10.0043 36.8478 10.2018 38.3175C10.3917 39.7296 10.7264 40.3836 11.1716 40.8287C11.6167 41.2739 12.2708 41.6086 13.6829 41.7985C15.1525 41.9961 17.115 42.0003 20 42.0003H28C30.885 42.0003 32.8475 41.9961 34.3171 41.7985C35.7293 41.6086 36.3833 41.2739 36.8284 40.8287C37.2736 40.3836 37.6083 39.7296 37.7982 38.3175C37.9958 36.8478 38 34.8853 38 32.0003V19.1729C38 16.4529 37.962 15.6729 37.6733 15.0032C37.3846 14.3336 36.8437 13.7704 34.8662 11.9027L31.5071 8.73019C29.6805 7.00513 29.1333 6.53347 28.5041 6.28332Z"
                        fill="#21A2FF"
                        fillRule="evenodd"
                    />
                </svg>
                <Box>
                    <span>Для подключения автоматической отправки закрывающих документов в ЭДО перейдите в раздел <Link href="/entity">Моя компания</Link></span>
                    <Box sx={styles.subText}>*настройка отправки закрывающих документов доступна только <u>подтвержденным</u> пользователям личного кабинета. Для подтверждения обратитесь к вашему персональному менеджеру.</Box>
                </Box>
            </Box>
        );
    }

    return null;
}