import React, { useCallback, useContext, useMemo, useState } from 'react';

import { AppStateContext } from '@core/AppContextProvider';
import useActiveLegalEntity from '@hooks/useActiveLegalEntity';
import { Button, Typography } from '@material-ui/core';
import {
  ApplicationContext,
  DialogApplicationCreate,
} from '@product-site-frontend/shared';
import { formatName } from '@utils/format';

import FormApplication from '../FormApplication';

export default function DialogBoxApplication() {
  const {
    activeLegalEntityId,
    data: activeLegalEntityData,
  } = useActiveLegalEntity();
  const { user } = useContext(AppStateContext);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = useCallback(() => setDialogOpen(true), []);
  const handleCloseDialog = useCallback(() => setDialogOpen(false), []);

  const handleSuccess = useCallback(() => window.location.reload(), []);

  const contextValue = useMemo(() => ({
    data: {
      amount: '',
      applicationOpen: dialogOpen,
      inn: activeLegalEntityData?.inn || '',
      phone: user?.phone || '',
      full_name: formatName(user?.firstName, user?.lastName, user?.middleName),
      email: user?.email || '',
    },
    setApplicationOpen: setDialogOpen,
  }), [dialogOpen, activeLegalEntityData, user]);

  return (
    <ApplicationContext.Provider value={contextValue}>
      <Button
        disableElevation
        onClick={handleOpenDialog}
        sx={{
          backgroundImage: 'radial-gradient(circle at 44% 50%, #ff720a, #ffb63d 197%)',
          borderRadius: '24px',
          py: 1,
          textTransform: 'none',
        }}
        variant="contained"
      >
        <Typography noWrap variant="inherit">
          + Создать новую заявку
        </Typography>
      </Button>
      <DialogApplicationCreate
        content={(
          <FormApplication
            entityId={activeLegalEntityId}
            onSuccess={handleSuccess}
            url={process.env.GATSBY_FRONTOFFICE_API}
          />
        )}
        keepMounted={false}
        maxWidth="sm"
        onClose={handleCloseDialog}
        open={dialogOpen}
        title="Заявка на лизинг"
      />
    </ApplicationContext.Provider>
  );
}
