import PropTypes from "prop-types";
import React from "react";

import { Box } from "@material-ui/core";
import { FormattedNumberParts } from "react-intl";

CurrencyFormatter.propTypes = {
  sx: PropTypes.object,
  truncate: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default function CurrencyFormatter({ sx,  truncate, value }) {
  if (typeof value === "undefined" || value === null || isNaN(value)) {
    return null;
  }

  const props = {
    currency: "RUB",
    currencyDisplay: "symbol",
    style: "currency",
  };

  return (
    <FormattedNumberParts value={value} {...props}>
      {parts => {
        return (
          <Box sx={{ display: "inline-block", whiteSpace: "nowrap", ...sx }}>
            {parts
              .slice(0, -4)
              .map(({ value }) => value)
              .join("")}
            {truncate ? null : (
              <Box sx={{ display: "inline-block", /* opacity: 0.6 */ }}>
                {parts
                  .slice(-4, -2)
                  .map(({ value }) => value)
                  .join("")}
              </Box>
            )}
            {/* <Box sx={{ display: "inline-block", opacity: 0.8 }}>
              {parts
                .slice(-2)
                .map(({ value }) => value)
                .join("")}
            </Box> */}
          </Box>
        );
      }}
    </FormattedNumberParts>
  );
}

// import PropTypes from "prop-types";
// import React from "react";

// import { FormattedNumber } from "react-intl";

// export default function FormattedCurrency({
//   currency,
//   currencyDisplay,
//   hideCurrency,
//   placeholder = null,
//   value,
//   ...rest
// }) {
//   if (typeof value === "undefined" || value === null || isNaN(value)) {
//     return placeholder;
//   }

//   const props = hideCurrency
//     ? {
//         style: "decimal",
//         minimumFractionDigits: 2,
//       }
//     : {
//         style: "currency",
//         currencyDisplay,
//       };

//   return <FormattedNumber currency={currency} value={value} {...props} {...rest} />;
// }

// FormattedNumber.defaultProps = {
//   currency: "RUB",
//   currencyDisplay: "symbol",
// };

// FormattedCurrency.propTypes = {
//   currency: PropTypes.string,
//   currencyDisplay: PropTypes.string,
//   hideCurrency: PropTypes.bool,
//   placeholder: PropTypes.node,
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
// };
