import React from 'react';

import ChangeChargeNotice from '@components/ChangeChargesNotice';
import EdoBanner from '@components/EdoBanner';
import AccessControl from '@core/AccessControl';
import { Box } from '@material-ui/core';

import WidgetActions from './WidgetActions';
import WidgetApplications from './WidgetApplications';
import WidgetLeasingDeals from './WidgetLeasingDeals';

export default function PageLeasingDashboard() {
  return (
    <>
      <EdoBanner />
      <ChangeChargeNotice />

      <Box sx={{ mb: { xs: 5, md: 6 } }}>
        <WidgetActions />
      </Box>
      <Box sx={{ mb: { xs: 5, md: 6 } }}>
        <WidgetApplications />
      </Box>
      <AccessControl>
        <WidgetLeasingDeals />
      </AccessControl>
    </>
  );
}
