import useActiveLegalEntity from "../hooks/useActiveLegalEntity";

export default function AccessControl({ children }) {
  const { meta } = useActiveLegalEntity();

  if (!meta.userConfirmed) {
    return null;
  }

  return children;
}
