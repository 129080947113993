import { useContext } from 'react';

import { gql, useQuery } from '@apollo/client';
import { AppDispatchContext } from '@core/AppContextProvider';

import useActiveLegalEntity from './useActiveLegalEntity';

export const FETCH_LEASING_APPLICATIONS = gql`
  query FetchLeasingApplications($entityId: Number!, $dealId: Number!) {
    leasingApplications(entityId: $entityId, dealId: $dealId)
      @rest(type: "LeasingApplication", path: "/entities/{args.entityId}/applications") {
      advanceRate
      amount
      borrowerEntityId
      clientManager
      createdAt
      id
      status
      subject
      subjectClassificatorId
      term
      urgency
    }
  }
`;

export default function useApplicationsFetch() {
  const dispatch = useContext(AppDispatchContext);
  const { activeLegalEntityId } = useActiveLegalEntity();

  const { data, error, loading } = useQuery(FETCH_LEASING_APPLICATIONS, {
    variables: { entityId: activeLegalEntityId },
    fetchPolicy: 'cache-and-network',
    onCompleted: handleCompleted,
  });

  function handleCompleted({ leasingApplications }) {
    const appWithClientManager = leasingApplications
      .find(({ clientManager }) => clientManager);

    if (appWithClientManager) {
      dispatch({
        type: 'SET_LEASING_MANAGER',
        payload: appWithClientManager.clientManager,
      });
    }
  }

  return { applications: data?.leasingApplications, loading, error };
}