import {
  EMAIL_VALIDATION,
  FIO_VALIDATION,
  PHONE_VALIDATION,
} from '@product-site-frontend/account/src/components/FormEntityQuestionnaire/constants/validations';
import {
  AmountMaskInput,
  FormFieldContainer,
  PhoneMaskInput,
} from '@product-site-frontend/shared';
import * as yup from 'yup';

export const APPLICATION_FIELDS_CONFIG = {
  full_name: {
    name: 'full_name',
    validation: FIO_VALIDATION.required(
      'Укажите фамилию, имя и отчество через пробел',
    ),
    defaultValue: '',
    FieldComponent: FormFieldContainer,
    componentProps: {
      fieldType: 'nameAutocomplete',
      placeholder: 'Фёдоров Михаил Сергеевич',
      label: 'Фамилия Имя Отчество',
    },
  },
  inn: {
    name: 'inn',
    validation: yup
      .string()
      .nullable()
      .required('Укажите организацию или ИП'),
    FieldComponent: FormFieldContainer,
    componentProps: {
      label: 'Название компании, ИП или ИНН',
      fieldType: 'legalEntityAutocomplete',
    },
  },
  phone: {
    name: 'phone',
    validation: PHONE_VALIDATION.required('Укажите номер телефона'),
    defaultValue: '',
    FieldComponent: FormFieldContainer,
    componentProps: {
      label: 'Мобильный телефон',
      InputProps: {
        inputComponent: PhoneMaskInput,
      },
      fieldType: 'text',
      placeholder: '+7 (***) ***-**-**',
      type: 'tel',
    },
  },
  email: {
    name: 'email',
    validation: EMAIL_VALIDATION.required('Укажите адрес электронной почты'),
    defaultValue: '',
    FieldComponent: FormFieldContainer,
    componentProps: {
      fieldType: 'text',
      name: 'email',
      placeholder: 'fedorov@example.ru',
      label: 'Электронная почта',
    },
  },
  amount: {
    name: 'amount',
    validation: yup.string().required('Укажите стоимость оборудования'),
    defaultValue: '',
    FieldComponent: FormFieldContainer,
    componentProps: {
      InputProps: {
        inputComponent: AmountMaskInput,
      },
      label: 'Стоимость оборудования',
      fieldType: 'text',
      placeholder: '500 000 руб.',
      inputProps: {
        inputMode: 'numeric',
        pattern: '[0-9]*',
      },
    },
  },
  attachments: {
    name: 'attachments',
    defaultValue: [],
    FieldComponent: FormFieldContainer,
    componentProps: {
      label: 'Коммерческое предложение',
      fieldType: 'file',
    },
  },
};