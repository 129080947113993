import React from "react";

import { AzHeader, AzLogo } from "@Development/ui-kit";
import { Box } from "@material-ui/core";
import { Router } from "@reach/router";

import NavPrimary from "./NavPrimary";
import NavSecondary from "./NavSecondary";

export default function Appbar() {
  const renderIndexLink = () => {
    let link = null;

    if (window.location.pathname.includes('/partnership')) {
      link = '/partnership';
    } else {
      link = '/';
    }

    return link
  }

  return (
    <AzHeader
      autoHeight={true}
      button={(<Box
        component={Router}
        primary={false}
        sx={{ display: "flex", flex: 1, minWidth: 0, justifyContent: "flex-end" }}
      >
        <NavPrimary path=":primary/*" />
        <NavPrimary default />
      </Box>)}
      fixedWidth={true}
      hideCallback={true}
      href={renderIndexLink()}
      logo={(<AzLogo svg={{ width: 125, height: 36 }} type="basic-new" />)}
      notFixed={true}
      secondLine={(
        <Box component={Router} primary={false} sx={{ maxWidth: 1, marginBottom: '-5px' }}>
          <NavSecondary path=":primary/:secondary/:tertiary/*" />
          <NavSecondary path=":primary/:secondary/*" />
          <NavSecondary path=":primary/*" />
          <NavSecondary default />
        </Box>
      )}
      showDivider={true}
    >
      <div />
    </AzHeader>
  );
}
