import PropTypes from 'prop-types';
import React from 'react';

import { Box, Stack, Typography } from '@material-ui/core';
import FileUploadRoundedIcon from '@material-ui/icons/FileUploadRounded';
import 'react-dropzone-uploader/dist/styles.css';

DropzoneDocuments.propTypes = {
  getInputProps: PropTypes.func,
  getRootProps: PropTypes.func,
  title: PropTypes.node,
};
export default function DropzoneDocuments({ getInputProps, getRootProps, title }) {
  return (
    <Box
      {...getRootProps({ className: "dropzone" })}
      sx={{
        px: 4,
        py: 3,
        backgroundColor: "#fafafa",
        cursor: "pointer",
        outline: "none",
        transition: "border .24s ease-in-out",
      }}
    >
      <input {...getInputProps()} />
      <Stack direction="row" spacing={3}>
        <FileUploadRoundedIcon color="action" />
        <Typography color="text.secondary" variant="body1">
          {title}
        </Typography>
      </Stack>
    </Box>
  );
}
