import PropTypes from 'prop-types';
import React, { useCallback, useReducer } from 'react';

import { gql, useQuery } from '@apollo/client';
import { arenzaStorage, AUTH_TOKEN_PARTNER_STORAGE_KEY, AUTH_TOKEN_STORAGE_KEY } from "@core/storage";
import { LinearProgress } from '@material-ui/core';
import * as Sentry from "@sentry/react";
import { find } from 'lodash';

import BlockAddEntity from '../components/BlockAddEntity';
import { initialState, reducer } from './reducer';

export const AppStateContext = React.createContext();
export const AppDispatchContext = React.createContext();

AppContextProvider.propTypes = {
  children: PropTypes.node,
};
export default function AppContextProvider({ children }) {
  let FETCH_INITIAL_DATA;

  if (window?.location?.pathname?.includes('/partnership')) {
    FETCH_INITIAL_DATA = gql`
      query FetchInitialData {
        me @rest(type: "User", path: "/partners/user_profile") {
          confirmedEntitiesIds
          dateOfBirth
          email
          firstName
          id
          lastName
          lastVerification
          middleName
          phone
          roles
          status
          abilities
        }
        entities @rest(type: "Entity", path: "/entities") {
          id
          inn
          legalName
          shortLegalName
        }
        classificators @rest(type: "Classificator", path: "/dictionaries/subject_classificators") {
          id
          name
          parentId
        }
      }
    `;
  } else {
    FETCH_INITIAL_DATA = gql`
      query FetchInitialData {
        me @rest(type: "User", path: "/me") {
          confirmedEntitiesIds
          dateOfBirth
          email
          firstName
          id
          lastName
          lastVerification
          middleName
          phone
          abilities
          roles
          hasPersonalElectronicSignature
        }
        entities @rest(type: "Entity", path: "/entities") {
          id
          inn
          legalName
          shortLegalName
        }
        classificators @rest(type: "Classificator", path: "/dictionaries/subject_classificators") {
          id
          name
          parentId
        }
      }
    `;
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleCompleted = useCallback((data) => {
    if (find(data?.me?.status, (u) => u.values === 'disabled') && data?.entities?.length === 1) {
      arenzaStorage.remove(AUTH_TOKEN_PARTNER_STORAGE_KEY);

      return window.location.reload();
    }

    dispatch({ type: 'SET_USER', payload: data.me });

    dispatch({ type: 'SET_USER_ENTITIES', payload: data.entities });

    dispatch({ type: 'SET_DICTIONARIES', payload: data.classificators });
  }, []);

  const { error, loading } = useQuery(FETCH_INITIAL_DATA, {
    onCompleted: handleCompleted,
    onError: () => {
      if (window?.location?.pathname?.includes('/partnership')) {
        arenzaStorage.remove(AUTH_TOKEN_PARTNER_STORAGE_KEY);
      } else {
        arenzaStorage.remove(AUTH_TOKEN_STORAGE_KEY);
      }

      window.location.reload();
    }
  });

  Sentry.getCurrentScope().setUser({
    id: state?.user?.id || 'User ID not init',
    activeLegalEntityId: state?.activeLegalEntityId || 'Active Legal Entity ID not init',
    typeUser: window?.location?.pathname?.includes('/partnership') ? 'partner' : 'client'
  });

  if (error) {
    return 'Error';
  }

  if (loading) {
    return (
      <LinearProgress sx={{ position: 'fixed', top: 0, left: 0, right: 0 }} />
    );
  }

  if (!state.lists.legalEntities.length) {
    return <BlockAddEntity />;
  }

  if (state.activeLegalEntityId) {
    return (
      <AppDispatchContext.Provider value={dispatch}>
        <AppStateContext.Provider value={state}>
          {children}
        </AppStateContext.Provider>
      </AppDispatchContext.Provider>
    );
  }

  return null;
}