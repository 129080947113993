import React, { useCallback, useContext, useMemo, useState } from "react";

import Tabs from "@components/Tabs";
import { AppStateContext } from "@core/AppContextProvider";
import useActiveLegalEntity from "@hooks/useActiveLegalEntity";
import { Box, Button, Typography } from "@material-ui/core";
import useFetch from "use-http";

import iconCertificatePEP from '../../static/images/certificatePEP.svg';

export default function PageUserProfile(params) {
  const stylesTabs = useMemo(() => ({
    wrapperTabs: {
      borderBottom: '1px solid #E7E8EA',
    },
  }), []);

  const styles = useMemo(() => ({
    titlePage: {
      fontSize: '36px',
      fontWeight: '900',
    },
    wrapperContent: {
      background: '#FFFFFF',
      boxShadow: '0px 10px 32px rgba(13, 29, 50, 0.08)',
      borderRadius: '16px',
      marginTop: '34px',
    },
    content: {
      padding: '32px',
      minHeight: '400px',
    },
    buttonPEP: {
      fontSize: '18px',
      fontWeight: 700,
      color: '#0D1D32',
      textTransform: 'initial',

      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '&:hover': {
        background: 'none',
        textDecoration: 'underline'
      },
    },
  }), []);

  const { user } = useContext(AppStateContext);
  const { activeLegalEntityId } = useActiveLegalEntity();

  const [ hasElectronicSignature ] = useState(user?.hasPersonalElectronicSignature?.includes(activeLegalEntityId) || false);

  const [activeTab, setActiveTab] = useState(0);

  const [showError, setShowError] = useState(false);

  const onChangeTab = event => {
    setActiveTab(event);
  };

  const {
    get: getPersonalElectronicSignature,
    response: responsePersonalElectronicSignature
  } = useFetch();

  const onDownloadingPersonalElectronicSignature = useCallback( async () => {
    await getPersonalElectronicSignature(`me/personal_electronic_signature?entity_id=${activeLegalEntityId}`);

    if (responsePersonalElectronicSignature.ok) {
      if (responsePersonalElectronicSignature?.data?.personal_electronic_signature) {
        const linkSource = `data:application/pdf;base64,${responsePersonalElectronicSignature.data.personal_electronic_signature}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = `Сертификат ПЭП.pdf`;
        downloadLink.click();
      } else {
        setShowError(true)
      }
    } else {
      setShowError(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsePersonalElectronicSignature]);

  return (
    <>
      <Typography sx={ styles.titlePage }>Настройки профиля</Typography>

      <Box sx={ styles.wrapperContent }>
        <Tabs
          defaultActiveTab={activeTab}
          onChange={onChangeTab}
          sx={stylesTabs}
          tabs={[
            'Документы профиля',
          ]}
        />

        <Box sx={ styles.content }>
          {activeTab === 0 && (
            <Box>
              <Button
                disabled={!hasElectronicSignature}
                onClick={onDownloadingPersonalElectronicSignature}
                startIcon={
                  <Box alt="Сертификат ПЭП" component="img" src={iconCertificatePEP} />
                }
                sx={styles.buttonPEP}
              >
                Сертификат ПЭП {!hasElectronicSignature && <Typography sx={{ color: '#0D1D32', marginLeft: '6px' }}>(у вас отсутствует данный сертификат)</Typography>}
              </Button>
              {showError && (
                <Typography sx={{ color: 'red' }}>
                  При загрузке сертификата ПЭП возникла ошибка. Попробуйте позже.
                  {responsePersonalElectronicSignature?.data?.error && (
                    <>
                      {responsePersonalElectronicSignature?.data?.error} - {responsePersonalElectronicSignature?.data?.status}
                    </>
                  )}
                </Typography>
              )}
            </Box>
          )}
        </Box>

      </Box>
    </>
  );
}
