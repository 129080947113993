import PropTypes from 'prop-types';
import React from "react";

import { Box } from "@material-ui/core";
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import dayjs from 'dayjs';

const styles = {
    accordeonElement: {
        padding: '16px 24px',
        backgroundColor: '#FFF',
        borderTop: '1px solid #E7E8EA',
        boxSizing: 'border-box',
        display: 'grid',
        gridTemplateColumns: {
            xs: '1fr',
            lg: '48px 1fr 110px 162px 305px'
        },
        alignItems: 'center',
        gap: '8px'
    },
    icon: {
        color: '#FF780F',
        display: {
            xs: 'none',
            lg: 'block'
        }
    },
    mainData: {
        fontSize: '16px',
        lineHeight: '24px',

        '& span': {
            color: '#FF780F'
        }
    },
    mainDataTitle: {
        fontWeight: '600'
    },
    mainDataNormal: {
        fontWeight: '400'
    },
    mainDataThin: {
        fontWeight: '300',
        color: '#9EA5AD'
    },
    date: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '300',
        color: '#9EA5AD'
    },
    leasing: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '300',
        color: '#9EA5AD'
    }
};

export default function TableShipping({ data }) {
    return (
        <Box sx={styles.accordeonElement}>
            <Box sx={styles.icon}>
                <LocalShippingOutlinedIcon />
            </Box>
            <Box sx={styles.mainData}>
                <Box sx={styles.mainDataTitle}>Отгрузка <span>No{data.number}</span></Box>
                <Box sx={styles.mainDataNormal}>По Заказу №{data.order_number} от {dayjs(data.order_date).format('DD/MM/YYYY')}</Box>
                <Box sx={styles.mainDataThin}>Поставщик {data.supplier_name} ИНН {data.supplier_inn}</Box>
            </Box>
            <Box sx={styles.date}>
                {dayjs(data.supply_date).format('DD/MM/YYYY')}
            </Box>
            <Box sx={styles.leasing}>
                {data.leasing_agreement} от {dayjs(data.leasing_agreement_date).format('DD/MM/YYYY')}
            </Box>
            <Box />
        </Box>
    );
};

TableShipping.propTypes = {
    data: PropTypes.object,
};
