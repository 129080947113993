import React from 'react';

import RouteProtected from '@core/RouteProtected';
import useActiveLegalEntity from '@hooks/useActiveLegalEntity';
import { Box } from '@material-ui/core';
import { Router } from '@reach/router';

import PageAccounting from './components/PageAccounting';
import PageEntityBankStatements from './components/PageEntityBankStatements';
import PageEntityDocs from './components/PageEntityDocs';
import PageEntityQuestionnaire from './components/PageEntityQuestionnaire';
import PageLeasingApplications from './components/PageLeasingApplications';
import PageLeasingDashboard from './components/PageLeasingDashboard';
import PageLeasingDeal from './components/PageLeasingDeal';
import PageLeasingDeals from './components/PageLeasingDeals';
import PageMain from './components/PageMain';
import PageSignature from './components/PageSignature';
import WrapperEntityQuestionnaire from './components/WrapperEntityQuestionnaire';

export default function Routes() {
  const { meta, permissions } = useActiveLegalEntity();

  return (
    <Box component={Router}>
      <PageMain path="/">
        <PageLeasingDashboard path="/" />
        <PageLeasingApplications path="/leasing/applications" />
        <RouteProtected
          component={PageLeasingDeals}
          condition={meta.userConfirmed}
          path="/leasing/deals"
        />
        <RouteProtected
          component={PageLeasingDeal}
          condition={meta.userConfirmed}
          path="/leasing/deals/:dealId"
        />
      </PageMain>
      <RouteProtected
        component={PageSignature}
        condition={true}
        path="/leasing/deals/detail/:dealId"
      />

      <RouteProtected
        component={WrapperEntityQuestionnaire}
        condition={permissions?.canViewQuestionnaire}
        path="/leasing/questionnaire"
      >
        <PageEntityQuestionnaire path="/" />
      </RouteProtected>

      <RouteProtected
        component={PageEntityBankStatements}
        condition={permissions?.canViewBankStatements}
        path="/leasing/bank-statements"
      />
      <RouteProtected
        component={PageEntityDocs}
        condition={permissions?.canViewDocs}
        path="/leasing/docs"
      />
      <RouteProtected
        component={PageAccounting}
        condition={permissions.canViewAccounting}
        path="/leasing/accounting"
      />
    </Box>
  );
}