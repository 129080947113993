import PropTypes from 'prop-types';
import React from 'react';

import WidgetBalance from '@components/WidgetBalance';
import WidgetClientManager from '@components/WidgetClientManager';
import WidgetDocumentsForSigning from '@components/WidgetDocumentsForSigning';
import AccessControl from '@core/AccessControl';
import { Box } from '@material-ui/core';
import { useLocation } from "@reach/router";

PageMain.propTypes = {
  children: PropTypes.node,
};
export default function PageMain({ children }) {
  const { pathname } = useLocation();

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Box
        sx={{
          flexShrink: 0,
          display: { xs: 'none', md: 'block' },
          width: 240,
          mr: { xs: 0, md: 6, lg: 8 },
        }}
      >
        <AccessControl>
          <WidgetBalance />
        </AccessControl>
        <WidgetClientManager />
        {
          // TODO: ниже Sign
        }
        {pathname !== '/' && (
          false && <WidgetDocumentsForSigning />
        )}
      </Box>
      <Box sx={{ flex: 1, maxWidth: 1 }}>
        {children}
      </Box>
    </Box>
  );
}
