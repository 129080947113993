import React, { useEffect, useState } from "react";

import { Alert, AlertTitle, Container, Link, Typography } from "@material-ui/core";
import { FooterContainer } from "@product-site-frontend/gatsby-theme-blocks";
import { Layout } from "@product-site-frontend/landing";
import * as Sentry from "@sentry/react";
import { navigate } from "gatsby";
import useFetch from "use-http";

import { arenzaStorage, AUTH_TOKEN_STORAGE_KEY, USER_PHONE_STORAGE_KEY } from "../../core/storage";
import FormOtp from "./FormOtp";
import FormPhone from "./FormPhone";

export default function PageLogin() {
  return (
    <Layout>
      <Container maxWidth="md" sx={{ mt: 8, mb: "240px", textAlign: "center" }}>
        <Typography sx={{ mb: 3, fontWeight: 900, fontSize: "28px !important" }} variant="h1">
          Вход в личный кабинет Arenza
        </Typography>
        <Typography sx={{ fontWeight: 500, fontSize: 14, color: "#7b7b7b", mb: 3 }}>
          Личный кабинет для просмотра информации по заявкам и сделкам, для загрузки необходимых документов.<br />
          Введите свой мобильный номер телефона, который вы указывали в заявке, в виджете или указывали менеджеру по телефону
        </Typography>

        <Login />
      </Container>
      <FooterContainer />
    </Layout>
  );
}

function Login() {
  const params = new URLSearchParams(window.location.search);
  const [mode, setMode] = useState("phone");
  const [yandexCaptchaToken, setYandexCaptchaToken] = useState('');
  const [phone, setPhone] = useState(
    params.get("phone") ||
    arenzaStorage(USER_PHONE_STORAGE_KEY) ||
    (process.env.GATSBY_ACTIVE_ENV !== "prod" && "+79626247776") ||
    "",
  );

  const { error, loading, post, response } = useFetch(`/login`, {
    cachePolicy: "no-cache",
    retries: 3,
    retryDelay: ({ attempt }) => Math.min(attempt > 0 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
    async retryOn({ attempt, error, response }) {
      // retry on any network error, or 5xx status codes
      if (error || response?.status >= 500) {
        if (attempt > 0) {
          arenzaStorage.remove(AUTH_TOKEN_STORAGE_KEY);
        }
        return true;
      }
    },
  });

  useEffect(() => {
    if (arenzaStorage(AUTH_TOKEN_STORAGE_KEY)) {
      navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    arenzaStorage(USER_PHONE_STORAGE_KEY, phone);
  }, [phone]);

  async function handlePhoneSubmit(data) {
    setPhone(data.phone);
    setMode("otp");
  }

  const [loadingOtpSubmit, setLoadingOtpSubmit] = useState(false);

  async function handleOtpSubmit(data) {
    setLoadingOtpSubmit(true);;

    const result = await post("/submit_otp", { ...data, phone });

    if (response.ok && result?.token) {
      arenzaStorage(AUTH_TOKEN_STORAGE_KEY, result.token);
      window.location.reload(); // TODO: нужно обновить чтобы сбросить memoized значение токена в packages/account/src/pages/index.jsx. Подумать, как это лучше сделать
      // navigate("/");
      setLoadingOtpSubmit(false);
    } else if (response.status === 422 || response.status === 429) {
      setLoadingOtpSubmit(false);

      return response;
    } else {
        Sentry.captureMessage("Ошибка при submit otp")
        setLoadingOtpSubmit(false);
    }
  }

  if (mode === "otp") {
    return (
      <>
        <Typography color="text.secondary" sx={{ mb: 6 }} variant="subtitle1">
          Мы отправили смс с кодом на номер{" "}
          <Typography
            color="text.primary"
            component="span"
            fontWeight={500}
            noWrap
            variant="inherit"
          >
            {phone}
          </Typography>
        </Typography>

        <FormOtp
          loadingOtpSubmit={loadingOtpSubmit}
          onSubmit={handleOtpSubmit}
          phone={phone}
          yandexCaptchaToken={yandexCaptchaToken}
        />

        <Link
          color="#1871e4"
          component="button"
          onClick={() => {
            setMode("phone");
          }}
          type="button"
          underline="none"
          variant="body2"
        >
          Вернуться
        </Link>
      </>
    );
  }

  return (
    <>
      <FormPhone
        loading={loading}
        onSubmit={handlePhoneSubmit}
        phone={phone}
        setYandexCaptchaToken={setYandexCaptchaToken}
        yandexCaptchaToken={yandexCaptchaToken}
      />

      {error && (
        <Alert
          severity="error"
          sx={{
            mt: 3,
            maxWidth: theme => theme.breakpoints.values.sm,
            mx: "auto",
            textAlign: "left",
          }}
        >
          <AlertTitle>Произошла ошибка</AlertTitle>
          Мы уже знаем об этом и скоро исправим. Попробуйте войти позднее.
        </Alert>
      )}
    </>
  );
}
