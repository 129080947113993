import React, { useMemo, useState } from "react";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
} from "@material-ui/core";

import FooterInf from "../FooterInf";
import download from "./img/download.svg";
import Logos from "./tabs/Logos";
import Widgets from "./tabs/Widgets";

export default function PageMarketing() {
  const styles = useMemo(() => ({
    titlePage: {
      fontSize: { xs: '24px', sm: '32px', md: '36px', lg: '36px' },
      fontWeight: 900,
    },
    tabs: {
      display: 'inline-flex',
      marginTop: '30px',
      marginBottom: '43px',
      borderRadius: '8px',

      '& button': {
        position: 'relative',
        zIndex: 5,
        borderRight: '1px solid #E7E8EA',
        fontSize: {
          xs: '14px',
          sm: '14px',
          md: '18px',
          lg: '18px',
        },
        fontWeight: 500,
        color: '#6E7884',
        textTransform: 'inherit',
        borderTop: '1px solid #CFD2D6',
        borderBottom: '1px solid #CFD2D6',
        padding: {
          xs: '8px 10px',
          sm: '12px 16px',
          md: '12px 16px',
          lg: '12px 16px',
        },
        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

        '& .develop': {
          fontSize: '12px',
          fontWeight: 700,
          color: '#ffffff',
          background: '#67BF3D',
          borderRadius: '6px',
          padding: '0 4px'
        },

        '&:first-child': {
          borderLeft: '1px solid #CFD2D6',
          borderRadius: '8px 0px 0px 8px',
        },

        '&:last-child': {
          borderRight: '1px solid #CFD2D6',
          borderRadius: '0px 8px 8px 0px'
        }
      },
      '& button.Mui-selected': {
        color: '#fff',
        borderColor: '#FF780F',
      },
      '& .MuiTabs-indicator': {
        height: '100%',
      },
      '& .MuiTabs-scroller': {
        overflow: 'auto !important',
      }
    },
    whiteButton: {
      background: '#ffffff',
      border: '1px solid #FFBB86',
      borderRadius: '8px',
      color: '#FF7810',
      fontSize: '16px',
      padding: '12px 20px',
      maxHeight: '48px',
      marginTop: '30px',
      textTransform: 'inherit',
      fontWeight: '500',
      marginBottom: '30px',

      '&:hover': {
        background: '#FFF5EC',
        color: '#FF7810',
        border: '1px solid #FFA157',
      },
      '&:active': {
        background: '#FFEEE1',
        color: '#D8670D',
        border: '1px solid #D8670D',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      }
    },
  }),[])

  const [activeTab, setActiveTab] = useState(0);
  const handleChangeTabs = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Box sx={{ background: '#fff' }}>
        <Box>
          <Box sx={styles.titlePage}>
            Маркетинг
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', }}>
            <Tabs onChange={handleChangeTabs} sx={styles.tabs} value={activeTab}>
              <Tab label="Виджет" />
              <Tab disabled label={<Box>Лендинг <Typography className={"develop"} component={"span"}>Скоро!</Typography> </Box>} />
              <Tab disabled label={<Box>Email шаблоны <Typography className={"develop"} component={"span"}>Скоро!</Typography> </Box>} />
              <Tab disabled label={<Box>Баннеры <Typography className={"develop"} component={"span"}>Скоро!</Typography> </Box>} />
              <Tab label="Логотип" />
            </Tabs>

            {activeTab === 4 && (
              <Button
                component="a"
                download={true}
                href={`${process.env.GATSBY_DOCUMENTS_PUBLIC_URL}/marketing_ploy/Arenza_Guideline.pdf`}
                sx={ styles.whiteButton }
                target="_blank"
                variant="contained"
              >
                Скачать брендбук
                <Box component={"img"} src={download} sx={{ marginLeft: '8px' }} />
              </Button>
            )}
          </Box>

          {activeTab === 0 && (
            <Widgets />
          )}

          {activeTab === 4 && (
            <Logos />
          )}
        </Box>

        <FooterInf />
      </Box>
    </>
  );
}