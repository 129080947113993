import React, { useMemo } from "react";

import {
  Box,
  Link,
  Typography,
} from "@material-ui/core";

import arenza_logo_orange from "../img/arenza_logo_orange.svg";
import arenza_logo_orange_descr_bottom from "../img/arenza_logo_orange_descr_bottom.svg";
import arenza_logo_orange_descr_right from "../img/arenza_logo_orange_descr_right.svg";
import arenza_logo_white from "../img/arenza_logo_white.svg";
import arenza_logo_white_descr_bottom from "../img/arenza_logo_white_descr_bottom.svg";
import arenza_logo_white_descr_right from "../img/arenza_logo_white_descr_right.svg";
import arenza_single_character_orange from "../img/arenza_single_character_orange.svg";
import arenza_single_character_white from "../img/arenza_single_character_white.svg";
import group_space_1 from "../img/group_space_1.svg";
import group_space_2 from "../img/group_space_2.svg";
import group_space_3 from "../img/group_space_3.svg";
import group_space_4 from "../img/group_space_4.svg";


export default function Logos() {
  const styles = useMemo(() => ({
    logoBlockWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '24px',
      marginTop: '13px',
    },
    logoBlock: {
      flex: '1 0 21%',
      minHeight: '335px',
      minWidth: '290px',
      maxWidth: '311px',

      '& .title': {
        color: '#0D1D32',
        fontSize: '18px',
        fontWeight: 700,
        marginTop: '16px',
      },

      '& .description': {
        color: '#3E4B5B',
        fontSize: '16px',
        fontWeight: 300,
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    imgWrapper: {
      maxWidth: '311px',
      maxHeight: '158px',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '16px',
      background: 'red',
    },
    subTitle: {
      fontSize: '30px',
      fontWeight: 900,
      marginTop: '56px',
      marginBottom: '56px',
    },
    halfBlock: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginBottom: '56px',
      gap: '24px',

      '& > div' : {
        flex: '0 49%',
      }
    },
    colorSlurpWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginBottom: '56px',
      gap: '24px',
    },
    colorSlurp: {
      flex: '1 0 17%',
      minWidth: '200px',
      maxWidth: '260px',
      paddingBottom: '16px',

      '& .color': {
        height: '88px',
        width: '88px',
        borderRadius: '100px',
        marginBottom: '24px',
      },
      '& .color_title': {
        fontSize: '16px',
        fontWeight: 700,
        marginBottom: '24px',
      },
      '& .color_text': {
        fontSize: '16px',
        fontWeight: 300,
      }
    },
  }),[])

  return (
    <>
      <Box sx={styles.logoBlockWrapper}>
        <Box sx={styles.logoBlock}>
          <Box sx={{ ...styles.imgWrapper, background: '#F5F6F7', }}>
            <Box alt={"Дескриптор под логотипом, на светлом фоне"} component={"img"} src={arenza_logo_orange_descr_bottom} />
          </Box>

          <Typography className={'title'}>Дескриптор под логотипом, на светлом фоне</Typography>
          <Typography className={'description'}>Используется на сайтах, в соц. сетях, брошюрах и т.д.</Typography>

          <Link download href={`${process.env.GATSBY_DOCUMENTS_PUBLIC_URL}/marketing_ploy/arenza_logo_orange_descriptor_bottom.zip`} rel="noreferrer">Скачать</Link>
        </Box>

        <Box sx={ styles.logoBlock }>
          <Box sx={{ ...styles.imgWrapper, background: '#FF780F', }}>
            <Box alt={"Дескриптор под логотипом на темном или цветном фоне"} component={"img"} src={arenza_logo_white_descr_bottom} />
          </Box>

          <Typography className={'title'}>Дескриптор под логотипом на темном или цветном фоне</Typography>
          <Typography className={'description'}>Используется на сайтах, в соц. сетях, брошюрах и т.д.</Typography>

          <Link download href={`${process.env.GATSBY_DOCUMENTS_PUBLIC_URL}/marketing_ploy/arenza_logo_white_descriptor_bottom.zip`} rel="noreferrer">Скачать</Link>
        </Box>

        <Box sx={ styles.logoBlock }>
          <Box sx={{ ...styles.imgWrapper, background: '#F5F6F7', }}>
            <Box alr={"Лого с дескриптором справа на прозрачном фоне"} component={"img"} src={arenza_logo_orange_descr_right} />
          </Box>

          <Typography className={'title'}>Лого с дескриптором справа на прозрачном фоне</Typography>
          <Typography className={'description'}>Используется на сайтах, в соц. сетях, брошюрах и т.д.</Typography>

          <Link download href={`${process.env.GATSBY_DOCUMENTS_PUBLIC_URL}/marketing_ploy/arenza_logo_orange_descriptor_right.zip`} rel="noreferrer">Скачать</Link>
        </Box>

        <Box sx={ styles.logoBlock }>
          <Box sx={{ ...styles.imgWrapper, background: '#FF780F', }}>
            <Box alt={"Лого с дескриптором справа на темном или цветном фоне"} component={"img"} src={arenza_logo_white_descr_right} />
          </Box>

          <Typography className={'title'}>Лого с дескриптором справа на темном или цветном фоне</Typography>
          <Typography className={'description'}>Используется на сайтах, в соц. сетях, брошюрах и т.д.</Typography>

          <Link download href={`${process.env.GATSBY_DOCUMENTS_PUBLIC_URL}/marketing_ploy/arenza_logo_white_descriptor_right.zip`} rel="noreferrer">Скачать</Link>
        </Box>

        <Box sx={ styles.logoBlock }>
          <Box sx={{ ...styles.imgWrapper, background: '#F5F6F7', }}>
            <Box alt={"Оранжевый лого, на светлом фоне без дескриптора"} component={"img"} src={arenza_logo_orange} />
          </Box>

          <Typography className={'title'}>Оранжевый лого, на светлом фоне без дескриптора</Typography>
          <Typography className={'description'}>Используется для корпоративной атрибутики</Typography>

          <Link download href={`${process.env.GATSBY_DOCUMENTS_PUBLIC_URL}/marketing_ploy/arenza_logo_orange.zip`} rel="noreferrer">Скачать</Link>
        </Box>

        <Box sx={ styles.logoBlock }>
          <Box sx={{ ...styles.imgWrapper, background: '#FF780F', }}>
            <Box alt={"Белый лого, на темном или цветном фоне без дескриптора"} component={"img"} src={arenza_logo_white} />
          </Box>

          <Typography className={'title'}>Белый лого, на темном или цветном фоне без дескриптора</Typography>
          <Typography className={'description'}>Используется для корпоративной атрибутики</Typography>

          <Link download href={`${process.env.GATSBY_DOCUMENTS_PUBLIC_URL}/marketing_ploy/arenza_logo_white.zip`} rel="noreferrer">Скачать</Link>
        </Box>

        <Box sx={ styles.logoBlock }>
          <Box sx={{ ...styles.imgWrapper, background: '#F5F6F7', }}>
            <Box alt={"Одиночный символ на светлом фоне"} component={"img"} src={arenza_single_character_orange} />
          </Box>

          <Typography className={'title'}>Одиночный символ на светлом фоне</Typography>
          <Typography className={'description'}>Используется на аватарках, fav icon, в айдентике</Typography>

          <Link download href={`${process.env.GATSBY_DOCUMENTS_PUBLIC_URL}/marketing_ploy/arenza_single_character_orange.zip`} rel="noreferrer">Скачать</Link>
        </Box>

        <Box sx={ styles.logoBlock }>
          <Box sx={{ ...styles.imgWrapper, background: '#FF780F', }}>
            <Box alt={"Одиночный символ на темном или цветном фоне"} component={"img"} src={arenza_single_character_white} />
          </Box>

          <Typography className={'title'}>Одиночный символ на темном или цветном фоне</Typography>
          <Typography className={'description'}>Используется на аватарках, fav icon, в айдентике</Typography>

          <Link download href={`${process.env.GATSBY_DOCUMENTS_PUBLIC_URL}/marketing_ploy/arenza_single_character_white.zip`} rel="noreferrer">Скачать</Link>
        </Box>
      </Box>

      <Box>
        <Typography sx={styles.subTitle}>Охранное поле логотипа</Typography>

        <Box sx={styles.halfBlock}>
          <Box>
            <Typography sx={{ fontSize: '16px', fontWeight: 300, lineHeight: '24px' }}>Вокруг логотипа необходимо оставлять охранное поле («воздух») и следить за тем, чтобы в это пространство не попадали посторонние элементы. Для расчета комфортного охранного поля мы оперируем диаметром круга, в который вписан знак.</Typography>
          </Box>

          <Box>
          </Box>
        </Box>

        <Box sx={styles.halfBlock}>
          <Box>
            <Box component={"img"} src={group_space_2} />
          </Box>

          <Box>
            <Box component={"img"} src={group_space_1} />
          </Box>
        </Box>

        <Box sx={styles.halfBlock}>
          <Box>
            <Box component={"img"} src={group_space_3} />
          </Box>

          <Box>
            <Box component={"img"} src={group_space_4} />
          </Box>
        </Box>
      </Box>

      <Box>
        <Typography sx={styles.subTitle}>Корпоративная палитра</Typography>

        <Box sx={styles.colorSlurpWrapper}>
          <Box sx={styles.colorSlurp}>
            <Box className={'color'} sx={{ background: '#FF780F', boxShadow: '0px 10px 24px rgba(255, 120, 15, 0.3)' }}></Box>

            <Typography className={'color_title'} component={"p"}>Оранжевый</Typography>

            <Box className={'color_text'}>
              HEX - #FF780F <br />
              RGB - 255 / 120 / 15 <br />
              CSS - rgba(255, 120, 15, 1) <br />
              HSL - 26 / 100 / 53 <br />
              HSB - 26 / 94 / 100 <br />
              PANTONE 12-1-7 C (GoeCoated) <br />
              CMYK: 0/68/100/0 <br />
            </Box>
          </Box>

          <Box sx={styles.colorSlurp}>
            <Box className={'color'} sx={{ background: '#0D1D32', boxShadow: '0px 10px 24px rgba(13, 29, 50, 0.28)' }}></Box>

            <Typography className={'color_title'} component={"p"}>Темно-синий</Typography>

            <Box className={'color_text'}>
              HEX - #0D1D32 <br />
              RGB - 13 / 29 / 50 <br />
              CSS - rgba(13, 29, 50, 1) <br />
              HSL - 214 / 59 / 12 <br />
              HSB - 205 / 87 / 100 <br />
              PANTONE 70-5-7 C (GoeCoated) <br />
              CMYK: 91/80/51/63 <br />
            </Box>
          </Box>

          <Box sx={styles.colorSlurp}>
            <Box className={'color'} sx={{ background: '#21A2FF', boxShadow: '0px 10px 24px rgba(33, 162, 255, 0.3)' }}></Box>

            <Typography className={'color_title'} component={"p"}>Голубой</Typography>

            <Box className={'color_text'}>
              HEX - 21A2FF <br />
              RGB - 33 / 162 / 255 <br />
              CSS - rgba(33, 162, 255, 1) <br />
              HSL - 205 / 100 / 56 <br />
              HSB - 205 / 87 / 100 <br />
              PANTONE 78-1-1 C (GoeCoated) <br />
              CMYK: 66/29/0/0 <br />
            </Box>
          </Box>

          <Box sx={styles.colorSlurp}>
            <Box className={'color'} sx={{ background: '#6E7884', boxShadow: '0px 10px 24px rgba(13, 29, 50, 0.28)' }}></Box>

            <Typography className={'color_title'} component={"p"}>Серый</Typography>

            <Box className={'color_text'}>
              HEX - 6E7884 <br />
              RGB - 110 / 120 / 132 <br />
              CSS - rgba(110, 120, 132, 1) <br />
              HSL - 213 / 9 / 47 <br />
              HSB - 213 / 17 / 52 <br />
              PANTONE 82-3-4 C (GoeCoated) <br />
              CMYK: 60/47/38/8 <br />
            </Box>
          </Box>

          <Box sx={styles.colorSlurp}>
            <Box className={'color'} sx={{ background: '#FFFFFF', boxShadow: '0px 10px 32px rgba(13, 29, 50, 0.1)' }}></Box>

            <Typography className={'color_title'} component={"p"}>Белый</Typography>

            <Box className={'color_text'}>
              HEX - FFFFFF <br />
              RGB - 255 / 255 / 255 <br />
              CSS - rgba(255, 255, 255, 1) <br />
              HSL - 0 / 0 / 100 <br />
              HSB - 0 / 0 / 100 <br />
              PANTONE 653 C <br />
              CMYK: 1/0/0/1 <br />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}