import PropTypes from "prop-types";
import React, {useMemo} from 'react';

import {Box} from "@material-ui/core";
import { ArenzaHeadLogo } from "@product-site-frontend/shared";

AvatarUser.propTypes = {
  avatar: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.number,
};

AvatarUser.defaultProps = {
  avatar: null,
  firstName: "",
  lastName: "",
  size: 40
}

export default function AvatarUser({ avatar, firstName, lastName, size }) {
  const styles = useMemo(() => ({
    avatar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: size,
      height: size,
      flex: 'none',
      borderRadius: '50%',
      backgroundColor: '#e9e7e7',
      color: 'white',
      fontSize: 18,
      fontWeight: 500,
      textTransform: 'uppercase',
      textShadow: '1px 1px 3px #1b2835',
      overflow: 'hidden',
    },
    wrapperImg: {
      width: 50,
      height: 50,

      '& > img': {
        pointerEvents: 'none',
      }
    }
  }), [])

  return (
    <Box sx={styles.avatar}>
      {avatar && avatar !== null ? (
        <Box sx={styles.wrapperImg}>
          <img alt={`${lastName} ${firstName}`} src={avatar} style={{ width: "auto", height: "100%" }} />
        </Box>
      ) : (
        <ArenzaHeadLogo sx={{ height: "79px", width: "80px" }} />
      )}
    </Box>
  )
}