import React from 'react';

import BlockDocTypeNumberDate from '@components/BlockDocTypeNumberDate';
import FormattedCurrency from '@components/FormattedCurrency';
import { formatDate } from '@core/helpers';
import useLeasingDealsFetch from '@hooks/useLeasingDealsFetch';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Skeleton,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink } from 'gatsby';
import _upperFirst from 'lodash.upperfirst';

/* TODO: сделать один компонент Card packages/account/src/components/PageLeasingDashboard/WidgetLeasingDeals.jsx */

export default function CardLeasingDeals() {
  const { deals, loading } = useLeasingDealsFetch();

  if (loading) {
    return [...Array(3)].map((e, index) => <SkeletonCard key={index} />);
  }

  return deals.map(elem => (
    <Box key={elem.id} sx={{ my: 4 }}>
      <Card variant="outlined">
        <CardContent>
          <BlockDocTypeNumberDate
            date={formatDate(elem.date, "full")}
            docType="Договор"
            number={elem.number}
          />
          <Typography sx={{ my: 2 }} variant="h4">
            {_upperFirst(elem.subject)}
          </Typography>
          <Box sx={{ maxWidth: 512 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography color="text.secondary" variant="body2">
                Срок лизинга
              </Typography>
              <Typography>{elem.term} мес</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Typography color="text.secondary" variant="body2">
                Стоимость предмета лизинга&nbsp;
              </Typography>
              <Typography sx={{ fontWeight: 500, whiteSpace: "nowrap" }}>
                <FormattedCurrency hideCurrency value={elem.requestedAmount} />
                &nbsp;руб
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          <Button component={RouterLink} to={`${elem.id}`}>
            Подробнее
          </Button>
        </CardActions>
      </Card>
    </Box>
  ));
}

// Авансовый платеж (с НДС)
// Размер ежемесячного платежа (с НДС)
// Размер комиссии
// Сумма лизингового договора платежа (С НДС)
function SkeletonCard() {
  return (
    <Box sx={{ my: 4 }}>
      <Card variant="outlined">
        <CardContent>
          <Skeleton sx={{ maxWidth: 240 }} />
          <Skeleton height={28} />
          <Skeleton sx={{ maxWidth: 512 }} />
          <Skeleton sx={{ maxWidth: 512 }} />
        </CardContent>
        <CardActions>
          <Skeleton height={36} sx={{ maxWidth: 104, mx: 2, width: 1 }} />
        </CardActions>
      </Card>
    </Box>
  );
}
